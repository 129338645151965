export class Battalion {

	constructor() 
	constructor(jsonData: Battalion)
	constructor(jsonData?: Battalion) {
		if(jsonData) {
			Object.assign(this, jsonData);
    }
    else {
      this.id = 0;
    }
	}

  id: number | undefined;
  brigadeId: number | undefined;
  brigadeCode: string | undefined;
  battalionSeqNbr: number | undefined;
  battalionName: string | undefined;
  //xSeqNbr: string | undefined;
  //xT_ID: string | undefined;

}


