import { DatePipe } from '@angular/common';
import { Component, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from "@angular/flex-layout";
import { FormControl, FormGroup } from '@angular/forms';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataStateChangeEvent, GridComponent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { aggregateBy, AggregateDescriptor, AggregateResult, process, State } from '@progress/kendo-data-query';
import { of, Subject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AuthenticationService } from '../../auth/auth.service';
import { Filter } from '../../_bases/filter';
import { ReportService } from '../report.service';
import { ReportAnnualPaymentsReceived } from './report-annual-payments-received';

@Component({
  selector: 'app-annual-payments-received',
  templateUrl: './report-annual-payments-received.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [`
  .main-ctr
  {
    overflow-y: scroll;
    max-height: 90vh;
  }
`],
})
export class ReportAnnualPaymentsReceivedComponent implements OnInit {

  @ViewChild(GridComponent)
  public grid!: GridComponent;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  filters: Filter[] = [];

  list: ReportAnnualPaymentsReceived[] = [];
  protected ngUnsubscribe: Subject<boolean> = new Subject();

  public state: State = {
    skip: 0,
    take: 1000,
    filter: {
      logic: "and",
      filters: [],
    },
  };

  public gridData: GridDataResult = process(this.list, this.state);
  public aggregates: AggregateDescriptor[] = [
    { field: "depositAmt", aggregate: "sum" },
  ];

  public total: AggregateResult = aggregateBy(
    this.gridData.data,
    this.aggregates
  );

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.list, this.state);
    this.total = aggregateBy(this.gridData.data, this.aggregates);
  }

  constructor(
    private service: ReportService,
    public media: MediaObserver,
    public authService: AuthenticationService,
    private ngZone: NgZone,
    public datePipe: DatePipe,
  ) {
    this.allData = this.allData.bind(this);
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.list, {}).data,
    };

    return result;
  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {

    this.filters = [];
    this.filters.push(new Filter("startDate", this.datePipe.transform(this.range.get('start')?.value, 'yyyy-MM-dd'), 'eq'));
    this.filters.push(new Filter("endDate", this.datePipe.transform(this.range.get('end')?.value, 'yyyy-MM-dd'), 'eq'));

    this.service
      .getByCustomAction$<ReportAnnualPaymentsReceived>("getAnnualPaymentsReceived", ReportAnnualPaymentsReceived, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {

        console.log(data);

        this.list = data;

        this.gridData = process(this.list, this.state);
        this.total = aggregateBy(this.gridData.data, this.aggregates);
      });
  }

  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }


  private fitColumns(): void {
    this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
      this.grid.autoFitColumns();
    });
  }

  public rowCallback(context: RowClassArgs) {
    return {
    };
  }
}
