export class Unit {

	constructor() 
	constructor(jsonData: Unit)
	constructor(jsonData?: Unit) {
		if(jsonData) {
			Object.assign(this, jsonData);
    }
    else {
      this.id = 0;
    }
	}

  id: number | undefined;
  unitCode: string | undefined;
  uic: string | undefined;
  unitName: string | undefined;
  //nbrMembers: number | undefined;
  //unitStrength: number | undefined;
  //battalionCode: string | undefined;
  battalionId: number | undefined;
  //nbrInsured: number | undefined;
  armyGuard: boolean | undefined;

}


