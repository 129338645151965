
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, Validators } from "@angular/forms"
import { ActivatedRoute, Router, ParamMap } from "@angular/router";

import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { PopupSettings } from '@progress/kendo-angular-dropdowns';

import { BaseItemComponent } from '../../../_bases/base-item-component';
import { Filter } from '../../../_bases/filter';
import { FormValidation } from '../../../shared/forms/form-validation';
import { VolunteerNetwork } from '../volunteernetwork';
import { VolunteerNetworkService } from '../volunteernetwork.service';


@Component({
  selector: 'app-volunteernetwork-detail',
  templateUrl: './volunteernetwork-detail.component.html',
})
export class VolunteerNetworkComponent extends BaseItemComponent implements OnInit, OnDestroy {

  model: VolunteerNetwork | any;
  public loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private service: VolunteerNetworkService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router) {
    super();
  }

  ngOnInit() {

    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => this.service.getSingleByCustomAction$<VolunteerNetwork>("getSingle", VolunteerNetwork, [new Filter("id", params.get("id"), "eq")]))
      )
      .subscribe((model: VolunteerNetwork | any) => {

        this.createForm(model);
      });
  }

  ngOnChanges() {
    if (this.model) {
      this.createForm(this.model);
    }
  }

  createForm(model?: VolunteerNetwork): void {
    if (this.theForm) {
      this.model = model;
      this.theForm.reset(this.bindForm());
    } else {
      this.theForm = this.fb.group({
        id: [model?.id, Validators.required],
        name: [model?.name, Validators.required],
        isAvailable: model?.isAvailable,
      });

      this.model = model;
    }
  }

  bindForm(): any {
    return {
      id: this.model.id,
      name: this.model.name,
      isAvailable: this.model.isAvailable,
    };
  }

  onSubmit() {
    if (this.theForm?.valid) {

      this.loading = true;
      this.service.postModelByCustomAction$<VolunteerNetwork>("upsert", this.prepareSave(), VolunteerNetwork)
        .pipe(
          catchError((err, caught) => {
            this.loading = false;
            FormValidation.parseSubmitErrors(err, this.theForm);
            return of(null as VolunteerNetwork | any);
          })
        )
        .subscribe((savedData: any) => {
          this.loading = false;
          if (savedData) {
            this.goBack();
          }
        })
    } else {
      FormValidation.validateAllFormFields(this.theForm);
    }
  }

  prepareSave(): VolunteerNetwork {
    const formModel = this.theForm.value;

    let saveModel: VolunteerNetwork = new VolunteerNetwork({
      id: formModel.id,
      name: formModel.name,
      isAvailable: formModel.isAvailable,
    });
    return saveModel;
  }

  cancel(): void {
    this.goBack();
  }

  goBack() {
    this.location.back();
  }

  undo(): void {
    this.theForm.reset(this.bindForm());
  }

  // Form Control Helpers
  get id(): AbstractControl {
    return this.theForm.get('id');
  }
  get name(): AbstractControl {
    return this.theForm.get('name');
  }
  get isAvailable(): AbstractControl {
    return this.theForm.get('isAvailable');
  }

}
