export class ReportReductionTerminationLetter {

  constructor();
  constructor(jsonData?: ReportReductionTerminationLetter) {
    Object.assign(this, jsonData);
  }

  public id: number | undefined;
  public firstName: string | undefined;
  public lastName: string | undefined;
  public middleName: string | undefined;
  public address1: string | undefined;
  public address2: string | undefined;
  public city: string | undefined;
  public state: string | undefined;
  public zip: string | undefined;
  public primaryPhone: string | undefined;
  public secondaryPhone: string | undefined;
  public unitId: number | undefined
  public email: string | undefined;
  public militaryEmail: string | undefined;
  public effectiveDate: Date | undefined;
  public unitCode: string | undefined;
  public unitName: string | undefined;
  public memberCoverageName: number | undefined;
  public memberPremium: number | undefined;
  public spouseCoverageName: number | undefined;
  public spousePremium: number | undefined;
  public dependentCoverageName: number | undefined;
  public dependentPremium: number | undefined;
  public beneficiaries: any[] | undefined;
  public unitStrength: number | undefined;




  public websiteurl: string | undefined;
  public date: string | undefined;
  public name: string | undefined;
  public address: string | undefined;
  public memberOrSpouse: string | undefined;
  public yourOrTheir: string | undefined;
  public noteInSpouse: string | undefined;
  public effectiveDateNew: string | undefined;
  public memberCoverage: string | undefined;
  public memberCoverageNew: string | undefined;
  public memberAndSpouseCoverageNew: string | undefined;
  public spouseCoverage: string | undefined;
  public spouseCoverageNew: string | undefined;
  public dependentCoverage: string | undefined;
  public dependentCoverageNew: string | undefined;
  public memberOrSpouse1: string | undefined;
  public wholeLifeAnnualPremium: string | undefined;
  public wholeLifeMonthlyPremium: string | undefined;
  public dateEffectiveMinusOne: string | undefined;
  public wholeLifeSpouseAnnualPremium: string | undefined;
  public wholeLifeSpouseMonthlyPremium: string | undefined;
}
