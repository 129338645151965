export class ReportMembership {

  constructor();
  constructor(jsonData?: ReportMembership) {
    Object.assign(this, jsonData);
  }

  public id: number | undefined;
  public unitName: string | undefined;
  public battalionName: string | undefined;
  public brigadeName: string | undefined;
  public unitStrength: number | undefined;
  public insuredMembers: number | undefined;
  public free1000Coverage: number | undefined;
  public insuredPercentage: number | undefined;
}
