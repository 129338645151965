import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MsalInterceptor } from '@azure/msal-angular';
import { NgxMaskModule } from 'ngx-mask';
import { TokenInterceptor } from '../auth/auth.interceptor';
import { AlertModalComponent } from '../shared/alert/alert-modal.component';
import { ConfirmModalComponent } from '../shared/confirm-modal/confirm-modal.component';
import { KendoControlsModule } from '../_modules/kendo-controls.module';
import { MaterialControlsModule } from '../_modules/material-controls.module';
import { BankAccountTypeComponent } from './bankaccounttypes/bankaccounttype-detail/bankaccounttype-detail.component';
import { BankAccountTypeListComponent } from './bankaccounttypes/bankaccounttype-list/bankaccounttype-list.component';
import { BankComponent } from './banks/bank-detail/bank-detail.component';
import { BankListComponent } from './banks/bank-list/bank-list.component';
import { BattalionComponent } from './battalions/battalion-detail/battalion-detail.component';
import { BattalionListComponent } from './battalions/battalion-list/battalion-list.component';
import { BrigadeComponent } from './brigades/brigade-detail/brigade-detail.component';
import { BrigadeListComponent } from './brigades/brigade-list/brigade-list.component';
import { CoverageComponent } from './coverages/coverage-detail/coverage-detail.component';
import { CoverageListComponent } from './coverages/coverage-list/coverage-list.component';
import { CoverageTypeComponent } from './coveragetypes/coveragetype-detail/coveragetype-detail.component';
import { CoverageTypeListComponent } from './coveragetypes/coveragetype-list/coveragetype-list.component';
import { FeaturesRoutingModule } from './features-routing.module';
import { GroupComponent } from './groups/group-detail/group-detail.component';
import { GroupListComponent } from './groups/group-list/group-list.component';
import { HangfireComponent } from './hangfire/hangfire.component';
import { ImportAirAgrComponent } from './jumps/import-air-agr/import-air-agr.component';
import { ImportAirJumpsComponent } from './jumps/import-air-jumps/import-air-jumps.component';
import { ImportAllJumpsComponent } from './jumps/import-all/import-all.component';
import { ImportArmyAgrComponent } from './jumps/import-army-agr/import-army-agr.component';
import { ImportArmyJumpsComponent } from './jumps/import-army-jumps/import-army-jumps.component';
import { ImportRetiredPayComponent } from './jumps/import-retired-pay/import-retired-pay.component';
import { MissingJumpsListComponent } from './jumps/missing-jumps/missing-jumps-list.component';
import { AlphaRosterComponent } from './navigator/alpha-roster/alpha-roster.component';
import { ImportAirAlphaRosterModalComponent } from './navigator/import-air-alpha-roster-modal/import-air-alpha-roster-modal.component';
import { ImportArmyAlphaRosterModalComponent } from './navigator/import-army-alpha-roster-modal/import-army-alpha-roster-modal.component';
import { NavigatorComponent } from './navigator/navigator.component';
import { PersonComponent } from './navigator/person-detail/person-detail.component';
import { PaymentTypeComponent } from './paymenttypes/paymenttype-detail/paymenttype-detail.component';
import { PaymentTypeListComponent } from './paymenttypes/paymenttype-list/paymenttype-list.component';
import { ApplicantUpdatesApproveOrRejectModalComponent } from './people/applicant-updates-approve-or-reject-modal.component';
import { PersonListComponent } from './people/person-list/person-list.component';
import { PersonAttachmentListComponent } from './personattachments/personattachments-list/personattachment-list.component';
import { PersonChildrenListComponent } from './personchildren/personchildren-list/personchildren-list.component';
import { PersonCommentListComponent } from './personcomments/personcomment-list/personcomment-list.component';
import { PersonInsBeneficiaryListComponent } from './personinsbeneficiaries/personinsbeneficiary-list/personinsbeneficiary-list.component';
import { PersonPaymentInfoListComponent } from './personpaymentinfos/personpaymentinfo-list/personpaymentinfo-list.component';
import { RelationshipComponent } from './relationships/relationship-detail/relationship-detail.component';
import { RelationshipListComponent } from './relationships/relationship-list/relationship-list.component';
import { SettingListComponent } from './settings/setting-list/setting-list.component';
import { UnitComponent } from './units/unit-detail/unit-detail.component';
import { UnitListComponent } from './units/unit-list/unit-list.component';
import { VolunteerNetworkComponent } from './volunteernetwork/volunteernetwork-detail/volunteernetwork-detail.component';
import { VolunteerNetworkListComponent } from './volunteernetwork/volunteernetwork-list/volunteernetwork-list.component';
import { PersonMembershipPaymentListComponent } from './personmembershippayments/personmembershippayment-list/personmembershippayment-list.component';
import { ImportMembershipModalComponent } from './navigator/import-membership-modal/import-membership-modal.component';
import { MembershipDataComponent } from './navigator/membership-data/membership-data.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    KendoControlsModule,
    MaterialControlsModule,
    FeaturesRoutingModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    ImportAllJumpsComponent,
    ImportArmyJumpsComponent,
    ImportAirJumpsComponent,
    ImportArmyAgrComponent,
    ImportAirAgrComponent,
    ImportRetiredPayComponent,
    MissingJumpsListComponent,
    NavigatorComponent,
    HangfireComponent,
    ImportAirAlphaRosterModalComponent,
    ImportArmyAlphaRosterModalComponent,
    ImportMembershipModalComponent,
    AlphaRosterComponent,
    MembershipDataComponent,
    PersonComponent,
    PersonInsBeneficiaryListComponent,
    PersonPaymentInfoListComponent,
    PersonMembershipPaymentListComponent,
    PersonAttachmentListComponent,
    PersonCommentListComponent,
    PersonListComponent,
    BankListComponent,
    BankComponent,
    BankAccountTypeListComponent,
    BankAccountTypeComponent,
    BattalionListComponent,
    BattalionComponent,
    BrigadeListComponent,
    BrigadeComponent,
    CoverageListComponent,
    CoverageComponent,
    CoverageTypeListComponent,
    CoverageTypeComponent,
    GroupListComponent,
    GroupComponent,
    PaymentTypeComponent,
    PaymentTypeListComponent,
    RelationshipComponent,
    RelationshipListComponent,
    UnitListComponent,
    UnitComponent,
    VolunteerNetworkComponent,
    VolunteerNetworkListComponent,
    PersonChildrenListComponent,
    ConfirmModalComponent,
    SettingListComponent,
    AlertModalComponent,
    ApplicantUpdatesApproveOrRejectModalComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  exports: [
    ImportAllJumpsComponent,
    ImportArmyJumpsComponent,
    ImportAirJumpsComponent,
    ImportArmyAgrComponent,
    ImportAirAgrComponent,
    ImportRetiredPayComponent,
    MissingJumpsListComponent,
    NavigatorComponent,
    HangfireComponent,
    ImportAirAlphaRosterModalComponent,
    ImportArmyAlphaRosterModalComponent,
    ImportMembershipModalComponent,
    AlphaRosterComponent,
    MembershipDataComponent,
    PersonComponent,
    PersonInsBeneficiaryListComponent,
    PersonPaymentInfoListComponent,
    PersonMembershipPaymentListComponent,
    PersonAttachmentListComponent,
    PersonCommentListComponent,
    PersonListComponent,
    BankListComponent,
    BankComponent,
    BankAccountTypeListComponent,
    BankAccountTypeComponent,
    BattalionListComponent,
    BattalionComponent,
    BrigadeListComponent,
    BrigadeComponent,
    CoverageListComponent,
    CoverageComponent,
    CoverageTypeListComponent,
    CoverageTypeComponent,
    GroupListComponent,
    GroupComponent,
    PaymentTypeComponent,
    PaymentTypeListComponent,
    RelationshipComponent,
    RelationshipListComponent,
    UnitListComponent,
    UnitComponent,
    VolunteerNetworkComponent,
    VolunteerNetworkListComponent,
    PersonChildrenListComponent,
    ConfirmModalComponent,
    SettingListComponent,
    AlertModalComponent,
    ApplicantUpdatesApproveOrRejectModalComponent,
  ],
})
export class FeaturesModule { }
