<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <button mat-raised-button color="accent" (click)="pdf.saveAs('AnnualStatement.pdf')">
      Save As PDF...
    </button>
  </div>

  <div fxLayout="column" fxLayoutGap="8px" class="m-2">
    <div fxFlex="100" *ngIf="this.list">
      Page {{ index + 1 }} of {{ this.list.length }}

      <button mat-raised-button (click)="moveToPage(0)" [disabled]="index == 0">
        <mat-icon aria-label="Side nav toggle icon">first_page</mat-icon>
      </button>

      <button mat-raised-button (click)="moveNextPrev(-1)" [disabled]="index == 0">
        <mat-icon aria-label="Side nav toggle icon">chevron_left</mat-icon>
      </button>

      <button mat-raised-button (click)="moveNextPrev(1)" [disabled]="index == this.list.length - 1">
        <mat-icon aria-label="Side nav toggle icon">chevron_right</mat-icon>
      </button>

      <button mat-raised-button (click)="moveToPage(this.list.length -1)" [disabled]="index == this.list.length - 1">
        <mat-icon aria-label="Side nav toggle icon">last_page</mat-icon>
      </button>

    </div>
    <div fxLayout="row" fxLayoutGap="8px" class="m-2  main-ctr" #divMain>
      <kendo-pdf-export #pdf paperSize="Letter" margin="0cm" [scale]=".75" forcePageBreak=".page-break" keepTogether=".prevent-split">
        <div class="pageSizeLetter prevent-split" *ngFor="let selectedRow of this.list; let idxMain = index">

          <div class="pageHeadSection">
            <span class="personId">{{ selectedRow.id }}</span>

            <span class="lblAnnualPremium">
              Annual Premium Due for NCNGA Life Insurance Coverage:
            </span>

            <span class="annualPremiumAmt">
              {{ selectedRow.annualPremiumAmount | currency }}
            </span>

            <span class="lblPaymentDue">
              PAYMENT DUE NO LATER THAN:
            </span>

            <span class="paymentDue">
              {{ selectedRow.dueDate | date:'shortDate' }}
            </span>

            <span class="memberInfo">
              {{ selectedRow.firstName }} {{ selectedRow.middleName }} {{ selectedRow.lastName }} <br />
              <span *ngIf="selectedRow.address1"> {{ selectedRow.address1 }} <br /></span>
              <span *ngIf="selectedRow.address2"> {{ selectedRow.address2 }} <br /></span>
              {{ selectedRow.city }} {{ selectedRow.state }} {{ selectedRow.zip }}
            </span>

          </div>
          <div class="pageDetailSection">
            <span class="lblAnnualPremium">
              Annual Premium Due for NCNGA Life Insurance Coverage:
            </span>
            <span class="annualPremiumAmt">
              {{ selectedRow.annualPremiumAmount | currency }}
            </span>

            <span class="lblInsurancefor">
              NCNGA Group Term Life Insurance for:
            </span>

            <span class="memberName">
              {{ selectedRow.firstName }} {{ selectedRow.middleName }} {{ selectedRow.lastName }}
            </span>

            <span class="lblMemberCoverage">
              Member Coverage:
            </span>

            <span class="memberCoverage">
              {{ selectedRow.memberCoverage | currency: 'USD':true:'2.0' }}
            </span>

            <span class="lblSpouseCoverage">
              Spouse Coverage:
            </span>

            <span class="spouseCoverage">
              {{ selectedRow.spouseCoverage | currency: 'USD':true:'2.0' }}
            </span>

            <span class="lblDependentCoverage">
              Dependent Coverage:
            </span>
            <span class="dependentCoverage">
              {{ selectedRow.dependentCoverage | currency: 'USD':true:'2.0' }}
            </span>

            <div class="beneficiaryList">
              <div class="headBeneficiary">
                Member/Beneficiary
              </div>
              <div class="headRelationship">
                Relationship
              </div>
              <div class="headPercentage">
                Percentage
              </div>
              <ng-container *ngIf="selectedRow.beneficiaries.length > 0">
                <div class="beneficiaryrow" [style.top.in]="(i+1) * .25" *ngFor="let itm of selectedRow.beneficiaries; let i = index">
                  <div class="beneficiary">
                    {{ itm.beneficiary }}
                  </div>
                  <div class="relationship">
                    {{ itm.relationship }}
                  </div>
                  <div class="percentage">
                    {{ itm.percentage }}%
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </kendo-pdf-export>
    </div>
  </div>
  <style></style>
</div>
