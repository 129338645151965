<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      Separated/ISF List + Continuation Letters
    </h1>
  </div>
  <div fxFlex="100">
    <form [formGroup]="sepContLetters" (ngSubmit)="getData()" fxFlex="100">

      <!--<label class="k-form-field">
        <input type="checkbox" id="isSendEmail" class="Xk-checkbox" formControlName="isSendEmail"> &nbsp;
        <label class="k-checkbox-label" for="isSendEmail">Send Email as well</label>
      </label>
      &nbsp;-->
      <button kendoButton [primary]="true" icon="check-outline" style="margin-left: 3px;">Go</button>
    </form>

  </div>
  <div fxLayout="row" fxLayoutGap="8px" class="m-2">

    <div class="example-config" *ngIf="this.list && this.list.length > 0">
      <button mat-raised-button color="primary" (click)="saveToPdf();">
        Save As PDF
      </button>
      &nbsp;
      <button mat-raised-button color="primary" (click)="emailLetters();">
        Send Email
      </button>
    </div>
    <div fxLayout="column" fxLayoutGap="8px" class="m-2">

      <kendo-pdf-export #pdfCont paperSize="Letter" [margin]="{top: 0, bottom: 0, right: 0, left: 0}" forcePageBreak=".page-break" [scale]="scale">

        <div class="mainBody" *ngFor="let person of this.list; let i = index">
          <span style="width: 100%; float: left; display: none;" [ngClass]="{'noEmail' : (person.email === null || person.email === undefined || person.email == '')}">&nbsp;</span>
          <span style="width: 100%; float: left; display: none;" class="personEmail">{{ person.email}}</span>
          <style>

            @font-face {
              font-family: "Cambria";
              src: url("{{ person.websiteurl}}/assets/fonts/cambria.ttf") format("truetype");
            }

            @font-face {
              font-family: "CambriaBold";
              font-weight: bold;
              src: url("{{ person.websiteurl}}/assets/fonts/cambria-Bold.ttf") format("truetype");
            }

            @font-face {
              font-family: "Tahoma";
              font-style: italic;
              src: url("{{ person.websiteurl}}/assets/fonts/tahoma.ttf") format("truetype");
            }

            @font-face {
              font-family: "TahomaBold";
              font-weight: bold;
              font-style: italic;
              src: url("{{ person.websiteurl}}/assets/fonts/tahoma-Bold.ttf") format("truetype");
            }

            table {
              border: 1px solid black;
              border-collapse: collapse;
              font-size: 12px;
            }

            tr {
              border: 1px solid black;
              font-size: 12px;
            }

            td {
              border: 1px solid black;
              font-size: 12px;
            }

            .mainBody {
              padding-top: .38in;
              padding-left: .50in;
              padding-right: .50in;
              padding-bottom: .1in;
              Xfont-family: "Cambria";
              font-size: 12px;
              width: 7.8in;
              height: 11in;
              clear: both;
              line-height: 16px;
            }

            .header {
              Xfont-family: "TahomaBold";
              font-size: 12px;
            }

            .sub-header {
              Xfont-family: "Tahoma";
              font-size: 12px;
            }
          </style>

          <div style="float:left; width:50%;  text-align:left;">
            <img src="{{ person.websiteurl}}/assets/logo-for-email.jpg" style="height:.6in" />
          </div>

          <div style="float:left; width:50%; text-align:right;">
            <b class="header">
              North Carolina <br />
              National Guard Association  <br />
            </b>
            <span class="sub-header">
              7410 Chapel Hill Rd <br />
              Raleigh, NC 27607-5047 <br />
            </span>
          </div>

          <div style="float:left; width:100%">
            <br />
            <br />
            <div style="float:left; width:50%;  text-align:left;">
              {{ person.name}}
              <a *ngIf="showHiddenContent" href="/person/{{ person.id}}"> Edit Person</a> &nbsp;
              <a *ngIf="showHiddenContent" href="javascript:void(0);" (click)="removeFromSepGroup(person.id)">Remove from Sep Group</a>
              <br />
            </div>
            <div style="float:left; width:50%;  text-align:right;">
              {{ person.date}} <br /> <br />
            </div>
            {{ person.address}}<br />
            {{ person.city}}, {{ person.state}} {{ person.zip}}<br /><br />
            <p>
              RE: Keep Your Benefits
            </p>

            <p>
              The NC National Guard Association is responsible for administering the State Sponsored Life Insurance (SSLI) benefit.
              This insurance plan is not Servicemember's Group Life Insurance (SGLI). While SGLI converts to VGLI and premiums
              increase, our SSLI benefit can be retained at the same coverage amounts until age 60 and can be changed to whole life
              insurance up to age 65.
            </p>

            <p>
              This letter is to advise you that we are no longer receiving your allotment for NCNGA SSLI- Group Term Life Insurance.
              This could occur due to retirement, separation from the Guard, absence from drill, transfer to the Active Guard Reserve
              (AGR) program, etc.
            </p>
            <p>

              IF YOU ARE STILL A GUARD MEMBER CALL Rhonda at 919.851.3391 ext. 1. If you have separated for ANY reason and
              would like to keep your coverage, Go online to www.ncnga.org/insurance and click "Pay Insurance Premium"
              or mail
              payment to 7410 Chapel Hill Road Raleigh, NC 27607

              no later than {{ person.dateEffectiveMinusOne }}, if you would like to continue this
              worthwhile coverage. To convert this coverage to a whole life plan, contact Rhonda at 919-851-3390 ext. 1.
              This
              unique life insurance coverage through the NCNGA can be a very important part of your financial future. In addition to
              securing your financial future, the administration of this benefit affords the NCNGA to fund programs for Airmen,
              Soldiers, and their Families. By retaining your insurance coverage, you will keep all of your active membership benefits
              including scholarship eligibility for you and your dependents.
            </p>

            <div style="float:left; width:40%;  text-align:left;">
              <b>Coverage in Force:</b>
            </div>
            <div style="float:left; width:1%">
              &nbsp;
            </div>
            <div style="float:left; width:50%;  text-align:center;">
              <b>Choose your payment frequency & payment:</b>
            </div>

            <table width="40%" style="float:left;">
              <tr>
                <td width="50%">
                  Member Coverage
                </td>
                <td width="50%">
                  {{ person.memberCoverage}}
                </td>
              </tr>
              <tr>
                <td>
                  Spouse Coverage
                </td>
                <td>
                  {{ person.spouseCoverage}}
                </td>
              </tr>
              <tr>
                <td>
                  Dependent Coverage
                </td>
                <td>
                  {{ person.dependentCoverage}}
                </td>
              </tr>
            </table>
            <div style="float:left; width:1%">
              &nbsp;
            </div>
            <table width="50%" style="float:left;">
              <tr>
                <td>
                  Type of Payment:
                </td>
                <td>
                  Coverage Dates
                </td>
                <td>
                  Amount Due
                </td>
              </tr>
              <tr>
                <td>
                  Annually
                </td>
                <td>
                  {{ person.coverageDatesAnnually}}
                </td>
                <td>
                  {{ person.annualPremium}}
                </td>
              </tr>
              <tr>
                <td>
                  Monthly Bank Draft
                </td>
                <td>
                  {{ person.coverageDatesMonthly}}
                </td>
                <td>
                  {{ person.monthlyPremium}}
                </td>
              </tr>
            </table>
          </div>

          <p style="float:left; width:100%;">
            <br />
            Recurring ACH Payment Authorization Form enclosed. Payments will be drafted the first business day of each month.
            Once your group term life insurance coverage has ended, it cannot be reinstated.
          </p>

          <p>
            Whether you are completing one term of service or retiring after 20 years, this benefit is available for you to keep. You
            can continue your SSLI coverage after your service. We pay claims within 1 to 2 business days; giving your family peace
            of mind during a difficult time. If you choose not to continue this coverage, your coverage will cancel effective when the
            last allotment was received.
          </p>

          <br />

          <p style="float:left; width:100%;">
            Sincerely,
            &nbsp;<br />
            &nbsp;<br />
          </p>

          <p style="float:left; width:100%;">
            &nbsp;<br />
          </p>

          <p style="float:left; width:100%;">
            Christine Quante<br />
            Executive Director<br />
            North Carolina National Guard Association<br />
            &nbsp;<br />
          </p>

          <!--<p style="float:left; width:100%;">
            &nbsp;<br />
            &nbsp;<br />
          </p>-->

          <p style="float:left; width:100%; text-align: center">
            NCNGA <br />
            7410 Chapel Hill Road <br />
            Raleigh, NC 27607 <br />
          </p>

          <div class="page-breakXXXX" *ngIf="i < this.list.length - 1"></div>
        </div>
      </kendo-pdf-export>
    </div>
  </div>
