import { Component, OnInit, NgZone, ViewChild, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent, DataStateChangeEvent } from '@progress/kendo-angular-grid';

import { PopupSettings } from '@progress/kendo-angular-dropdowns';

import { PersonPaymentInfoService } from '../personpaymentinfo.service';
import { PersonPaymentInfoReadOnly } from '../personpaymentinfo-readonly';
import { filter } from '@progress/kendo-data-query/dist/npm/transducers';
import { Lookup } from '../../../lookups/lookup';
import { LookupService } from '../../../lookups/lookup.service';
import { AuthenticationService } from '../../../auth/auth.service';
import { Filter } from '../../../_bases/filter';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'app-personpaymentinfos-list',
  templateUrl: './personpaymentinfo-list.component.html',
  animations: [
  ]
})
export class PersonPaymentInfoListComponent implements OnInit {

  @ViewChild(GridComponent)
  public grid!: GridComponent;

  list: PersonPaymentInfoReadOnly[] = [];
  @Input() filters: Filter[] = [];
  @Input() newItemProperties: any;
  public loading: boolean = false;

  public formGroup: FormGroup | any;
  private editedRowIndex: number | undefined;
  paymentTypeIdLookups$: Observable<Lookup[]> | any;
  paymentTypesArray: Lookup[] | any;

  public sort: SortDescriptor[] = [
    {
      field: "date",
      dir: "desc",
    },
  ];

  private increment: number = 0;
  createPersonPaymentInfoFormGroup = (dataItem: PersonPaymentInfoReadOnly) =>
    new FormGroup({
      id: new FormControl(dataItem.id),
      personId: new FormControl(dataItem.personId, Validators.required),
      date: new FormControl(dataItem.date, Validators.required),
      premiumAmount: new FormControl(dataItem.premiumAmount, Validators.required),
      amountPaid: new FormControl(dataItem.amountPaid, Validators.required),
      overUnder: new FormControl(dataItem.overUnder, Validators.required),
      monthsCollected: new FormControl(dataItem.monthsCollected, Validators.required),
      monthsInsufficient: new FormControl(dataItem.monthsInsufficient),
      comment: new FormControl(dataItem.comment),
      paymentTypeId: new FormControl(dataItem.paymentTypeId),
      sepCode: new FormControl(dataItem.sepCode),
      //personDepositId: new FormControl(dataItem.personDepositId),
      //rptCategory: new FormControl(dataItem.rptCategory),
      //acct: new FormControl(dataItem.acct),
      //starts: new FormControl(dataItem.starts),
      isNew: new FormControl(dataItem.isNew == true ? true : false),
    });

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: PersonPaymentInfoService,
    private ngZone: NgZone,
    private lookupService: LookupService,
    private authService: AuthenticationService,
  ) {

    this.paymentTypeIdLookups$ = this.lookupService.getList("paymenttypes", []);

    this.paymentTypeIdLookups$.subscribe((s: Lookup[]) => {
      this.paymentTypesArray = s;
    });
  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {
    this.service
      .getByCustomAction$<PersonPaymentInfoReadOnly>("list", PersonPaymentInfoReadOnly, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {
        this.list = data;
      });
  }

  public onDataStateChange(state: DataStateChangeEvent): void {
    this.fitColumns();
  }


  private fitColumns(): void {
    //this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
    //  this.grid.autoFitColumns();
    //});
  }

  public rowCallback(context: RowClassArgs) {
    return {
      // blocked: context.dataItem.isBlocked
    };
  }

  public addPersonPaymentInfoHandler(evt: any) {
    this.closePersonPaymentInfoEditor(evt.sender);

    this.increment += 1;

    this.formGroup = this.createPersonPaymentInfoFormGroup({
      id: this.increment,
      personId: this.filters[0].value,
      date: new Date(),
      premiumAmount: 0,
      amountPaid: 0,
      overUnder: 0,
      monthsCollected: 0,
      monthsInsufficient: 0,
      comment: '',
      paymentTypeId: 0,
      sepCode: '',
      //personDepositId: 0,
      //rptCategory: '',
      //acct: '',
      //starts: '',
      isNew: true,
    });

    evt.sender.addRow(this.formGroup);
  }

  public editPersonPaymentInfoHandler({ sender, rowIndex, dataItem }: { sender: any, rowIndex: any, dataItem: any }) {
    this.closePersonPaymentInfoEditor(sender);
    this.formGroup = this.createPersonPaymentInfoFormGroup(dataItem);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelPersonPaymentInfoHandler({ sender, rowIndex }: { sender: any, rowIndex: any }) {
    this.closePersonPaymentInfoEditor(sender, rowIndex);
  }

  public savePersonPaymentInfoHandler({ sender, rowIndex, formGroup, isNew }: { sender: any, rowIndex: any, formGroup: any, isNew: any }) {

    const personPaymentInfo: PersonPaymentInfoReadOnly = formGroup.value;

    if (isNew) {
      if (this.list == null) {
        this.list = [];
      }
      this.list.unshift(personPaymentInfo);
    }
    else {
      this.list.forEach(o => {

        if (o.id == personPaymentInfo.id) {
          o.date = personPaymentInfo.date;
          o.premiumAmount = personPaymentInfo.premiumAmount;
          o.amountPaid = personPaymentInfo.amountPaid;
          o.overUnder = personPaymentInfo.overUnder;
          o.monthsCollected = personPaymentInfo.monthsCollected;
          o.monthsInsufficient = personPaymentInfo.monthsInsufficient;
          o.comment = personPaymentInfo.comment;
          o.paymentTypeId = personPaymentInfo.paymentTypeId;
          o.sepCode = personPaymentInfo.sepCode;
          //o.personDepositId = personPaymentInfo.personDepositId;
          //o.starts = personPaymentInfo.starts;
          //o.rptCategory = personPaymentInfo.rptCategory;
          //o.acct = personPaymentInfo.acct;
          o.isNew = personPaymentInfo.isNew;
        }
      });
    }

    setTimeout(() => {
      this.authService.eventPersonSubDataEventRaised(true);
    }, 500);
    this.grid.data = this.list;
    sender.closeRow(rowIndex);
  }

  public removePersonPaymentInfoHandler({ dataItem }: { dataItem: any }) {
    let tempList: PersonPaymentInfoReadOnly[] = [];

    this.list.forEach(o => {

      if (o.id != dataItem.id) {
        tempList.push(o);
      }
    });

    setTimeout(() => {
      this.authService.eventPersonSubDataEventRaised(true);
    }, 500);
    this.list = tempList;
    this.grid.data = this.list;
  }

  private closePersonPaymentInfoEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public paymentTypes(value: any): any {

    if (this.paymentTypesArray !== undefined) {
      let val = this.paymentTypesArray.find((f: Lookup) => f.key == value);
      return val;
    }

    return "";
  }
}
