import { Component, OnInit, NgZone, ViewChild, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent, DataStateChangeEvent } from '@progress/kendo-angular-grid';

import { PopupSettings } from '@progress/kendo-angular-dropdowns';

import { PersonMembershipPaymentService } from '../personmembershippayment.service';
import { PersonMembershipPaymentReadOnly } from '../personmembershippayment-readonly';
import { filter } from '@progress/kendo-data-query/dist/npm/transducers';
import { Lookup } from '../../../lookups/lookup';
import { LookupService } from '../../../lookups/lookup.service';
import { AuthenticationService } from '../../../auth/auth.service';
import { Filter } from '../../../_bases/filter';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'app-personmembershippayments-list',
  templateUrl: './personmembershippayment-list.component.html',
  animations: [
  ]
})
export class PersonMembershipPaymentListComponent implements OnInit {

  @ViewChild(GridComponent)
  public grid!: GridComponent;

  list: PersonMembershipPaymentReadOnly[] = [];
  @Input() filters: Filter[] = [];
  @Input() newItemProperties: any;
  public loading: boolean = false;

  public formGroup: FormGroup | any;
  private editedRowIndex: number | undefined;
  paymentTypeIdLookups$: Observable<Lookup[]> | any;
  groupIdLookups$: Observable<Lookup[]> | any;
  paymentTypesArray: Lookup[] | any;
  groupsArray: Lookup[] | any;

  public sort: SortDescriptor[] = [
    {
      field: "date",
      dir: "desc",
    },
  ];

  membershipNumberOrExpirationDateValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const membershipNumber = formGroup.get('membershipNumber')?.value;
    const annualExpirationDate = formGroup.get('annualExpirationDate')?.value;

    if ((membershipNumber && annualExpirationDate) || (!membershipNumber && !annualExpirationDate)) {
      return { membershipNumberOrExpirationDateRequired: true };
    }

    return null;
  };
}

  private increment: number = 0;
  createPersonMembershipPaymentFormGroup = (dataItem: PersonMembershipPaymentReadOnly) =>
    new FormGroup({
      id: new FormControl(dataItem.id),
      personId: new FormControl(dataItem.personId, Validators.required),
      paymentDate: new FormControl(dataItem.paymentDate, Validators.required),
      groupId: new FormControl(dataItem.groupId, Validators.required),
      paymentTypeId: new FormControl(dataItem.paymentTypeId, Validators.required),
      membershipNumber: new FormControl(dataItem.membershipNumber),
      annualExpirationDate: new FormControl(dataItem.annualExpirationDate),
      isNew: new FormControl(dataItem.isNew == true ? true : false),
    }/*, { validators: this.membershipNumberOrExpirationDateValidator() }*/);

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: PersonMembershipPaymentService,
    private ngZone: NgZone,
    private lookupService: LookupService,
    private authService: AuthenticationService,
  ) {

    this.paymentTypeIdLookups$ = this.lookupService.getList("paymenttypes", []);
    this.groupIdLookups$ = this.lookupService.getList("groupsonlymembership", []);

    this.paymentTypeIdLookups$.subscribe((s: Lookup[]) => {
      this.paymentTypesArray = s;
    });

    this.groupIdLookups$.subscribe((s: Lookup[]) => {
      this.groupsArray = s;
    });
  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {
    this.service
      .getByCustomAction$<PersonMembershipPaymentReadOnly>("list", PersonMembershipPaymentReadOnly, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {
        this.list = data;
      });
  }

  public onDataStateChange(state: DataStateChangeEvent): void {
    this.fitColumns();
  }


  private fitColumns(): void {
    //this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
    //  this.grid.autoFitColumns();
    //});
  }

  public rowCallback(context: RowClassArgs) {
    return {
      // blocked: context.dataItem.isBlocked
    };
  }

  public addPersonMembershipPaymentHandler(evt: any) {
    this.closePersonMembershipPaymentEditor(evt.sender);

    this.increment += 1;

    this.formGroup = this.createPersonMembershipPaymentFormGroup({
      id: this.increment,
      personId: this.filters[0].value,
      paymentDate: new Date(),
      groupId: undefined,
      paymentTypeId: undefined,
      membershipNumber: undefined,
      annualExpirationDate: undefined,
      isNew: true,
      isActive: true,
      isToBeExpire: false,
    });

    evt.sender.addRow(this.formGroup);
  }

  public editPersonMembershipPaymentHandler({ sender, rowIndex, dataItem }: { sender: any, rowIndex: any, dataItem: any }) {
    this.closePersonMembershipPaymentEditor(sender);
    this.formGroup = this.createPersonMembershipPaymentFormGroup(dataItem);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelPersonMembershipPaymentHandler({ sender, rowIndex }: { sender: any, rowIndex: any }) {
    this.closePersonMembershipPaymentEditor(sender, rowIndex);
  }

  public savePersonMembershipPaymentHandler({ sender, rowIndex, formGroup, isNew }: { sender: any, rowIndex: any, formGroup: any, isNew: any }) {

    const personPaymentInfo: PersonMembershipPaymentReadOnly = sender == 'expire' ? formGroup :  formGroup.value;

    if (isNew) {
      if (this.list == null) {
        this.list = [];
      }
      this.list.unshift(personPaymentInfo);
    }
    else {
      this.list.forEach(o => {

        if (o.id == personPaymentInfo.id) {
          o.paymentDate = personPaymentInfo.paymentDate;
          o.groupId = personPaymentInfo.groupId;
          o.paymentTypeId = personPaymentInfo.paymentTypeId;
          o.membershipNumber = personPaymentInfo.membershipNumber;
          o.annualExpirationDate = personPaymentInfo.annualExpirationDate;
          o.isNew = personPaymentInfo.isNew;
          o.isToBeExpire = sender == 'expire' ? true : false;
        }
      });
    }

    setTimeout(() => {
      this.authService.eventPersonSubDataEventRaised(true);
    }, 500);
    this.grid.data = this.list;

    if (sender != 'expire') {
      sender.closeRow(rowIndex);
    }
  }

  public removePersonMembershipPaymentHandler({ dataItem }: { dataItem: any }) {
    let tempList: PersonMembershipPaymentReadOnly[] = [];

    this.list.forEach(o => {

      if (o.id != dataItem.id) {
        tempList.push(o);
      }
    });

    setTimeout(() => {
      this.authService.eventPersonSubDataEventRaised(true);
    }, 500);
    this.list = tempList;
    this.grid.data = this.list;
  }

  private closePersonMembershipPaymentEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public paymentTypes(value: any): any {

    if (this.paymentTypesArray !== undefined) {
      let val = this.paymentTypesArray.find((f: Lookup) => f.key == value);
      return val;
    }

    return "";
  }

  public groups(value: any): any {

    if (this.groupsArray !== undefined) {
      let val = this.groupsArray.find((f: Lookup) => f.key == value);
      return val;
    }

    return "";
  }
}
