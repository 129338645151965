import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../auth/auth.service';
import { ImportAirAlphaRosterModalComponent } from '../../features/navigator/import-air-alpha-roster-modal/import-air-alpha-roster-modal.component';
import { ImportArmyAlphaRosterModalComponent } from '../../features/navigator/import-army-alpha-roster-modal/import-army-alpha-roster-modal.component';
import { ImportMembershipModalComponent } from '../../features/navigator/import-membership-modal/import-membership-modal.component';
//import { AuthenticationService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: [],
})
export class NavComponent {
  @Input() loading: boolean = false;
  newApplicationsCount$!: Observable<any>;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
    public authService: AuthenticationService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.newApplicationsCount$ =
      this.authService.raiseNewApplicationCountChangeEvent$.pipe(
        tap((rslt) => {
          console.log(rslt);
        })
      );
    if (this.loggedIn) {
      this.authService.eventNewApplicationCountChangeRaised();
    }
  }

  logout() {
    this.authService.logout();
  }

  dashboard() {
    this.authService.dashboard();
  }

  importAirAlphaRoster(): void {
    let dialogRef = this.dialog.open(ImportAirAlphaRosterModalComponent, {
      data: {},
      width: "420px"
    });
  }

  importArmyAlphaRoster(): void {
    let dialogRef = this.dialog.open(ImportArmyAlphaRosterModalComponent, {
      data: {},
      width: "420px"
    });
  }

  get loggedIn(): boolean {
    return this.authService.isAuthenticated;
  }

  get fullName(): string | undefined {
    return this.authService.fullName;
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  get isVolunteer(): boolean {
    return this.authService.isVolunteer;
  }

  get isInsured(): boolean {
    return this.authService.isInsured;
  }
}
