<kendo-grid #grid fxFlex="100" [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'"
  [filterable]="false" [groupable]="false" [pageSize]="100" [pageable]="(list && (list.length > 100) ? true : false)"
  class="condensed" [resizable]="true" (edit)="editPersonChildHandler($event)"
  (cancel)="cancelPersonChildHandler($event)" (save)="savePersonChildHandler($event)"
  (remove)="removePersonChildHandler($event)" (add)="addPersonChildHandler($event)">
  <ng-template kendoGridToolbarTemplate>
    <button type="button" kendoGridAddCommand [disabled]="this.list.length == 4">Add new</button>
  </ng-template>
  <kendo-grid-column field="firstName" title="First Name" [filterable]="true"></kendo-grid-column>
  <kendo-grid-column field="lastName" title="Last Name" [filterable]="true"></kendo-grid-column>
  <kendo-grid-column field="middleName" title="Middle Name" [filterable]="true"></kendo-grid-column>
  <kendo-grid-column field="dob" editor="date" title="DOB" [filterable]="true" filter="date" format="{0:d}">
  </kendo-grid-column>
  <kendo-grid-column field="gender" title="Gender" [filterable]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.gender}}
    </ng-template>
    <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">
      <mat-form-field>
        <mat-select [formControl]="formGroup.get(column.field)">
          <mat-option value="Male">Male</mat-option>
          <mat-option value="Female">Female</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column title="-">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button type="button" kendoGridEditCommand [primary]="true">Edit</button>
      <button type="button" kendoGridRemoveCommand>Remove</button>
      <button type="button" kendoGridSaveCommand [disabled]="formGroup?.invalid">
        {{ isNew ? "Add" : "Update" }}
      </button>
      <button type="button" kendoGridCancelCommand>
        {{ isNew ? "Discard changes" : "Cancel" }}
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>