<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      Reduction Letters / Termination Letters
    </h1>
  </div>
  <div fxFlex="100">
    <form [formGroup]="reductionTerminationLetters" (ngSubmit)="getData()" fxFlex="100">
      <mat-form-field appearance="fill">
        <mat-label>Date</mat-label>
        <input matInput formControlName="date" [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>

        <mat-error *ngIf="reductionTerminationLetters.controls.date.hasError('required')">
          Please enter a <strong>date</strong>
        </mat-error>

      </mat-form-field> &nbsp;

      <mat-form-field appearance="fill" fxFlex.gt-sm="18">
        <mat-label>AFBA Letter <span class="error-color">*</span></mat-label>
        <mat-select formControlName="afbaLetter">
          <mat-option value="memberReduction">Age 60 AFBA reduction letter</mat-option>
          <mat-option value="spouseReduction">Age 60 AFBA Spouse reduction letter</mat-option>
          <mat-option value="termination">Age 65 AFBA Termination letter</mat-option>
        </mat-select>
        <mat-hint></mat-hint>
        <mat-error *ngIf="reductionTerminationLetters.controls.afbaLetter.hasError('required')">
          AFBA Letter is <strong>required</strong>
        </mat-error>

      </mat-form-field>
      <button kendoButton [primary]="true" icon="check-outline" style="margin-left: 3px;" [disabled]="!this.reductionTerminationLetters.valid">Go</button>
    </form>

  </div>
  <div fxLayout="row" fxLayoutGap="8px" class="m-2">

    <div class="example-config" *ngIf="this.list && this.list.length > 0">
      <button mat-raised-button color="primary" (click)="saveToPdf();">
        Save As PDF/Add Comments
      </button>
    </div>
    <div fxLayout="column" fxLayoutGap="8px" class="m-2">

      <kendo-pdf-export #pdfCont paperSize="Letter" [margin]="{top: 0, bottom: 0, right: 0, left: 0}" forcePageBreak=".page-break" [scale]="scale">

        <div class="mainBody" *ngFor="let person of this.list; let i = index">

          <style>

            @font-face {
              font-family: "Cambria";
              src: url("../../../../assets/fonts/cambria.ttf") format("truetype");
            }

            @font-face {
              font-family: "CambriaBold";
              font-weight: bold;
              src: url("../../../../assets/fonts/cambria-Bold.ttf") format("truetype");
            }

            @font-face {
              font-family: "Tahoma";
              font-style: italic;
              src: url("../../../../assets/fonts/tahoma.ttf") format("truetype");
            }

            @font-face {
              font-family: "TahomaBold";
              font-weight: bold;
              font-style: italic;
              src: url("../../../../assets/fonts/tahoma-Bold.ttf") format("truetype");
            }

            table {
              border: 1px solid black;
              border-collapse: collapse;
              font-size: 13px;
            }

            tr {
              border: 1px solid black;
              font-size: 13px;
            }

            td {
              border: 1px solid black;
              font-size: 13px;
            }

            .mainBody {
              padding-top: .38in;
              padding-left: .71in;
              padding-right: .65in;
              padding-bottom: .1in;
              Xfont-family: "Cambria";
              font-size: 13px;
              width: 8.5in;
              height: 11in;
              clear: both;
            }

            .header {
              Xfont-family: "TahomaBold";
              font-size: 13px;
            }

            .sub-header {
              Xfont-family: "Tahoma";
              font-size: 13px;
            }
          </style>

          <div style="float:left; width:50%;  text-align:center;">
            <img src="../../../../assets/logo-for-email.jpg" style="height:1.2in" />
          </div>

          <div style="float:left; width:50%; text-align:center;">
            <b class="header">
              North Carolina <br />
              National Guard Association  <br />
            </b>
            <span class="sub-header">
              7410 Chapel Hill Rd <br />
              Raleigh, NC 27607-5047 <br />
            </span>
          </div>

          <div style="float:left; width:100%">
            <br />
            <br />
            {{ person.date}} <br /> <br />

            {{ person.name}}<br />
            {{ person.address}}<br />
            {{ person.city}}, {{ person.state}} {{ person.zip}}<br /><br />
            <p>
              Dear {{ person.name}}:
            </p>

            <!-- REDUCTION SPECIFIC -->
            <p *ngIf="this.reductionTerminationLetters.get('afbaLetter')?.value != 'termination'">
              This letter is to remind you that according to the terms of our Term Group Life Insurance Policy,
              your {{ person.memberOrSpouse}} coverage reduces by 50% at the end of {{ person.yourOrTheir}} birth month.
              Therefore, effective {{ person.effectiveDateNew }}, your  Group Term coverage is as follows{{ person.noteInSpouse}}:
            </p>

            <!-- TERMINATION SPECIFIC -->
            <p *ngIf="this.reductionTerminationLetters.get('afbaLetter')?.value == 'termination'">
              This letter is to remind you that according to the terms of our Term Group Life Insurance Policy, your coverage will <i><b>end</b></i> on the last day of your birth month.
            </p>

            <!-- REDUCTION SPECIFIC -->
            <table width="100%" *ngIf="this.reductionTerminationLetters.get('afbaLetter')?.value != 'termination'">
              <tr>
                <td>
                  Group Term Policy
                </td>
                <td>
                  Current Coverage
                </td>
                <td>
                  Beginning {{ person.effectiveDateNew }}
                </td>
              </tr>
              <tr>
                <td>
                  Member
                </td>
                <td>
                  {{ person.memberCoverage}}
                </td>
                <td>
                  {{ person.memberCoverageNew}}
                </td>
              </tr>
              <tr>
                <td>
                  Spouse
                </td>
                <td>
                  {{ person.spouseCoverage}}
                </td>
                <td>
                  {{ person.spouseCoverageNew}}
                </td>
              </tr>
              <tr>
                <td>
                  Dependents (including Spouse)
                </td>
                <td>
                  {{ person.dependentCoverage}}
                </td>
                <td>
                  {{ person.dependentCoverageNew}}
                </td>
              </tr>
            </table>
            
            <p>
              One of the provisions of this policy is that you may apply for a whole life policy with no medical
              information required. The amount of coverage would be the same amount you are losing from
              the Group Term Policy. Please see coverage and estimated premiums below. If you choose to
              apply for this coverage, I have enclosed the necessary forms for you to complete.
            </p>

            <!-- REDUCTION SPECIFIC -->
            <table width="100%" *ngIf="this.reductionTerminationLetters.get('afbaLetter')?.value != 'termination'">
              <tr>
                <td>
                  Whole Life 
                </td>
                <td>
                  Coverage
                </td>
                <td>
                  Annual Premium
                </td>
                <td>
                  Monthly Premium
                </td>
              </tr>
              <tr>
                <td>
                  {{ person.memberOrSpouse1}}
                </td>
                <td>
                  <!--{{ person.memberCoverageNew}} ->--> {{ person.memberAndSpouseCoverageNew}}
                </td>
                <td>
                  {{ person.wholeLifeAnnualPremium}}
                </td>
                <td>
                  {{ person.wholeLifeMonthlyPremium}}
                </td>
              </tr>
            </table>
          </div>

          <!-- TERMINATION SPECIFIC -->
          <table width="100%" *ngIf="this.reductionTerminationLetters.get('afbaLetter')?.value == 'termination'">
            <tr>
              <td>
                Whole Life
              </td>
              <td>
                Coverage
              </td>
              <td>
                Annual Premium
              </td>
              <td>
                Monthly Premium
              </td>
            </tr>
            <tr>
              <td>
                Member
              </td>
              <td>
                {{ person.memberCoverage}}
              </td>
              <td>
                {{ person.wholeLifeAnnualPremium}}
              </td>
              <td>
                {{ person.wholeLifeMonthlyPremium}}
              </td>
            </tr>
            <tr>
              <td>
                Spouse
              </td>
              <td>
                {{ person.spouseCoverage}}
              </td>
              <td>
                {{ person.wholeLifeSpouseAnnualPremium}}
              </td>
              <td>
                {{ person.wholeLifeSpouseMonthlyPremium}}
              </td>
            </tr>
          </table>

          <!-- REDUCTION SPECIFIC -->
          <p style="float:left; width:100%;" *ngIf="this.reductionTerminationLetters.get('afbaLetter')?.value != 'termination'">
            If you would like to apply for this coverage, please send in the forms prior to {{ person.dateEffectiveMinusOne }}. If you have any questions,
            please call me at 919-851-3390 ext. 1 or email at Rhonda@ncnga.org.
          </p>

          <!-- TERMINATION SPECIFIC -->
          <p style="float:left; width:100%;" *ngIf="this.reductionTerminationLetters.get('afbaLetter')?.value == 'termination'">
            If you would like information on this additional coverage, please let me know prior to {{ person.dateEffectiveMinusOne }}. If you have any questions,
            please call me at 919-851-3390 ext. 1 or email at rhonda@ncnga.org.
          </p>

          <br />

          <p style="float:left; width:100%;">
            Sincerely,
            &nbsp;<br />
            &nbsp;<br />
          </p>

          <p style="float:left; width:100%;">
            &nbsp;<br />
            &nbsp;<br />
          </p>

          <p style="float:left; width:100%;">
            Rhonda Mooring<br />
            Insurance Administrator
            &nbsp;<br />
            &nbsp;<br />
          </p>

          <p style="float:left; width:100%;">
            &nbsp;<br />
            &nbsp;<br />
          </p>

          <p style="float:left; width:100%; text-align: center">
            919-851-3390 &emsp; info@ncnga.org &emsp; www.ncnga.org
          </p>

          <div class="page-breakXXXX" *ngIf="i < this.list.length - 1"></div>
        </div>
      </kendo-pdf-export>
    </div>
  </div>
