<div fxLayout="column" fxLayoutGap="10px" fxFlex="100">

  <div fxLayout="row" fxLayoutGap="10px" fxFlex="100" *ngIf="this.authService.isAdmin">
    <div fxFlex="98" fxLayoutAlign="end">
      <button mat-stroked-button color="accent" icon="bookmark" (click)="importFiles()"
              [disabled]="processingFiles">
        Import Files
      </button>
    </div>
  </div>
    

    <mat-tab-group>
      <mat-tab label="People">
        <ng-template matTabContent>

          <mat-card class="mt-2">
            <mat-card-subtitle>Search Fields</mat-card-subtitle>
            <div fxLayout="column" [formGroup]="frmPersonSearchFilters">
              <div fxLayout="row" fxLayoutGap="10px" fxFlex="100">

                <mat-form-field appearance="fill" fxFlex="15">
                  <mat-label>Last Name</mat-label>
                  <input matInput formControlName="lastName">
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="15">
                  <mat-label>First Name</mat-label>
                  <input matInput formControlName="firstName">
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="10" *ngIf="this.authService.isAdmin">
                  <mat-label>SSN</mat-label>
                  <input matInput formControlName="ssn" mask="000-00-0000" />
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="10">
                  <mat-label>DOD ID</mat-label>
                  <input matInput formControlName="dodid">
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="15" *ngIf="this.authService.isAdmin">
                  <mat-label>Enter a DOB date range</mat-label>
                  <mat-date-range-input [rangePicker]="dobPicker">
                    <input matStartDate formControlName="dobStart" placeholder="DBO Start">
                    <input matEndDate formControlName="dobEnd" placeholder="DBO End">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
                  <mat-date-range-picker #dobPicker></mat-date-range-picker>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="10">
                  <mat-label>Enter a UIC</mat-label>
                  <input matInput formControlName="uic" placeholder="UIC">
                </mat-form-field>

              </div>
              <div fxLayout="row" fxLayoutGap="10px" fxFlex="100" *ngIf="this.authService.isAdmin">
                <mat-form-field appearance="fill" fxFlex="15">
                  <mat-label>Enter a Effective date range</mat-label>
                  <mat-date-range-input [rangePicker]="effectivePicker">
                    <input matStartDate formControlName="effectiveStart" placeholder="Effective Start">
                    <input matEndDate formControlName="effectiveEnd" placeholder="Effective End">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="effectivePicker"></mat-datepicker-toggle>
                  <mat-date-range-picker #effectivePicker></mat-date-range-picker>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="15">
                  <mat-label>Enter a Next Payment date range</mat-label>
                  <mat-date-range-input [rangePicker]="pmtDuePicker">
                    <input matStartDate formControlName="nextPmtDueStart" placeholder="Payment Due Start">
                    <input matEndDate formControlName="nextPmtDueEnd" placeholder="Payment Due End">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="pmtDuePicker"></mat-datepicker-toggle>
                  <mat-date-range-picker #pmtDuePicker></mat-date-range-picker>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="15">
                  <mat-label>Payment Type</mat-label>
                  <mat-select formControlName="paymentTypeId">
                    <mat-option *ngFor="let item of this.paymentTypeLookups" [value]="item.key">
                      {{item.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="10">
                  <mat-label>Enter a Premium Start</mat-label>
                  <input matInput formControlName="premiumStart" placeholder="Premium Start">
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="10">
                  <mat-label>Enter a Premium End</mat-label>
                  <input matInput formControlName="premiumEnd" placeholder="Premium End">
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="15">
                  <mat-label>Group</mat-label>
                  <mat-select formControlName="groupId">
                    <mat-option *ngFor="let item of this.groupLookups" [value]="item.key">
                      {{item.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="15">
                  <mat-label>Coverage Type</mat-label>
                  <mat-select formControlName="coverageTypeId">
                    <mat-option *ngFor="let item of this.coverageTypesLookups" [value]="item.key">
                      {{item.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- <mat-form-field appearance="fill" fxFlex="15">
                  <mat-label>Tagged/Untagged:</mat-label>
                  <mat-select formControlName="taggedUntagged">
                    <mat-option *ngFor="let item of this.taggedUntaggedLookups" [value]="item.key">
                      {{item.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
              </div>
              <div fxLayout="row" fxLayoutGap="10px" fxFlex="100">
                <div fxFlex="40">
                  <button mat-raised-button color="accent" icon="search" (click)="searchPerson()"
                          style="margin-left: 3px;">
                    Search
                  </button>
                  <button mat-stroked-button color="accent" icon="info" (click)="resetPerson()" style="margin-left: 3px;">
                    Reset
                  </button>
                </div>

                <div fxFlex="60" fxLayoutAlign="end">
                  <!-- <mat-form-field appearance="fill" fxFlex="15">
                    <mat-label>Tag / Untag</mat-label>
                    <mat-select formControlName="tagUntagTypeId">
                      <mat-option *ngFor="let item of this.taggedUntaggedTypeLookups" [value]="item.key">
                        {{item.value}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button mat-stroked-button color="accent" icon="bookmark" (click)="tagUntagPersonClick()"
                    style="margin-left: 3px;" [disabled]="(listPerson && (listPerson.length == 0) ? true : false)">
                    Tag/Untag Person
                  </button> -->
                  <button mat-stroked-button [matMenuTriggerFor]="matPrint">
                    <mat-icon class="example-icon">print</mat-icon> Print <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
                  <mat-menu #matPrint="matMenu" yPosition="below" xPosition="before" overlapTrigger="false">
                    <button mat-menu-item (click)="alphaRoster()">
                      <mat-icon class="example-icon">groups</mat-icon> Alpha Roster Report
                    </button>
                    <button mat-menu-item (click)="annualStatement()">
                      <mat-icon class="example-icon">receipt_long</mat-icon> Annual Statement
                    </button>
                    <button mat-menu-item (click)="billingJournalNotPaid()">
                      <mat-icon class="example-icon">receipt</mat-icon> Billing Journal Not Paid
                    </button>
                    <button mat-menu-item (click)="memberDetail()">
                      <mat-icon class="example-icon">person</mat-icon> Member Detail
                    </button>
                  </mat-menu>

                </div>
              </div>
            </div>
          </mat-card>

          <kendo-grid [kendoGridBinding]="listPerson" [sortable]="true" [scrollable]="'scrollable'"
                      [columnMenu]="{ filter: true }" [groupable]="false" [pageSize]="100"
                      [pageable]="(listPerson && (listPerson.length > 100) ? true : false)" [rowClass]="rowCallback"
                      class="mt-2 condensed" #gridPerson id="grdPeopleNavigator" [navigable]="true" [selectable]="true"
                      kendoGridSelectBy="id" [(selectedKeys)]="personSelectionKeys">
            <ng-template kendoGridToolbarTemplate>
              <!--<button type="button" kendoGridPDFCommand icon="file-pdf">Export to PDF</button>-->
              <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
              <button type="button" class="k-button k-button-md k-rounded-md k-button-solid-base k-button-solid ng-star-inserted" icon="file-excel" (click)="exportPersonToCSV()"
                      *ngIf="this.authService.isAdmin">
                Export to CSV
              </button>
            </ng-template>
            <kendo-grid-excel fileName="People.xlsx" [fetchData]="this.allPersonData">
              <kendo-excelexport-column field="firstName" title="First Name"></kendo-excelexport-column>
              <kendo-excelexport-column field="middleName" title="Middle Name"></kendo-excelexport-column>
              <kendo-excelexport-column field="lastName" title="Last Name"></kendo-excelexport-column>
              <kendo-excelexport-column field="ssn" title="SSN" *ngIf="this.authService.isAdmin">
              </kendo-excelexport-column>
              <kendo-excelexport-column field="dodid" title="DOD ID" *ngIf="this.authService.isAdmin">
              </kendo-excelexport-column>
              <kendo-excelexport-column field="uic" title="UIC"></kendo-excelexport-column>
              <kendo-excelexport-column field="premium" title="Premium"></kendo-excelexport-column>
              <kendo-excelexport-column field="pmtType" title="Payment Type"></kendo-excelexport-column>
              <kendo-excelexport-column field="rosterName" title="Roster"></kendo-excelexport-column>

              <kendo-excelexport-column field="address1" title="Address1"></kendo-excelexport-column>
              <kendo-excelexport-column field="address2" title="Address2"></kendo-excelexport-column>
              <kendo-excelexport-column field="city" title="City"></kendo-excelexport-column>
              <kendo-excelexport-column field="state" title="State"></kendo-excelexport-column>
              <kendo-excelexport-column field="zip" title="Zip"></kendo-excelexport-column>
              <kendo-excelexport-column field="primaryPhone" title="Primary Phone"></kendo-excelexport-column>
              <kendo-excelexport-column field="secondaryPhone" title="Secondary Phone"></kendo-excelexport-column>
              <kendo-excelexport-column field="email" title="Email"></kendo-excelexport-column>
              <kendo-excelexport-column field="militaryEmail" title="Military Email"></kendo-excelexport-column>
              <kendo-excelexport-column field="dob" title="DOB"></kendo-excelexport-column>
            </kendo-grid-excel>
            <!--<kendo-grid-pdf fileName="People.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true" [landscape]="true">
              <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
            </kendo-grid-pdf>-->
            <kendo-grid-checkbox-column [width]="90"></kendo-grid-checkbox-column>
            <kendo-grid-column field="id" title=" " [filterable]="false" [width]="120" [sortable]="false" *ngIf="this.authService.isAdmin">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <button mat-button color="primary" routerLink="/person/{{ dataItem.id }}">
                  <mat-icon>edit</mat-icon>
                </button>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="firstName" title="First Name" [filterable]="true"></kendo-grid-column>
            <kendo-grid-column field="middleName" title="Middle Name" [filterable]="true"></kendo-grid-column>
            <kendo-grid-column field="lastName" title="Last Name" [filterable]="true"></kendo-grid-column>
            <kendo-grid-column field="ssn" title="SSN" [filterable]="true" *ngIf="this.authService.isAdmin">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{dataItem.ssn | mask: '000-00-0000'}}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="dodid" title="DOD ID" [filterable]="true"></kendo-grid-column>
            <kendo-grid-column field="uic" title="UIC" [filterable]="true"></kendo-grid-column>
            <!--<kendo-grid-column field="unitCode" title="Unit Code" [filterable]="true"></kendo-grid-column>-->
            <kendo-grid-column field="premium" title="Premium" [filterable]="true" format="{0:c}"></kendo-grid-column>
            <kendo-grid-column field="pmtType" title="Payment Type" [filterable]="true"></kendo-grid-column>
            <kendo-grid-column field="rosterName" title="Roster" [filterable]="true"></kendo-grid-column>
            <kendo-grid-column field="id" title=" " [filterable]="false" [width]="120" [sortable]="false" *ngIf="this.authService.isAdmin">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <button type="button" mat-button color="accent" (click)="deleteClick(dataItem.id)">
                  <mat-icon>delete</mat-icon>
                </button>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>

        </ng-template>
      </mat-tab>
      <mat-tab label="Units">
        <ng-template matTabContent>
          <div fxLayout="column" fxLayoutGap="8px" class="m-2">
            <mat-card fxFlex="100">
              <mat-card-subtitle>Search Fields</mat-card-subtitle>
              <div fxLayout="column" [formGroup]="frmUnitSearchFilters">
                <div fxLayout="row" fxLayoutGap="10px" fxFlex="100">

                  <mat-form-field appearance="fill" fxFlex="10">
                    <mat-label>Enter a UIC</mat-label>
                    <input matInput formControlName="uic" placeholder="UIC">
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="15">
                    <mat-label>Unit Name</mat-label>
                    <input matInput formControlName="unit">
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="15">
                    <mat-label>Battalion</mat-label>
                    <mat-select formControlName="battalionId">
                      <mat-option *ngFor="let item of this.battalionsLookups" [value]="item.key">
                        {{item.value}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="15">
                    <mat-label>Brigade</mat-label>
                    <mat-select formControlName="brigadeId">
                      <mat-option *ngFor="let item of this.brigadesLookups" [value]="item.key">
                        {{item.value}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                  <mat-form-field appearance="fill" fxFlex="15">
                    <mat-label>Army / Air</mat-label>
                    <mat-select formControlName="armyOrAir">
                      <mat-option [value]="0">
                        All
                      </mat-option>
                      <mat-option [value]="1">
                        Army
                      </mat-option>
                      <mat-option [value]="2">
                        Air
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" fxFlex="100">
                  <div fxFlex="40">
                    <button mat-raised-button color="accent" icon="search" (click)="searchUnit()"
                            style="margin-left: 3px;">
                      Search
                    </button>
                    <button mat-raised-button color="accent" icon="info" (click)="resetUnit()" style="margin-left: 3px;">
                      Reset
                    </button>
                  </div>
                  <div fxFlex="60" fxLayoutAlign="end">
                    <button mat-stroked-button [matMenuTriggerFor]="matPrint">
                      <mat-icon class="example-icon">print</mat-icon> Print <mat-icon>keyboard_arrow_down</mat-icon>
                    </button>
                    <mat-menu #matPrint="matMenu" yPosition="below" xPosition="before" overlapTrigger="false">
                      <button mat-menu-item (click)="unitRoster()">
                        <mat-icon class="example-icon">groups</mat-icon> Unit Roster report
                      </button>
                    </mat-menu>

                  </div>
                </div>
              </div>
            </mat-card>

            <div fxFlex="100">
              <kendo-grid [kendoGridBinding]="listUnit" [sortable]="true" [scrollable]="'scrollable'"
                          [columnMenu]="{ filter: true }" [groupable]="false" [pageSize]="100"
                          [pageable]="(listUnit && (listUnit.length > 100) ? true : false)" [rowClass]="rowCallback"
                          class="condensed" #gridUnit fxFlex id="grdUnitNavigator">
                <ng-template kendoGridToolbarTemplate>
                  <!--<button type="button" kendoGridPDFCommand icon="file-pdf">Export to PDF</button>-->
                  <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
                  <button type="button" class="k-button" icon="file-excel" (click)="exportUnitToCSV()"
                          *ngIf="this.authService.isAdmin">
                    Export to
                    CSV
                  </button>
                </ng-template>
                <kendo-grid-excel fileName="Unit.xlsx" [fetchData]="this.allUnitData"></kendo-grid-excel>
                <!--<kendo-grid-pdf fileName="Unit.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true" [landscape]="true">
                    <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
                  </kendo-grid-pdf>-->
                <kendo-grid-column field="uic" title="UIC" [filterable]="true"></kendo-grid-column>
                <kendo-grid-column field="unitName" title="Unit Name" [filterable]="true"></kendo-grid-column>
                <kendo-grid-column field="battalionName" title="Battalion" [filterable]="true"></kendo-grid-column>
                <kendo-grid-column field="brigadeName" title="Brigade" [filterable]="true"></kendo-grid-column>
              </kendo-grid>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <style>
  </style>
