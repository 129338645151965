import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';
import { ReportService } from '../report.service';
import { MediaObserver } from "@angular/flex-layout";
import { Filter } from '../../_bases/filter';
import { AuthenticationService } from '../../auth/auth.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { ReportMembershipExport } from './report-membership-export';

@Component({
  selector: 'app-membership-export',
  templateUrl: './report-membership-export.component.html',
  animations: [
  ]
})
export class ReportMembershipExportComponent implements OnInit {

  @ViewChild(GridComponent) public grid!: GridComponent;


  list: ReportMembershipExport[] = [];
  filters: Filter[] = [];

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: ReportService,
    public media: MediaObserver,
    public authService: AuthenticationService,
    private ngZone: NgZone,
  ) {
    this.allData = this.allData.bind(this);
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.list, {}).data,
    };

    return result;
  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {

    this.service
      .getByCustomAction$<ReportMembershipExport>("getMembershipExport", ReportMembershipExport, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {
        console.log(data);
        this.list = data;
      });
  }

  public rowCallback(context: RowClassArgs) {
    return {
    };
  }
}
