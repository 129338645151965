import { Component, OnInit, NgZone, ViewChild, Inject, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';
import { ReportService } from '../report.service';
import { ReportAnnualStatement } from './report-annual-statement';
import { MediaObserver } from "@angular/flex-layout";
import { Filter } from '../../_bases/filter';
import { AuthenticationService } from '../../auth/auth.service';
import { DatePipe, ViewportScroller } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { typeModel } from '../../_bases/typeModel';

@Component({
  selector: 'app-annual-statement',
  templateUrl: './report-annual-statement.component.html',
  styleUrls: ['./report-annual-statement.component.scss'],
  animations: [
  ]
})
export class ReportAnnualStatementComponent implements OnInit {

  @ViewChild("divMain", { static: true })
    divMain!: ElementRef;
  list: ReportAnnualStatement[] = [];
  index: number = 0;
  topVal: number = 0.25;

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: ReportService,
    public media: MediaObserver,
    public authService: AuthenticationService,
    private ngZone: NgZone,
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public model: any,
  ) {
  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {

    const tymodel = new typeModel<any>({
      personIds: this.model,
    });

    this.service
      .postByCustomAction$<ReportAnnualStatement>("getAnnualStatement", tymodel)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {

        console.log(data);
        this.list = data;
        this.loadRowData();
      });
  }

  moveToPage(index: number) {

    this.index = index;
    this.loadRowData();
  }

  moveNextPrev(index: number) {

    this.index = this.index + index;
    this.loadRowData();
  }

  loadRowData() {
    this.divMain.nativeElement.scrollTo(0, this.index * 1080);
  }
}
