
<kendo-grid #grid fxFlex="100" [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [filterable]="false"
            [groupable]="false" [pageSize]="100" [pageable]="(list && (list.length > 100) ? true : false)" [rowClass]="rowCallback"
            class="condensed" [resizable]="true"
            (dataStateChange)="onDataStateChange($event)"
            (edit)="editPersonCommentsHandler($event)"
            (cancel)="cancelPersonCommentsHandler($event)"
            (save)="savePersonCommentsHandler($event)"
            (remove)="removePersonCommentsHandler($event)"
            (add)="addPersonCommentsHandler($event)">

  <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand>Add new</button>
  </ng-template>
  <kendo-grid-column field="commentText" title="Comment" [filterable]="true" ></kendo-grid-column>
  <kendo-grid-column field="addedBy" title="Added By" [editable]="false" [filterable]="true"></kendo-grid-column>
  <kendo-grid-column field="addedDate" title="Added Date" [editable]="false" [filterable]="true" filter="date" format="{0:d}"></kendo-grid-column>
  <kendo-grid-command-column title="command">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridEditCommand [primary]="true">Edit</button>
      <button kendoGridRemoveCommand>Remove</button>
      <button kendoGridSaveCommand [disabled]="formGroup?.invalid">
        {{ isNew ? "Add" : "Update" }}
      </button>
      <button kendoGridCancelCommand>
        {{ isNew ? "Discard changes" : "Cancel" }}
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
