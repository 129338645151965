import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { Observable, Subject } from 'rxjs';
import { AuthenticationService } from '../../auth/auth.service';
import { Lookup } from '../../lookups/lookup';
import { LookupService } from '../../lookups/lookup.service';
import { NewApplicationPersonInsBeneficiary } from './newapplicationpersoninsbeneficiary';

@Component({
  selector: 'app-newapplicationpersoninsbeneficiaries-list',
  templateUrl: './newapplicationpersoninsbeneficiary-list.component.html',
  animations: [],
})
export class NewApplicationPersonInsBeneficiaryListComponent implements OnInit {
  @ViewChild(GridComponent)
  public grid!: GridComponent;

  list: NewApplicationPersonInsBeneficiary[] = [];
  @Input() newItemProperties: any;
  public loading: boolean = false;

  public formGroup: FormGroup | any;
  private editedRowIndex: number | undefined;
  relationshipIdLookups$: Observable<Lookup[]> | any;
  relationshipsArray: Lookup[] | any;

  private increment: number = 0;
  createPersonInsBenFormGroup = (
    dataItem: NewApplicationPersonInsBeneficiary
  ) =>
    new FormGroup({
      id: new FormControl(dataItem.id),
      personId: new FormControl(dataItem.personId, Validators.required),
      beneficiary: new FormControl(dataItem.beneficiary, Validators.required),
      //beneficiaryDate: new FormControl(dataItem.beneficiaryDate, Validators.required),
      percentage: new FormControl(
        dataItem.percentage,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.max(100),
        ])
      ),
      relationship: new FormControl(dataItem.relationship, Validators.required),
      isNew: new FormControl(dataItem.isNew == true ? true : false),
      //ssn: new FormControl(dataItem.ssn, Validators.required),
      //designation: new FormControl(dataItem.designation, Validators.required),
    });

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private ngZone: NgZone,
    private lookupService: LookupService,
    private authService: AuthenticationService
  ) {
    this.relationshipIdLookups$ = this.lookupService.getList(
      'relationships',
      []
    );

    this.relationshipIdLookups$.subscribe((s: Lookup[]) => {
      this.relationshipsArray = s;
    });
  }

  ngOnInit(): void {}

  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }

  private fitColumns(): void {
    //this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
    //  this.grid.autoFitColumns();
    //});
  }

  public rowCallback(context: RowClassArgs) {
    return {};
  }

  public addPersonInsBenHandler(evt: any) {
    this.closePersonInsBenEditor(evt.sender);

    this.increment += 1;

    this.formGroup = this.createPersonInsBenFormGroup({
      personId: 0,
      beneficiary: '',
      //beneficiaryDate: new Date(),
      id: this.increment,
      percentage: 0,
      relationship: '',
      isNew: true,
      //ssn: '',
      //designation: '',
    });

    evt.sender.addRow(this.formGroup);
  }

  public editPersonInsBenHandler({
    sender,
    rowIndex,
    dataItem,
  }: {
    sender: any;
    rowIndex: any;
    dataItem: any;
  }) {
    this.closePersonInsBenEditor(sender);
    this.formGroup = this.createPersonInsBenFormGroup(dataItem);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelPersonInsBenHandler({
    sender,
    rowIndex,
  }: {
    sender: any;
    rowIndex: any;
  }) {
    this.closePersonInsBenEditor(sender, rowIndex);
  }

  public savePersonInsBenHandler({
    sender,
    rowIndex,
    formGroup,
    isNew,
  }: {
    sender: any;
    rowIndex: any;
    formGroup: any;
    isNew: any;
  }) {
    const personInsBeneficiary: NewApplicationPersonInsBeneficiary =
      formGroup.value;

    if (isNew) {
      if (this.list == null) {
        this.list = [];
      }
      this.list.push(personInsBeneficiary);
    } else {
      this.list.forEach((o) => {
        if (o.id == personInsBeneficiary.id) {
          o.beneficiary = personInsBeneficiary.beneficiary;
          //o.beneficiaryDate = personInsBeneficiary.beneficiaryDate;
          o.percentage = personInsBeneficiary.percentage;
          o.relationship = personInsBeneficiary.relationship;
          o.isNew = personInsBeneficiary.isNew;
          //o.ssn = personInsBeneficiary.ssn;
          //o.designation = personInsBeneficiary.designation;
        }
      });
    }

    setTimeout(() => {
      this.authService.eventPersonNewAppBeneDataEventRaised(true);
    }, 500);
    this.grid.data = this.list;
    sender.closeRow(rowIndex);
  }

  public removePersonInsBenHandler({ dataItem }: { dataItem: any }) {
    let tempList: NewApplicationPersonInsBeneficiary[] = [];

    this.list.forEach((o) => {
      if (o.id != dataItem.id) {
        tempList.push(o);
      }
    });

    setTimeout(() => {
      this.authService.eventPersonNewAppBeneDataEventRaised(true);
    }, 500);
    this.list = tempList;
    this.grid.data = this.list;
  }

  private closePersonInsBenEditor(
    grid: GridComponent,
    rowIndex = this.editedRowIndex
  ) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public relationships(value: any): any {
    if (this.relationshipsArray !== undefined) {
      let val = this.relationshipsArray.find((f: Lookup) => f.value == value);
      return val;
    }

    return '';
  }
}
