<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <button mat-raised-button color="accent" (click)="pdf.saveAs('Summary.pdf')">
      Save As PDF...
    </button>
  </div>
  <kendo-pdf-export #pdf paperSize="Letter" margin="1cm" [scale]=".5" forcePageBreak=".page-break" keepTogether=".prevent-split">
    <h3 mat-dialog-title>
      Summary
    </h3>

    <div class="mat-dialog-content" style="display:block !important">
      <div fxLayout="column" fxLayoutGap="8px" class="m-2" *ngIf="this.model">
        <div fxLayout="row">
          <div fxFlex><strong>Class</strong></div>
          <div fxFlex><strong>Lives</strong></div>
          <div fxFlex><strong>Insurance Volume</strong></div>
          <div fxFlex><strong>Premium Due</strong></div>
          <div fxFlex><strong>Premium Received</strong></div>
        </div>
        <div fxLayout="row">
          <div fxFlex><strong>Member</strong></div>
          <div fxFlex>{{ this.model.MemberLives }}</div>
          <div fxFlex>{{ this.model.MemberCoverage | currency }}</div>
          <div fxFlex>{{ this.model.MemberPremium | currency}}</div>
          <div fxFlex>{{ this.model.MemberActualRevenue | currency}}</div>
        </div>
        <div fxLayout="row">
          <div fxFlex><strong>Child</strong></div>
          <div fxFlex>{{ this.model.ChildLives }}</div>
          <div fxFlex>{{ this.model.ChildCoverage | currency }}</div>
          <div fxFlex>{{ this.model.ChildPremium | currency }}</div>
          <div fxFlex>{{ this.model.ChildActualRevenue | currency}}</div>
        </div>
        <div fxLayout="row">
          <div fxFlex><strong>Spouse</strong></div>
          <div fxFlex>{{ this.model.SpouseLives }}</div>
          <div fxFlex>{{ this.model.SpouseCoverage | currency }}</div>
          <div fxFlex>{{ this.model.SpousePremium | currency }}</div>
          <div fxFlex>{{ this.model.SpouseActualRevenue | currency}}</div>
        </div>
      </div>
    </div>
  </kendo-pdf-export>
  <section class="footer">
    <button mat-button type="button" (click)="cancel()">
      Close
    </button>
  </section>

</div>
