import { Component, OnInit, NgZone, ViewChild, Inject, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { ReportService } from '../report.service';
import { MediaObserver } from "@angular/flex-layout";
import { AuthenticationService } from '../../auth/auth.service';
import { ReportAlphaRoster } from './report-alpha-roster';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { typeModel } from '../../_bases/typeModel';
import { GridComponent, DetailExpandEvent } from '@progress/kendo-angular-grid';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';

@Component({
  selector: 'app-report-alpha-roster',
  templateUrl: './report-alpha-roster.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [`
  .main-ctr
  {
    overflow-y: scroll;
    max-height: 90vh;
  }

  .bdr-btm {
    border-bottom: 1px solid black;
  }

  .bdr-dashed
  {
    border-bottom: 1px  dashed black;
  }

  .beneficiary {
    border: 1px solid black;
  }
`],
})
export class ReportAlphaRosterComponent implements OnInit {

  list: ReportAlphaRoster[] = [];
  protected ngUnsubscribe: Subject<boolean> = new Subject();
  //@ViewChild(GridComponent) gridA!: GridComponent;
  //@ViewChild(PDFExportComponent) pdfExport!: PDFExportComponent;
  //public expandedDetailKeys: any[] = [];

  constructor(
    private service: ReportService,
    public media: MediaObserver,
    public authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public model: any,
  ) {
  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {
    const tymodel = new typeModel<any>({
      personIds: this.model,
    });

    this.service
      .postByCustomAction$<any>("getAlphaRoster", tymodel)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe((data: any) => {
        console.log(data);
        this.list = data;
        //this.expandedDetailKeys = this.list.map((itm) => itm.id);
      });
  }

  //public expandDetailsBy = (dataItem: ReportAlphaRoster): number | undefined => {
  //  return dataItem.id;
  //};
}
