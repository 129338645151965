
<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      Setting
      <span class="k-icon k-i-loading" *ngIf="loading"></span>
    </h1>
  </div>

  <kendo-grid #grid fxFlex="100" [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [filterable]="false"
              [groupable]="false" [pageSize]="100" [pageable]="(list && (list.length > 100) ? true : false)" [rowClass]="rowCallback"
              class="condensed mt-5" [resizable]="true"
              (edit)="editHandler($event)"
              (cancel)="cancelHandler($event)"
              (save)="saveHandler($event)"
              (dataStateChange)="onDataStateChange($event)">

    <kendo-grid-column field="notInsuredCoverageTypeId" title="Not Insured Coverage Type" [filterable]="true">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{coveragetypes(dataItem.notInsuredCoverageTypeId)?.value}}
      </ng-template>
      <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">
        <kendo-combobox [data]="coverageTypesIdLookups$ | async" textField="value" valueField="key"
                        [valuePrimitive]="true"
                        [suggest]="true" [popupSettings]="{width: 360,height: 150}"
                        [formControl]="formGroup.get(column.field)">
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="formerMembersGroupId" title="Former Members Group" [filterable]="true">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{groups(dataItem.formerMembersGroupId)?.value}}
      </ng-template>
      <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">
        <kendo-combobox [data]="groupsIdLookups$ | async" textField="value" valueField="key"
                        [valuePrimitive]="true"
                        [suggest]="true" [popupSettings]="{width: 360,height: 150}"
                        [formControl]="formGroup.get(column.field)">
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="airFormerMembersUnitId" title="Air Former Members Unit" [filterable]="true">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{airUnits(dataItem.airFormerMembersUnitId)?.value}}
      </ng-template>
      <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">
        <kendo-combobox [data]="airUnitIdLookups$ | async" textField="value" valueField="key"
                        [valuePrimitive]="true"
                        [suggest]="true" [popupSettings]="{width: 360,height: 150}"
                        [formControl]="formGroup.get(column.field)">
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="armyFormerMembersUnitId" title="Army Former Members Unit" [filterable]="true">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{armyUnits(dataItem.armyFormerMembersUnitId)?.value}}
      </ng-template>
      <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">
        <kendo-combobox [data]="armyUnitIdLookups$ | async" textField="value" valueField="key"
                        [valuePrimitive]="true"
                        [suggest]="true" [popupSettings]="{width: 360,height: 150}"
                        [formControl]="formGroup.get(column.field)">
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="nonMemberPaymentTypeId" title="Non-Member Payment Type" [filterable]="true">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{paymentTypes(dataItem.nonMemberPaymentTypeId)?.value}}
      </ng-template>
      <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">
        <kendo-combobox [data]="paymentTypeIdLookups$ | async" textField="value" valueField="key"
                        [valuePrimitive]="true"
                        [suggest]="true" [popupSettings]="{width: 360,height: 150}"
                        [formControl]="formGroup.get(column.field)">
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="-">
      <ng-template kendoGridCellTemplate>
        <button kendoGridEditCommand [primary]="true">Edit</button>
        <button kendoGridSaveCommand [disabled]="formGroup?.invalid">
          Update
        </button>
        <button kendoGridCancelCommand>
          Cancel
        </button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
