//import { IDto } from "src/app/core/iDto";

export class PersonAttachmentReadOnly { //} implements IDto<PersonJUMPSData> {

	constructor() 
  constructor(jsonData: PersonAttachmentReadOnly)
  constructor(jsonData?: PersonAttachmentReadOnly) {
		if(jsonData) {
			Object.assign(this, jsonData);
      this.dateAdded = (jsonData.dateAdded && jsonData.dateAdded.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.dateAdded) : undefined;
		}
	}

  id: number | undefined;
  personId: number | undefined;
  fileName: string | undefined;
  dateAdded: Date | undefined;

}


