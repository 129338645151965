<div fxLayout="column" fxLayoutGap="10px" fxFlex="100">
  <div fxFlex="100">
    <div fxLayout="row" fxLayoutGap="8px" class="m-2">
      <h1 fxFlex="50" class="mr-auto primary">
        Alpha Roster
      </h1>
      <div fxFlex="50" class="text-end"  *ngIf="this.asOfDate">
        <button mat-raised-button color="primary" type="button" (click)="finishImport()">Finish Import</button>
      </div>
    </div>
  </div>
  <mat-tab-group>
    <mat-tab [label]="tab1lbl">
      <ng-template matTabContent>
        <kendo-grid [kendoGridBinding]="listArmyGuard" [sortable]="true" [scrollable]="'scrollable'"
                    [columnMenu]="{ filter: true }" [groupable]="false" [pageSize]="100"
                    [pageable]="(listArmyGuard && (listArmyGuard.length > 100) ? true : false)" [rowClass]="rowCallback"
                    class="mt-2 condensed" #grdArmyGuard id="grdArmyGuard">
          <ng-template kendoGridToolbarTemplate>
            <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
            <button type="button" class="k-button" icon="file-excel" (click)="exportArmyGuardToCSV()">
              Export to CSV
            </button>
          </ng-template>
          <kendo-grid-excel fileName="Guard.xlsx" [fetchData]="this.allArmyGuardData"></kendo-grid-excel>
          <kendo-grid-column field="piqName" title="PIQ Name" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="ncngaMember" title="NCNGA Member" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="dodid" title="DOD ID" [filterable]="true"></kendo-grid-column>
          <!--<kendo-grid-column field="dob" title="DOB" [filterable]="true"></kendo-grid-column>-->
          <!--<kendo-grid-column field="currentPayCode" title="Pay Code" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="newPayCode" title="New Pay Code" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="payCodeChange" title="Pay Code Change" [filterable]="true"></kendo-grid-column>-->
          <kendo-grid-column field="currentPayCode" title="New Pay Code" [filterable]="true"></kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </mat-tab>
    <mat-tab label="Review Pay Codes">
      <ng-template matTabContent>
        <kendo-grid [kendoGridBinding]="listArmyReviewPayCodes" [sortable]="true" [scrollable]="'scrollable'"
                    [columnMenu]="{ filter: true }" [groupable]="false" [pageSize]="100"
                    [pageable]="(listArmyReviewPayCodes && (listArmyReviewPayCodes.length > 100) ? true : false)" [rowClass]="rowCallback"
                    class="mt-2 condensed" #grdArmyReviewPayCodes id="grdArmyReviewPayCodes">
          <ng-template kendoGridToolbarTemplate>
            <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
            <button type="button" class="k-button" icon="file-excel" (click)="exportArmyReviewPayCodesToCSV()">
              Export to CSV
            </button>
          </ng-template>
          <kendo-grid-excel fileName="ReviewPayCodes.xlsx" [fetchData]="this.allArmyReviewPayCodesData"></kendo-grid-excel>
          <kendo-grid-column field="payCode" title="Pay Code" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="numberOfSoldiers" title="Number Of Soldiers" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="ncngaUnitCode" title="NCNGA Unit Code" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="ncngaUnit" title="NCNGA Unit" [filterable]="true"></kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </mat-tab>
    <mat-tab label="Review Transfers">
      <ng-template matTabContent>
        <kendo-grid [kendoGridBinding]="listArmyReviewTransfers" [sortable]="true" [scrollable]="'scrollable'"
                    [columnMenu]="{ filter: true }" [groupable]="false" [pageSize]="100"
                    [pageable]="(listArmyReviewTransfers && (listArmyReviewTransfers.length > 100) ? true : false)" [rowClass]="rowCallback"
                    class="mt-2 condensed" #grdArmyReviewTransfers id="grdArmyReviewTransfers">
          <ng-template kendoGridToolbarTemplate>
            <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
            <button type="button" class="k-button" icon="file-excel" (click)="exportArmyReviewTransfersToCSV()">
              Export to CSV
            </button>
          </ng-template>
          <kendo-grid-excel fileName="ReviewTransfers.xlsx" [fetchData]="this.allArmyReviewTransfersData"></kendo-grid-excel>
          <kendo-grid-column field="piqName" title="PIQ Name" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="payCode" title="Pay Code" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="currentUnitCode" title="Current Unit Code" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="currentPayCode" title="Current Pay Code" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="newUnitCode" title="New Unit Code" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="newPayCode" title="New Pay Code" [filterable]="true"></kendo-grid-column>

        </kendo-grid>
      </ng-template>
    </mat-tab>
    <mat-tab label="Review Non-Transfers">
      <ng-template matTabContent>
        <kendo-grid [kendoGridBinding]="listArmyNonReviewTransfers" [sortable]="true" [scrollable]="'scrollable'"
                    [columnMenu]="{ filter: true }" [groupable]="false" [pageSize]="100"
                    [pageable]="(listArmyNonReviewTransfers && (listArmyNonReviewTransfers.length > 100) ? true : false)" [rowClass]="rowCallback"
                    class="mt-2 condensed" #grdArmyNonReviewTransfers id="grdArmyNonReviewTransfers">
          <ng-template kendoGridToolbarTemplate>
            <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
            <button type="button" class="k-button" icon="file-excel" (click)="exportArmyNonReviewTransfersToCSV()">
              Export to CSV
            </button>
          </ng-template>
          <kendo-grid-excel fileName="NonReviewTransfers.xlsx" [fetchData]="this.allArmyNonReviewTransfersData"></kendo-grid-excel>
          <kendo-grid-column field="piqName" title="PIQ Name" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="payCode" title="Pay Code" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="newPayCode" title="New Pay Code" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="currentUnitCode" title="Current Unit Code" [filterable]="true"></kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </mat-tab>
    <mat-tab label="Unit Strengths">
      <ng-template matTabContent>
        <kendo-grid [kendoGridBinding]="listArmyUnitStrengths" [sortable]="true" [scrollable]="'scrollable'"
                    [columnMenu]="{ filter: true }" [groupable]="false" [pageSize]="100"
                    [pageable]="(listArmyUnitStrengths && (listArmyUnitStrengths.length > 100) ? true : false)" [rowClass]="rowCallback"
                    class="mt-2 condensed" #grdArmyUnitStrengths id="grdArmyUnitStrengths">
          <ng-template kendoGridToolbarTemplate>
            <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
            <button type="button" class="k-button" icon="file-excel" (click)="exportArmyUnitStrengthsToCSV()">
              Export to CSV
            </button>
          </ng-template>
          <kendo-grid-excel fileName="UnitStrengths.xlsx" [fetchData]="this.allArmyUnitStrengthsData"></kendo-grid-excel>
          <kendo-grid-column field="payCode" title="Pay Code" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="attached" title="Attached" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="unattached" title="Unattached" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="totalStrength" title="Total Strength" [filterable]="true"></kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
