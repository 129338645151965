import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation, } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FileRestrictions, SuccessEvent, UploadEvent } from "@progress/kendo-angular-upload";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-import-air-alpha-roster-modal",
  templateUrl: "./import-air-alpha-roster-modal.component.html",
  encapsulation: ViewEncapsulation.None,
  styles: [`
  .k-actions {
    display: none;
  }
`],
})
export class ImportAirAlphaRosterModalComponent implements OnInit {

  uploadSaveUrl: string =
    environment.serviceUrl + "people/importAirAlphaRosterData";
  fileRestrictions: FileRestrictions = {
    allowedExtensions: [".xls", ".xlsx"],
  };

  importAirAlphaRoster = new FormGroup({
    upload: new FormControl(),
    asOfDate: new FormControl()
  });

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private router: Router,
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public model: any,
    public dialogRef: MatDialogRef<ImportAirAlphaRosterModalComponent>
  ) {

  }

  ngOnInit() {
  }

  cancel(): void {
    this.model = null;
    this.dialogRef.close();
  }

  uploadSuccessEventHandler(e: SuccessEvent) {
    console.log("The " + e.operation + " was successful!");

    this.router
      .navigateByUrl("/loading", { skipLocationChange: true })
      .then(() => {
        this.router.navigateByUrl(`alpha-roster?guard=air&asOfDate=${this.datePipe.transform(this.importAirAlphaRoster.get('asOfDate')?.value, 'yyyy-MM-dd')}`);
      });

    this.dialogRef.close();
  }

  uploadEventHandler(e: UploadEvent) {
    e.data = {
      asOfDate: this.datePipe.transform(this.importAirAlphaRoster.get('asOfDate')?.value, 'yyyy-MM-dd'),
    };
  }

  public onUploadButtonClick(upload: any) {
    upload.uploadFiles();
  }
}
