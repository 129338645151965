import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';
import { ReportService } from '../report.service';
import { ReportBankDraftDiscrepancyNew } from './report-bank-draft-discrepancy-new';
import { MediaObserver } from "@angular/flex-layout";
import { Filter } from '../../_bases/filter';
import { AuthenticationService } from '../../auth/auth.service';
import { ReportBankDraftDiscrepencyPremiumsNotEqual } from './report-bank-draft-discrepancy-premiums-not-equal';
import { ReportBankDraftDiscrepancyRemoved } from './report-bank-draft-discrepancy-removed';

@Component({
  selector: 'app-report-bank-draft-discrepancy',
  templateUrl: './report-bank-draft-discrepancy.component.html',
  animations: [
  ]
})
export class ReportBankDraftDiscrepancyComponent implements OnInit {

  @ViewChild(GridComponent) public gridDiscrepancyNew!: GridComponent;
  @ViewChild(GridComponent) public gridDiscrepancyRemoved!: GridComponent;
  @ViewChild(GridComponent) public gridDiscrepencyPremiumsNotEqual!: GridComponent;


  listReportBankDraftDiscrepancyNew: ReportBankDraftDiscrepancyNew[] = [];
  listReportBankDraftDiscrepencyPremiumsNotEqual: ReportBankDraftDiscrepencyPremiumsNotEqual[] = [];
  listReportBankDraftDiscrepancyRemoved: ReportBankDraftDiscrepancyRemoved[] = [];
  filters: Filter[] = [];

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: ReportService,
    public media: MediaObserver,
    public authService: AuthenticationService,
    private ngZone: NgZone,
  ) {
  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {

    this.service
      .getByCustomAction$<ReportBankDraftDiscrepancyNew>("getBankDraftDiscrepencyNew", ReportBankDraftDiscrepancyNew, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {
        console.log(data);
        this.listReportBankDraftDiscrepancyNew = data;
      });

    this.service
      .getByCustomAction$<ReportBankDraftDiscrepencyPremiumsNotEqual>("getBankDraftDiscrepencyPremiumsNotEqual", ReportBankDraftDiscrepencyPremiumsNotEqual, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {
        console.log(data);
        this.listReportBankDraftDiscrepencyPremiumsNotEqual = data;
      });

    this.service
      .getByCustomAction$<ReportBankDraftDiscrepancyRemoved>("getBankDraftDiscrepencyRemoved", ReportBankDraftDiscrepancyRemoved, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {
        console.log(data);
        this.listReportBankDraftDiscrepancyRemoved = data;
      });
  }

  public rowCallback(context: RowClassArgs) {
    return {
    };
  }
}
