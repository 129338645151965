<form *ngIf="model" [formGroup]="theForm" (ngSubmit)="onSubmit()" class="detail-component" novalidate>
  <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="60">
    <div fxFlex>
    </div>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Pmt Type Code</mat-label>
      <input matInput formControlName="pmtTypeCode" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="pmtTypeCode.touched && pmtTypeCode.hasError('required')">
        Pmt Type Code is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('pmtTypeCode')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Pmt Type</mat-label>
      <input matInput formControlName="pmtType" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="pmtType.touched && pmtType.hasError('required')">
        Pmt Type is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('pmtType')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <div fxFlex>
      <label class="k-form-field">
        <input type="checkbox" id="setPmtDueDate" class="k-checkboxX" formControlName="setPmtDueDate">
        <label class="k-checkbox-label" for="setPmtDueDate">Set Pmt Due Date</label>
      </label>
    </div>
    <div fxFlex>
      <label class="k-form-field">
        <input type="checkbox" id="isInsurance" class="k-checkboxX" formControlName="isInsurance">
        <label class="k-checkbox-label" for="isInsurance">Is Insurance?</label>
      </label>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <section class="footer">
      <button mat-raised-button color="primary" type="submit" [disabled]="theForm.pristine || (loading)">
        Save
      </button>
      <button mat-button type="button" (click)="undo()" [disabled]="theForm.pristine">
        Undo
      </button>
      <button mat-button type="button" (click)="cancel()">
        Cancel
      </button>
    </section>
  </div>
</form>
