export class ReportBankDraftDiscrepancyRemoved {

  constructor();
  constructor(jsonData?: ReportBankDraftDiscrepancyRemoved) {
    Object.assign(this, jsonData);
  }

  public id: number | undefined;
  public person: string | undefined;
  public ssn: string | undefined;
  public abaNumber: string | undefined;
  public bankAccNbr: string | undefined;
  public premium: number | undefined;
}
