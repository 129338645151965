<form *ngIf="model" [formGroup]="theForm" (ngSubmit)="onSubmit()" class="detail-component" novalidate>
  <div fxLayout="row" fxLayoutGap="8px" class="m-2">
    <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="40">
      <mat-form-field appearance="fill">
        <mat-label>Brigade Code</mat-label>
        <input matInput formControlName="brigadeCode" />
        <mat-hint></mat-hint>
        <mat-error *ngIf="brigadeCode.touched && brigadeCode.hasError('required')">
          Brigade Code is <strong>required</strong>
        </mat-error>

        <mat-error *ngFor="let e of fieldErrors('brigadeCode')">
          {{ e.params }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Brigade Name</mat-label>
        <input matInput formControlName="brigadeName" />
        <mat-hint></mat-hint>
        <mat-error *ngIf="brigadeName.touched && brigadeName.hasError('required')">
          Brigade Name is <strong>required</strong>
        </mat-error>

        <mat-error *ngFor="let e of fieldErrors('brigadeName')">
          {{ e.params }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Brigade Seq Nbr</mat-label>
        <input matInput formControlName="brigadeSeqNbr" type="number" />
        <mat-hint></mat-hint>
        <mat-error *ngIf="brigadeSeqNbr.touched && brigadeSeqNbr.hasError('required')">
          Brigade Seq Nbr is <strong>required</strong>
        </mat-error>

        <mat-error *ngFor="let e of fieldErrors('brigadeSeqNbr')">
          {{ e.params }}
        </mat-error>
      </mat-form-field>


      <!--<div fxFlex>
        <kendo-textbox-container floatingLabel="x G MEM">
          <input kendoTextBox formControlName="xG_MEM" />

          <mat-error *ngFor="let e of fieldErrors('xG_MEM')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x G ASSOC">
          <input kendoTextBox formControlName="xG_ASSOC" />

          <mat-error *ngFor="let e of fieldErrors('xG_ASSOC')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>-->
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
      <section class="footer">
        <button mat-raised-button color="primary" type="submit" [disabled]="theForm.pristine || (loading)">
          Save
        </button>
        <button mat-button type="button" (click)="undo()" [disabled]="theForm.pristine">
          Undo
        </button>
        <button mat-button type="button" (click)="cancel()">
          Cancel
        </button>
      </section>
    </div>
    <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="60">
      <div *ngIf="model" fxFlex="100" fxLayoutAlign="start stretch">
        <app-battalions-list *ngIf="model.id != 0" fxFlex="100" [filters]="childFilters" [newItemProperties]="{brigadeId: model.id}"></app-battalions-list>
      </div>
    </div>

  </div>

</form>
