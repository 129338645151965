<div class="m-2">

  <h1 class="mr-auto primary">
    Insurance Download File
  </h1>

  <div class="mt-2">
    <kendo-grid [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [columnMenu]="{ filter: true }"
      [groupable]="false" [pageSize]="100" [pageable]="(list && (list.length > 100) ? true : false)"
      [rowClass]="rowCallback" class="condensed" #grid fxFlex id="grdAmericanEquityDownloadFile">
      <ng-template kendoGridToolbarTemplate>
        <!--<button type="button" kendoGridPDFCommand icon="file-pdf">Export to PDF</button>-->
        <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
        <button type="button" class="k-button" icon="file-excel" (click)="exportToCSV()">Export to CSV</button>
        <button type="button" class="k-button" icon="file-excel" (click)="showMonthlySummary()">
          <mat-icon>functions</mat-icon>Show Summary
        </button>
      </ng-template>
      <kendo-grid-excel fileName="InsuranceDownloadFile.xlsx" [fetchData]="this.allData"></kendo-grid-excel>
      <kendo-grid-pdf fileName="InsuranceDownloadFile.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
        [landscape]="true">
        <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
        <!--<ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
       <div class="page-template">
        <div class="header">
           <div style="float: right">
             Page {{ pageNum }} of {{ totalPages }}
           </div>
         </div>
          <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
      </div>
    </ng-template>-->
      </kendo-grid-pdf>
      <kendo-grid-column field="ssn" title="SSN" [filterable]="true">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{dataItem.ssn | mask: '000-00-0000'}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="lastName" title="Last Name" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="firstName" title="First Name" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="middleName" title="Middle Name" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="memberDOB" title="Member DOB" [filterable]="true" filter="date" format="{0:MM/dd/yyyy}">
      </kendo-grid-column>
      <kendo-grid-column field="effectiveDate" title="Effective Date" [filterable]="true" filter="date"
        format="{0:MM/dd/yyyy}"></kendo-grid-column>
      <kendo-grid-column field="amEqUnitCode" title="Am Eq Unit Code" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="memberCoverageCode" title="Member Coverage Code" [filterable]="true">
      </kendo-grid-column>
      <kendo-grid-column field="memberCoverage" title="Member Coverage" [filterable]="true" format="{0:c}">
      </kendo-grid-column>
      <kendo-grid-column field="memberPremium" title="Member Premium" [filterable]="true" format="{0:c}">
      </kendo-grid-column>

      <kendo-grid-column field="spouseCoverageCode" title="Spouse Coverage Code" [filterable]="true">
      </kendo-grid-column>
      <kendo-grid-column field="spouseCoverage" title="Spouse Coverage" [filterable]="true" format="{0:c}">
      </kendo-grid-column>
      <kendo-grid-column field="spousePremium" title="Spouse Premium" [filterable]="true" format="{0:c}">
      </kendo-grid-column>
      <kendo-grid-column field="spouseDOB" title="Spouse DOB" [filterable]="true" filter="date" format="{0:MM/dd/yyyy}">
      </kendo-grid-column>

      <kendo-grid-column field="dependentCoverageCode" title="Dependent Coverage Code" [filterable]="true">
      </kendo-grid-column>
      <kendo-grid-column field="dependentCoverage" title="Dependent Coverage" [filterable]="true" format="{0:c}">
      </kendo-grid-column>
      <kendo-grid-column field="dependentPremium" title="Dependent Premium" [filterable]="true" format="{0:c}">
      </kendo-grid-column>

      <kendo-grid-column field="nextPmtDue" title="Next Payment Due" [filterable]="true" filter="date" format="{0:MM/dd/yyyy}"></kendo-grid-column>
      <kendo-grid-column field="unitCode" title="unit Code" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="pmtType" title="Payment Type" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="premiumDue" title="Premium Due" [filterable]="true" format="{0:c}"></kendo-grid-column>

    </kendo-grid>
  </div>

</div>
