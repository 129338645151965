import { Component, OnInit, NgZone, ViewChild, Input } from '@angular/core';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';
import { Filter } from '../../../_bases/filter';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter } from '@progress/kendo-data-query/dist/npm/transducers';
import { Lookup } from '../../../lookups/lookup';
import { LookupService } from '../../../lookups/lookup.service';
import { AuthenticationService } from '../../../auth/auth.service';
import { PersonChildrenReadOnly } from '../personchildren-readonly';
import { PersonChildrenService } from '../personchildren.service';

@Component({
  selector: 'app-personchildren-list',
  templateUrl: './personchildren-list.component.html',
  animations: [
  ]
})
export class PersonChildrenListComponent implements OnInit {

  @ViewChild(GridComponent)
  public grid!: GridComponent;

  list: PersonChildrenReadOnly[] = [];
  @Input() filters: Filter[] = [];
  @Input() newItemProperties: any;
  public loading: boolean = false;

  public formGroup: FormGroup | any;
  private editedRowIndex: number | undefined;

  private increment: number = 0;
  createPersonChildFormGroup = (dataItem: PersonChildrenReadOnly) =>
    new FormGroup({
      id: new FormControl(dataItem.id),
      personId: new FormControl(dataItem.personId, Validators.required),
      firstName: new FormControl(dataItem.firstName, Validators.required),
      lastName: new FormControl(dataItem.lastName, Validators.required),
      middleName: new FormControl(dataItem.middleName, Validators.required),
      dob: new FormControl(dataItem.dob, Validators.required),
      gender: new FormControl(dataItem.gender, Validators.required),
      isNew: new FormControl(dataItem.isNew == true ? true : false),
    });

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: PersonChildrenService,
    private ngZone: NgZone,
    private lookupService: LookupService,
    private authService: AuthenticationService,
  ) {
  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {

    this.service
      .getByCustomAction$<PersonChildrenReadOnly>("list", PersonChildrenReadOnly, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {
        this.list = data;
      });
  }

  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }

  private fitColumns(): void {
    //this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
    //  this.grid.autoFitColumns();
    //});
  }

  public rowCallback(context: RowClassArgs) {
    return {

    };
  }

  public addPersonChildHandler(evt: any) {
    this.closePersonChildEditor(evt.sender);

    this.increment += 1;

    this.formGroup = this.createPersonChildFormGroup({
      personId: this.filters[0].value,
      firstName: '',
      lastName: '',
      middleName: '',
      dob: new Date(),
      id: this.increment,
      gender: '',
      isNew: true,
    });

    evt.sender.addRow(this.formGroup);
  }

  public editPersonChildHandler({ sender, rowIndex, dataItem }: { sender: any, rowIndex: any, dataItem: any }) {
    this.closePersonChildEditor(sender);
    this.formGroup = this.createPersonChildFormGroup(dataItem);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelPersonChildHandler({ sender, rowIndex }: { sender: any, rowIndex: any }) {
    this.closePersonChildEditor(sender, rowIndex);
  }

  public savePersonChildHandler({ sender, rowIndex, formGroup, isNew }: { sender: any, rowIndex: any, formGroup: any, isNew: any }) {

    const personChildren: PersonChildrenReadOnly = formGroup.value;

    if (isNew) {
      if (this.list == null) {
        this.list = [];
      }
      this.list.push(personChildren);
    }
    else {
      this.list.forEach(o => {

        if (o.id == personChildren.id) {
          o.firstName = personChildren.firstName;
          o.lastName = personChildren.lastName;
          o.middleName = personChildren.middleName;
          o.dob = personChildren.dob;
          o.gender = personChildren.gender;
          o.isNew = personChildren.isNew;
        }
      });
    }

    setTimeout(() => {
      this.authService.eventPersonSubDataEventRaised(true);
    }, 500);
    this.grid.data = this.list;
    sender.closeRow(rowIndex);
  }

  public removePersonChildHandler({ dataItem }: { dataItem: any }) {
    let tempList: PersonChildrenReadOnly[] = [];

    this.list.forEach(o => {

      if (o.id != dataItem.id) {
        tempList.push(o);
      }
    });

    setTimeout(() => {
      this.authService.eventPersonSubDataEventRaised(true);
    }, 500);
    this.list = tempList;
    this.grid.data = this.list;
  }

  private closePersonChildEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
}
