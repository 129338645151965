export class ReportBankDraft {

  constructor();
  constructor(jsonData?: ReportBankDraft) {
    Object.assign(this, jsonData);
  }

  public id: number | undefined;
  public ssn: string | undefined;
  public person: string | undefined;
  public bankAccNbr: string | undefined;
  public premium: number | undefined;
  public abaNumber: string | undefined;
  public bankName: string | undefined;
  public firstName: string | undefined;
  public lastName: string | undefined;
  public expCol1: string | undefined;
  public expCol2: string | undefined;
  public expCol3: string | undefined;
  public expCol4: string | undefined;
  public expCol5: string | undefined;
  public expCol6: string | undefined;
  public expCol7: string | undefined;
}
