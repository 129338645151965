<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <h2 mat-dialog-title>
    DOD Id Exists
  </h2>

  <div class="mat-dialog-content" style="display:block !important">
    <div fxLayout="column" fxLayoutGap="8px" class="m-2">
      <div fxFlex *ngIf="this.model">
          <p>
            The DOD Id entered, is already registered.
          </p>
          <p>
            You are enrolled in the State Sponsored Life Insurance (SSLI) benefit administered by the North Carolina National Guard.
            If you would like to update your personal information and change your beneficiary,
            <a href="javascript:void(0);" (click)="navigateToFindMyPolicy()">click here</a>. If you would like to increase coverage,  <a href="javascript:void(0);" (click)="navigateToFindMyPolicy()">click here</a>.
          </p>
      </div>
    </div>
  </div>
  <section class="footer">
    <button mat-stroked-button type="button" (click)="cancel()">
      Close
    </button>
  </section>
</div>
