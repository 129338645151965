import { Component, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { FormError } from "../shared/errors/form-error";
import { WebApiMessage } from "../shared/errors/webapi-message";
import { FormValidation } from "../shared/forms/form-validation";

@Component({
  template: "",
}) // implements OnChanges
export class BaseItemComponent {
  public theForm: FormGroup | any;
  protected parentId: number | any;
  protected errorMessages: WebApiMessage[] | any;
  protected submitted$ = false;
  protected saving$ = false;
  public environmentDefaultNewId: any = environment.defaultNewId;
  protected ngUnsubscribe: Subject<boolean> = new Subject();

  public formErrors(): FormError[] | any {
    if (this.submitted$ && this.theForm.errors) {
      return FormValidation.getErrors(this.theForm);
    } else {
      return undefined;
    }
  }

  public fieldErrors(name: string): FormError[] | any {
    let control = FormValidation.findFieldControl(name, this.theForm);
    if (control && (control.touched || this.submitted$) && control.errors) {
      return FormValidation.getErrors(control);
    } else {
      return undefined;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["filters"]) {
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  // TODO: added this to shortcut some bugs. need to remove the onSubmit() calls in templates
  saving: boolean = false;
  onSubmit(...args: any[]) {
    console.log("This should not be hit.");
  }
}
