import { NgModule } from '@angular/core';

// kendo
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { PanelBarModule } from '@progress/kendo-angular-layout';
import { UploadModule } from '@progress/kendo-angular-upload';
import { PopupModule } from '@progress/kendo-angular-popup';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@progress/kendo-angular-editor';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';

@NgModule({
  imports: [InputsModule, ButtonsModule, ChartsModule, GridModule, ExcelModule, DropDownsModule, DateInputsModule, PanelBarModule, UploadModule, FormsModule, ReactiveFormsModule, PopupModule, EditorModule, PDFExportModule, ExcelExportModule, PDFModule, ExcelModule, MultiSelectModule],
  exports: [InputsModule, ButtonsModule, ChartsModule, GridModule, ExcelModule, DropDownsModule, DateInputsModule, PanelBarModule, UploadModule, FormsModule, ReactiveFormsModule, PopupModule, EditorModule, PDFExportModule, ExcelExportModule, PDFModule, ExcelModule, MultiSelectModule],
})
export class KendoControlsModule { }
