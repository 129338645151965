export class Brigade {

	constructor() 
	constructor(jsonData: Brigade)
	constructor(jsonData?: Brigade) {
		if(jsonData) {
			Object.assign(this, jsonData);
    }
    else {
      this.id = 0;
    }
	}

  id: number | undefined;
  brigadeCode: string | undefined;
  brigadeName: string | undefined;
  brigadeSeqNbr: string | undefined;
  //xG_MEM: string | undefined;
  //xG_ASSOC: string | undefined;

}


