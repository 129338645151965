<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      Import Air Jumps
    </h1>
  </div>
  <div fxFlex="100">
    <div fxLayout="row wrap" fxLayoutAlign="center stretch" fxLayoutGap="10px" [formGroup]="importAirJumps">
      <div fxFlex="25%">
        <kendo-upload formControlName="upload"
                      #upload
                      [restrictions]="fileRestrictions"
                      [autoUpload]="false"
                      [batch]="false"
                      [multiple]="false"
                      [saveUrl]="uploadSaveUrl"
                      (success)="uploadSuccessEventHandler($event)"
                      (upload)="uploadEventHandler($event)">
        </kendo-upload>
      </div>
      <div fxFlex="20%">
        <label class="k-form-field">
          <input type="checkbox" id="tagRecords" class="k-checkboxX" formControlName="tagRecords">
          <label class="k-checkbox-label" for="tagRecords">Would you like to tag the imported records?</label>
        </label>
      </div>
      <div fxFlex="20%">
        <button kendoButton [primary]="true" icon="check-outline" (click)="onUploadButtonClick(upload)" style="margin-left: 3px;">Upload</button>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="8px" class="m-2">
    <kendo-grid [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [columnMenu]="{ filter: true }"
                [groupable]="false" [pageSize]="100"
                [pageable]="(list && (list.length > 100) ? true : false)" [rowClass]="rowCallback" class="condensed" #grid fxFlex
                id="grdImportJumpsAirJumps">
      <ng-template kendoGridToolbarTemplate>
        <button type="button" kendoGridPDFCommand icon="file-pdf">Export to PDF</button>
        <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
        <button type="button" class="k-button" icon="file-excel" (click)="exportToCSV()">Export to CSV</button>
      </ng-template>
      <kendo-grid-excel fileName="JumpsAirJumps.xlsx" [fetchData]="this.allData"></kendo-grid-excel>
      <kendo-grid-pdf fileName="JumpsAirJumps.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true" [landscape]="true">
        <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
      </kendo-grid-pdf>
      <kendo-grid-column field="ssn" title="SSN" [filterable]="true">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{dataItem.ssn | mask: '000-00-0000'}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="personName" title="Person" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="collectedAmt" title="Collected Amt" [filterable]="true" format="{0:c}"></kendo-grid-column>
      <kendo-grid-column field="middleName" title="Middle Name" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="monthsCollected" title="Months Collected" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="premAmt" title="Prem Amt" [filterable]="true" format="{0:c}"></kendo-grid-column>
      <kendo-grid-column field="sepCode" title="Sep Code" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="starts" title="Starts" [filterable]="true"></kendo-grid-column>
    </kendo-grid>
  </div>

</div>
