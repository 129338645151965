import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';
import { MediaObserver } from "@angular/flex-layout";
import { DatePipe } from '@angular/common';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { JumpsService } from '../jumps.service';
import { AuthenticationService } from '../../../auth/auth.service';
import { Filter } from '../../../_bases/filter';
import { environment } from '../../../../environments/environment';
import { FileRestrictions, SuccessEvent, UploadEvent } from '@progress/kendo-angular-upload';
import { ImportAirJumps } from './import-air-jumps';

@Component({
  selector: 'app-import-air-jumps',
  templateUrl: './import-air-jumps.component.html',
  animations: [
  ]
})
export class ImportAirJumpsComponent implements OnInit {

  @ViewChild(GridComponent)
  public grid!: GridComponent;

  list: ImportAirJumps[] = [];
  filters: Filter[] = [];
  uploadSaveUrl: string =
    environment.serviceUrl + "jumpsdatas/importAirJumpsData";
  fileRestrictions: FileRestrictions = {
    allowedExtensions: [".txt"],
  };

  importAirJumps = new FormGroup({
    upload: new FormControl(),
    tagRecords: new FormControl()
  });

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: JumpsService,
    public media: MediaObserver,
    public authService: AuthenticationService,
    private ngZone: NgZone,
    public datePipe: DatePipe,
  ) {
    this.allData = this.allData.bind(this);
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.list, {}).data,
    };

    return result;
  }

  ngOnInit(): void {
    this.loadList();
  }

  uploadSuccessEventHandler(e: SuccessEvent) {
    console.log("The " + e.operation + " was successful!");
    this.loadList();
  }

  uploadEventHandler(e: UploadEvent) {
    e.data = {
      tagRecords: this.importAirJumps.get('tagRecords')?.value,
    };
  }

  public onUploadButtonClick(upload: any) {
    upload.uploadFiles();
  }


  loadList() {

    this.filters = [];

    this.service
      .getByCustomAction$<ImportAirJumps>("getJumpsData", ImportAirJumps, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {

        console.log(data);

        this.list = data;
      });
  }

  exportToCSV() {
    let columnHeaders: any[] = [
      { field: "ssn", title: "ssn" },
      { field: "personName", title: "person" },
      { field: "premAmt", title: "premAmt" },
      { field: "collectedAmt", title: "collectedAmt" },
      { field: "monthsCollected", title: "monthsCollected" },
      { field: "sepCode", title: "sepCode" },
      { field: "starts", title: "starts" },
    ];

    this.service.ExportToCSV(columnHeaders, this.list, ["id"], "AirJumps.csv");
  }

  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }


  private fitColumns(): void {
    this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
      this.grid.autoFitColumns();
    });
  }

  public rowCallback(context: RowClassArgs) {
    return {
    };
  }
}
