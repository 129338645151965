export class ImportArmyJumps {

  constructor();
  constructor(jsonData?: ImportArmyJumps) {

    if (jsonData) {
      Object.assign(this, jsonData);
      this.starts = (jsonData.starts && jsonData.starts.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.starts) : null;
    }
  }

  public id: number | undefined;
  public ssn: string | undefined;
  public collectedAmt: number | undefined;
  public monthsCollected: number | undefined;
  public personName: string | undefined;
  public premAmt: number | undefined;
  public sepCode: string | undefined;
  public starts?: Date | null;
}
