  <kendo-grid #grid fxFlex="100" [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [filterable]="false"  [sort]="sort"
            [groupable]="false" [pageSize]="25" [pageable]="(list && (list.length > 25) ? true : false)" [rowClass]="rowCallback"
            class="condensed" [resizable]="true"
            (dataStateChange)="onDataStateChange($event)"
            (edit)="editPersonPaymentInfoHandler($event)"
            (cancel)="cancelPersonPaymentInfoHandler($event)"
            (save)="savePersonPaymentInfoHandler($event)"
            (remove)="removePersonPaymentInfoHandler($event)"
            (add)="addPersonPaymentInfoHandler($event)">
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand>Add new</button>
  </ng-template>
  <!--<kendo-grid-column field="personId" title="Person Id" [filterable]="true" filter="numeric"></kendo-grid-column>-->
  <kendo-grid-column field="date" title="Date" editor="date" [filterable]="true" filter="date" format="{0:d}"></kendo-grid-column>
  <kendo-grid-column field="premiumAmount" title="Premium Amount" editor="numeric" [filterable]="true" filter="numeric" format="{0:c}"></kendo-grid-column>
  <kendo-grid-column field="amountPaid" title="Amount Paid" editor="numeric" [filterable]="true" filter="numeric" format="{0:c}"></kendo-grid-column>
  <kendo-grid-column field="overUnder" title="Over Under" editor="numeric" [filterable]="true" filter="numeric" format="{0:c}"></kendo-grid-column>
  <kendo-grid-column field="monthsCollected" title="Months Collected" editor="numeric" [filterable]="true" filter="numeric"></kendo-grid-column>
  <kendo-grid-column field="monthsInsufficient" title="Months Insufficient" editor="numeric" [filterable]="true" filter="numeric"></kendo-grid-column>
  <kendo-grid-column field="comment" title="Comment" [filterable]="true"></kendo-grid-column>
  <kendo-grid-column field="paymentTypeId" title="Payment Type" [filterable]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{paymentTypes(dataItem.paymentTypeId)?.value}}
    </ng-template>
    <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">
      <kendo-combobox [data]="paymentTypeIdLookups$ | async" textField="value" valueField="key"
                      [valuePrimitive]="true"
                      [suggest]="true" [popupSettings]="{width: 360,height: 150}"
                      [formControl]="formGroup.get(column.field)">
      </kendo-combobox>
    </ng-template>

  </kendo-grid-column>
  <kendo-grid-column field="sepCode" title="SepCode" [filterable]="true"></kendo-grid-column>
  <!--<kendo-grid-column field="personDepositId" title="personDepositId" [filterable]="true"></kendo-grid-column>
  <kendo-grid-column field="starts" title="starts" [filterable]="true"></kendo-grid-column>
  <kendo-grid-column field="rptCategory" title="rpt Category" [filterable]="true"></kendo-grid-column>
  <kendo-grid-column field="acct" title="Acct" [filterable]="true"></kendo-grid-column>-->
  <kendo-grid-command-column title="-">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridEditCommand [primary]="true">Edit</button>
      <button kendoGridRemoveCommand>Remove</button>
      <button kendoGridSaveCommand [disabled]="formGroup?.invalid">
        {{ isNew ? "Add" : "Update" }}
      </button>
      <button kendoGridCancelCommand>
        {{ isNew ? "Discard changes" : "Cancel" }}
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
