export class GroupReadOnly {

  constructor()
  constructor(jsonData: GroupReadOnly)
  constructor(jsonData?: GroupReadOnly) {
    if (jsonData) {
      Object.assign(this, jsonData);
    }
  }

  id: number | undefined;
  groupName: string | undefined;
  insGroup: boolean | undefined;
  isMembership: boolean | undefined;
  //xCommitteeNbr: string | undefined;
  //xCommitteeYear: number | undefined;
  //xReportHeading1: string | undefined;
  //xReportHeading2: string | undefined;

}


