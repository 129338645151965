<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      DFAS Deposit Report
    </h1>
  </div>
  <div fxLayout="row" fxLayoutGap="8px" class="m-2">
    <kendo-grid [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [columnMenu]="{ filter: true }"
                [groupable]="false" [pageSize]="100"
                [pageable]="(list && (list.length > 100) ? true : false)" [rowClass]="rowCallback" class="condensed" #grid fxFlex
                id="grdDFASDeposit">
      <ng-template kendoGridToolbarTemplate>
        <button type="button" kendoGridPDFCommand icon="file-pdf">Export to PDF</button>
        <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
      </ng-template>
      <kendo-grid-excel fileName="DFASDeposit.xlsx" [fetchData]="this.allData"></kendo-grid-excel>
      <kendo-grid-pdf fileName="DFASDeposit.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true" [landscape]="true">
        <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
      </kendo-grid-pdf>
      <kendo-grid-column field="dfasCategory" title="DFAS Category" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="amount" title="Amount" [filterable]="true" format="{0:c}"></kendo-grid-column>
      <kendo-grid-column field="year" title="Year" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="month" title="Month" [filterable]="true"></kendo-grid-column>
    </kendo-grid>
  </div>

</div>
