
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, Validators } from "@angular/forms"
import { ActivatedRoute, Router, ParamMap } from "@angular/router";


import { PopupSettings } from '@progress/kendo-angular-dropdowns';
import { BaseItemComponent } from '../../_bases/base-item-component';
import { NewApplication } from './newapplication';


@Component({
  selector: 'app-newapplicationsubmitted',
  templateUrl: './newapplicationsubmitted.component.html',
})
export class NewApplicationSubmittedComponent extends BaseItemComponent implements OnInit, OnDestroy {

  @Input() model: NewApplication | any;
  @Input() opened: boolean = false;
  @Output() onSaved: EventEmitter<NewApplication> = new EventEmitter<NewApplication>();
  @Output() onCancelled: EventEmitter<NewApplication> = new EventEmitter<NewApplication>();

  isEditApplication: boolean = false;
  isDocUSign: boolean = true;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router) {
    super();

    if (this.router.url.endsWith('applicationEditsubmitted')) {
      this.isEditApplication = true;

      const navigation = this.router.getCurrentNavigation();

      if (navigation != null) {
        const state = navigation.extras.state as {
          docUSign: boolean,
          isEditApplication: boolean,
        };

        if (state != undefined) {
          this.isDocUSign = state.docUSign;
          this.isEditApplication = state.isEditApplication;
        }
      }
    }
  }

  ngOnInit() {

  }

  ngOnChanges() {

  }

  undo(): void {
  }
}
