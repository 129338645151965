export class ApplicantUpdatesApproveReject {

  constructor();
  constructor(jsonData?: ApplicantUpdatesApproveReject) {

    if (jsonData) {
      Object.assign(this, jsonData);
    }
  }

  public personId: number | any;
  public isApproved: boolean | any;
}
