
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, Validators } from "@angular/forms"
import { ActivatedRoute, Router, ParamMap } from "@angular/router";

import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { PopupSettings } from '@progress/kendo-angular-dropdowns';

import { CoverageType } from "../coveragetype";
import { CoverageTypeService } from "../coveragetype.service";
import { BaseItemComponent } from '../../../_bases/base-item-component';
import { Filter } from '../../../_bases/filter';
import { FormValidation } from '../../../shared/forms/form-validation';


@Component({
  selector: 'app-coveragetype-detail',
  templateUrl: './coveragetype-detail.component.html',
})
export class CoverageTypeComponent extends BaseItemComponent implements OnInit, OnDestroy {

  model: CoverageType | any;
  public loading: boolean = false;


  constructor(
    private fb: FormBuilder,
    private service: CoverageTypeService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router) {
    super();
  }

  ngOnInit() {

    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => this.service.getSingleByCustomAction$<CoverageType>("getSingle", CoverageType, [new Filter("id", params.get("id"), "eq")]))
      )
      .subscribe((model: CoverageType | any) => {

        this.createForm(model);
      });
  }

  ngOnChanges() {
    if (this.model) {
      this.createForm(this.model);
    }
  }

  createForm(model?: CoverageType): void {
    if (this.theForm) {
      this.model = model;
      this.theForm.reset(this.bindForm());
    } else {
      this.theForm = this.fb.group({
        id: [model?.id, Validators.required],
        coverageTypeName: model?.coverageTypeName,
        coverageTypeUnitCode: model?.coverageTypeUnitCode,
        comments: model?.comments,
        seqNbr: model?.seqNbr,
      });

      this.model = model;
    }
  }

  bindForm(): any {
    return {
      id: this.model.id,
      coverageTypeName: this.model.coverageTypeName,
      coverageTypeUnitCode: this.model.coverageTypeUnitCode,
      comments: this.model.comments,
      seqNbr: this.model.seqNbr,
    };
  }

  onSubmit() {
    if (this.theForm?.valid) {

      this.loading = true;
      this.service.postModelByCustomAction$<CoverageType>("upsert", this.prepareSave(), CoverageType)
        .pipe(
          catchError((err, caught) => {
            this.loading = false;
            FormValidation.parseSubmitErrors(err, this.theForm);
            return of(null as CoverageType | any);
          })
        )
        .subscribe((savedData: any) => {
          this.loading = false;
          if (savedData) {
            this.goBack();
          }
        })
    } else {
      FormValidation.validateAllFormFields(this.theForm);
    }
  }

  prepareSave(): CoverageType {
    const formModel = this.theForm.value;

    let saveModel: CoverageType = new CoverageType({
      id: formModel.id,
      coverageTypeName: formModel.coverageTypeName,
      coverageTypeUnitCode: formModel.coverageTypeUnitCode,
      comments: formModel.comments,
      seqNbr: formModel.seqNbr,
    });
    return saveModel;
  }

  cancel(): void {
    this.goBack();
  }

  goBack() {
    this.location.back();
  }

  undo(): void {
    this.theForm.reset(this.bindForm());
  }

  get id(): AbstractControl {
    return this.theForm.get('id');
  }
  get coverageTypeName(): AbstractControl {
    return this.theForm.get('coverageTypeName');
  }
  get coverageTypeUnitCode(): AbstractControl {
    return this.theForm.get('coverageTypeUnitCode');
  }
  get comments(): AbstractControl {
    return this.theForm.get('comments');
  }
  get seqNbr(): AbstractControl {
    return this.theForm.get('seqNbr');
  }

}
