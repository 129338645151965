export class ArmyReviewPayCodes {

  constructor();
  constructor(jsonData?: ArmyReviewPayCodes) {

    if (jsonData) {
      Object.assign(this, jsonData);
    }
  }

  public id: number | undefined;
  public payCode: string | undefined;
  public numberOfSoldiers: number | undefined;
  public ncngaUnitCode: string | undefined;
  public ncngaUnit: string | undefined;
}
