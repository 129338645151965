<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <h3 mat-dialog-title>
    Import Membership Data
  </h3>

  <div class="mat-dialog-content" style="display:block !important">
    <div fxLayout="column" fxLayoutGap="8px" class="m-2" [formGroup]="importMembership">
      <div fxFlex>
        <kendo-upload formControlName="upload"
                      #upload
                      [restrictions]="fileRestrictions"
                      [autoUpload]="false"
                      [batch]="false"
                      [multiple]="false"
                      [saveUrl]="uploadSaveUrl"
                      (success)="uploadSuccessEventHandler($event)"
                      (upload)="uploadEventHandler($event)">
        </kendo-upload>
      </div>
    </div>
  </div>
  <section class="footer">
    <button mat-button type="button" (click)="cancel()">
      Close
    </button>

    <button type="button" kendoButton [primary]="true" icon="check-outline" (click)="onUploadButtonClick(upload)" style="margin-left: 3px;">
      Import Membership Data
    </button>
  </section>
</div>
