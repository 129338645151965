import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { FormError } from "../errors/form-error";
import { WebApi } from "../errors/webapi";
import { WebApiError } from "../errors/webapi-error";

export class FormValidation {
  public static getErrors(control: AbstractControl | any): FormError[] {
    return Object.keys(control.errors)
      .filter((error) => control.errors[error])
      .map((error) => {
        let params = control.errors[error];
        return {
          error: error,
          params: params === true || params == {} ? null : params,
        };
      });
  }

  public static findFieldControl(
    field: string,
    theForm: FormGroup
  ): AbstractControl {
    let control: AbstractControl | any;
    if (field === "base") {
      control = theForm;
    } else if (theForm.contains(field)) {
      control = theForm.get(field);
    } else if (
      field.match(/_id$/) &&
      theForm.contains(field.substring(0, field.length - 3))
    ) {
      control = theForm.get(field.substring(0, field.length - 3));
    } else if (field.indexOf(".") > 0) {
      let group = theForm;
      field.split(".").forEach((f) => {
        if (group.contains(f)) {
          control = group.get(f);
          if (control instanceof FormGroup) group = control;
        } else {
          control = group;
        }
      });
    } else {
      // Field is not defined in form but there is a validation error for it, set it globally
      control = theForm;
    }
    return control;
  }

  private fetchFieldErrors(data: any, field: string): any {
    const errors: any = {};
    data[field].forEach((e : any) => {
      let name: string = e.error;
      delete e.error;
      errors[name] = e;
    });
    return errors;
  }

  public static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        FormValidation.validateAllFormFields(control);
      }
    });
  }

  public static getErrorCount(container: FormGroup | any, controls: string[]): number {
    let errorCount = 0;
    controls.forEach((control) => {
      if (container.controls.hasOwnProperty(control)) {
        if (container.controls[control].errors) {
          errorCount += Object.keys(container.controls[control].errors).length;
          console.log(errorCount);
        }
      }
    });
    return errorCount;
  }

  public static parseSubmitErrors(reason: any, theForm: FormGroup) {
    let err: WebApiError | any = WebApi.parseError(reason.error);
    Object.keys(err.modelState).forEach((key) => {
      let notice: any = {};
      notice[key] = err.modelState[key][0];
      let control = FormValidation.findFieldControl(key, theForm);
      control.setErrors(notice);
    });
  }
}
