
import { DatePipe, Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";

import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BaseItemComponent } from '../../../_bases/base-item-component';
import { Filter } from '../../../_bases/filter';
import { AuthenticationService } from '../../../auth/auth.service';
import { Lookup } from '../../../lookups/lookup';
import { LookupService } from '../../../lookups/lookup.service';
import { FormValidation } from '../../../shared/forms/form-validation';
import { PersonCommentListComponent } from '../../personcomments/personcomment-list/personcomment-list.component';
import { PersonInsBeneficiaryListComponent } from '../../personinsbeneficiaries/personinsbeneficiary-list/personinsbeneficiary-list.component';
import { PersonPaymentInfoListComponent } from '../../personpaymentinfos/personpaymentinfo-list/personpaymentinfo-list.component';
import { NavigatorService } from '../navigator.service';
import { Person } from './person';
import { PersonMembershipPaymentListComponent } from '../../personmembershippayments/personmembershippayment-list/personmembershippayment-list.component';
import { PersonMembershipPaymentReadOnly } from '../../personmembershippayments/personmembershippayment-readonly';

interface MembershipPaymentInfo {
  groupName: string | undefined;
  value: any | undefined;
}

@Component({
  selector: 'app-person-detail',
  templateUrl: './person-detail.component.html',
  //styleUrls: ['./person.component.scss']
})
export class PersonComponent extends BaseItemComponent
  implements OnInit, OnDestroy {
  childFilters: Filter[] = [];
  public model: Person | any;
  @ViewChild(PersonInsBeneficiaryListComponent) private personInsBeneficiaryListComponent: PersonInsBeneficiaryListComponent | any;
  @ViewChild(PersonPaymentInfoListComponent) private personPaymentInfoListComponent: PersonPaymentInfoListComponent | any;
  @ViewChild(PersonCommentListComponent) private personCommentListComponent: PersonCommentListComponent | any;
  @ViewChild(PersonMembershipPaymentListComponent) private personMembershipPaymentListComponent: PersonMembershipPaymentListComponent | any;

  unitIdLookups$: Observable<Lookup[]> | any;
  paymentTypeIdLookups$: Observable<Lookup[]> | any;
  coverageTypeIdLookups$: Observable<Lookup[]> | any;
  bankIdLookups$: Observable<Lookup[]> | any;
  bankAccountTypeIdLookups$: Observable<Lookup[]> | any;
  groupIdLookups$: Observable<Lookup[]> | any;
  coverageIdLookups$: Observable<Lookup[]> | any;
  volunteerNetworkIdLookups$: Observable<Lookup[]> | any;
  rankIdLookups$: Observable<Lookup[]> | any;
  showPaymentInfo: boolean = false;
  today = new Date();

  selectedCoveragesData: any[] | any;
  coveragesMember: any[] | any;
  coveragesSpouse: any[] | any;
  coveragesDependent: any[] | any;
  isNew: boolean = true;
  public activeMembershipGroups: MembershipPaymentInfo[] = [];

  constructor(
    private fb: FormBuilder,
    private service: NavigatorService,
    private route: ActivatedRoute,
    private location: Location,
    private lookupService: LookupService,
    private authService: AuthenticationService,
    public snackBar: MatSnackBar,
    public datePipe: DatePipe,
    private router: Router) {
    super();

    this.unitIdLookups$ = this.lookupService.getList("units", []);
    this.paymentTypeIdLookups$ = this.lookupService.getList("paymentTypesOnlyInsurance", []);
    this.coverageTypeIdLookups$ = this.lookupService.getList("coverageTypes", []);
    this.bankIdLookups$ = this.lookupService.getList("banks", []);
    this.rankIdLookups$ = this.lookupService.getList("ranks", []);
    this.bankAccountTypeIdLookups$ = this.lookupService.getList("bankAccountTypes", []);
    this.groupIdLookups$ = this.lookupService.getList("groupsnonmembership", []);
    this.volunteerNetworkIdLookups$ = this.lookupService.getList("volunteernetworks", []);
  }

  ngOnInit() {

    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {

          if (params.get("id") !== null) {
            this.isNew = params.get("id") == "0";
          }

          return this.service.getSingleByCustomAction$<Person>("getSingle", Person, [new Filter("id", params.get("id"), "eq")])
        })
      )
      .subscribe((model: Person | any) => {


        if (model.membershipGroupIds !== undefined && model.membershipGroupIds.length > 0 && model.personMembershipPaymentList !== undefined && model.personMembershipPaymentList.length > 0) {

          model.membershipGroupIds.forEach((groupId: number) => {
            const latestPayment: PersonMembershipPaymentReadOnly[] = model.personMembershipPaymentList
              .filter((payment: PersonMembershipPaymentReadOnly) => payment.groupId === groupId)
              .sort((a: PersonMembershipPaymentReadOnly, b: PersonMembershipPaymentReadOnly) => (b.paymentDate !== undefined ? new Date(b.paymentDate).getTime() : new Date().getTime()) - (a.paymentDate !== undefined ? new Date(a.paymentDate).getTime() : new Date().getTime()));

            if (latestPayment !== null && latestPayment.length > 0) {
              this.activeMembershipGroups.push({ groupName: latestPayment[0].groupName, value: (latestPayment[0].annualExpirationDate !== null && latestPayment[0].annualExpirationDate !== undefined ? new Date(latestPayment[0].annualExpirationDate).toLocaleDateString() : undefined) || latestPayment[0].membershipNumber });
            }
          });
        }

        this.childFilters = [new Filter("personId", model.id)];
        this.createForm(model);

        if (model.coverageTypeId > 0) {
          this.loadCoverages(model.coverageTypeId);
        }

        this.coverageTypeId.valueChanges.subscribe((coverageTypeIdVal: any) => {
          this.loadCoverages(coverageTypeIdVal);
        });

        this.paymentTypeChange(model.paymentTypeId);

      });


    this.authService.raisePersonSubdataEvent$.subscribe(result => {
      if (result == true) {
        this.id.markAsPristine();
        this.id.markAsDirty();
      }
    });
  }

  loadCoverages(coverageTypeId: number) {
    //this.coverageIdLookups$ = this.lookupService.getList("coverages", [new Filter("coverageTypeId", coverageTypeId)]);

    this.coverageIdLookups$ = this.lookupService.getList('coverages', [
      new Filter('coverageTypeId', coverageTypeId),
      new Filter('isAssignable', 1),
    ]);

    this.coverageIdLookups$.subscribe((s: any[]) => {

      this.selectedCoveragesData = s;

      this.coveragesMember = [];
      this.coveragesSpouse = [];
      this.coveragesDependent = [];

      s.forEach((cov) => {
        if (cov.type == 'M') {
          this.coveragesMember.push(cov);
        } else if (cov.type == 'S') {
          this.coveragesSpouse.push(cov);
        } else if (cov.type == 'D') {
          this.coveragesDependent.push(cov);
        }
      });

      this.memberCoverageChange(this.model.memberCoverageId);
      this.spouseCoverageChange(this.model.spouseCoverageId);
      this.dependentCoverageChange(this.model.dependentCoverageId);
    });

  }

  ngOnChanges() {
    if (this.model) {
      this.createForm(this.model);
    }
  }

  createForm(model?: Person): void {
    if (this.theForm) {
      this.model = model;
      this.theForm.reset(this.bindForm());
    } else {
      this.theForm = this.fb.group({
        id: model?.id,
        //ssn: [model?.ssn, Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])],
        ssn: [model?.ssn, Validators.compose([Validators.pattern('^[0-9]*$')])],
        lastName: [model?.lastName, Validators.required],
        firstName: [model?.firstName, Validators.required],
        middleName: model?.middleName,
        suffixName: model?.suffixName,
        rosterName: model?.rosterName,
        unitId: model?.unitId,
        address1: model?.address1,
        address2: model?.address2,
        city: model?.city,
        state: model?.state,
        zip: model?.zip,
        primaryPhone: model?.primaryPhone,
        secondaryPhone: model?.secondaryPhone,
        email: model?.email,
        comment: model?.comment,
        paymentTypeId: model?.paymentTypeId,
        coverageTypeId: model?.coverageTypeId,
        memberCoverageId: model?.memberCoverageId,
        dob: [{ value: this.datePipe.transform(model?.dob, 'MM/dd/yyyy'), disabled: false }],
        effectiveDate: model?.effectiveDate,
        spouseCoverageId: model?.spouseCoverageId,
        spouseDOB: [{ value: this.datePipe.transform(model?.spouseDOB, 'MM/dd/yyyy'), disabled: false }],
        dependentCoverageId: model?.dependentCoverageId,
        premium: [{ value: model?.premium, disabled: true }],
        bankId: model?.bankId,
        bankAcctNbr: model?.bankAcctNbr,
        bankAccountTypeId: model?.bankAccountTypeId,
        dateBilled: model?.dateBilled,
        amtDue: model?.amtDue,
        datePaid: model?.datePaid,
        insuranceComments: model?.insuranceComments,
        annualMembershipYear: model?.annualMembershipYear,
        annualMembershipPaid: model?.annualMembershipPaid,
        lifeMemberNbr: model?.lifeMemberNbr,
        nextPmtDue: model?.nextPmtDue,
        created: [{ value: model?.created, disabled: true }],
        createdBy: [{ value: model?.createdBy, disabled: true }],
        updated: [{ value: model?.updated, disabled: true }],
        updatedBy: [{ value: model?.updatedBy, disabled: true }],
        prevAmEqUnitCode: model?.prevAmEqUnitCode,
        militaryEmail: model?.militaryEmail,
        toBeDeleted: model?.toBeDeleted,
        dodId: [model?.dodId, Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10)])],
        groupIds: [model?.groupIds],
        rankId: [model?.rankId],
        age: [{ value: model?.age, disabled: true }],
        spouseAge: [{ value: model?.spouseAge, disabled: true }],
        spousePremium: [{ value: model?.spousePremium, disabled: true }],
        dependentPremium: [{ value: model?.dependentPremium, disabled: true }],
        volunteerNetworkId: model?.volunteerNetworkId,
        //enlistmentDate: model?.enlistmentDate,

        newApplicationSpouseAndDependentCoverage: new FormControl(null, Validators.compose([Validators.pattern('^[0-9]*$')])),
        newApplicationDependentCoverage: new FormControl(null, Validators.compose([Validators.pattern('^[0-9]*$')])),

      });

      this.model = model;
    }
  }

  memberCoverageChange(coverageId: any) {
    let selectedCoverage = this.selectedCoveragesData.filter(
      (f: any) => f.key == coverageId
    )[0];
    let premium = 0;

    if (selectedCoverage !== undefined) {
      premium = selectedCoverage.premium;
    }
    //this.memberCoverageName = selectedCoverage.value;
    //let fa = this.theForm.get('formArray') as FormArray;

    this.premium.patchValue(premium);
    this.theForm.controls['premium'].patchValue(premium);

    this.newApplicationSpouseAndDependentCoverage.clearValidators();
    //this.newApplicationSpouseAndDependentCoverage.setValidators([
    //  Validators.pattern('^[0-9]*$'),
    //  Validators.min(0),
    //  Validators.max(parseFloat(selectedCoverage.value)),
    //]);
    //this.newApplicationSpouseAndDependentCoverage.updateValueAndValidity();

    this.newApplicationDependentCoverage.clearValidators();
    //this.newApplicationDependentCoverage.setValidators([
    //  Validators.pattern('^[0-9]*$'),
    //  Validators.min(0),
    //  Validators.max(parseFloat(selectedCoverage.value) / 2),
    //]);
    //this.newApplicationDependentCoverage.updateValueAndValidity();
  }

  spouseCoverageChange(coverageId: any) {
    let premium: number = 0;
    let spouseCoverageValue: number = 0;

    //this.addSpouseInfo = false;
    if (coverageId !== undefined) {
      let selectedSpouseCoverage = this.selectedCoveragesData.filter(
        (f: any) => f.key == coverageId
      )[0];

      if (selectedSpouseCoverage !== undefined) {
        premium = selectedSpouseCoverage.premium;
        spouseCoverageValue = parseFloat(selectedSpouseCoverage.value);
      }
      //  this.addSpouseInfo = this.isMarried.value == '1';
    }

    //this.updateSpouseValidations();

    this.spousePremium.patchValue(premium);
    this.theForm.controls['spousePremium'].patchValue(premium);

    let dependentCoverage: number = 0;
    if (this.dependentCoverageId !== undefined) {
      let selectedDependentCoverage = this.selectedCoveragesData.filter(
        (f: any) => f.key == this.dependentCoverageId.value
      );

      if (selectedDependentCoverage !== undefined && selectedDependentCoverage.length > 0) {
        dependentCoverage = parseFloat(selectedDependentCoverage[0].value);
      }
    }

    this.newApplicationSpouseAndDependentCoverage.setValue(
      spouseCoverageValue + dependentCoverage
    );
  }

  dependentCoverageChange(coverageId: any) {
    let premium: number = 0;
    let dependentCoverageValue: number = 0;

    if (coverageId !== undefined) {
      let selectedDependentCoverage = this.selectedCoveragesData.filter(
        (f: any) => f.key == coverageId
      )[0];

      if (selectedDependentCoverage !== undefined) {
        premium = selectedDependentCoverage.premium;
        dependentCoverageValue = parseFloat(selectedDependentCoverage.value);
      }
    }

    this.dependentPremium.patchValue(premium);
    this.theForm.controls['dependentPremium'].patchValue(premium);

    let spouseCoverage: number = 0;

    if (this.spouseCoverageId !== undefined) {
      let selectedSpouseCoverage = this.selectedCoveragesData.filter(
        (f: any) => f.key == this.spouseCoverageId.value
      );

      if (selectedSpouseCoverage.length > 0) {
        spouseCoverage = parseFloat(selectedSpouseCoverage[0].value);
      }
    }

    this.newApplicationSpouseAndDependentCoverage.setValue(
      dependentCoverageValue + spouseCoverage
    );
    this.newApplicationDependentCoverage.setValue(dependentCoverageValue);
  }


  dobChange() {

    let formattedDate: string = this.dob.value;

    if (this.dob.value.length >= 8) {
      formattedDate = this.dob.value.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
      this.theForm.controls['dob'].patchValue(formattedDate);
      this.dob.setValue(formattedDate, { emitEvent: false });

      let age = this.calculateAge(new Date(this.dob.value));

      this.age.patchValue(age);
      this.theForm.controls['age'].patchValue(age);
    }
  }

  spouseDobChange() {


    let formattedDate: string = this.spouseDOB.value;

    if (this.spouseDOB.value.length >= 8) {
      formattedDate = this.spouseDOB.value.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
      this.theForm.controls['spouseDOB'].patchValue(formattedDate);
      this.spouseDOB.setValue(formattedDate, { emitEvent: false });

      let age = this.calculateAge(new Date(this.spouseDOB.value));

      this.spouseAge.patchValue(age);
      this.theForm.controls['spouseAge'].patchValue(age);
    }
  }


  calculateAge(dob: Date): number {
    let timeDiff = Math.abs(Date.now() - dob.getTime());
    let age = parseFloat((timeDiff / (1000 * 3600 * 24) / 365.25).toFixed(1));
    return age;
  }


  paymentTypeChange(paymentTypeId: any) {

    this.showPaymentInfo = false;

    //bank draft
    if (paymentTypeId == 6) {
      this.showPaymentInfo = true;
    }
  }

  bindForm(): any {
    return {
      id: this.model?.id,
      ssn: this.model?.ssn,
      lastName: this.model?.lastName,
      firstName: this.model?.firstName,
      middleName: this.model?.middleName,
      suffixName: this.model?.suffixName,
      rosterName: this.model?.rosterName,
      unitId: this.model?.unitId,
      address1: this.model?.address1,
      address2: this.model?.address2,
      city: this.model?.city,
      state: this.model?.state,
      zip: this.model?.zip,
      primaryPhone: this.model?.primaryPhone,
      secondaryPhone: this.model?.secondaryPhone,
      email: this.model?.email,
      comment: this.model?.comment,
      paymentTypeId: this.model?.paymentTypeId,
      coverageTypeId: this.model?.coverageTypeId,
      memberCoverageId: this.model?.memberCoverageId,
      dob: this.model?.dob,
      effectiveDate: this.model?.effectiveDate,
      spouseCoverageId: this.model?.spouseCoverageId,
      spouseDOB: this.model?.spouseDOB,
      dependentCoverageId: this.model?.dependentCoverageId,
      premium: this.model?.premium,
      bankId: this.model?.bankId,
      bankAcctNbr: this.model?.bankAcctNbr,
      bankAccountTypeId: this.model?.bankAccountTypeId,
      dateBilled: this.model?.dateBilled,
      amtDue: this.model?.amtDue,
      datePaid: this.model?.datePaid,
      insuranceComments: this.model?.insuranceComments,
      annualMembershipYear: this.model?.annualMembershipYear,
      annualMembershipPaid: this.model?.annualMembershipPaid,
      lifeMemberNbr: this.model?.lifeMemberNbr,
      nextPmtDue: this.model?.nextPmtDue,
      created: this.model?.created,
      createdBy: this.model?.createdBy,
      updated: this.model?.updated,
      updatedBy: this.model?.updatedBy,
      prevAmEqUnitCode: this.model?.prevAmEqUnitCode,
      militaryEmail: this.model?.militaryEmail,
      toBeDeleted: this.model?.toBeDeleted,
      dodId: this.model?.dodId,
      groupIds: this.model?.groupIds,
      rankId: this.model?.rankId,
      age: this.model?.age,
      spouseAge: this.model?.spouseAge,
      spousePremium: this.model?.spousePremium,
      dependentPremium: this.model?.dependentPremium,
      volunteerNetworkId: this.model?.volunteerNetworkId,
      //enlistmentDate: this.model?.enlistmentDate,
    };
  }

  saveAndCloseClicked(): void {
    this.onSubmit("saveandclose");
  }

  saveAndContinueClicked(): void {
    this.onSubmit("saveandcontinue");
  }

  onSubmit(triggerBtn: any) {
    if (this.theForm?.valid) {

      if (this.model.membershipGroupIds != null && this.model.membershipGroupIds != undefined
        //&& this.model.membershipGroupIds.length > 0
      ) {

        console.log(this.personMembershipPaymentListComponent.list);
        const expiringPayments = this.personMembershipPaymentListComponent.list.filter((payment: any) => {
          return payment.isToBeExpire === true;
        });

        if (expiringPayments !== null && expiringPayments !== undefined && expiringPayments.length > 0) {

          const distinctExpiringGroupIds = [...new Set(
            expiringPayments
              .map((m: any) => m.groupId)
              .filter((groupId: any) => groupId !== undefined && groupId !== null)
          )];

          const updatedGroupIds = this.model.membershipGroupIds.filter((groupId: any) => {
            if (!distinctExpiringGroupIds.includes(groupId)) {
              return groupId;
            }
          });
          this.model.membershipGroupIds = updatedGroupIds;
        }

        const newPayments = this.personMembershipPaymentListComponent.list.filter((payment: any) => {
          return payment.isNew === true;
        });

        if (newPayments !== null && newPayments !== undefined && newPayments.length > 0) {

          const distinctNewGroupIds = [...new Set(
            newPayments
              .map((m: any) => m.groupId)
              .filter((groupId: any) => groupId !== undefined && groupId !== null)
          )];

          if (distinctNewGroupIds != null && distinctNewGroupIds != undefined && distinctNewGroupIds.length > 0) {
            this.model.membershipGroupIds = [...new Set([...this.model.membershipGroupIds, ...distinctNewGroupIds])];
          }
          console.log(this.model.membershipGroupIds);
        }
      }

      this.service.postModelByCustomAction$<Person>("upsert", this.prepareSave(), Person)
        .pipe(
          catchError((err, caught) => {
            FormValidation.parseSubmitErrors(err, this.theForm);
            // return Observable.empty();
            return of(null as Person | any);
          })
        )
        .subscribe((savedData: any) => {

          if (savedData != null && savedData.id != 0) {
            this.authService.eventNewApplicationCountChangeRaised();

            if (triggerBtn == "saveandclose") {
              this.goBack();
            }
            else if (triggerBtn == "saveandcontinue") {
              debugger;
              let personId = this.environmentDefaultNewId;
              //if (this.isNew == false) {
              personId = savedData.id;
              //}

              this.router
                .navigateByUrl("/loading", { skipLocationChange: true })
                .then(() => {
                  this.router.navigate([
                    "person",
                    personId,
                  ]);
                });
            }
            else {
              this.router.navigateByUrl('/navigator');
            }
          }
          else {
            let verticalPosition: MatSnackBarVerticalPosition = "bottom";

            let snackBarRef = this.snackBar.open("Person already exists with same SSN or DODId", "OK", {
              duration: 2500,
              panelClass: ["warning-snackbar"],
              verticalPosition: verticalPosition,
            });
            snackBarRef.afterDismissed().subscribe((result: any) => { });
            snackBarRef.onAction().subscribe(() => {
              snackBarRef.dismiss();
            });
          }
        })
    } else {
      FormValidation.validateAllFormFields(this.theForm);
    }
  }

  prepareSave(): Person {
    const formModel = this.theForm.value;

    let saveModel: Person = new Person({
      id: formModel.id,
      ssn: formModel.ssn,
      lastName: formModel.lastName,
      firstName: formModel.firstName,
      middleName: formModel.middleName,
      suffixName: formModel.suffixName,
      rosterName: formModel.rosterName,
      unitId: formModel.unitId,
      address1: formModel.address1,
      address2: formModel.address2,
      city: formModel.city,
      state: formModel.state,
      zip: formModel.zip,
      primaryPhone: formModel.primaryPhone,
      secondaryPhone: formModel.secondaryPhone,
      email: formModel.email,
      comment: formModel.comment,
      paymentTypeId: formModel.paymentTypeId,
      coverageTypeId: formModel.coverageTypeId,
      memberCoverageId: formModel.memberCoverageId,
      dob: formModel.dob,
      effectiveDate: formModel.effectiveDate,
      spouseCoverageId: formModel.spouseCoverageId,
      spouseDOB: formModel.spouseDOB,
      dependentCoverageId: formModel.dependentCoverageId,
      premium: formModel.premium,
      bankId: formModel.bankId,
      bankAcctNbr: formModel.bankAcctNbr,
      bankAccountTypeId: formModel.bankAccountTypeId,
      dateBilled: formModel.dateBilled,
      amtDue: formModel.amtDue,
      datePaid: formModel.datePaid,
      insuranceComments: formModel.insuranceComments,
      annualMembershipYear: formModel.annualMembershipYear,
      annualMembershipPaid: formModel.annualMembershipPaid,
      lifeMemberNbr: formModel.lifeMemberNbr,
      nextPmtDue: formModel.nextPmtDue,
      created: this.model.created,
      createdBy: this.model.createdBy,
      updated: this.model.updated,
      updatedBy: this.model.updatedBy,
      prevAmEqUnitCode: formModel.prevAmEqUnitCode,
      militaryEmail: formModel.militaryEmail,
      toBeDeleted: formModel.toBeDeleted,
      dodId: formModel.dodId == '' ? null : formModel.dodId,
      groupIds: formModel.groupIds,
      rankId: formModel.rankId,
      personInsBeneficiaryList: this.personInsBeneficiaryListComponent !== undefined ? this.personInsBeneficiaryListComponent.list : null,
      personPaymentInfoList: this.personPaymentInfoListComponent !== undefined ? this.personPaymentInfoListComponent.list : null,
      personCommentList: this.personCommentListComponent != undefined ? this.personCommentListComponent.list : null,
      personMembershipPaymentList: this.personMembershipPaymentListComponent != undefined ? this.personMembershipPaymentListComponent.list : null,
      age: formModel?.age,
      spouseAge: formModel?.spouseAge,
      spousePremium: formModel?.spousePremium,
      dependentPremium: formModel?.dependentPremium,
      enlistmentDate: this.model.enlistmentDate,
      isNewApplicant: false, //this.model.isNewApplicant,
      isWetInkApplication: this.model.isWetInkApplication,
      volunteerNetworkId: formModel?.volunteerNetworkId,
      membershipGroupIds: this.model.membershipGroupIds
    });
    return saveModel;
  }

  cancel(): void {
    this.goBack();
  }

  goBack() {
    this.location.back();
  }

  undo(): void {
    this.theForm.reset(this.bindForm());
  }

  getFlexValue(index: number): string {
    const itemsPerRow = this.activeMembershipGroups.length || 1;
    const flexBasis = 100 / itemsPerRow;
    return `${flexBasis}%`;
  }

  // Form Control Helpers
  get id(): AbstractControl {
    return this.theForm.get('id');
  }
  get ssn(): AbstractControl {
    return this.theForm.get('ssn');
  }
  get lastName(): AbstractControl {
    return this.theForm.get('lastName');
  }
  get firstName(): AbstractControl {
    return this.theForm.get('firstName');
  }
  get middleName(): AbstractControl {
    return this.theForm.get('middleName');
  }
  get suffixName(): AbstractControl {
    return this.theForm.get('suffixName');
  }
  get rosterName(): AbstractControl {
    return this.theForm.get('rosterName');
  }
  get unitId(): AbstractControl {
    return this.theForm.get('unitId');
  }
  get address1(): AbstractControl {
    return this.theForm.get('address1');
  }
  get address2(): AbstractControl {
    return this.theForm.get('address2');
  }
  get city(): AbstractControl {
    return this.theForm.get('city');
  }
  get state(): AbstractControl {
    return this.theForm.get('state');
  }
  get zip(): AbstractControl {
    return this.theForm.get('zip');
  }
  get primaryPhone(): AbstractControl {
    return this.theForm.get('primaryPhone');
  }
  get secondaryPhone(): AbstractControl {
    return this.theForm.get('secondaryPhone');
  }
  get email(): AbstractControl {
    return this.theForm.get('email');
  }
  get comment(): AbstractControl {
    return this.theForm.get('comment');
  }
  get paymentTypeId(): AbstractControl {
    return this.theForm.get('paymentTypeId');
  }
  get coverageTypeId(): AbstractControl {
    return this.theForm.get('coverageTypeId');
  }
  get memberCoverageId(): AbstractControl {
    return this.theForm.get('memberCoverageId');
  }
  get dob(): AbstractControl {
    return this.theForm.get('dob');
  }
  get effectiveDate(): AbstractControl {
    return this.theForm.get('effectiveDate');
  }
  get spouseCoverageId(): AbstractControl {
    return this.theForm.get('spouseCoverageId');
  }
  get spouseDOB(): AbstractControl {
    return this.theForm.get('spouseDOB');
  }
  get dependentCoverageId(): AbstractControl {
    return this.theForm.get('dependentCoverageId');
  }
  get premium(): AbstractControl {
    return this.theForm.get('premium');
  }
  get bankId(): AbstractControl {
    return this.theForm.get('bankId');
  }
  get bankAcctNbr(): AbstractControl {
    return this.theForm.get('bankAcctNbr');
  }
  get bankAccountTypeId(): AbstractControl {
    return this.theForm.get('bankAccountTypeId');
  }
  get dateBilled(): AbstractControl {
    return this.theForm.get('dateBilled');
  }
  get amtDue(): AbstractControl {
    return this.theForm.get('amtDue');
  }
  get datePaid(): AbstractControl {
    return this.theForm.get('datePaid');
  }
  get insuranceComments(): AbstractControl {
    return this.theForm.get('insuranceComments');
  }
  get annualMembershipYear(): AbstractControl {
    return this.theForm.get('annualMembershipYear');
  }
  get annualMembershipPaid(): AbstractControl {
    return this.theForm.get('annualMembershipPaid');
  }
  get lifeMemberNbr(): AbstractControl {
    return this.theForm.get('lifeMemberNbr');
  }
  get nextPmtDue(): AbstractControl {
    return this.theForm.get('nextPmtDue');
  }
  get created(): AbstractControl {
    return this.theForm.get('created');
  }
  get createdBy(): AbstractControl {
    return this.theForm.get('createdBy');
  }
  get updated(): AbstractControl {
    return this.theForm.get('updated');
  }
  get updatedBy(): AbstractControl {
    return this.theForm.get('updatedBy');
  }

  get prevAmEqUnitCode(): AbstractControl {
    return this.theForm?.get('prevAmEqUnitCode');
  }

  get militaryEmail(): AbstractControl {
    return this.theForm?.get('militaryEmail');
  }

  get toBeDeleted(): AbstractControl {
    return this.theForm?.get('toBeDeleted');
  }

  get dodId(): AbstractControl {
    return this.theForm?.get('dodId');
  }

  get groupIds(): AbstractControl {
    return this.theForm?.get('groupIds');
  }

  get rankId(): AbstractControl {
    return this.theForm?.get('rankId');
  }

  get age(): AbstractControl {
    return this.theForm?.get('age');
  }

  get spouseAge(): AbstractControl {
    return this.theForm?.get('spouseAge');
  }

  get spousePremium(): AbstractControl {
    return this.theForm?.get('spousePremium');
  }

  get dependentPremium(): AbstractControl {
    return this.theForm?.get('dependentPremium');
  }

  get volunteerNetworkId(): AbstractControl {
    return this.theForm?.get('volunteerNetworkId');
  }


  get newApplicationSpouseAndDependentCoverage(): AbstractControl {
    return this.theForm?.get('newApplicationSpouseAndDependentCoverage');
  }
  get newApplicationDependentCoverage(): AbstractControl {
    return this.theForm?.get('newApplicationDependentCoverage');
  }

  //get enlistmentDate(): AbstractControl {
  //  return this.theForm?.get('enlistmentDate');
  //}
}
