<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <div fxLayout="row" fxLayoutGap="8px" class="m-2">
      <div fxFlex="50">
        <h1 class="mr-auto primary">
          Bank Draft Discrepancy Report
        </h1>
      </div>
      <div fxFlex="50" fxLayoutAlign="end end">
        <button mat-raised-button color="accent" (click)="pdf.saveAs('BankDraftDiscrepancyReport.pdf')">
          Save As PDF...
        </button>
      </div>
    </div>
  </div>
  <kendo-pdf-export #pdf paperSize="Letter" margin="1cm" [scale]=".5" forcePageBreak=".page-break" keepTogether=".prevent-split">

    <div fxLayout="column" fxLayoutGap="8px" class="m-2">


      <div fxFlex="100">
        <h3>
          New Insurers
        </h3>
      </div>


      <div fxLayout="row" fxLayoutGap="8px" class="m-2">
        <kendo-grid [kendoGridBinding]="listReportBankDraftDiscrepancyNew" [sortable]="true" [scrollable]="'scrollable'" [columnMenu]="{ filter: true }"
                    [groupable]="false" [pageSize]="100"
                    [pageable]="(listReportBankDraftDiscrepancyNew && (listReportBankDraftDiscrepancyNew.length > 100) ? true : false)" [rowClass]="rowCallback" class="condensed" #gridDiscrepancyNew fxFlex
                    id="grdBankDraftDiscrepancyNew">
          <kendo-grid-column field="ssn" title="SSN" [filterable]="true">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{dataItem.ssn | mask: '000-00-0000'}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="person" title="Person" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="bankAcctNbr" title="Bank Acc Nbr" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="premium" title="Premium" [filterable]="true" format="{0:c}"></kendo-grid-column>
          <kendo-grid-column field="abaNbr" title="ABA Number" [filterable]="true"></kendo-grid-column>
        </kendo-grid>
      </div>

      <div fxFlex="100">
        <h3>
          Removed Insurers
        </h3>
      </div>

      <div fxLayout="row" fxLayoutGap="8px" class="m-2">
        <kendo-grid [kendoGridBinding]="listReportBankDraftDiscrepancyRemoved" [sortable]="true" [scrollable]="'scrollable'" [columnMenu]="{ filter: true }"
                    [groupable]="false" [pageSize]="100"
                    [pageable]="(listReportBankDraftDiscrepancyRemoved && (listReportBankDraftDiscrepancyRemoved.length > 100) ? true : false)" [rowClass]="rowCallback" class="condensed" #gridDiscrepancyRemoved fxFlex
                    id="grdBankDraftDiscrepancyRemoved">
          <kendo-grid-column field="ssn" title="SSN" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="person" title="Person" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="bankAcctNbr" title="Bank Acc Nbr" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="premium" title="Premium" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="abaNbr" title="ABA Number" [filterable]="true"></kendo-grid-column>
        </kendo-grid>
      </div>

      <div fxFlex="100">
        <h3>
          Premium Changes
        </h3>
      </div>

      <div fxLayout="row" fxLayoutGap="8px" class="m-2">
        <kendo-grid [kendoGridBinding]="listReportBankDraftDiscrepencyPremiumsNotEqual" [sortable]="true" [scrollable]="'scrollable'" [columnMenu]="{ filter: true }"
                    [groupable]="false" [pageSize]="100"
                    [pageable]="(listReportBankDraftDiscrepencyPremiumsNotEqual && (listReportBankDraftDiscrepencyPremiumsNotEqual.length > 100) ? true : false)" [rowClass]="rowCallback" class="condensed" #gridDiscrepencyPremiumsNotEqual fxFlex
                    id="grdBankDraftDiscrepencyPremiumsNotEqual">
          <kendo-grid-column field="ssn" title="SSN" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="person" title="Person" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="bankAcctNbr" title="Bank Acc Nbr" [filterable]="true"></kendo-grid-column>
          <kendo-grid-column field="premium" title="Premium" [filterable]="true" format="{0:c}"></kendo-grid-column>
          <kendo-grid-column field="abaNbr" title="ABA Number" [filterable]="true"></kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </kendo-pdf-export>
</div>
