export class InsertBankDraft {

  constructor();
  constructor(jsonData?: InsertBankDraft) {
    Object.assign(this, jsonData);
  }

  public date: Date | undefined;
  public personIds: string | undefined;
}
