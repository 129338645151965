import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';
import { ReportService } from '../report.service';
import { MediaObserver } from "@angular/flex-layout";
import { Filter } from '../../_bases/filter';
import { AuthenticationService } from '../../auth/auth.service';
import { DatePipe } from '@angular/common';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { ReportReductionTerminationLetter } from './report-reduction-termination-letter';
import { typeModel } from '../../_bases/typeModel';

@Component({
  selector: 'app-report-reduction-termination-letters',
  templateUrl: './report-reduction-termination-letter.component.html',
  animations: [
  ]
})
export class ReportReductionTerminationLetterComponent implements OnInit {

  public list: ReportReductionTerminationLetter[] = [];
  filters: Filter[] = [];
  public scale = 0.8;
  @ViewChild('pdfCont') pdfCont: any;

  reductionTerminationLetters = new FormGroup({
    date: new FormControl({ value: null, disabled: false }, Validators.required),
    afbaLetter: new FormControl({ value: null, disabled: false }, Validators.required),
  });

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: ReportService,
    public media: MediaObserver,
    public authService: AuthenticationService,
    private ngZone: NgZone,
    public datePipe: DatePipe,
  ) {
    this.allData = this.allData.bind(this);
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.list, {}).data,
    };

    return result;
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {

    if (this.reductionTerminationLetters.valid) {

      this.filters = [];
      this.filters.push(new Filter("date", this.datePipe.transform(this.reductionTerminationLetters.get('date')?.value, 'yyyy-MM-dd'), 'eq'));
      this.filters.push(new Filter("afbaLetter", this.reductionTerminationLetters.get('afbaLetter')?.value, 'eq'));

      this.service
        .getByCustomAction$<ReportReductionTerminationLetter>("getReductionOrTerminationLetters", ReportReductionTerminationLetter, this.filters)
        .pipe(
          catchError((err, caught) => {
            return of(null as any);
          })
        ).subscribe(data => {
          console.log(data);
          this.list = data;
        });
    }
  }

  saveToPdf(): void {

    let personIds = [];
    personIds.push(...this.list.map(person => person.id));
    console.log(personIds);

    const tymodel = new typeModel<any>(personIds);

    this.service
      .postByCustomAction$<any>("addCommentOnReductionTermination", tymodel)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
    ).subscribe((data: any) => {

      });

    this.pdfCont.saveAs(this.datePipe.transform(this.reductionTerminationLetters.get('date')?.value, 'yyyy-MM-dd')  + "-" + this.reductionTerminationLetters.get('afbaLetter')?.value + '.pdf')
  }
}
