import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../../_bases/base-service.service";
import { Filter } from "../../_bases/filter";

@Injectable()
export class JumpsService extends BaseService<Filter, Filter> {
  constructor(http: HttpClient) {
    super(http, "jumpsdatas", "id");
  }
}
