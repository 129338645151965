import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';
import { MediaObserver } from "@angular/flex-layout";
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { catchError, switchMap, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ArmyGuard } from './army-guard';
import { ArmyNonReviewTransfers } from './army-non-review-transfers';
import { ArmyReviewPayCodes } from './army-review-pay-codes';
import { ArmyReviewTransfers } from './army-review-transfers';
import { ArmyUnitStrengths } from './army-unit-strengths';
import { NavigatorService } from '../navigator.service';
import { AuthenticationService } from '../../../auth/auth.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Filter } from '../../../_bases/filter';
import { typeModel } from '../../../_bases/typeModel';

@Component({
  selector: 'app-alpha-Roster',
  templateUrl: './alpha-roster.component.html',
  animations: [
  ]
})
export class AlphaRosterComponent implements OnInit {

  @ViewChild(GridComponent)
  public grdArmyGuard!: GridComponent;
  public grdArmyNonReviewTransfers!: GridComponent;
  public grdArmyReviewPayCodes!: GridComponent;
  public grdArmyReviewTransfers!: GridComponent;
  public grdArmyUnitStrengths!: GridComponent;

  listArmyGuard: ArmyGuard[] = [];
  listArmyNonReviewTransfers: ArmyNonReviewTransfers[] = [];
  listArmyReviewPayCodes: ArmyReviewPayCodes[] = [];
  listArmyReviewTransfers: ArmyReviewTransfers[] = [];
  listArmyUnitStrengths: ArmyUnitStrengths[] = [];
  isArmyGuard: boolean = true;
  tab1lbl: string = 'Army Guard';
  protected ngUnsubscribe: Subject<boolean> = new Subject();
  asOfDate: Date | undefined = undefined;

  constructor(
    private service: NavigatorService,
    public media: MediaObserver,
    public authService: AuthenticationService,
    public datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
  ) {

    this.route.queryParams
      .subscribe((params: any) => {
        console.log(params["guard"]);
        this.isArmyGuard = (params["guard"] == 'army');

        this.tab1lbl = this.isArmyGuard ? "Army Guard" : "Air Guard";

        if (params["asOfDate"] != undefined && params["asOfDate"] != '') {
          this.asOfDate = new Date(params["asOfDate"]);
        }
      });

    this.allArmyGuardData = this.allArmyGuardData.bind(this);
    this.allArmyNonReviewTransfersData = this.allArmyNonReviewTransfersData.bind(this);
    this.allArmyReviewPayCodesData = this.allArmyReviewPayCodesData.bind(this);
    this.allArmyReviewTransfersData = this.allArmyReviewTransfersData.bind(this);
    this.allArmyUnitStrengthsData = this.allArmyUnitStrengthsData.bind(this);

  }

  public allArmyGuardData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.listArmyGuard, {}).data,
    };

    return result;
  }

  public allArmyNonReviewTransfersData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.listArmyNonReviewTransfers, {}).data,
    };

    return result;
  }

  public allArmyReviewPayCodesData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.listArmyReviewPayCodes, {}).data,
    };

    return result;
  }

  public allArmyReviewTransfersData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.listArmyReviewTransfers, {}).data,
    };

    return result;
  }

  public allArmyUnitStrengthsData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.listArmyUnitStrengths, {}).data,
    };

    return result;
  }

  ngOnInit(): void {
    this.loadArmyGuardList();
    this.loadArmyNonReviewTransfersList();
    this.loadArmyReviewPayCodesList();
    this.loadArmyReviewTransfersList();
    this.loadArmyUnitStrengthsList();
  }

  loadArmyGuardList() {

    this.service
      .getByCustomAction$<ArmyGuard>("getNavigatorArmyGuardData", ArmyGuard, [new Filter('armyguard', this.isArmyGuard, "eq")])
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe((data: any) => {

        console.log(data);

        this.listArmyGuard = data;
      });
  }

  exportArmyGuardToCSV() {
    let columnHeaders: any[] = [
      { field: "piqName", title: "piqName" },
      { field: "ncngaMember", title: "ncngaMember" },
      { field: "dodid", title: "dodID" },
      { field: "dob", title: "dob" },
      { field: "currentPayCode", title: "currentPayCode" },
      { field: "newPayCode", title: "newPayCode" },
      { field: "payCodeChange", title: "payCodeChange" },
    ];

    this.service.ExportToCSV(columnHeaders, this.listArmyGuard, ["id"], "Guard.csv");
  }

  loadArmyNonReviewTransfersList() {

    this.service
      .getByCustomAction$<ArmyNonReviewTransfers>("getNavigatorArmyNonReviewTransfers", ArmyNonReviewTransfers, [new Filter('armyguard', this.isArmyGuard, "eq")])
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe((data: any) => {

        console.log(data);

        this.listArmyNonReviewTransfers = data;
      });
  }

  exportArmyNonReviewTransfersToCSV() {
    let columnHeaders: any[] = [
      { field: "piqName", title: "piqName" },
      { field: "payCode", title: "payCode" },
      { field: "currentUnitCode", title: "currentUnitCode" },
    ];

    this.service.ExportToCSV(columnHeaders, this.listArmyNonReviewTransfers, ["id"], "NonReviewTransfers.csv");
  }

  loadArmyReviewPayCodesList() {

    this.service
      .getByCustomAction$<ArmyReviewPayCodes>("getNavigatorArmyReviewPayCodes", ArmyReviewPayCodes, [new Filter('armyguard', this.isArmyGuard, "eq")])
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe((data: any) => {

        console.log(data);

        this.listArmyReviewPayCodes = data;
      });
  }

  exportArmyReviewPayCodesToCSV() {
    let columnHeaders: any[] = [
      { field: "payCode", title: "payCode" },
      { field: "numberOfSoldiers", title: "numberOfSoldiers" },
      { field: "ncngaUnitCode", title: "ncngaUnitCode" },
      { field: "ncngaUnit", title: "ncngaUnit" },
    ];

    this.service.ExportToCSV(columnHeaders, this.listArmyReviewPayCodes, ["id"], "ReviewPayCodes.csv");
  }

  loadArmyReviewTransfersList() {

    this.service
      .getByCustomAction$<ArmyReviewTransfers>("getNavigatorArmyReviewTransfers", ArmyReviewTransfers, [new Filter('armyguard', this.isArmyGuard, "eq")])
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe((data: any) => {

        console.log(data);

        this.listArmyReviewTransfers = data;
      });
  }

  exportArmyReviewTransfersToCSV() {
    let columnHeaders: any[] = [
      { field: "piqName", title: "piqName" },
      { field: "payCode", title: "payCode" },
      { field: "currentUnitCode", title: "currentUnitCode" },
      { field: "currentPayCode", title: "currentPayCode" },
      { field: "newUnitCode", title: "newUnitCode" },
      { field: "newPayCode", title: "newPayCode" },

    ];

    this.service.ExportToCSV(columnHeaders, this.listArmyReviewTransfers, ["id"], "ReviewTransfers.csv");
  }


  loadArmyUnitStrengthsList() {

    this.service
      .getByCustomAction$<ArmyUnitStrengths>("getNavigatorUnitStrengths", ArmyUnitStrengths, [new Filter('armyguard', this.isArmyGuard, "eq")])
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe((data: any) => {
        console.log(data);
        this.listArmyUnitStrengths = data;
      });
  }

  exportArmyUnitStrengthsToCSV() {
    let columnHeaders: any[] = [
      { field: "payCode", title: "payCode" },
      { field: "attached", title: "attached" },
      { field: "unattached", title: "Unattached" },
      { field: "totalStrength", title: "Total Strength" },
    ];

    this.service.ExportToCSV(columnHeaders, this.listArmyUnitStrengths, ["id"], "UnitStrengths.csv");
  }

  finishImport(): void {
    const tymodel = new typeModel<any>({
      asOfDate: this.asOfDate,
      isArmyGuard: this.isArmyGuard
    });

    this.service
      .postByCustomAction$<any>("finishImport", tymodel)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe((data: any) => {
        console.log(data);

        this.asOfDate = undefined;
        this.router.navigate(
          ['.'],
          { relativeTo: this.route, queryParams: { guard: this.isArmyGuard ? "army" : "air" } }
        );

        this.loadArmyGuardList();
        this.loadArmyNonReviewTransfersList();
        this.loadArmyReviewPayCodesList();
        this.loadArmyReviewTransfersList();
        this.loadArmyUnitStrengthsList();
      });
  }

  public rowCallback(context: RowClassArgs) {
    return {
    };
  }

}
