export class ReportAnnualPremiumMonthlyDue {

  constructor();
  constructor(jsonData?: ReportAnnualPremiumMonthlyDue) {
    Object.assign(this, jsonData);
  }

  public id: number | undefined;
  public ssn: string | undefined;
  public name: string | undefined;
  public totalPremium: number | undefined;
  public age: number | undefined;
  public pmtTypeCode: string | undefined;
  public coverageName: number | undefined;
}
