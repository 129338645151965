<div fxLayout="row" fxLayoutAlign="space-between">
  <div fxFlex="60" fxLayout="column" fxLayoutAlign="center center">
    <img src="../../../assets/ssli.jpg">
    <br>
    <br>
    <button mat-raised-button color="accent" routerLink="/new-application">
      Sign up Now!
    </button>
  </div>
  <div fxFlex="40" fxLayout="row wrap" fxLayoutAlign="center center">
    <button fxFlex="80" class="mb-3" mat-raised-button color="grey" routerLink="/am-i-insured">
      Am I insured?
    </button>
    <!--<button fxFlex="80" mat-raised-button color="grey" routerLink="/contact-info">
    Update Insurance Contact Information
  </button>-->
    <button fxFlex="80" mat-raised-button color="grey" routerLink="/find-my-policy">
      Make changes to my profile / policy
    </button>
  </div>
</div>
