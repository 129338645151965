export class ReportMembershipExport {

  constructor();
  constructor(jsonData?: ReportMembershipExport) {
    if (jsonData) {
      Object.assign(this, jsonData);
      this.annualExpirationDate = (jsonData.annualExpirationDate && jsonData.annualExpirationDate.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.annualExpirationDate) : null;
      this.paymentDate = (jsonData.paymentDate && jsonData.paymentDate.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.paymentDate) : null;
    }
  }

  public personMembershipPaymentId: number | undefined;
  public personId: number | undefined;
  public firstName: string | undefined;
  public middleName: string | undefined;
  public lastName: string | undefined;
  public email: string | undefined;
  public address1: string | undefined;
  public address2: string | undefined;
  public city: string | undefined;
  public state: string | undefined;
  public zip: string | undefined;
  public primaryPhone: string | undefined;
  public secondaryPhone: string | undefined;
  public groupName: string | undefined;
  public membershipNumber: string | undefined;
  public annualExpirationDate?: Date | null;
  public groupId: number | undefined;
  public paymentDate?: Date | null;
}
