export class PersonReadOnly { 

	constructor() 
	constructor(jsonData: PersonReadOnly)
	constructor(jsonData?: PersonReadOnly) {
		if(jsonData) {
			Object.assign(this, jsonData);
      this.dob = (jsonData.dob && jsonData.dob.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.dob) : undefined;
      this.effectiveDate = (jsonData.effectiveDate && jsonData.effectiveDate.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.effectiveDate) : undefined;
      this.spouseDOB = (jsonData.spouseDOB && jsonData.spouseDOB.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.spouseDOB) : undefined;
      this.dateBilled = (jsonData.dateBilled && jsonData.dateBilled.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.dateBilled) : undefined;
      this.datePaid = (jsonData.datePaid && jsonData.datePaid.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.datePaid) : undefined;
      this.annualMembershipPaid = (jsonData.annualMembershipPaid && jsonData.annualMembershipPaid.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.annualMembershipPaid) : undefined;
      this.nextPmtDue = (jsonData.nextPmtDue && jsonData.nextPmtDue.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.nextPmtDue) : undefined;
      this.created = (jsonData.created && jsonData.created.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.created) : undefined;
      this.updated = (jsonData.updated && jsonData.updated.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.updated) : undefined;
      this.enlistmentDate = (jsonData.enlistmentDate && jsonData.enlistmentDate.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.enlistmentDate) : undefined;
      this.dateChanged = (jsonData.dateChanged && jsonData.dateChanged.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.dateChanged) : undefined;
		}
	}

  id: number | undefined;
  ssn: string | undefined;
  lastName: string | undefined;
  firstName: string | undefined;
  middleName: string | undefined;
  suffixName: string | undefined;
  rosterName: string | undefined;
  unitId: number | undefined;
  address1: string | undefined;
  address2: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zip: string | undefined;
  primaryPhone: string | undefined;
  secondaryPhone: string | undefined;
  email: string | undefined;
  comment: string | undefined;
  paymentTypeId: number | undefined;
  coverageTypeId: number | undefined;
  memberCoverageId: number | undefined;
  dob: Date | undefined;
  age?: number | undefined;
  effectiveDate: Date | undefined;
  spouseCoverageId: number | undefined;
  spouseDOB: Date | undefined;
  spouseAge?: number | undefined;
  spousePremium?: number | undefined;
  dependentCoverageId: number | undefined;
  dependentPremium?: number | undefined;
  premium: number | undefined;
  bankId: number | undefined;
  bankAcctNbr: string | undefined;
  bankAccountTypeId: number | undefined;
  dateBilled: Date | undefined;
  amtDue: number | undefined;
  datePaid: Date | undefined;
  insuranceComments: string | undefined;
  annualMembershipYear: number | undefined;
  annualMembershipPaid: Date | undefined;
  lifeMemberNbr: string | undefined;
  nextPmtDue: Date | undefined;
  created: Date | undefined;
  createdBy: string | undefined;
  updated: Date | undefined;
  updatedBy: string | undefined;
  prevAmEqUnitCode: string | undefined;
  militaryEmail: string | undefined;
  toBeDeleted: boolean | undefined;
  dodId: number | undefined;
  enlistmentDate: Date | undefined;
  isNewApplicant: boolean | undefined;
  isWetInkApplication: boolean | undefined;
  wetInkFileName: string | undefined;
  wetInkAttachmentId: string | undefined;
  volunteerNetworkId: any | undefined;
  isUntouchedEditApplication: boolean | undefined;
  dateChanged: Date | undefined;
  oldJsonData: string | undefined;
}


