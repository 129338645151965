export class CoverageReadOnly {

  constructor()
  constructor(jsonData: CoverageReadOnly)
  constructor(jsonData?: CoverageReadOnly) {
    if (jsonData) {
      Object.assign(this, jsonData);
    }
  }

  id: number | undefined;
  coverageTypeId: number | undefined;
  type: string | undefined;
  coverageCode: string | undefined;
  coverageName: number | undefined;
  premium: number | undefined;
  isAssignable: boolean | undefined;
}
