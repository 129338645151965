<form *ngIf="model" [formGroup]="theForm" (ngSubmit)="onSubmit()" class="detail-component" novalidate>
  <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="60">
    <div fxFlex="100">
      <div fxLayout="row" fxFlex="100">
        <span class="error-color">Required "*"</span>
      </div>
    </div>
    <div fxFlex>
      <mat-form-field appearance="fill" fxFlex="100">
        <mat-label>Name *</mat-label>
        <input matInput formControlName="name" />
        <mat-hint></mat-hint>
        <mat-error *ngIf="name.touched && name.hasError('required')">
          Name is <strong>required</strong>
        </mat-error>

        <mat-error *ngFor="let e of fieldErrors('name')">
          {{ e.params }}
        </mat-error>
      </mat-form-field>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="this.loading"></mat-progress-bar>
    <section class="footer">
      <button mat-raised-button color="primary" type="submit" [disabled]="theForm.pristine || (loading)">
        Save
      </button>
      <button mat-button type="button" (click)="undo()" [disabled]="theForm.pristine">
        Undo
      </button>
      <button mat-button type="button" (click)="cancel()">
        Cancel
      </button>
    </section>
  </div>
</form>
