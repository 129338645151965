import { Component, OnInit, NgZone, ViewChild, Input, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';

import { PopupSettings } from '@progress/kendo-angular-dropdowns';

import { filter } from '@progress/kendo-data-query/dist/npm/transducers';
import { Lookup } from '../../../lookups/lookup';
import { LookupService } from '../../../lookups/lookup.service';
import { AuthenticationService } from '../../../auth/auth.service';
import { Filter } from '../../../_bases/filter';
import { PersonAttachmentReadOnly } from '../personattachment-readonly';
import { PersonAttachmentService } from '../personattachment.service';
import { environment } from '../../../../environments/environment';
import { FileRestrictions, SuccessEvent, UploadEvent } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-personattachments-list',
  templateUrl: './personattachment-list.component.html',
  animations: [
  ]
})
export class PersonAttachmentListComponent implements OnInit {

  @ViewChild(GridComponent)
  public grid!: GridComponent;

  list: PersonAttachmentReadOnly[] = [];
  @Input() filters: Filter[] = [];
  @Input() newItemProperties: any;
  public loading: boolean = false;
  @ViewChild('aTest') aTest: ElementRef | any;

  uploadSaveUrl: string =
    environment.serviceUrl + "personAttachments/importFile";
  fileRestrictions: FileRestrictions = {
    allowedExtensions: [".pdf", ".doc", ".docx", ".xls", ".xlsx"],
  };

  importPersonAttachment = new FormGroup({
    upload: new FormControl(),
  });

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: PersonAttachmentService,
    private ngZone: NgZone,
    private lookupService: LookupService,
    private authService: AuthenticationService,
  ) {

  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {
    this.service
      .getByCustomAction$<PersonAttachmentReadOnly>("list", PersonAttachmentReadOnly, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {
        this.list = data;
      });
  }

  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }


  private fitColumns(): void {
    //this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
    //  this.grid.autoFitColumns();
    //});
  }

  public rowCallback(context: RowClassArgs) {
    return {
      // blocked: context.dataItem.isBlocked
    };
  }

  downloadAttachment(id: string, fileName: string): string {
    this.service.http.get<Blob>(environment.serviceUrl + `PersonAttachments/downloadAttachment?id=${id.toString()}&fileName=${encodeURIComponent(fileName)}`, { responseType: 'blob' as 'json' })
      .subscribe(res => {
        var link = this.aTest.nativeElement;

        link.href = window.URL.createObjectURL(res);
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(link.href);

        //return res;
      });
    return "";
  }

  uploadSuccessEventHandler(e: SuccessEvent) {
    console.log("The " + e.operation + " was successful!");
    this.loadList();
  }

  uploadEventHandler(e: UploadEvent) {
    e.data = {
      personId: this.filters[0].value,
    };
  }

  public onUploadButtonClick(upload: any) {
    upload.uploadFiles();
  }

}
