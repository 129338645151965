import { Component, OnInit, NgZone, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent, SelectableSettings } from '@progress/kendo-angular-grid';
import { MediaObserver } from "@angular/flex-layout";
import { DatePipe } from '@angular/common';
import { AuthenticationService } from '../../auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-hangfire',
  templateUrl: './hangfire.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [`
`],
})
export class HangfireComponent implements OnInit {

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public media: MediaObserver,
    public authService: AuthenticationService,
    public datePipe: DatePipe,
    public snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {
  }

  hangfireURL() {
    //let url = "http://localhost:5000" + "/hangfire?auth=" + this.getCookie('authForIdHang');
    let url = `/hangfire?auth=${this.getCookie('authForIdHang')}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getCookie(c_name: any) {
    if (document.cookie.length > 0) {
      let c_start = document.cookie.indexOf(c_name + "=");
      if (c_start != -1) {
        c_start = c_start + c_name.length + 1;
        let c_end = document.cookie.indexOf(";", c_start);
        if (c_end == -1) {
          c_end = document.cookie.length;
        }
        return unescape(document.cookie.substring(c_start, c_end));
      }
    }
    return "";
  }

  public ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
