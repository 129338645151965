import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { RoleGuard } from './auth/role.guard';
import { BankAccountTypeComponent } from './features/bankaccounttypes/bankaccounttype-detail/bankaccounttype-detail.component';
import { BankAccountTypeListComponent } from './features/bankaccounttypes/bankaccounttype-list/bankaccounttype-list.component';
import { BankComponent } from './features/banks/bank-detail/bank-detail.component';
import { BankListComponent } from './features/banks/bank-list/bank-list.component';
import { BattalionComponent } from './features/battalions/battalion-detail/battalion-detail.component';
import { BattalionListComponent } from './features/battalions/battalion-list/battalion-list.component';
import { BrigadeComponent } from './features/brigades/brigade-detail/brigade-detail.component';
import { BrigadeListComponent } from './features/brigades/brigade-list/brigade-list.component';
import { CoverageComponent } from './features/coverages/coverage-detail/coverage-detail.component';
import { CoverageListComponent } from './features/coverages/coverage-list/coverage-list.component';
import { CoverageTypeComponent } from './features/coveragetypes/coveragetype-detail/coveragetype-detail.component';
import { CoverageTypeListComponent } from './features/coveragetypes/coveragetype-list/coveragetype-list.component';
import { GroupComponent } from './features/groups/group-detail/group-detail.component';
import { GroupListComponent } from './features/groups/group-list/group-list.component';
import { HangfireComponent } from './features/hangfire/hangfire.component';
import { ImportAirAgrComponent } from './features/jumps/import-air-agr/import-air-agr.component';
import { ImportAirJumpsComponent } from './features/jumps/import-air-jumps/import-air-jumps.component';
import { ImportAllJumpsComponent } from './features/jumps/import-all/import-all.component';
import { ImportArmyAgrComponent } from './features/jumps/import-army-agr/import-army-agr.component';
import { ImportArmyJumpsComponent } from './features/jumps/import-army-jumps/import-army-jumps.component';
import { ImportRetiredPayComponent } from './features/jumps/import-retired-pay/import-retired-pay.component';
import { MissingJumpsListComponent } from './features/jumps/missing-jumps/missing-jumps-list.component';
import { AlphaRosterComponent } from './features/navigator/alpha-roster/alpha-roster.component';
import { NavigatorComponent } from './features/navigator/navigator.component';
import { PersonComponent } from './features/navigator/person-detail/person-detail.component';
import { PaymentTypeComponent } from './features/paymenttypes/paymenttype-detail/paymenttype-detail.component';
import { PaymentTypeListComponent } from './features/paymenttypes/paymenttype-list/paymenttype-list.component';
import { PersonListComponent } from './features/people/person-list/person-list.component';
import { RelationshipComponent } from './features/relationships/relationship-detail/relationship-detail.component';
import { RelationshipListComponent } from './features/relationships/relationship-list/relationship-list.component';
import { SettingListComponent } from './features/settings/setting-list/setting-list.component';
import { UnitComponent } from './features/units/unit-detail/unit-detail.component';
import { UnitListComponent } from './features/units/unit-list/unit-list.component';
import { VolunteerNetworkComponent } from './features/volunteernetwork/volunteernetwork-detail/volunteernetwork-detail.component';
import { VolunteerNetworkListComponent } from './features/volunteernetwork/volunteernetwork-list/volunteernetwork-list.component';
import { AmIInsuredComponent } from './public/amiinsured/amiinsured.component';
import { ContactInfoComponent } from './public/contact-info/contact-info.component';
import { HomeComponent } from './public/home/home.component';
import { NewApplicationComponent } from './public/newapplication/newapplication.component';
import { NewApplicationSubmittedComponent } from './public/newapplication/newapplicationsubmitted.component';
import { ReportAmericanEquityDownloadFileComponent } from './reports/report-american-equity-download-file/report-american-equity-download-file.component';
import { ReportAnnualPaymentsReceivedComponent } from './reports/report-annual-payments-received/report-annual-payments-received.component';
import { ReportAnnualPremiumMonthlyDueComponent } from './reports/report-annual-premium-monthly-due/report-annual-premium-monthly-due.component';
import { ReportAnnualStatementComponent } from './reports/report-annual-statement/report-annual-statement.component';
import { ReportBankDraftDiscrepancyComponent } from './reports/report-bank-draft-discrepancy/report-bank-draft-discrepancy.component';
import { ReportBankDraftComponent } from './reports/report-bank-draft/report-bank-draft.component';
import { ReportBillingJournalNotPaidComponent } from './reports/report-billing-journal-not-paid/report-billing-journal-not-paid.component';
import { ReportDelinquencyComponent } from './reports/report-delinquency/report-delinquency.component';
import { ReportDFASDepositComponent } from './reports/report-dfas-deposit/report-dfas-deposit.component';
import { ReportDiscrepancyComponent } from './reports/report-discrepancy/report-discrepancy.component';
import { ReportInsuredPercentageComponent } from './reports/report-insured-percentage/report-insured-percentage.component';
import { ReportMembershipComponent } from './reports/report-membership/report-membership.component';
import { ReportPreviousMonthComponent } from './reports/report-previous-month/report-previous-month.component';
import { ReportReductionTerminationLetterComponent } from './reports/report-reduction-termination-letter/report-reduction-termination-letter.component';
import { ReportSepContLetterComponent } from './reports/report-sep-cont-letter/report-sep-cont-letter.component';
import { ReportSeparationComponent } from './reports/report-separation/report-separation.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { ReportMembershipExportComponent } from './reports/report-membership-export/report-membership-export.component';
import { MembershipDataComponent } from './features/navigator/membership-data/membership-data.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full', data: { title: 'Home' } },
  {
    path: 'home',
    pathMatch: 'full',
    component: HomeComponent,
    data: { title: 'Home' },
  },
  {
    path: 'loading',
    pathMatch: 'full',
    component: LoadingComponent,
    data: { title: 'Loading' },
  },
  {
    path: 'contact-info',
    pathMatch: 'full',
    component: ContactInfoComponent,
    data: { title: 'Home' },
  },
  {
    path: 'new-application',
    pathMatch: 'full',
    component: NewApplicationComponent,
    //canActivate: [MsalGuard],
    data: { title: 'New application' },
  },
  {
    path: 'edit-application',
    pathMatch: 'full',
    component: NewApplicationComponent,
    //canActivate: [MsalGuard],
    data: { title: 'Edit application' },
  },
  {
    path: 'newapplicationsubmitted',
    pathMatch: 'full',
    component: NewApplicationSubmittedComponent,
    //canActivate: [MsalGuard],
    data: { title: 'New application Submitted' },
  },
  {
    path: 'applicationEditsubmitted',
    pathMatch: 'full',
    component: NewApplicationSubmittedComponent,
    //canActivate: [MsalGuard],
    data: { title: 'Application Edit Submitted' },
  },
  {
    path: 'am-i-insured',
    pathMatch: 'full',
    component: AmIInsuredComponent,
    //canActivate: [MsalGuard],
    data: { title: 'Am I Insured ?' },
  },
  {
    path: 'find-my-policy',
    pathMatch: 'full',
    component: AmIInsuredComponent,
    //canActivate: [MsalGuard],
    data: { title: 'Find My Policy' },
  },
  //{ path: '', redirectTo: '/navigator', pathMatch: 'full', data: { title: "Navigator" } },
  {
    path: 'navigator',
    pathMatch: 'full',
    component: NavigatorComponent,
    canActivate: [MsalGuard],
    data: { title: 'Navigator' },
  },
  {
    path: 'ncngahangfire',
    pathMatch: 'full',
    component: HangfireComponent,
    canActivate: [MsalGuard],
    data: { title: 'Hangfire' },
  },
  {
    path: 'person/:id',
    component: PersonComponent,
    canActivate: [MsalGuard],
    data: { title: 'Report Templates' },
  },
  {
    path: 'alpha-roster',
    pathMatch: 'full',
    component: AlphaRosterComponent,
    canActivate: [MsalGuard],
    data: { title: 'Alpha Roster' },
  },
  {
    path: 'membership-data',
    pathMatch: 'full',
    component: MembershipDataComponent,
    canActivate: [MsalGuard],
    data: { title: 'Membership Data' },
  },
  {
    path: 'reports/bankdraft',
    pathMatch: 'full',
    component: ReportBankDraftComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { title: 'Report: Bank Draft', roles: ['Admin', 'Staff'] },
  },
  {
    path: 'reports/bankdraftdiscrepancy',
    pathMatch: 'full',
    component: ReportBankDraftDiscrepancyComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      title: 'Report: Bank Draft Discrepancy',
      roles: ['Admin', 'Staff'],
    },
  },
  {
    path: 'reports/annualpremiummonthlydue',
    pathMatch: 'full',
    component: ReportAnnualPremiumMonthlyDueComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      title: 'Report: Annual Premium Monthly Due Report',
      roles: ['Admin', 'Staff'],
    },
  },
  {
    path: 'reports/membershipexport',
    pathMatch: 'full',
    component: ReportMembershipExportComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      title: 'Report: Membership Export',
      roles: ['Admin', 'Staff'],
    },
  },
  {
    path: 'reports/annualpaymentsreceived',
    pathMatch: 'full',
    component: ReportAnnualPaymentsReceivedComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { title: 'Report: Annual Payments Received', roles: ['Admin', 'Staff'] },
  },
  {
    path: 'reports/billingjournalnotpaid',
    pathMatch: 'full',
    component: ReportBillingJournalNotPaidComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      title: 'Report: Billing Journal Not Paid',
      roles: ['Admin', 'Staff'],
    },
  },
  {
    path: 'reports/annualstatement',
    pathMatch: 'full',
    component: ReportAnnualStatementComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { title: 'Report: Annual Statement', roles: ['Admin', 'Staff'] },
  },
  {
    path: 'reports/discrepancy',
    pathMatch: 'full',
    component: ReportDiscrepancyComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { title: 'Report: Discrepancy', roles: ['Admin', 'Staff'] },
  },
  {
    path: 'reports/separation',
    pathMatch: 'full',
    component: ReportSeparationComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { title: 'Report: Separation', roles: ['Admin', 'Staff'] },
  },
  {
    path: 'reports/americanequitydownloadfile',
    pathMatch: 'full',
    component: ReportAmericanEquityDownloadFileComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      title: 'Report: American Equity Download File',
      roles: ['Admin', 'Staff'],
    },
  },
  {
    path: 'reports/previousmonth',
    pathMatch: 'full',
    component: ReportPreviousMonthComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { title: 'Report: Previous Month', roles: ['Admin', 'Staff'] },
  },
  {
    path: 'reports/delinquency',
    pathMatch: 'full',
    component: ReportDelinquencyComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { title: 'Report: Delinquency', roles: ['Admin', 'Staff'] },
  },
  {
    path: 'reports/membership',
    pathMatch: 'full',
    component: ReportMembershipComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { title: 'Report: Membership', roles: ['Admin', 'Staff'] },
  },
  {
    path: 'reports/dfasdepositreport',
    pathMatch: 'full',
    component: ReportDFASDepositComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: { title: 'Report: DFAS Deposit', roles: ['Admin', 'Staff'] },
  },
  {
    path: 'reports/insuredpercentage',
    pathMatch: 'full',
    component: ReportInsuredPercentageComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      title: 'Report: Insured Percentage',
      roles: ['Admin', 'Staff'],
    },
  },
  {
    path: 'reports/reductionterminationletters',
    pathMatch: 'full',
    component: ReportReductionTerminationLetterComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      title: 'Report: Reduction Termination Letters',
      roles: ['Admin', 'Staff'],
    },
  },
{
  path: 'reports/sepcontletters',
    pathMatch: 'full',
    component: ReportSepContLetterComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      title: 'Report: Reduction Termination Letters',
      roles: ['Admin', 'Staff'],
    },
  },
  {
    path: 'jumps/importalljumps',
    pathMatch: 'full',
    component: ImportAllJumpsComponent,
    canActivate: [MsalGuard],
    data: { title: 'Jumps: Import All Jumps', roles: ['Admin', 'Staff'] },
  },
  {
    path: 'jumps/importarmyjumps',
    pathMatch: 'full',
    component: ImportArmyJumpsComponent,
    canActivate: [MsalGuard],
    data: { title: 'Jumps: Import Army Jumps', roles: ['Admin', 'Staff'] },
  },
  {
    path: 'jumps/importairjumps',
    pathMatch: 'full',
    component: ImportAirJumpsComponent,
    canActivate: [MsalGuard],
    data: { title: 'Jumps: Import Air Jumps', roles: ['Admin', 'Staff'] },
  },
  {
    path: 'jumps/importarmyagr',
    pathMatch: 'full',
    component: ImportArmyAgrComponent,
    canActivate: [MsalGuard],
    data: { title: 'Jumps: Import Army Agr', roles: ['Admin', 'Staff'] },
  },
  {
    path: 'jumps/importairagr',
    pathMatch: 'full',
    component: ImportAirAgrComponent,
    canActivate: [MsalGuard],
    data: { title: 'Jumps: Import Air Agr', roles: ['Admin', 'Staff'] },
  },
  {
    path: 'jumps/importretiredpay',
    pathMatch: 'full',
    component: ImportRetiredPayComponent,
    canActivate: [MsalGuard],
    data: { title: 'Jumps: Import Retired Pay', roles: ['Admin', 'Staff'] },
  },
  {
    path: 'jumps/missingjumps',
    pathMatch: 'full',
    component: MissingJumpsListComponent,
    canActivate: [MsalGuard],
    data: { title: 'Jumps: Missing Jumps', roles: ['Admin', 'Staff'] },
  },
  {
    path: 'people/wetink',
    pathMatch: 'full',
    component: PersonListComponent,
    canActivate: [MsalGuard],
    data: { title: 'New application: WetInk' },
  },
  {
    path: 'people/digitalink',
    pathMatch: 'full',
    component: PersonListComponent,
    canActivate: [MsalGuard],
    data: { title: 'New application: WetInk' },
  },
  {
    path: 'people/applicantUpdates',
    pathMatch: 'full',
    component: PersonListComponent,
    canActivate: [MsalGuard],
    data: { title: 'Applicant Updates' },
  },
  {
    path: 'bank',
    component: BankListComponent,
    canActivate: [MsalGuard],
    data: { title: 'Bank' },
  },
  {
    path: 'bank/:id',
    component: BankComponent,
    canActivate: [MsalGuard],
    data: { title: 'Bank' },
  },
  {
    path: 'bankaccounttype',
    component: BankAccountTypeListComponent,
    canActivate: [MsalGuard],
    data: { title: 'Bank Account Types' },
  },
  {
    path: 'bankaccounttype/:id',
    component: BankAccountTypeComponent,
    canActivate: [MsalGuard],
    data: { title: 'Bank Account Type' },
  },
  {
    path: 'battalion',
    component: BattalionListComponent,
    canActivate: [MsalGuard],
    data: { title: 'Battalion' },
  },
  {
    path: 'battalion/:id/:brigadeId',
    component: BattalionComponent,
    canActivate: [MsalGuard],
    data: { title: 'Battalion' },
  },
  {
    path: 'brigade',
    component: BrigadeListComponent,
    canActivate: [MsalGuard],
    data: { title: 'Brigade' },
  },
  {
    path: 'brigade/:id',
    component: BrigadeComponent,
    canActivate: [MsalGuard],
    data: { title: 'Brigade' },
  },
  {
    path: 'coverage',
    component: CoverageListComponent,
    canActivate: [MsalGuard],
    data: { title: 'Coverage' },
  },
  {
    path: 'coverage/:id',
    component: CoverageComponent,
    canActivate: [MsalGuard],
    data: { title: 'Coverage' },
  },
  {
    path: 'coveragetype',
    component: CoverageTypeListComponent,
    canActivate: [MsalGuard],
    data: { title: 'Coverage Type' },
  },
  {
    path: 'coveragetype/:id',
    component: CoverageTypeComponent,
    canActivate: [MsalGuard],
    data: { title: 'Coverage Type' },
  },
  {
    path: 'group',
    component: GroupListComponent,
    canActivate: [MsalGuard],
    data: { title: 'Group' },
  },
  {
    path: 'group/:id',
    component: GroupComponent,
    canActivate: [MsalGuard],
    data: { title: 'Group' },
  },
  {
    path: 'paymenttype',
    component: PaymentTypeListComponent,
    canActivate: [MsalGuard],
    data: { title: 'Payment Type' },
  },
  {
    path: 'paymenttype/:id',
    component: PaymentTypeComponent,
    canActivate: [MsalGuard],
    data: { title: 'Payment Type' },
  },
  {
    path: 'relationship',
    component: RelationshipListComponent,
    canActivate: [MsalGuard],
    data: { title: 'Relationship' },
  },
  {
    path: 'relationship/:id',
    component: RelationshipComponent,
    canActivate: [MsalGuard],
    data: { title: 'Relationship' },
  },
  {
    path: 'unit',
    component: UnitListComponent,
    canActivate: [MsalGuard],
    data: { title: 'Unit' },
  },
  {
    path: 'unit/:id',
    component: UnitComponent,
    canActivate: [MsalGuard],
    data: { title: 'Unit' },
  },
  {
    path: 'volunteernetwork',
    component: VolunteerNetworkListComponent,
    canActivate: [MsalGuard],
    data: { title: 'Volunteer Network' },
  },
  {
    path: 'volunteernetwork/:id',
    component: VolunteerNetworkComponent,
    canActivate: [MsalGuard],
    data: { title: 'Volunteer Network' },
  },
  {
    path: 'setting',
    component: SettingListComponent,
    canActivate: [MsalGuard],
    data: { title: 'Setting' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
