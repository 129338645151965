export class ArmyUnitStrengths {

  constructor();
  constructor(jsonData?: ArmyUnitStrengths) {

    if (jsonData) {
      Object.assign(this, jsonData);
    }
  }

  public id: number | undefined;
  public payCode: string | undefined;
  public attached: number | undefined;
  public unattached: number | undefined;
  public totalStrength: number | undefined;
}
