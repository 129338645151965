import { Component, OnInit, NgZone, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';
import { MediaObserver } from "@angular/flex-layout";
import { DatePipe, formatDate } from '@angular/common';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { JumpsService } from '../jumps.service';
import { AuthenticationService } from '../../../auth/auth.service';
import { Filter } from '../../../_bases/filter';
import { environment } from '../../../../environments/environment';
import { FileRestrictions, SelectEvent, SuccessEvent, UploadEvent } from '@progress/kendo-angular-upload';
import { Router } from '@angular/router';

@Component({
  selector: 'app-import-all',
  templateUrl: './import-all.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [`
  .k-actions {
    display: none;
  }
`],
})
export class ImportAllJumpsComponent implements OnInit {

  @ViewChild(GridComponent)
  public grid!: GridComponent;

  filters: Filter[] = [];
  uploadAirAGRSaveUrl: string =
    environment.serviceUrl + "jumpsdatas/importAirAGRData";
  uploadArmyAGRSaveUrl: string =
    environment.serviceUrl + "jumpsdatas/importArmyAGRData";
  uploadAirJumpsSaveUrl: string =
    environment.serviceUrl + "jumpsdatas/importAirJumpsData";
  uploadArmyJumpsSaveUrl: string =
    environment.serviceUrl + "jumpsdatas/importArmyJumpsData";
  uploadRetiredPaySaveUrl: string =
    environment.serviceUrl + "jumpsdatas/importRetiredPayFile";
  uploadSuccessCount : number = 0;

  fileRestrictions: FileRestrictions = {
    allowedExtensions: [".txt"],
  };

  importAll = new FormGroup({
    date: new FormControl({ value: null, disabled: false }, Validators.required),
    uploadAirAgrFile: new FormControl({ value: null, disabled: false }, Validators.required),
    uploadArmyAgrFile: new FormControl({ value: null, disabled: false }, Validators.required),
    uploadAirJumpsFile: new FormControl({ value: null, disabled: false }, Validators.required),
    uploadArmyJumpsFile: new FormControl({ value: null, disabled: false }, Validators.required),
    uploadRetiredPayFile: new FormControl({ value: null, disabled: false }, Validators.required),
    tagRecords: new FormControl()
  });

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: JumpsService,
    public media: MediaObserver,
    public authService: AuthenticationService,
    private ngZone: NgZone,
    private router: Router,
    public datePipe: DatePipe,
  ) {
  }


  ngOnInit(): void {
  }

  uploadSuccessEventHandler(e: SuccessEvent) {
    this.uploadSuccessCount = this.uploadSuccessCount + 1;
    console.log("The " + e.operation + " was successful!");

    if (this.uploadSuccessCount == 5) {
      this.router.navigateByUrl('/jumps/missingjumps');
    }
  }

  uploadEventHandler(e: UploadEvent) {
    e.data = {
      tagRecords: this.importAll.get('tagRecords')?.value,
      date: formatDate(this.importAll.get('date')?.value, "yyyy-MM-dd", "en_US"),
    };
  }

  uploadAirAGRFileSelect(e: SelectEvent) {
    this.uploadAirAgrFile?.setValue(null);
    if (e.files[0].extension != ".txt") {
      e.preventDefault();
      return;
    }

    this.uploadAirAgrFile?.setValue(1);
  }

  uploadArmyAGRFileSelect(e: SelectEvent) {
    this.uploadArmyAgrFile?.setValue(null);
    if (e.files[0].extension != ".txt") {
      e.preventDefault();
      return;
    }

    this.uploadArmyAgrFile?.setValue(1);
  }

  uploadAirJumpsFileSelect(e: SelectEvent) {
    this.uploadAirJumpsFile?.setValue(null);
    if (e.files[0].extension != ".txt") {
      e.preventDefault();
      return;
    }

    this.uploadAirJumpsFile?.setValue(1);
  }

  uploadArmyJumpsFileSelect(e: SelectEvent) {
    this.uploadArmyJumpsFile?.setValue(null);
    if (e.files[0].extension != ".txt") {
      e.preventDefault();
      return;
    }

    this.uploadArmyJumpsFile?.setValue(1);
  }

  uploadRetiredPayFileSelect(e: SelectEvent) {
    this.uploadRetiredPayFile?.setValue(null);
    if (e.files[0].extension != ".txt") {
      e.preventDefault();
      return;
    }

    this.uploadRetiredPayFile?.setValue(1);
  }

  public onImportButtonClick(uploadAirAgr: any, uploadArmyAgr: any,
    uploadAirJumps: any, uploadArmyJumps: any, uploadRetiredPay: any) {

    if (this.importAll.valid) {
      uploadAirAgr.uploadFiles();
      uploadArmyAgr.uploadFiles();
      uploadAirJumps.uploadFiles();
      uploadArmyJumps.uploadFiles();
      uploadRetiredPay.uploadFiles();
    }
  }

  get date(): AbstractControl | null {
    return this.importAll.get('date');
  }
  //get uploadAirAgr(): AbstractControl | null {
  //  return this.importAll.get('uploadAirAgr');
  //}

  //get uploadArmyAgr(): AbstractControl | null {
  //  return this.importAll.get('uploadArmyAgr');
  //}

  //get uploadAirJumps(): AbstractControl | null {
  //  return this.importAll.get('uploadAirJumps');
  //}

  //get uploadArmyJumps(): AbstractControl | null {
  //  return this.importAll.get('uploadArmyJumps');
  //}

  //get uploadRetiredPay(): AbstractControl | null {
  //  return this.importAll.get('uploadRetiredPay');
  //}

  get uploadAirAgrFile(): AbstractControl | null {
    return this.importAll.get('uploadAirAgrFile');
  }

  get uploadArmyAgrFile(): AbstractControl | null {
    return this.importAll.get('uploadArmyAgrFile');
  }

  get uploadAirJumpsFile(): AbstractControl | null {
    return this.importAll.get('uploadAirJumpsFile');
  }

  get uploadArmyJumpsFile(): AbstractControl | null {
    return this.importAll.get('uploadArmyJumpsFile');
  }

  get uploadRetiredPayFile(): AbstractControl | null {
    return this.importAll.get('uploadRetiredPayFile');
  }

}
