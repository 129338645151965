export class VolunteernNetworkReadOnly {

	constructor() 
  constructor(jsonData: VolunteernNetworkReadOnly)
  constructor(jsonData?: VolunteernNetworkReadOnly) {
		if(jsonData) {
			Object.assign(this, jsonData);
		}
	}

  id: number | undefined;
  name: string | undefined;
  isAvailable: boolean | undefined;

}


