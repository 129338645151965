export class UnitNavigator {

  constructor();
  constructor(jsonData?: UnitNavigator) {

    if (jsonData) {
      Object.assign(this, jsonData);
    }
  }

  public id: number | undefined;
  public unitCode: string | undefined;
  public unitName: string | undefined;
  public uic: string | undefined;
  public battalionName: string | undefined;
  public brigadeName: string | undefined;
}
