
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, Validators } from "@angular/forms"
import { ActivatedRoute, Router, ParamMap } from "@angular/router";

import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { PopupSettings } from '@progress/kendo-angular-dropdowns';

import { Coverage } from "../coverage";
import { CoverageService } from "../coverage.service";
import { BaseItemComponent } from '../../../_bases/base-item-component';
import { Filter } from '../../../_bases/filter';
import { FormValidation } from '../../../shared/forms/form-validation';
import { Lookup } from '../../../lookups/lookup';
import { LookupService } from '../../../lookups/lookup.service';


@Component({
  selector: 'app-coverage-detail',
  templateUrl: './coverage-detail.component.html',
})
export class CoverageComponent extends BaseItemComponent implements OnInit, OnDestroy {

  model: Coverage | any;
  public loading: boolean = false;
  coverageTypeIdLookups$: Observable<Lookup[]> | any;

  constructor(
    private fb: FormBuilder,
    private service: CoverageService,
    private route: ActivatedRoute,
    private location: Location,
    private lookupService: LookupService,
    private router: Router) {
    super();

    this.coverageTypeIdLookups$ = this.lookupService.getList("coverageTypes", []);
  }

  ngOnInit() {

    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => this.service.getSingleByCustomAction$<Coverage>("getSingle", Coverage, [new Filter("id", params.get("id"), "eq")]))
      )
      .subscribe((model: Coverage | any) => {

        this.createForm(model);
      });
  }

  ngOnChanges() {
    if (this.model) {
      this.createForm(this.model);
    }
  }

  createForm(model?: Coverage): void {
    if (this.theForm) {
      this.model = model;
      this.theForm.reset(this.bindForm());
    } else {
      this.theForm = this.fb.group({
        id: [model?.id, Validators.required],
        coverageTypeId: model?.coverageTypeId,
        type: model?.type,
        coverageCode: model?.coverageCode,
        coverageName: model?.coverageName,
        premium: model?.premium,
        isAssignable: model?.isAssignable,
      });

      this.model = model;
    }
  }

  bindForm(): any {
    return {
      id: this.model.id,
      coverageTypeId: this.model.coverageTypeId,
      type: this.model.type,
      coverageCode: this.model.coverageCode,
      coverageName: this.model.coverageName,
      premium: this.model.premium,
      isAssignable: this.model.isAssignable,
    };
  }

  onSubmit() {
    if (this.theForm?.valid) {

      this.loading = true;
      this.service.postModelByCustomAction$<Coverage>("upsert", this.prepareSave(), Coverage)
        .pipe(
          catchError((err, caught) => {
            this.loading = false;
            FormValidation.parseSubmitErrors(err, this.theForm);
            return of(null as Coverage | any);
          })
        )
        .subscribe((savedData: any) => {
          this.loading = false;
          if (savedData) {
            this.goBack();
          }
        })
    } else {
      FormValidation.validateAllFormFields(this.theForm);
    }
  }

  prepareSave(): Coverage {
    const formModel = this.theForm.value;

    let saveModel: Coverage = new Coverage({
      id: formModel.id,
      coverageTypeId: formModel.coverageTypeId,
      type: formModel.type,
      coverageCode: formModel.coverageCode,
      coverageName: formModel.coverageName,
      premium: formModel.premium,
      isAssignable: formModel.isAssignable,
    });
    return saveModel;
  }

  cancel(): void {
    this.goBack();
  }

  goBack() {
    this.location.back();
  }

  undo(): void {
    this.theForm.reset(this.bindForm());
  }

  get id(): AbstractControl {
    return this.theForm.get('id');
  }
  get coverageTypeId(): AbstractControl {
    return this.theForm.get('coverageTypeId');
  }
  get type(): AbstractControl {
    return this.theForm.get('type');
  }
  get coverageCode(): AbstractControl {
    return this.theForm.get('coverageCode');
  }
  get coverageName(): AbstractControl {
    return this.theForm.get('coverageName');
  }
  get premium(): AbstractControl {
    return this.theForm.get('premium');
  }
  get isAssignable(): AbstractControl {
    return this.theForm.get('isAssignable');
  }

  
}
