export class Bank {

	constructor() 
	constructor(jsonData: Bank)
	constructor(jsonData?: Bank) {
		if(jsonData) {
			Object.assign(this, jsonData);
    }
    else {
      this.id = 0;
    }
	}

	id: number| undefined;
  abaNbr: string | undefined;
  bankName: string | undefined;
  branch: string | undefined;
  address: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zip: string | undefined;

}


