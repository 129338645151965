import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  BehaviorSubject,
  Observable,
  throwError as observableThrowError,
  throwError,
} from "rxjs";
import { catchError, filter, finalize, switchMap, take } from "rxjs/operators";
import { AuthenticationService } from "./auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor(public auth: AuthenticationService, private router: Router) {}

  addHeaders(req: HttpRequest<any>): HttpRequest<any> {
    var setHeaders: any = null;

    if (
      req.url.indexOf("/api/jumpsdatas/importAirJumpsData") > -1 ||
      req.url.indexOf("/api/jumpsdatas/importArmyJumpsData") > -1 ||
      req.url.indexOf("/api/jumpsdatas/importArmyAGRData") > -1 ||
      req.url.indexOf("/api/jumpsdatas/importAirAGRData") > -1 ||
      req.url.indexOf("/api/jumpsdatas/importRetiredPayFile") > -1 ||
      req.url.indexOf("/api/people/importAirAlphaRosterData") > -1 ||
      req.url.indexOf("/api/people/importArmyAlphaRosterData") > -1 ||
      req.url.indexOf("/api/people/importMembershipData") > -1 ||
      req.url.indexOf("/api/personAttachments/importFile") > -1 ||
      req.url.indexOf("/api/people/uploadWetInk") > -1
    ) {
      setHeaders = {
        //Authorization: `Bearer ${this.auth.accessToken}`,
      };
    } else {
      setHeaders = {
        //Authorization: `Bearer ${this.auth.accessToken}`,
        "Content-Type": "application/json",
      };
    }

    return req.clone({
      setHeaders: setHeaders,
    });

    //return req.clone({
    //  setHeaders: {
    //    Authorization: `Bearer ${this.auth.accessToken}`,
    //    "Content-Type": 'application/json'
    //  }
    //});
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(this.addHeaders(request)).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 400:
              return this.handle400Error(error);
            //case 401:
            //  return this.handle401Error(request, next);
            default:
              return observableThrowError(error);
          }
        } else {
          return observableThrowError(error);
        }
      })
    );
  }

  handle400Error(error: any) {
    if (
      error &&
      error.status === 400 &&
      error.error &&
      error.error.message === "invalid_grant"
    ) {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      // return this.logoutUser();
      this.auth.logout();
      return observableThrowError("");
    }

    return observableThrowError(error);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
