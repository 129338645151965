<div fxLayout="column" fxLayoutGap="8px" class="m-2 main-ctr">
  <div fxFlex="100">
    <button mat-raised-button color="accent" (click)="pdf.saveAs('AlphaRoster.pdf')">
      Save As PDF...
    </button>
  </div>
  <kendo-pdf-export #pdf paperSize="Letter" margin="1cm" [scale]=".5" forcePageBreak=".page-break" keepTogether=".prevent-split">
    <div fxLayout="column" fxLayoutGap="8px" class="m-2">
      <div fxFlex="100">
        <h1 class="mr-auto primary text-center">
          N.C National Guard Association
        </h1>
      </div>
      <div fxFlex="100">
        <h3 class="text-center">
          (919) 851-3390
        </h3>
      </div>

      <div fxFlex="100">
        <h3 class="text-center">
          ALPHA ROSTER
        </h3>
      </div>

      <div fxFlex="100" *ngIf="this.list">
        <div fxLayout="row" fxLayoutGap="8px" class="m-2 bdr-btm">
          <div fxFlex="25">
            <strong>Person</strong>
          </div>
          <div fxFlex="25">
            <strong>Address</strong>
          </div>
          <div fxFlex="15">
            <strong>City</strong>
          </div>
          <div fxFlex="10">
            <strong>State</strong>
          </div>
          <div fxFlex="10">
            <strong>Zip</strong>
          </div>
          <div fxFlex="15">
            <strong>Premium</strong>
          </div>
        </div>
        <div fxLayout="row" *ngFor="let person of list" class="prevent-split">
          <div fxFlex="100">
            <div fxLayout="row" fxLayoutGap="8px" class="m-2">
              <div fxFlex="25">
                {{ person.lastName }} {{ person.firstName }} {{ person.middleName }}
              </div>
              <div fxFlex="25">
                {{ person.address1 }} {{ person.address2 }}
              </div>
              <div fxFlex="15">
                {{ person.city }}
              </div>
              <div fxFlex="10">
                {{ person.state }}
              </div>
              <div fxFlex="10">
                {{ person.zip }}
              </div>
              <div fxFlex="15">
                {{ person.memberPremium | currency }}
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="8px" class="m-2">
              <div fxFlex="10">
                {{ person.unitCode }}
              </div>
              <div fxFlex="10">
                <strong>Coverage:</strong>
              </div>
              <div fxFlex="20">
                <strong>Member:</strong>
                {{ person.memberCoverageName| currency }}
              </div>
              <div fxFlex="20">
                <strong>Spouse:</strong>
                {{ person.spouseCoverageName| currency }}
              </div>
              <div fxFlex="20">
                <strong>Dependent:</strong>
                {{ person.dependentCoverageName| currency }}
              </div>
              <div fxFlex="20">
                <strong>Effective Date:</strong>
                {{ person.effectiveDate| date:'yyyy-MM-dd' }}
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="8px" class="m-2 bdr-dashed">
              <div fxFlex="35">
                Primary Phone: {{ person.primaryPhone }} <br />
                Secondary Phone: {{ person.secondaryPhone }}<br />
                Email: {{ person.email }}<br />
              </div>
              <div fxFlex="65" class="beneficiary m-2">
                <div fxLayout="row" fxLayoutGap="8px" class="m-2">
                  <div fxFlex="30">
                    <strong> Member/Beneficiary</strong>
                  </div>
                  <div fxFlex="20">
                    <strong>
                      Relationship
                    </strong>
                  </div>
                  <div fxFlex="15">
                    <strong>
                      Percentage
                    </strong>
                  </div>
                  <div fxFlex="15">
                    <strong>
                      Beneficiary Date
                    </strong>
                  </div>
                </div>
                <ng-container *ngFor="let beneficiary of person.beneficiaries">
                  <div fxLayout="row" fxLayoutGap="8px" class="m-2">
                    <div fxFlex="30">
                      {{ beneficiary.beneficiary }}
                    </div>
                    <div fxFlex="20">
                      {{ beneficiary.relationship }}
                    </div>
                    <div fxFlex="15">
                      {{ beneficiary.percentage | number }}%
                    </div>
                    <div fxFlex="15">
                      {{ beneficiary.beneficiaryDate | date: 'yyyy-MM-dd' }}
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>


    <!--<kendo-grid [kendoGridBinding]="list" [scrollable]="'scrollable'"
                [groupable]="false"
                [pageSize]="10000" [pageable]="(list && (list.length > 10000) ? true : false)"
                class="mt-2 condensed" #gridA id="gridA" [kendoGridExpandDetailsBy]="expandDetailsBy"
                [(expandedDetailKeys)]="expandedDetailKeys">
      <ng-template kendoGridToolbarTemplate>
        <button type="button" kendoGridPDFCommand icon="file-pdf">Export to PDF</button>
      </ng-template>
      <kendo-grid-pdf fileName="aa.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true" [landscape]="true">
        <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
      </kendo-grid-pdf>
      <kendo-grid-column field="firstName" title="Person" [width]="250">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ dataItem.lastName }} {{ dataItem.firstName }} {{ dataItem.middleName }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="address1" title="Address" [width]="210">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ dataItem.address1 }} {{ dataItem.address2 }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="city" title="City" [width]="140">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ dataItem.city }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="state" title="State" [width]="130">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ dataItem.state }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="zip" title="Zip" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ dataItem.zip }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="memberPremium" title="Premium" [width]="220">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ dataItem.memberPremium | currency }}
        </ng-template>
      </kendo-grid-column>
      <ng-template kendoGridDetailTemplate let-dataItem expanded="true">-->
        <!-- Your detail row content -->
        <!--<tr>
          <td>
            <table width="100%">
              <tr>
                <td colspan="10%">
                  {{ dataItem.unitCode }}
                </td>
                <td colspan="15%">
                  <strong>Coverage:</strong>
                </td>
                <td colspan="15%">
                  <strong>Member:</strong>
                  {{ dataItem.memberCoverageName| currency }}
                </td>
                <td colspan="20%">
                  <strong>Spouse:</strong>
                  {{ dataItem.spouseCoverageName| currency }}
                </td>
                <td colspan="20%">
                  <strong>Dependent:</strong>
                  {{ dataItem.dependentCoverageName| currency }}
                </td>
                <td colspan="20%">
                  <strong>Effective Date:</strong>
                  {{ dataItem.effectiveDate| date:'yyyy-MM-dd' }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table width="100%">
              <tr>
                <td style="width:30%">
                  Primary Phone: {{ dataItem.primaryPhone }} <br />
                  Secondary Phone: {{ dataItem.secondaryPhone }}<br />
                  Email: {{ dataItem.email }}<br />
                </td>
                <td style="width:70%">
                  <table width="100%" class="beneficiary m-2" style="border: 1px solid black; width:60%; ">
                    <tr class="m-2">
                      <td>
                        <strong> Member/Beneficiary</strong>
                      </td>
                      <td>
                        <strong>
                          Relationship
                        </strong>
                      </td>
                      <td>
                        <strong>
                          Percentage
                        </strong>
                      </td>
                      <td>
                        <strong>
                          Beneficiary Date
                        </strong>
                      </td>
                    </tr>
                    <tr class="m-2" *ngFor="let beneficiary of dataItem.beneficiaries">
                      <td>
                        {{ beneficiary.beneficiary }}
                      </td>
                      <td>
                        {{ beneficiary.relationship }}
                      </td>
                      <td>
                        {{ beneficiary.percentage | number }}%
                      </td>
                      <td>
                        {{ beneficiary.beneficiaryDate | date: 'yyyy-MM-dd' }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </ng-template>
    </kendo-grid>-->
    </div>
  </kendo-pdf-export>
</div>
