import { Component } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  //styleUrls: ['./loading.component.css'],
  //animations: [
  //  trigger('loading', [
  //    transition(':enter', [
  //      style({
  //        marginTop: '-36px'
  //      }),
  //      animate('500ms ease-in-out', style({
  //        marginTop: 0
  //      }))
  //    ]),
  //    transition(':leave', [
  //      animate('500ms ease-in-out', style({
  //        marginTop: '-36px'
  //      }))
  //    ])
  //  ])
  //]
})
export class LoadingComponent {
  constructor() { }
}
