export class BrigadeReadOnly {

	constructor() 
	constructor(jsonData: BrigadeReadOnly)
	constructor(jsonData?: BrigadeReadOnly) {
		if(jsonData) {
			Object.assign(this, jsonData);
		}
	}

  id: number | undefined;
  brigadeCode: string | undefined;
  brigadeName: string | undefined;
  brigadeSeqNbr: string | undefined;
  //xG_MEM: string | undefined;
  //xG_ASSOC: string | undefined;

}


