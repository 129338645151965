export class VolunteerNetwork {

	constructor() 
  constructor(jsonData: VolunteerNetwork)
  constructor(jsonData?: VolunteerNetwork) {
		if(jsonData) {
			Object.assign(this, jsonData);
    }
    else {
      this.id = 0;
    }
	}

  id: number | undefined;
  name: string | undefined;
  isAvailable: boolean | undefined;

}


