export class MissingJumps {

  constructor();
  constructor(jsonData?: MissingJumps) {

    if (jsonData) {
      Object.assign(this, jsonData);
      this.datePayment = (jsonData.datePayment && jsonData.datePayment.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.datePayment) : null;
    }
  }

  public id: number | undefined;
  public ssn: string | undefined;
  public personName: string | undefined;
  public premiumAmount: number | undefined;
  public amountPaid: number | undefined;
  public monthsCollected: number | undefined;
  public monthsInsufficient: number | undefined;
  public sepCode: number | undefined;
  public starts: number | undefined;
  public datePayment?: Date | null;
  public paymentTypeName?: string | null;
}
