import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../auth/auth.service';
import { Lookup } from '../../lookups/lookup';
import { LookupService } from '../../lookups/lookup.service';
import { FormValidation } from '../../shared/forms/form-validation';
import { BaseItemComponent } from '../../_bases/base-item-component';
import { Filter } from '../../_bases/filter';
import { typeModel } from '../../_bases/typeModel';
import { DodIdExistsModelComponent } from './dodId-exists-modal.component';
import { NewApplication } from './newapplication';
import { NewApplicationService } from './newapplication.service';
import { NewApplicationPersonChild } from './newapplicationpersonchild';
import { NewApplicationPersonChildListComponent } from './newapplicationpersonchild-list.component';
import { NewApplicationPersonInsBeneficiary } from './newapplicationpersoninsbeneficiary';
import { NewApplicationPersonInsBeneficiaryListComponent } from './newapplicationpersoninsbeneficiary-list.component';
import { Within90DaysModalComponent } from './within-90-days-modal.component';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-newapplication',
  templateUrl: './newapplication.component.html',
})
export class NewApplicationComponent
  extends BaseItemComponent
  implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild(GridComponent)
  public grid!: GridComponent;
  @ViewChild(GridComponent)
  public gridPersonChild!: GridComponent;

  @ViewChild("newApplicationStepper", { static: false })
  newApplicationStepper!: MatStepper;

  @ViewChild(NewApplicationPersonInsBeneficiaryListComponent)
  public newApplicationPersonInsBeneficiaryListComponent!: NewApplicationPersonInsBeneficiaryListComponent;
  @ViewChild(NewApplicationPersonChildListComponent)
  public newApplicationPersonChildListComponent!: NewApplicationPersonChildListComponent;

  saving: boolean = false;
  isLessThan90Days: boolean = false;
  addChildren: boolean = false;
  addSpouseInfo: boolean = false;
  verifyingDODId: boolean = false;
  isEditApplication: boolean = false;
  editApplication!: NewApplication;
  personId?: number = undefined;
  hasNameChanges: boolean = false;

  list: NewApplicationPersonInsBeneficiary[] = [];
  listPersonChild: NewApplicationPersonChild[] = [];
  displayedColumnsCov: string[] = ['coverage', 'contribution'];
  displayedColumns: string[] = [
    'beneficiary',
    'relationship',
    'percentage',
    'beneficiaryDate',
  ];
  displayedColumnsPersonChild: string[] = [
    'firstName',
    'lastName',
    'middleName',
    'dob',
    'gender',
  ];
  today = new Date();
  step1FormGroup: FormGroup | any;
  step2FormGroup: FormGroup | any;
  step3FormGroup: FormGroup | any;
  step4FormGroup: FormGroup | any;
  step5FormGroup: FormGroup | any;
  step6FormGroup: FormGroup | any;

  relationshipIdLookups$: Observable<Lookup[]> | any;
  volunteerNetworkIdLookups$: Observable<Lookup[]> | any;
  relationshipsArray: Lookup[] | any;
  selectedCoveragesData: any[] | any;
  coverageTypeData: Lookup[] | any;
  volunteerNetworkData: Lookup[] | any;
  rankIdLookups$: Observable<Lookup[]> | any;
  ranksArray: Lookup[] | any;

  coveragesMember: any[] | any;
  coveragesSpouse: any[] | any;
  coveragesDependent: any[] | any;

  public coverageName: string | any;
  public dependentCoverageName: string | any;
  public spouseCoverageName: string | any;
  public memberCoverageName: string | any;
  public volunteerName: string | any;
  years: any[] = [];

  public listMcover: any[] = [
    { coverage: 10000, contribution: 3.66 },
    { coverage: 20000, contribution: 7.0 },
    { coverage: 25000, contribution: 8.67 },
    { coverage: 30000, contribution: 10.34 },
    { coverage: 40000, contribution: 13.67 },
    { coverage: 50000, contribution: 17.0 },
  ];

  public listScover: any[] = [
    { coverage: 5000, contribution: 2.0 },
    { coverage: 10000, contribution: 3.66 },
    { coverage: 15000, contribution: 5.33 },
    { coverage: 20000, contribution: 7.0 },
    { coverage: 25000, contribution: 8.67 },
  ];

  public listDcover: any[] = [
    { coverage: 5000, contribution: 3.33 },
    { coverage: 10000, contribution: 5.66 },
  ];

  get formArray(): FormArray {
    return this.theForm.get('formArray');
  }

  coverageTypeIdLookups$: Observable<Lookup[]> | any;
  coverageIdLookups$: Observable<Lookup[]> | any;

  public formGroup: FormGroup | any;
  public formGroupPersonChild: FormGroup | any;

  buildStep1FormGroup(): FormGroup {
    return this.fb.group({
      id: new FormControl(0),
      enlistmentDateMonth: new FormControl(null, Validators.required),
      enlistmentDateYear: new FormControl(null, Validators.required),
      dodId: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
        ])
      ),
      dodIdVerified: new FormControl(null, Validators.required)
    });
  }

  buildStep2FormGroup(): FormGroup {
    return this.fb.group({
      lastName: new FormControl(null, Validators.required),
      firstName: new FormControl(null, Validators.required),
      middleName: new FormControl(null),
      //rosterName: new FormControl(null),
      suffixName: new FormControl(null),
      nameChangeReason: new FormControl(null),
      address1: new FormControl(null, Validators.required),
      address2: new FormControl(null),
      city: new FormControl(null, Validators.required),
      state: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(2),
        ])
      ),
      zip: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(5),
          Validators.maxLength(5),
        ])
      ),
      primaryPhone: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(9),
          Validators.maxLength(10),
        ])
      ),
      secondaryPhone: new FormControl(
        null,
        Validators.compose([
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(9),
          Validators.maxLength(10),
        ])
      ),
      email: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.email])
      ),
      militaryEmail: new FormControl(
        null,
        Validators.compose([Validators.email])
      ),
      ssn: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
        ])
      ),
      //dodId: new FormControl(
      //  null,
      //  Validators.compose([
      //    Validators.required,
      //    Validators.pattern('^[0-9]*$'),
      //    Validators.minLength(10),
      //  ])
      //),
      volunteerNetworkId: new FormControl(null, Validators.required),
      rankId: new FormControl(null, Validators.required),
      heightFeet: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.min(1),
          Validators.max(10),
        ])
      ),
      heightInch: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.min(0),
          Validators.max(12),
        ])
      ),
      weightInLBS: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.min(0),
          Validators.max(1500),
        ])
      ),
      gender: new FormControl(null, Validators.required),
      isMarried: new FormControl(null, Validators.required),
      dutyStatusAGR: new FormControl(null, Validators.required),
      branchOfService: new FormControl(null, Validators.required),
      nameOfUnit: new FormControl(null, Validators.required),
      dob: new FormControl(null, Validators.required),
      age: new FormControl(
        { value: null, disabled: true },
        Validators.required
      ),
      newApplicationPersonInsBeneficiary: new FormControl(null),
      newApplicationPersonInsTotalPercentage: new FormControl(
        0,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.min(100),
          Validators.max(100),
        ])
      ),
    });
  }

  buildStep3FormGroup(): FormGroup {
    return this.fb.group({
      coverageTypeId: new FormControl(null, Validators.required),
      memberCoverageId: new FormControl(null, Validators.required),
      premium: new FormControl(
        { value: null, disabled: true },
        Validators.required
      ),

      spouseCoverageId: new FormControl(null),
      spousePremium: new FormControl({ value: null, disabled: true }),

      dependentCoverageId: new FormControl(null),
      dependentPremium: new FormControl({ value: null, disabled: true }),

      newApplicationSpouseAndDependentCoverage: new FormControl(
        null,
        Validators.compose([Validators.pattern('^[0-9]*$')])
      ),
      newApplicationDependentCoverage: new FormControl(
        null,
        Validators.compose([Validators.pattern('^[0-9]*$')])
      ),

      //effectiveDate: new FormControl(null, Validators.required),
      //nextPmtDue: new FormControl(null, Validators.required),
    });
  }

  buildStep4FormGroup(): FormGroup {
    return this.fb.group({
      spouseFirstName: new FormControl(null, Validators.required),
      spouseLastName: new FormControl(null, Validators.required),
      spouseMiddleName: new FormControl(null),
      spouseSSN: new FormControl(
        null,
        Validators.compose([
          //Validators.required,
          Validators.pattern('^[0-9]*$'),
        ])
      ),
      spouseGender: new FormControl(null, Validators.required),
      spouseHeightFeet: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.min(1),
          Validators.max(10),
        ])
      ),
      spouseHeightInch: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.min(0),
          Validators.max(12),
        ])
      ),
      spouseWeightInLBS: new FormControl(null, Validators.required),
      spousePhone: new FormControl(
        null,
        Validators.compose([
          //Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(9),
          Validators.maxLength(10),
        ])
      ),
      spouseEmail: new FormControl(
        null,
        Validators.compose([
          //Validators.required,
          Validators.email])
      ),
      spouseDOB: new FormControl(null, Validators.required),
      spouseAge: new FormControl(
        { value: null, disabled: true },
        Validators.required
      ),

      noOfChildren: new FormControl(null),
      newApplicationPersonChild: new FormControl(null),
      //newApplicationAddChildren: new FormControl(0, Validators.compose([Validators.required, Validators.pattern("^[0-9]*$"), Validators.min(1)])),
      newApplicationAddChildren: new FormControl(0),
    });
  }

  buildStep5FormGroup(): FormGroup {
    return this.fb.group({
      last10YearsDiagnosed: new FormControl(null, Validators.required),
      last5YearAdmitted: new FormControl(null, Validators.required),
      everDignosedForAids: new FormControl(null, Validators.required),
      spouseLast10YearsDiagnosed: new FormControl(null, Validators.required),
      spouseLast5YearAdmitted: new FormControl(null, Validators.required),
      spouseEverDignosedForAids: new FormControl(null, Validators.required),
      listPrescribedMedication: new FormControl(null),
    });
  }

  buildStep6FormGroup(): FormGroup {
    return this.fb.group({
      signCity: new FormControl(null, Validators.required),
      signState: new FormControl(null, Validators.required),
    });
  }

  constructor(
    private fb: FormBuilder,
    private service: NewApplicationService,
    private route: ActivatedRoute,
    private lookupService: LookupService,
    private location: Location,
    private cd: ChangeDetectorRef,
    public authService: AuthenticationService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    super();

    let navigateToNewApplication: boolean = false;

    if (this.router.url.endsWith('edit-application')) {
      const navigation = this.router.getCurrentNavigation();

      if (navigation != null) {
        const state = navigation.extras.state as {
          pid: number,
        };

        if (state == undefined || isNaN(state.pid)) {
          navigateToNewApplication = true;
        }
        else {

          this.isEditApplication = true;
          this.personId = state.pid;
        }
      }
      else {
        navigateToNewApplication = true;
      }

      if (navigateToNewApplication == true) {
        this.router.navigate(['new-application'], { state: {} });
      }
    }


    this.coverageTypeIdLookups$ = this.lookupService.getList(
      'coverageTypes',
      []
    );

    this.coverageTypeIdLookups$.subscribe((s: Lookup[]) => {
      this.coverageTypeData = s;
    });

    this.relationshipIdLookups$ = this.lookupService.getList(
      'relationships',
      []
    );
    this.volunteerNetworkIdLookups$ = this.lookupService.getList(
      'volunteernetworks',
      []
    );

    this.rankIdLookups$ = this.lookupService.getList(
      'ranks',
      []
    );

    this.volunteerNetworkIdLookups$.subscribe((s: Lookup[]) => {
      this.volunteerNetworkData = s;
    });

    this.relationshipIdLookups$.subscribe((s: Lookup[]) => {
      this.relationshipsArray = s;
    });

    this.rankIdLookups$.subscribe((s: Lookup[]) => {
      this.ranksArray = s;
    });

    let startYear = new Date().getFullYear();

    for (var i = startYear; i >= startYear - 40; i--) {
      this.years.push({ key: i, value: i });
    }
  }

  ngOnInit() {
    this.theForm = this.fb.group({
      formArray: this.fb.array([
        this.buildStep1FormGroup(),
        this.buildStep2FormGroup(),
        this.buildStep3FormGroup(),
        this.buildStep4FormGroup(),
        this.buildStep5FormGroup(),
        this.buildStep6FormGroup(),
      ]),
    });

    this.step1FormGroup = this.buildStep1FormGroup();

    this.dodId.valueChanges.subscribe(s => {
      this.dodIdVerified.setValue(null);

      for (var i in (this.formArray.controls[0] as FormGroup).controls) {
        (this.formArray.controls[0] as FormGroup).controls[i].markAsTouched();
      }
    });

    this.step2FormGroup = this.buildStep2FormGroup();
    this.step3FormGroup = this.buildStep3FormGroup();
    this.step4FormGroup = this.buildStep4FormGroup();
    (this.step5FormGroup = this.buildStep5FormGroup()),
      (this.step6FormGroup = this.buildStep6FormGroup()),
      this.authService.raisePersonNewAppBeneDataEvent$.subscribe((result) => {
        if (result == true) {
          this.list = this.newApplicationPersonInsBeneficiaryListComponent.list;

          let totalPercentage: number = 0;
          if (this.newApplicationPersonInsBeneficiaryListComponent) {
            this.newApplicationPersonInsBeneficiaryListComponent.list.forEach(
              (obj: any) => {
                totalPercentage += obj.percentage;
              }
            );
          }
          this.newApplicationPersonInsTotalPercentage.setValue(totalPercentage);

          //this.id.markAsPristine();
          //this.id.markAsDirty();
        }
      });

    this.authService.raisePersonNewAppChildDataEvent$.subscribe((result) => {
      if (result == true) {
        this.listPersonChild = this.newApplicationPersonChildListComponent.list;
        this.newApplicationAddChildren.setValue(this.listPersonChild.length);

        //this.id.markAsPristine();
        //this.id.markAsDirty();
      }
    });

    this.bindEditApplicationData();
  }

  bindEditApplicationData(): void {

    if (this.isEditApplication == true && this.personId !== undefined && this.personId > 0) {
      const tymodel = new typeModel<any>(this.personId);

      this.service
        .postByCustomAction$<any>("getEditApplicationDetails", tymodel)
        .pipe(
          catchError((err, caught) => {
            return of(null as any);
          })
        ).subscribe((editApplicationModel: NewApplication) => {
          console.log(editApplicationModel);

          this.editApplication = editApplicationModel;

          if (editApplicationModel !== undefined) {
            //Tab 1

            this.id.setValue(editApplicationModel.id);

            if (editApplicationModel.enlistmentDate !== null && editApplicationModel.enlistmentDate !== undefined) {

              if (editApplicationModel.enlistmentDate == '0001-01-01T00:00:00') {
                editApplicationModel.enlistmentDate = null;
              }

              if (editApplicationModel.enlistmentDate != null) {
                var date = new Date(editApplicationModel.enlistmentDate);
                this.enlistmentDateMonth.setValue(date.getMonth().toString());
                this.enlistmentDateYear.setValue(date.getFullYear());

                this.checkEnlistmentDate();
                this.enlistmentDateMonth.disable();
                this.enlistmentDateYear.disable();
                this.enlistmentDateYear.clearValidators();
                this.enlistmentDateYear.updateValueAndValidity();
              }
            }

            if (editApplicationModel.dodId !== null && editApplicationModel.dodId !== undefined) {
              this.dodId.setValue(editApplicationModel.dodId);
              this.dodIdVerified.setValue(1);
              this.dodId.disable();
              this.dodIdVerified.clearValidators();
              this.dodIdVerified.updateValueAndValidity();
              this.dodId.clearValidators();
              this.dodId.updateValueAndValidity();
            }

            //Tab 2
            this.lastName.setValue(editApplicationModel.lastName);
            this.firstName.setValue(editApplicationModel.firstName);
            this.middleName.setValue(editApplicationModel.middleName);
            this.suffixName.setValue(editApplicationModel.suffixName);
            this.rankId.setValue(editApplicationModel.rankId);
            this.ssn.setValue(editApplicationModel.ssn);

            this.lastName.valueChanges.subscribe(s => {
              this.checkNameChanges();
            });

            this.firstName.valueChanges.subscribe(s => {
              this.checkNameChanges();
            });

            this.middleName.valueChanges.subscribe(s => {
              this.checkNameChanges();
            });

            this.suffixName.valueChanges.subscribe(s => {
              this.checkNameChanges();
            });

            if (editApplicationModel.isInsured == true) {
              if (editApplicationModel.dob !== undefined) {
                this.dob.setValue(editApplicationModel.dob);
                this.dobChange(new Date(editApplicationModel.dob));
              }

              if (editApplicationModel.heightInInch !== undefined) {

                let feetFromInches = Math.floor(editApplicationModel.heightInInch / 12);
                let inchesRemainder = editApplicationModel.heightInInch % 12;

                this.heightFeet.setValue(feetFromInches);
                this.heightInch.setValue(inchesRemainder);
              }

              this.weightInLBS.setValue(editApplicationModel.weightInLBS);
              this.gender.setValue(editApplicationModel.gender);

              this.isMarried.setValue(editApplicationModel.isMarried ? "1" : "0");

              if (editApplicationModel.isMarried != null && editApplicationModel.isMarried != undefined) {
                this.marriedChange(editApplicationModel.isMarried ? "1" : "0");
              }

              this.dutyStatusAGR.setValue(editApplicationModel.dutyStatusAGR ? "1" : "0");
              this.branchOfService.setValue(editApplicationModel.branchOfService);
              this.nameOfUnit.setValue(editApplicationModel.nameOfUnit);
              this.address1.setValue(editApplicationModel.address1);
              this.address2.setValue(editApplicationModel.address2);
              this.city.setValue(editApplicationModel.city);
              this.state.setValue(editApplicationModel.state);
              this.zip.setValue(editApplicationModel.zip);
              this.primaryPhone.setValue(editApplicationModel.primaryPhone);
              this.secondaryPhone.setValue(editApplicationModel.secondaryPhone);

              this.email.setValue(editApplicationModel.email);
              this.militaryEmail.setValue(editApplicationModel.militaryEmail);

              if (editApplicationModel.volunteerNetworkId != null && editApplicationModel.volunteerNetworkId != undefined) {
                this.volunteerNetworkId.setValue(editApplicationModel.volunteerNetworkId);
                this.volunteerChange(editApplicationModel.volunteerNetworkId);
                this.volunteerNetworkId.disable();

              }

              this.dob.disable();
            }

            this.ssn.disable();

            if (editApplicationModel.isInsured == true) {
              if (editApplicationModel.newApplicationPersonInsBeneficiary !== undefined) {

                let totalPercentage: number = 0;

                editApplicationModel.newApplicationPersonInsBeneficiary.forEach((o) => {
                  o.percentage = o.percentage * 100;
                  o.isNew = false;

                  totalPercentage = totalPercentage + o.percentage;
                });

                this.newApplicationPersonInsTotalPercentage.setValue(totalPercentage);
                this.newApplicationPersonInsBeneficiaryListComponent.list = editApplicationModel.newApplicationPersonInsBeneficiary;
                this.list = this.newApplicationPersonInsBeneficiaryListComponent.list;

              }

              this.loadCoverages(editApplicationModel.coverageTypeId);

              //Tab 3 - Nothing

              //Tab 4 
              this.memberCoverageId.setValue(editApplicationModel.memberCoverageId);
              // this.memberCoverageChange(editApplicationModel.memberCoverageId);
              this.premium.setValue(editApplicationModel.premium);

              if (editApplicationModel.spouseCoverageId !== undefined && editApplicationModel.spouseCoverageId > 0) {
                this.spouseCoverageId.setValue(editApplicationModel.spouseCoverageId);
                //this.spouseCoverageChange(editApplicationModel.spouseCoverageId);
                this.spousePremium.setValue(editApplicationModel.spousePremium);
              }

              if (editApplicationModel.dependentCoverageId !== undefined && editApplicationModel.dependentCoverageId > 0) {
                this.dependentCoverageId.setValue(editApplicationModel.dependentCoverageId);
                //this.dependentCoverageChange(editApplicationModel.dependentCoverageId);
                this.dependentPremium.setValue(editApplicationModel.dependentPremium);
              }

              //Tab 5
              this.spouseFirstName.setValue(editApplicationModel.spouseFirstName);
              this.spouseLastName.setValue(editApplicationModel.spouseLastName);
              this.spouseMiddleName.setValue(editApplicationModel.spouseMiddleName);
              this.spouseSSN.setValue(editApplicationModel.spouseSSN);
              this.spouseGender.setValue(editApplicationModel.spouseGender);

              if (editApplicationModel.spouseDOB != undefined) {
                this.spouseDOB.setValue(editApplicationModel.spouseDOB);
                this.spouseDobChange(new Date(editApplicationModel.spouseDOB));
              }

              if (editApplicationModel.spouseHeightInInch !== undefined) {

                let spouseFeetFromInches = Math.floor(editApplicationModel.spouseHeightInInch / 12);
                let spouseInchesRemainder = editApplicationModel.spouseHeightInInch % 12;

                this.spouseHeightFeet.setValue(spouseFeetFromInches);
                this.spouseHeightInch.setValue(spouseInchesRemainder);

              }

              this.spouseWeightInLBS.setValue(editApplicationModel.spouseWeightInLBS);
              this.spousePhone.setValue(editApplicationModel.spousePhone);
              this.spouseEmail.setValue(editApplicationModel.spouseEmail);

              if (editApplicationModel.newApplicationPersonChild !== undefined && this.newApplicationPersonChildListComponent != undefined) {

                editApplicationModel.newApplicationPersonChild.forEach((o) => {
                  o.isNew = false;
                });

                this.newApplicationPersonChildListComponent.list = editApplicationModel.newApplicationPersonChild;
                this.listPersonChild = this.newApplicationPersonChildListComponent.list;
              }

              //Tab 6
              if (editApplicationModel.last10YearsDiagnosed != undefined) {
                this.last10YearsDiagnosed.setValue(editApplicationModel.last10YearsDiagnosed ? "1" : "0");
              }

              if (editApplicationModel.last5YearAdmitted != undefined) {
                this.last5YearAdmitted.setValue(editApplicationModel.last5YearAdmitted ? "1" : "0");
              }

              if (editApplicationModel.spouseLast10YearsDiagnosed != undefined) {
                this.spouseLast10YearsDiagnosed.setValue(editApplicationModel.spouseLast10YearsDiagnosed ? "1" : "0");
              }

              if (editApplicationModel.spouseLast5YearAdmitted != undefined) {
                this.spouseLast5YearAdmitted.setValue(editApplicationModel.spouseLast5YearAdmitted ? "1" : "0");
              }

              if (editApplicationModel.everDignosedForAids != undefined) {
                this.everDignosedForAids.setValue(editApplicationModel.everDignosedForAids ? "1" : "0");
              }

              if (editApplicationModel.spouseEverDignosedForAids != undefined) {
                this.spouseEverDignosedForAids.setValue(editApplicationModel.spouseEverDignosedForAids ? "1" : "0");
              }

              this.listPrescribedMedication.setValue(editApplicationModel.listPrescribedMedication);
            }
          }
        });
    }
  }

  checkNameChanges(): void {

    this.nameChangeReason.clearValidators();

    if (this.editApplication !== undefined && this.editApplication.firstName != this.firstName.value ||
      this.editApplication.lastName != this.lastName.value ||
      this.editApplication.middleName != this.middleName.value ||
      this.editApplication.suffixName != this.suffixName.value) {
      this.nameChangeReason.setValidators([Validators.required]);
      this.hasNameChanges = true;
    }
    else {
      this.hasNameChanges = false;
    }

    this.nameChangeReason.updateValueAndValidity();
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  ngOnChanges() { }

  loadCoverages(coverageTypeId: any) {
    this.coverageName = this.coverageTypeData.filter(
      (f: any) => f.key == coverageTypeId
    )[0].value;
    this.coverageIdLookups$ = this.lookupService.getList('coverages', [
      new Filter('coverageTypeId', coverageTypeId),
      new Filter('isAssignable', 1),
    ]);

    this.coverageIdLookups$.subscribe((s: any[]) => {
      this.selectedCoveragesData = s;

      this.coveragesMember = [];
      this.coveragesSpouse = [];
      this.coveragesDependent = [];

      s.forEach((cov) => {
        if (cov.type == 'M') {
          this.coveragesMember.push(cov);
        } else if (cov.type == 'S') {
          this.coveragesSpouse.push(cov);
        } else if (cov.type == 'D') {
          this.coveragesDependent.push(cov);
        }
      });
    });
  }

  marriedChange(val: any) {
    this.addSpouseInfo = val == '1';
    this.updateSpouseValidations();
  }

  updateSpouseValidations() {
    this.spouseFirstName.clearValidators();
    this.spouseLastName.clearValidators();
    this.spouseSSN.clearValidators();
    this.spouseGender.clearValidators();
    this.spouseHeightFeet.clearValidators();
    this.spouseHeightInch.clearValidators();
    this.spouseWeightInLBS.clearValidators();
    this.spousePhone.clearValidators();
    this.spouseEmail.clearValidators();
    this.spouseLast10YearsDiagnosed.clearValidators();
    this.spouseLast5YearAdmitted.clearValidators();
    this.spouseEverDignosedForAids.clearValidators();
    this.spouseDOB.clearValidators();
    this.spouseAge.clearValidators();

    if (this.addSpouseInfo) {
      this.spouseFirstName.setValidators([Validators.required]);
      this.spouseLastName.setValidators([Validators.required]);
      this.spouseSSN.setValidators([
        //Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]);
      this.spouseGender.setValidators([Validators.required]);
      this.spouseHeightFeet.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(1),
        Validators.max(10),
      ]);
      this.spouseHeightInch.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(0),
        Validators.max(12),
      ]);
      this.spouseWeightInLBS.setValidators([Validators.required]);
      this.spousePhone.setValidators([
        //Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(9),
        Validators.maxLength(10),
      ]);
      this.spouseEmail.setValidators([
        //Validators.required,
        Validators.email]);
      //this.noOfChildren.setValidators([Validators.required]);
      this.spouseLast10YearsDiagnosed.setValidators([Validators.required]);
      this.spouseLast5YearAdmitted.setValidators([Validators.required]);
      this.spouseEverDignosedForAids.setValidators([Validators.required]);
      this.spouseDOB.setValidators([Validators.required]);
      this.spouseAge.setValidators([Validators.required]);
    }

    this.spouseFirstName.updateValueAndValidity();
    this.spouseLastName.updateValueAndValidity();
    this.spouseSSN.updateValueAndValidity();
    this.spouseGender.updateValueAndValidity();
    this.spouseHeightFeet.updateValueAndValidity();
    this.spouseHeightInch.updateValueAndValidity();
    this.spouseWeightInLBS.updateValueAndValidity();
    this.spousePhone.updateValueAndValidity();
    this.spouseEmail.updateValueAndValidity();
    this.spouseLast10YearsDiagnosed.updateValueAndValidity();
    this.spouseLast5YearAdmitted.updateValueAndValidity();
    this.spouseEverDignosedForAids.updateValueAndValidity();
    this.spouseDOB.updateValueAndValidity();
    this.spouseAge.updateValueAndValidity();
  }

  memberCoverageChange(coverageId: any) {
    let selectedCoverage = this.selectedCoveragesData.filter(
      (f: any) => f.key == coverageId
    )[0];
    let premium = selectedCoverage.premium;
    this.memberCoverageName = selectedCoverage.value;
    let fa = this.theForm.get('formArray') as FormArray;

    this.premium.patchValue(premium);
    (fa.controls[2] as FormGroup).controls['premium'].patchValue(premium);

    this.newApplicationSpouseAndDependentCoverage.clearValidators();
    this.newApplicationSpouseAndDependentCoverage.setValidators([
      Validators.pattern('^[0-9]*$'),
      Validators.min(0),
      Validators.max(parseFloat(selectedCoverage.value)),
    ]);
    this.newApplicationSpouseAndDependentCoverage.updateValueAndValidity();

    this.newApplicationDependentCoverage.clearValidators();
    this.newApplicationDependentCoverage.setValidators([
      Validators.pattern('^[0-9]*$'),
      Validators.min(0),
      Validators.max(parseFloat(selectedCoverage.value) / 2),
    ]);
    this.newApplicationDependentCoverage.updateValueAndValidity();
  }

  spouseCoverageChange(coverageId: any) {
    let premium: number = 0;
    this.spouseCoverageName = 'None';
    let spouseCoverageValue: number = 0;

    this.addSpouseInfo = false;
    if (coverageId !== undefined) {
      let selectedSpouseCoverage = this.selectedCoveragesData.filter(
        (f: any) => f.key == coverageId
      )[0];
      premium = selectedSpouseCoverage.premium;
      this.spouseCoverageName = selectedSpouseCoverage.value;
      spouseCoverageValue = parseFloat(selectedSpouseCoverage.value);
      this.addSpouseInfo = this.isMarried.value == '1';
    }

    this.updateSpouseValidations();

    this.spousePremium.patchValue(premium);
    (this.formArray.controls[2] as FormGroup).controls[
      'spousePremium'
    ].patchValue(premium);

    let dependentCoverage: number = 0;
    if (this.dependentCoverageId !== undefined) {
      let selectedDependentCoverage = this.selectedCoveragesData.filter(
        (f: any) => f.key == this.dependentCoverageId.value
      );

      if (selectedDependentCoverage.length > 0) {
        dependentCoverage = parseFloat(selectedDependentCoverage[0].value);
      }
    }

    this.newApplicationSpouseAndDependentCoverage.setValue(
      spouseCoverageValue + dependentCoverage
    );
  }

  dependentCoverageChange(coverageId: any) {
    let premium: number = 0;
    this.dependentCoverageName = 'None';
    let dependentCoverageValue: number = 0;

    this.newApplicationAddChildren.clearValidators();
    this.addChildren = false;

    if (coverageId !== undefined) {
      let selectedDependentCoverage = this.selectedCoveragesData.filter(
        (f: any) => f.key == coverageId
      )[0];
      premium = selectedDependentCoverage.premium;
      this.dependentCoverageName = selectedDependentCoverage.value;
      dependentCoverageValue = parseFloat(selectedDependentCoverage.value);
      this.newApplicationAddChildren.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(1),
      ]);
      this.addChildren = true;
    }

    this.newApplicationAddChildren.updateValueAndValidity();

    this.dependentPremium.patchValue(premium);
    (this.formArray.controls[2] as FormGroup).controls[
      'dependentPremium'
    ].patchValue(premium);

    let spouseCoverage: number = 0;

    if (this.spouseCoverageId !== undefined) {
      let selectedSpouseCoverage = this.selectedCoveragesData.filter(
        (f: any) => f.key == this.spouseCoverageId.value
      );

      if (selectedSpouseCoverage.length > 0) {
        spouseCoverage = parseFloat(selectedSpouseCoverage[0].value);
      }
    }

    this.newApplicationSpouseAndDependentCoverage.setValue(
      dependentCoverageValue + spouseCoverage
    );
    this.newApplicationDependentCoverage.setValue(dependentCoverageValue);
  }

  dobChange(dob: Date) {
    let age = this.calculateAge(dob);

    this.age.patchValue(age);
    (this.formArray.controls[1] as FormGroup).controls['age'].patchValue(age);
  }

  spouseDobChange(dob: Date) {
    let age = this.calculateAge(dob);

    this.spouseAge.patchValue(age);
    (this.formArray.controls[3] as FormGroup).controls['spouseAge'].patchValue(
      age
    );
  }

  volunteerChange(volunteerNetworkId: any) {
    this.volunteerName = this.volunteerNetworkData.filter(
      (f: any) => f.key == volunteerNetworkId
    )[0].value;
  }

  calculateAge(dob: Date): number {
    let timeDiff = Math.abs(Date.now() - dob.getTime());
    let age = parseFloat((timeDiff / (1000 * 3600 * 24) / 365.25).toFixed(1));
    return age;
  }

  calculateDaysDiff(pastDate: Date) {
    let currentDate = new Date();
    pastDate = new Date(pastDate);

    return Math.floor(
      (Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ) -
        Date.UTC(
          pastDate.getFullYear(),
          pastDate.getMonth(),
          pastDate.getDate()
        )) /
      (1000 * 60 * 60 * 24)
    );
  }

  goForward() {

    for (var i in (this.formArray.controls[0] as FormGroup).controls) {
      (this.formArray.controls[0] as FormGroup).controls[i].markAsTouched();
    }

    if (this.isEditApplication == true  //(this.formArray.controls[0] as FormGroup).valid

    ) {

      if (this.dodId.disabled) {
        this.dodIdVerified.setValue(1);
        this.dodIdVerified.clearValidators();
        this.dodIdVerified.updateValueAndValidity();

        this.dodId.clearValidators();
        this.dodId.updateValueAndValidity();

      }

      if (this.enlistmentDateMonth.disabled && this.enlistmentDateYear.disabled) {

        this.enlistmentDateMonth.clearValidators();
        this.enlistmentDateMonth.updateValueAndValidity();

        this.enlistmentDateYear.clearValidators();
        this.enlistmentDateYear.updateValueAndValidity();
      }

      this.newApplicationStepper.next();
    }

    if (this.dodId.valid) {
      const tymodel = new typeModel<any>(this.dodId.value);

      this.verifyingDODId = true;

      this.service
        .postByCustomAction$<any>("checkDODId", tymodel)
        .pipe(
          catchError((err, caught) => {
            this.verifyingDODId = false;
            return of(null as any);
          })
        ).subscribe((data: any) => {

          if (data == true) {

            const dialogRef = this.dialog.open(DodIdExistsModelComponent, {
              data: true,
              width: "40%",
            });
          }
          else {
            this.dodIdVerified.setValue(1);
            this.newApplicationStepper.next();
          }

          this.verifyingDODId = false;
        });
    }
  }

  matStepperSelectionChange(event: StepperSelectionEvent) {

    ////event.selectedStep();
    //debugger;
    //if (event.previouslySelectedIndex === 0) {

    //  this.goForward();
    //}

    //return;
  }

  onSubmit() {
    if (this.theForm?.valid) {
      let saveModel: NewApplication = new NewApplication({
        id: this.id.value,
        enlistmentDate: new Date(
          this.enlistmentDateYear.value,
          this.enlistmentDateMonth.value - 1,
          1
        ),
        lastName: this.lastName.value,
        firstName: this.firstName.value,
        middleName: this.middleName.value,
        //rosterName: this.rosterName.value,
        suffixName: this.suffixName.value,
        nameChangeReason: this.nameChangeReason.value,
        address1: this.address1.value,
        address2: this.address2.value,
        city: this.city.value,
        state: this.state.value,
        zip: this.zip.value,
        primaryPhone: this.primaryPhone.value,
        secondaryPhone: this.secondaryPhone.value,
        email: this.email.value,
        militaryEmail: this.militaryEmail.value,
        ssn: this.ssn.value,
        dodId: this.dodId.value,
        volunteerNetworkId: this.volunteerNetworkId.value,
        rankId: this.rankId.value,
        heightInInch:
          parseInt(this.heightFeet.value) * 12 +
          parseInt(this.heightInch.value),
        weightInLBS: this.weightInLBS.value,
        gender: this.gender.value,
        isMarried: this.isMarried.value == '1' ? true : false,
        dutyStatusAGR: this.dutyStatusAGR.value == '1' ? true : false,
        branchOfService: this.branchOfService.value,
        nameOfUnit: this.nameOfUnit.value,

        spouseFirstName: this.spouseFirstName.value,
        spouseLastName: this.spouseLastName.value,
        spouseMiddleName: this.spouseMiddleName.value,
        spouseSSN: this.spouseSSN.value,
        spouseGender: this.spouseGender.value,
        spouseHeightInInch:
          parseInt(this.spouseHeightFeet.value) * 12 +
          parseInt(this.spouseHeightInch.value),
        spouseWeightInLBS: this.spouseWeightInLBS.value == '' ? null : this.spouseWeightInLBS.value,
        spousePhone: this.spousePhone.value,
        spouseEmail: this.spouseEmail.value,
        noOfChildren: this.listPersonChild.length, //this.noOfChildren.value,
        last10YearsDiagnosed:
          this.last10YearsDiagnosed.value == '1' ? true : false,
        last5YearAdmitted: this.last5YearAdmitted.value == '1' ? true : false,
        everDignosedForAids:
          this.everDignosedForAids.value == '1' ? true : false,
        spouseLast10YearsDiagnosed:
          this.spouseLast10YearsDiagnosed.value == '1' ? true : false,
        spouseLast5YearAdmitted:
          this.spouseLast5YearAdmitted.value == '1' ? true : false,
        spouseEverDignosedForAids:
          this.spouseEverDignosedForAids.value == '1' ? true : false,
        listPrescribedMedication: this.listPrescribedMedication.value,
        signCity: this.signCity.value,
        signState: this.signState.value,
        spouseDOB: this.spouseDOB.value == '' ? null : this.spouseDOB.value,
        spouseAge: this.spouseAge.value,

        coverageTypeId: this.coverageTypeId.value,
        memberCoverageId: this.memberCoverageId.value == '' ? null : this.memberCoverageId.value,
        premium: this.premium.value == '' ? null : this.premium.value,
        dob: this.dob.value == '' ? null : this.dob.value,
        age: this.age.value,
        spouseCoverageId: this.spouseCoverageId.value == '' ? null : this.spouseCoverageId.value,
        spousePremium: this.spousePremium.value == '' ? null : this.spousePremium.value,

        dependentCoverageId: this.dependentCoverageId.value == '' ? null : this.dependentCoverageId.value,
        dependentPremium: this.dependentPremium.value == '' ? null : this.dependentPremium.value,
        //effectiveDate: this.effectiveDate.value,
        //nextPmtDue: this.nextPmtDue.value,
        newApplicationPersonInsBeneficiary: this.list,
        newApplicationPersonChild: this.listPersonChild,
        isEditApplication: this.isEditApplication,
      });

      this.saving = true;

      this.service
        .postModelByCustomAction$<NewApplication>(
          'submitApplication',
          saveModel,
          NewApplication
        )
        .pipe(
          catchError((err, caught) => {
            FormValidation.parseSubmitErrors(err, this.theForm);
            this.saving = false;
            return of(null as NewApplication | any);
          })
        )
        .subscribe((res: any) => {
          this.saving = false;

          if (res !== null && res !== undefined && res.isSuccess == true) {
            this.authService.eventNewApplicationCountChangeRaised();
            if (this.isEditApplication == true) {
              this.router.navigate(['applicationEditsubmitted'], { state: { isEditApplication: true, docUSign: res.sendToDocUSign } });
            }
            else {
              this.router.navigate(['newapplicationsubmitted'], { state: { isEditApplication: false, docUSign: true } });
            }
          }
        });
    } else {
      FormValidation.validateAllFormFields(this.theForm);
    }
  }

  undo(): void { }

  public rowCallback(context: RowClassArgs) {
    return {};
  }

  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }

  private fitColumns(): void {
    //this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
    //  this.grid.autoFitColumns();
    //});
  }

  public relationships(value: any): any {
    if (this.relationshipsArray !== undefined) {
      let val = this.relationshipsArray.find((f: Lookup) => f.value == value);
      return val;
    }

    return '';
  }

  checkEnlistmentDate() {
    let enlistmentDate = new Date(
      this.enlistmentDateYear.value,
      this.enlistmentDateMonth.value - 1,
      1
    );
    let daysDiff = this.calculateDaysDiff(enlistmentDate);
    this.isLessThan90Days = daysDiff < 90;

    if (this.isLessThan90Days) {
      let dialogRef = this.dialog.open(Within90DaysModalComponent, {
        width: '640px',
      });
    }

    let fa = this.theForm.get('formArray') as FormArray;

    //this.dodId.clearValidators();
    this.heightFeet.clearValidators();
    this.heightInch.clearValidators();
    this.weightInLBS.clearValidators();
    this.isMarried.clearValidators();
    this.dutyStatusAGR.clearValidators();
    this.militaryEmail.clearValidators();
    this.noOfChildren.clearValidators();
    this.last10YearsDiagnosed.clearValidators();
    this.last5YearAdmitted.clearValidators();
    this.everDignosedForAids.clearValidators();

    this.spouseFirstName.clearValidators();
    this.spouseLastName.clearValidators();
    this.spouseSSN.clearValidators();
    this.spouseGender.clearValidators();
    this.spouseHeightFeet.clearValidators();
    this.spouseHeightInch.clearValidators();
    this.spouseWeightInLBS.clearValidators();
    this.spousePhone.clearValidators();
    this.spouseEmail.clearValidators();
    this.spouseLast10YearsDiagnosed.clearValidators();
    this.spouseLast5YearAdmitted.clearValidators();
    this.spouseEverDignosedForAids.clearValidators();
    this.spouseDOB.clearValidators();
    this.spouseAge.clearValidators();

    //this.signCity.clearValidators();
    //this.signState.clearValidators();

    this.coverageTypeId.clearValidators();
    this.memberCoverageId.clearValidators();
    this.premium.clearValidators();
    //this.spouseCoverageId.clearValidators();
    //this.spousePremium.clearValidators();
    //this.dependentCoverageId.clearValidators();
    //this.dependentPremium.clearValidators();
    //this.effectiveDate.clearValidators();
    //this.nextPmtDue.clearValidators();

    let coverageTypeId: number = 6;
    if (this.isLessThan90Days == true) {
      coverageTypeId = 10;
    } else {

      //this.dodId.setValidators([
      //  Validators.required,
      //  Validators.pattern('^[0-9]*$'),
      //  Validators.minLength(10),
      //]);

      this.heightFeet.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(1),
        Validators.max(10),
      ]);
      this.heightInch.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(0),
        Validators.max(12),
      ]);
      this.weightInLBS.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(0),
        Validators.max(1500),
      ]);
      this.isMarried.setValidators([Validators.required]);
      this.dutyStatusAGR.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(0),
        Validators.max(1500),
      ]);
      this.militaryEmail.setValidators([Validators.email]);
      this.last10YearsDiagnosed.setValidators([Validators.required]);
      this.last5YearAdmitted.setValidators([Validators.required]);
      this.everDignosedForAids.setValidators([Validators.required]);

      this.spouseFirstName.setValidators([Validators.required]);
      this.spouseLastName.setValidators([Validators.required]);
      this.spouseSSN.setValidators([
        //Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]);
      this.spouseGender.setValidators([Validators.required]);
      this.spouseHeightFeet.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(1),
        Validators.max(10),
      ]);
      this.spouseHeightInch.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(0),
        Validators.max(12),
      ]);
      this.spouseWeightInLBS.setValidators([Validators.required]);
      this.spousePhone.setValidators([
        //Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(9),
        Validators.maxLength(10),
      ]);
      this.spouseEmail.setValidators([
        //Validators.required,
        Validators.email]);
      //this.noOfChildren.setValidators([Validators.required]);
      this.spouseLast10YearsDiagnosed.setValidators([Validators.required]);
      this.spouseLast5YearAdmitted.setValidators([Validators.required]);
      this.spouseEverDignosedForAids.setValidators([Validators.required]);
      this.spouseDOB.setValidators([Validators.required]);
      this.spouseAge.setValidators([Validators.required]);

      //this.signCity.setValidators([Validators.required]);
      //this.signState.setValidators([Validators.required]);

      this.coverageTypeId.setValidators([Validators.required]);
      this.memberCoverageId.setValidators([Validators.required]);
      this.premium.setValidators([Validators.required]);
      //this.spouseCoverageId.setValidators([Validators.required]);
      //this.spousePremium.setValidators([Validators.required]);
      //this.dependentCoverageId.setValidators([Validators.required]);
      //this.dependentPremium.setValidators([Validators.required]);
      //this.effectiveDate.setValidators([Validators.required]);
      //this.nextPmtDue.setValidators([Validators.required]);
    }

    //this.dodId.updateValueAndValidity();
    this.heightFeet.updateValueAndValidity();
    this.heightInch.updateValueAndValidity();
    this.weightInLBS.updateValueAndValidity();
    this.isMarried.updateValueAndValidity();
    this.dutyStatusAGR.updateValueAndValidity();
    this.militaryEmail.updateValueAndValidity();

    this.noOfChildren.updateValueAndValidity();
    this.last10YearsDiagnosed.updateValueAndValidity();
    this.last5YearAdmitted.updateValueAndValidity();
    this.everDignosedForAids.updateValueAndValidity();

    this.spouseFirstName.updateValueAndValidity();
    this.spouseLastName.updateValueAndValidity();
    this.spouseSSN.updateValueAndValidity();
    this.spouseGender.updateValueAndValidity();
    this.spouseHeightFeet.updateValueAndValidity();
    this.spouseHeightInch.updateValueAndValidity();
    this.spouseWeightInLBS.updateValueAndValidity();
    this.spousePhone.updateValueAndValidity();
    this.spouseEmail.updateValueAndValidity();
    this.spouseLast10YearsDiagnosed.updateValueAndValidity();
    this.spouseLast5YearAdmitted.updateValueAndValidity();
    this.spouseEverDignosedForAids.updateValueAndValidity();
    this.spouseDOB.updateValueAndValidity();
    this.spouseAge.updateValueAndValidity();

    //this.signCity.updateValueAndValidity();
    //this.signState.updateValueAndValidity();

    this.coverageTypeId.updateValueAndValidity();
    this.memberCoverageId.updateValueAndValidity();
    this.premium.updateValueAndValidity();
    //this.spouseCoverageId.updateValueAndValidity();
    //this.spousePremium.updateValueAndValidity();
    //this.dependentCoverageId.updateValueAndValidity();
    //this.dependentPremium.updateValueAndValidity();
    //this.effectiveDate.updateValueAndValidity();
    //this.nextPmtDue.updateValueAndValidity();

    this.coverageTypeId.patchValue(coverageTypeId);
    (fa.controls[2] as FormGroup).controls['coverageTypeId'].patchValue(
      coverageTypeId
    );

    this.loadCoverages(coverageTypeId);
  }

  get id(): AbstractControl {
    return (this.formArray.controls[0] as FormGroup).controls[
      'id'
    ];
  }

  get enlistmentDateMonth(): AbstractControl {
    return (this.formArray.controls[0] as FormGroup).controls[
      'enlistmentDateMonth'
    ];
  }
  get enlistmentDateYear(): AbstractControl {
    return (this.formArray.controls[0] as FormGroup).controls[
      'enlistmentDateYear'
    ];
  }
  get dodId(): AbstractControl {
    return (this.formArray.controls[0] as FormGroup).controls['dodId'];
  }

  get dodIdVerified(): AbstractControl {
    return (this.formArray.controls[0] as FormGroup).controls['dodIdVerified'];
  }

  get firstName(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['firstName'];
  }
  get lastName(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['lastName'];
  }
  get middleName(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['middleName'];
  }
  get rosterName(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['rosterName'];
  }
  get suffixName(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['suffixName'];
  }
  get nameChangeReason(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['nameChangeReason'];
  }
  get address1(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['address1'];
  }
  get address2(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['address2'];
  }
  get city(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['city'];
  }
  get state(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['state'];
  }
  get zip(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['zip'];
  }
  get primaryPhone(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['primaryPhone'];
  }
  get secondaryPhone(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['secondaryPhone'];
  }
  get email(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['email'];
  }
  get militaryEmail(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['militaryEmail'];
  }
  get ssn(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['ssn'];
  }
  get dob(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['dob'];
  }
  get age(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['age'];
  }

  get volunteerNetworkId(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls[
      'volunteerNetworkId'
    ];
  }
  get rankId(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['rankId'];
  }
  get heightFeet(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['heightFeet'];
  }
  get heightInch(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['heightInch'];
  }
  get weightInLBS(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['weightInLBS'];
  }
  get gender(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['gender'];
  }
  get isMarried(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['isMarried'];
  }
  get dutyStatusAGR(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['dutyStatusAGR'];
  }
  get branchOfService(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls[
      'branchOfService'
    ];
  }
  get nameOfUnit(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls['nameOfUnit'];
  }
  get newApplicationPersonInsBeneficiary(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls[
      'newApplicationPersonInsBeneficiary'
    ];
  }
  get newApplicationPersonInsTotalPercentage(): AbstractControl {
    return (this.formArray.controls[1] as FormGroup).controls[
      'newApplicationPersonInsTotalPercentage'
    ];
  }

  get coverageTypeId(): AbstractControl {
    return (this.formArray.controls[2] as FormGroup).controls['coverageTypeId'];
  }
  get memberCoverageId(): AbstractControl {
    return (this.formArray.controls[2] as FormGroup).controls[
      'memberCoverageId'
    ];
  }
  get premium(): AbstractControl {
    return (this.formArray.controls[2] as FormGroup).controls['premium'];
  }
  get spouseCoverageId(): AbstractControl {
    return (this.formArray.controls[2] as FormGroup).controls[
      'spouseCoverageId'
    ];
  }
  get spousePremium(): AbstractControl {
    return (this.formArray.controls[2] as FormGroup).controls['spousePremium'];
  }
  get dependentCoverageId(): AbstractControl {
    return (this.formArray.controls[2] as FormGroup).controls[
      'dependentCoverageId'
    ];
  }
  get dependentPremium(): AbstractControl {
    return (this.formArray.controls[2] as FormGroup).controls[
      'dependentPremium'
    ];
  }
  get newApplicationSpouseAndDependentCoverage(): AbstractControl {
    return (this.formArray.controls[2] as FormGroup).controls[
      'newApplicationSpouseAndDependentCoverage'
    ];
  }
  get newApplicationDependentCoverage(): AbstractControl {
    return (this.formArray.controls[2] as FormGroup).controls[
      'newApplicationDependentCoverage'
    ];
  }

  //get effectiveDate(): AbstractControl {
  //  return (this.formArray.controls[2] as FormGroup).controls["effectiveDate"];
  //}
  //get nextPmtDue(): AbstractControl {
  //  return (this.formArray.controls[2] as FormGroup).controls["nextPmtDue"];
  //}

  get spouseFirstName(): AbstractControl {
    return (this.formArray.controls[3] as FormGroup).controls[
      'spouseFirstName'
    ];
  }
  get spouseLastName(): AbstractControl {
    return (this.formArray.controls[3] as FormGroup).controls['spouseLastName'];
  }
  get spouseMiddleName(): AbstractControl {
    return (this.formArray.controls[3] as FormGroup).controls[
      'spouseMiddleName'
    ];
  }
  get spouseSSN(): AbstractControl {
    return (this.formArray.controls[3] as FormGroup).controls['spouseSSN'];
  }
  get spouseGender(): AbstractControl {
    return (this.formArray.controls[3] as FormGroup).controls['spouseGender'];
  }
  get spouseHeightFeet(): AbstractControl {
    return (this.formArray.controls[3] as FormGroup).controls[
      'spouseHeightFeet'
    ];
  }
  get spouseHeightInch(): AbstractControl {
    return (this.formArray.controls[3] as FormGroup).controls[
      'spouseHeightInch'
    ];
  }
  get spouseWeightInLBS(): AbstractControl {
    return (this.formArray.controls[3] as FormGroup).controls[
      'spouseWeightInLBS'
    ];
  }
  get spousePhone(): AbstractControl {
    return (this.formArray.controls[3] as FormGroup).controls['spousePhone'];
  }
  get spouseEmail(): AbstractControl {
    return (this.formArray.controls[3] as FormGroup).controls['spouseEmail'];
  }
  get spouseDOB(): AbstractControl {
    return (this.formArray.controls[3] as FormGroup).controls['spouseDOB'];
  }
  get spouseAge(): AbstractControl {
    return (this.formArray.controls[3] as FormGroup).controls['spouseAge'];
  }
  get noOfChildren(): AbstractControl {
    return (this.formArray.controls[3] as FormGroup).controls['noOfChildren'];
  }
  get newApplicationAddChildren(): AbstractControl {
    return (this.formArray.controls[3] as FormGroup).controls[
      'newApplicationAddChildren'
    ];
  }

  get last10YearsDiagnosed(): AbstractControl {
    return (this.formArray.controls[4] as FormGroup).controls[
      'last10YearsDiagnosed'
    ];
  }
  get last5YearAdmitted(): AbstractControl {
    return (this.formArray.controls[4] as FormGroup).controls[
      'last5YearAdmitted'
    ];
  }
  get everDignosedForAids(): AbstractControl {
    return (this.formArray.controls[4] as FormGroup).controls[
      'everDignosedForAids'
    ];
  }
  get spouseLast10YearsDiagnosed(): AbstractControl {
    return (this.formArray.controls[4] as FormGroup).controls[
      'spouseLast10YearsDiagnosed'
    ];
  }
  get spouseLast5YearAdmitted(): AbstractControl {
    return (this.formArray.controls[4] as FormGroup).controls[
      'spouseLast5YearAdmitted'
    ];
  }
  get spouseEverDignosedForAids(): AbstractControl {
    return (this.formArray.controls[4] as FormGroup).controls[
      'spouseEverDignosedForAids'
    ];
  }
  get listPrescribedMedication(): AbstractControl {
    return (this.formArray.controls[4] as FormGroup).controls[
      'listPrescribedMedication'
    ];
  }

  get signCity(): AbstractControl {
    return (this.formArray.controls[5] as FormGroup).controls['signCity'];
  }
  get signState(): AbstractControl {
    return (this.formArray.controls[5] as FormGroup).controls['signState'];
  }

  dummyData(): void {
    this.enlistmentDateMonth.setValue(0);
    this.enlistmentDateMonth.setValue('1');
    this.enlistmentDateYear.setValue(2021);
    this.firstName.setValue('John');
    this.lastName.setValue('Doe');
    this.middleName.setValue('J');
    // this.rosterName.setValue('');
    this.suffixName.setValue('JR');
    this.address1.setValue('123 Some Drive');
    this.address2.setValue('');
    this.city.setValue('Hereville');
    this.state.setValue('NC');
    this.zip.setValue('21212');
    this.primaryPhone.setValue('9195551212');
    this.secondaryPhone.setValue('');
    this.email.setValue('');
    this.militaryEmail.setValue('abc@mil.gov');
    this.ssn.setValue(987654321);
    this.dob.setValue(new Date('1/1/1985'));
    this.age.setValue('');
    this.dodId.setValue('1234567890');
    this.volunteerNetworkId.setValue(1);
    this.rankId.setValue('E1');
    this.heightFeet.setValue('6');
    this.heightInch.setValue('0');
    this.weightInLBS.setValue('225');
    this.gender.setValue('Male');
    this.isMarried.setValue('0');
    this.dutyStatusAGR.setValue('1');
    this.branchOfService.setValue('Army');
    this.nameOfUnit.setValue('UNITXYZ');
    this.newApplicationPersonInsBeneficiary.setValue(
      new NewApplicationPersonInsBeneficiary({
        id: 0,
        personId: 0,
        beneficiary: 'Sue Doe',
        relationship: 'Spouse',
        percentage: 100,
        isNew: true,
      })
    );
    this.newApplicationPersonInsTotalPercentage.setValue('');
    this.coverageTypeId.setValue('');
    this.memberCoverageId.setValue('');
    this.premium.setValue('');
    this.spouseCoverageId.setValue('');
    this.spousePremium.setValue('');
    this.dependentCoverageId.setValue('');
    this.dependentPremium.setValue('');
    this.newApplicationSpouseAndDependentCoverage.setValue('');
    this.newApplicationDependentCoverage.setValue('');
    this.spouseFirstName.setValue('');
    this.spouseLastName.setValue('');
    this.spouseMiddleName.setValue('');
    this.spouseSSN.setValue('');
    this.spouseGender.setValue('');
    this.spouseHeightFeet.setValue('');
    this.spouseHeightInch.setValue('');
    this.spouseWeightInLBS.setValue('');
    this.spousePhone.setValue('');
    this.spouseEmail.setValue('');
    this.email.setValue('a@a.com');
    this.spouseDOB.setValue('');
    this.spouseAge.setValue('');
    this.noOfChildren.setValue('');
    this.newApplicationAddChildren.setValue('');
    this.signCity.setValue('Raleigh');
    this.signState.setValue('NC');

    this.checkEnlistmentDate();
    this.last10YearsDiagnosed.setValue('0');
    this.last5YearAdmitted.setValue('1');

    this.updateSpouseValidations();
  }
}
