<form *ngIf="model" [formGroup]="theForm" (ngSubmit)="onSubmit()" class="detail-component" novalidate>
  <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="60">
    <div fxFlex>
    </div>
    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Coverage Type Name</mat-label>
      <input matInput formControlName="coverageTypeName" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="coverageTypeName.touched && coverageTypeName.hasError('required')">
        Coverage Type Name is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('coverageTypeName')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Coverage Type Unit Code</mat-label>
      <input matInput formControlName="coverageTypeUnitCode" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="coverageTypeUnitCode.touched && coverageTypeUnitCode.hasError('required')">
        Coverage Type Unit Code is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('coverageTypeUnitCode')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Comments</mat-label>
      <input matInput formControlName="comments" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="comments.touched && comments.hasError('required')">
        Comments is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('comments')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>


    <mat-form-field appearance="fill" fxFlex="25">
      <mat-label>Seq Nbr</mat-label>
      <input matInput formControlName="seqNbr" type="number" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="seqNbr.touched && seqNbr.hasError('required')">
        Seq Nbr is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('seqNbr')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>


    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <section class="footer">
      <button mat-raised-button color="primary" type="submit" [disabled]="theForm.pristine || (loading)">
        Save
      </button>
      <button mat-button type="button" (click)="undo()" [disabled]="theForm.pristine">
        Undo
      </button>
      <button mat-button type="button" (click)="cancel()">
        Cancel
      </button>
    </section>
  </div>
</form>
