<form *ngIf="model" [formGroup]="theForm" (ngSubmit)="onSubmit()" class="detail-component" novalidate>
  <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="60">
    <div fxFlex>
    </div>



    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>UIC</mat-label>
      <input matInput formControlName="uic" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="uic.touched && uic.hasError('required')">
        UIC is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('uic')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <div fxFlex>
      <label class="k-form-field">
        <input type="checkbox" id="armyGuard" class="k-checkboxX" formControlName="armyGuard">
        <label class="k-checkbox-label" for="armyGuard">Army Guard</label>
      </label>
    </div>

    <mat-form-field appearance="fill" fxFlex="25">
      <mat-label>Battalion</mat-label>
      <!-- <input matInput formControlName="battalionId" type="number" /> -->
      <mat-select formControlName="battalionId">
        <mat-option *ngFor="let item of this.battalionIdLookups$ | async" [value]="item.key">
          {{item.value}}
        </mat-option>
      </mat-select>
      <mat-hint></mat-hint>
      <mat-error *ngIf="battalionId.touched && battalionId.hasError('required')">
        Battalion is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('battalionId')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Unit Name</mat-label>
      <input matInput formControlName="unitName" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="unitName.touched && unitName.hasError('required')">
        Unit Name is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('unitName')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Unit Code</mat-label>
      <input matInput formControlName="unitCode" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="unitCode.touched && unitCode.hasError('required')">
        Unit Code is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('unitCode')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>





    <!-- 

    <mat-form-field appearance="fill" fxFlex="25">
      <mat-label>Nbr Members</mat-label>
      <input matInput formControlName="nbrMembers" type="number" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="nbrMembers.touched && nbrMembers.hasError('required')">
        Nbr Members is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('nbrMembers')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>


    <mat-form-field appearance="fill" fxFlex="25">
      <mat-label>Nbr Members</mat-label>
      <input matInput formControlName="nbrMembers" type="number" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="nbrMembers.touched && nbrMembers.hasError('required')">
        Nbr Members is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('nbrMembers')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="25">
      <mat-label>Unit Strength</mat-label>
      <input matInput formControlName="unitStrength" type="number" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="unitStrength.touched && unitStrength.hasError('required')">
        Unit Strength is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('unitStrength')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Battalion Code</mat-label>
      <input matInput formControlName="battalionCode" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="battalionCode.touched && battalionCode.hasError('required')">
        Battalion Code is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('battalionCode')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>


    <mat-form-field appearance="fill" fxFlex="25">
      <mat-label>Nbr Insured</mat-label>
      <input matInput formControlName="nbrInsured" type="number" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="nbrInsured.touched && nbrInsured.hasError('required')">
        Nbr Insured is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('nbrInsured')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

   -->

    
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <section class="footer">
      <button mat-raised-button color="primary" type="submit" [disabled]="theForm.pristine || (loading)">
        Save
      </button>
      <button mat-button type="button" (click)="undo()" [disabled]="theForm.pristine">
        Undo
      </button>
      <button mat-button type="button" (click)="cancel()">
        Cancel
      </button>
    </section>
  </div>
</form>
