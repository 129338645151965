export class ReportBankDraftDiscrepencyPremiumsNotEqual   {

  constructor();
  constructor(jsonData?: ReportBankDraftDiscrepencyPremiumsNotEqual) {
    Object.assign(this, jsonData);
  }

  public id: number | undefined;
  public person: string | undefined;
  public ssn: string | undefined;
  public abaNumber: string | undefined;
  public bankAccNbr: string | undefined;
  public premium: number | undefined;

  public qPersonId: number | undefined;
  public qPerson: string | undefined;
  public qSsn: string | undefined;
  public qAbaNumber: string | undefined;
  public qBankAccNbr: string | undefined;
  public qPremium: number | undefined;

}
