  <kendo-grid #grid fxFlex="100" [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [filterable]="false" [sort]="sort"
            [groupable]="false" [pageSize]="25" [pageable]="(list && (list.length > 25) ? true : false)" [rowClass]="rowCallback"
            class="condensed" [resizable]="true"
            (dataStateChange)="onDataStateChange($event)"
            (edit)="editPersonMembershipPaymentHandler($event)"
            (cancel)="cancelPersonMembershipPaymentHandler($event)"
            (save)="savePersonMembershipPaymentHandler($event)"
            (add)="addPersonMembershipPaymentHandler($event)">
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand>Add new</button>
  </ng-template>
  <!--<kendo-grid-column field="personId" title="Person Id" [filterable]="true" filter="numeric"></kendo-grid-column>-->
  <kendo-grid-column field="paymentDate" title="Payment Date" editor="date" [filterable]="true" filter="date" format="{0:d}"></kendo-grid-column>
  <kendo-grid-column field="groupId" title="Group" [filterable]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{groups(dataItem.groupId)?.value}}
    </ng-template>
    <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">
      <kendo-combobox [data]="groupIdLookups$ | async" textField="value" valueField="key"
                      [valuePrimitive]="true"
                      [suggest]="true" [popupSettings]="{width: 360,height: 150}"
                      [formControl]="formGroup.get(column.field)">
      </kendo-combobox>
    </ng-template>

  </kendo-grid-column>
  <kendo-grid-column field="paymentTypeId" title="Payment Type" [filterable]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{paymentTypes(dataItem.paymentTypeId)?.value}}
    </ng-template>
    <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">
      <kendo-combobox [data]="paymentTypeIdLookups$ | async" textField="value" valueField="key"
                      [valuePrimitive]="true"
                      [suggest]="true" [popupSettings]="{width: 360,height: 150}"
                      [formControl]="formGroup.get(column.field)">
      </kendo-combobox>
    </ng-template>

  </kendo-grid-column>

  <kendo-grid-column field="membershipNumber" title="Membership Number" [filterable]="true"></kendo-grid-column>
  <kendo-grid-column field="annualExpirationDate" title="Annual Expiration Date" editor="date" [filterable]="true" filter="date" format="{0:d}"></kendo-grid-column>
  <kendo-grid-command-column title="-">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
      <button kendoGridEditCommand [primary]="true">Edit</button>
      <button type="button" kendoButton  (click)="savePersonMembershipPaymentHandler({ sender: 'expire', rowIndex: -9999, formGroup: dataItem, isNew: false })" *ngIf="!dataItem.isNew && dataItem.isActive && !dataItem.isToBeExpire">Expire</button>
      <button kendoGridSaveCommand [disabled]="formGroup?.invalid">
        {{ isNew ? "Add" : "Update" }}
      </button>
      <button kendoGridCancelCommand>
        {{ isNew ? "Discard changes" : "Cancel" }}
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
