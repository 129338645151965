import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';
import { PersonReadOnly } from '../person-readonly';
import { Filter } from '../../../_bases/filter';
import { FileRestrictions, SuccessEvent, UploadEvent } from '@progress/kendo-angular-upload';
import { environment } from '../../../../environments/environment';
import { FormControl, FormGroup } from '@angular/forms';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { PersonService } from '../person.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ApplicantUpdatesApproveOrRejectModalComponent } from '../applicant-updates-approve-or-reject-modal.component';

@Component({
  selector: 'app-people-list',
  templateUrl: './person-list.component.html',
  animations: [
  ]
})
export class PersonListComponent implements OnInit {

  @ViewChild(GridComponent) public grid!: GridComponent;
  @ViewChild('aTest') aTest: ElementRef | any;

  list: PersonReadOnly[] = [];
  filters: Filter[] = [];
  uploadSaveUrl: string =
    environment.serviceUrl + "people/uploadWetInk";
  fileRestrictions: FileRestrictions = {
    allowedExtensions: [".pdf"],
  };
  isWetInkApplication: boolean = false;
  isEditApplication: boolean = false;

  uploadWetInk = new FormGroup({
    upload: new FormControl(),
  });

  loading: boolean = true;

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: PersonService,
    private ngZone: NgZone,
    private router: Router,
    public authService: AuthenticationService,
    private dialog: MatDialog,
  ) {
    this.allData = this.allData.bind(this);
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.list, {}).data,
    };

    return result;
  }

  ngOnInit(): void {

    if (this.router.url.endsWith("wetink")) {
      this.isWetInkApplication = true;
      this.filters.push(
        new Filter("isWetInkApplication", "1")
      );
    }
    else if (this.router.url.endsWith("applicantUpdates")) {
      this.isEditApplication = true;
      this.filters.push(
        new Filter("isEditApplication", "1")
      );
    }
    else {
      this.filters.push(
        new Filter("isWetInkApplication", "0")
      );
    }

    this.loadList();
  }

  uploadSuccessEventHandler(e: SuccessEvent) {
    console.log("The " + e.operation + " was successful!");
    this.authService.eventNewApplicationCountChangeRaised();
    this.loadList();
  }

  //uploadEventHandler(e: UploadEvent) {
  //  e.data = {
  //    tagRecords: this.importAirAgr.get('tagRecords')?.value,
  //  };
  //}

  public onUploadButtonClick(upload: any) {
    upload.uploadFiles();
  }

  loadList() {

    this.service
      .getByCustomAction$<PersonReadOnly>(this.isEditApplication == true ? "getApplicantUpdatesList" : "getNewApplications", PersonReadOnly, this.filters)
      .pipe(
        catchError((err, caught) => {
          this.loading = false;
          return of(null as any);
        })
      ).subscribe((data: PersonReadOnly[]) => {
        console.log(data);
        this.loading = false;
        this.list = data;
      });
  }

  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }

  private fitColumns(): void {
    this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
      this.grid.autoFitColumns();
    });
  }

  public rowCallback(context: RowClassArgs) {
    return {
    };
  }

  downloadAttachment(id: string, fileName: string): string {
    this.service.http.get<Blob>(environment.serviceUrl + `personattachments/downloadAttachment?id=${id.toString()}&fileName=${fileName}`, { responseType: 'blob' as 'json' })
      .subscribe(res => {
        var link = this.aTest.nativeElement;

        link.href = window.URL.createObjectURL(res);
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(link.href);

        //return res;
      });
    return "";
  }

  approveOrReject(data: PersonReadOnly) {

    let dialogRef = this.dialog.open(ApplicantUpdatesApproveOrRejectModalComponent, {
      data: data,
      width: '620px',
    });

    dialogRef.afterClosed().subscribe((reloadGrid: boolean) => {

      if (reloadGrid == true) {
        this.loadList();
      }
    });
  }

}
