
export class PersonPaymentInfoReadOnly { 

	constructor() 
	constructor(jsonData: PersonPaymentInfoReadOnly)
	constructor(jsonData?: PersonPaymentInfoReadOnly) {
		if(jsonData) {
			Object.assign(this, jsonData);
      this.date = (jsonData.date && jsonData.date.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.date) : undefined;
		}
	}

  id: number | undefined;
  personId: number | undefined;
  date: Date | undefined;
  premiumAmount: number | undefined;
  amountPaid: number | undefined;
  overUnder: number | undefined;
  monthsCollected: number | undefined;
  monthsInsufficient: number | undefined;
  comment: string | undefined;
  paymentTypeId: number | undefined;
  sepCode: string | undefined;
  //personDepositId: number | undefined;
  //rptCategory: string | undefined;
  //acct: string | undefined;
  //starts: string | undefined;
  isNew: boolean | undefined;
}


