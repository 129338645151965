import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';
import { ReportService } from '../report.service';
import { ReportDFASDeposit } from './report-dfas-deposit';
import { MediaObserver } from "@angular/flex-layout";
import { Filter } from '../../_bases/filter';
import { AuthenticationService } from '../../auth/auth.service';
import { DatePipe } from '@angular/common';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';

@Component({
  selector: 'app-dfas-deposit',
  templateUrl: './report-dfas-deposit.component.html',
  animations: [
  ]
})
export class ReportDFASDepositComponent implements OnInit {

  @ViewChild(GridComponent)
  public grid!: GridComponent;

  list: ReportDFASDeposit[] = [];
  filters: Filter[] = [];
  public currentDate: Date = new Date();
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: ReportService,
    public media: MediaObserver,
    public authService: AuthenticationService,
    private ngZone: NgZone,
    public datePipe: DatePipe,
  ) {
    this.allData = this.allData.bind(this);
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.list, {}).data,
    };

    return result;
  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {

    this.filters = [];

    this.service
      .getByCustomAction$<ReportDFASDeposit>("getDFASDepositReport", ReportDFASDeposit, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {
        console.log(data);
        this.list = data;
      });
  }

  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }


  private fitColumns(): void {
    this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
      this.grid.autoFitColumns();
    });
  }

  public rowCallback(context: RowClassArgs) {
    return {
    };
  }
}
