
<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      Brigade
      <span class="k-icon k-i-loading" *ngIf="loading"></span>
      <button mat-button color="primary" routerLink="/brigade/0">
        <mat-icon color="primary">add_circle_outline</mat-icon> Add New Brigade
      </button>
    </h1>
  </div>

  <kendo-grid #grid fxFlex="100" [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [filterable]="false"
              [groupable]="false" [pageSize]="100" [pageable]="(list && (list.length > 100) ? true : false)" [rowClass]="rowCallback"
              class="condensed mt-5" [resizable]="true" (dataStateChange)="onDataStateChange($event)">

    <kendo-grid-column>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <button mat-button color="primary" routerLink="/brigade/{{ dataItem.id }}">
          <mat-icon>edit</mat-icon>
        </button>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="brigadeCode" title="Brigade Code" [filterable]="true"></kendo-grid-column>
    <kendo-grid-column field="brigadeName" title="Brigade Name" [filterable]="true"></kendo-grid-column>
    <kendo-grid-column field="brigadeSeqNbr" title="Brigade Seq Nbr" [filterable]="true"></kendo-grid-column>
    <!--<kendo-grid-column field="xG_MEM" title="x G MEM" [filterable]="true"></kendo-grid-column>
    <kendo-grid-column field="xG_ASSOC" title="x G ASSOC" [filterable]="true"></kendo-grid-column>-->
  </kendo-grid>
</div>
