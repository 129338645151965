export class ReportDFASDeposit {

  constructor();
  constructor(jsonData?: ReportDFASDeposit) {
    Object.assign(this, jsonData);
  }

  public dFASCategory: string | undefined;
  public amount: number | undefined;
  public month: number | undefined;
  public year: number | undefined;
}
