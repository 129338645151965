export class ReportAmericanEquityDownloadFile {

  constructor();
  constructor(jsonData?: ReportAmericanEquityDownloadFile) {

    if (jsonData) {
      Object.assign(this, jsonData);
      this.memberDOB = (jsonData.memberDOB && jsonData.memberDOB.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.memberDOB) : null;
      this.effectiveDate = (jsonData.effectiveDate && jsonData.effectiveDate.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.effectiveDate) : null;
      this.spouseDOB = (jsonData.spouseDOB && jsonData.spouseDOB.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.spouseDOB) : null;
      this.nextPmtDue = (jsonData.nextPmtDue && jsonData.nextPmtDue.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.nextPmtDue) : null;
    }
  }

  public ssn: string | undefined;
  public lastName: string | undefined;
  public firstName: string | undefined;
  public middleName: string | undefined;
  public memberDOB?: Date | null;
  public effectiveDate?: Date | null;
  public amEqUnitCode: string | undefined;
  public memberCoverageCode: number | undefined;
  public memberCoverage: number | undefined;
  public memberPremium: number | undefined;

  public spouseCoverageCode: number | undefined;
  public spouseCoverage: number | undefined;
  public spousePremium: number | undefined;
  public spouseDOB?: Date | null;

  public dependentCoverageCode: number | undefined;
  public dependentCoverage: number | undefined;
  public dependentPremium: number | undefined;

  public nextPmtDue?: Date | null;

  public unitCode: string | undefined;
  public pmtType: string | undefined;
  
  public premiumDue: number | undefined;
}
