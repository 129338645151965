<a #aTest style="display:none;">&nbsp;</a>
<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      <span>Missing Jumps </span>
      <span class="k-icon k-i-loading" *ngIf="loading"></span>
    </h1>
  </div>

  <kendo-grid #grid fxFlex="100" [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'"
              [filterable]="false" [groupable]="false" [pageSize]="100" [pageable]="(list && (list.length > 100) ? true : false)"
              [rowClass]="rowCallback" class="condensed" [resizable]="true" (dataStateChange)="onDataStateChange($event)">
    <kendo-grid-column field="ssn" title="SSN" [filterable]="true">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{dataItem.ssn | mask: '000-00-0000'}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="personName" title="Person Name" [filterable]="true"></kendo-grid-column>
    <kendo-grid-column field="premiumAmount" title="Premium Amount" [filterable]="true" format="{0:c}"></kendo-grid-column>
    <kendo-grid-column field="amountPaid" title="Amount Paid" [filterable]="true" format="{0:c}"></kendo-grid-column>
    <kendo-grid-column field="monthsCollected" title="Months Collected" [filterable]="true"></kendo-grid-column>
    <kendo-grid-column field="monthsInsufficient" title="Months Insufficient" [filterable]="true"></kendo-grid-column>
    <kendo-grid-column field="sepCode" title="Sep Code" [filterable]="true"></kendo-grid-column>
    <kendo-grid-column field="starts" title="starts" [filterable]="true"></kendo-grid-column>
    <kendo-grid-column field="paymentTypeName" title="Payment Type" [filterable]="true"></kendo-grid-column>
    <kendo-grid-column field="id" title=" " [filterable]="false" [width]="90">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <button type="button" mat-button (click)="applyJumps(dataItem.id)">
          <mat-icon>done</mat-icon> Apply
        </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
