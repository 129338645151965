import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { AuthenticationService } from "./auth.service";

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(private authService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedRole = route.data.roles;
    //if (!this.authService.isInRole(expectedRole)) {

    if (this.authService.userRoles.filter(value => expectedRole.includes(value)).length <= 0) {
      //window.alert('You do not have access as expected role is missing. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.');
      return false;
    }

    return true;

  }
}
