<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      Membership
    </h1>
  </div>
    <div fxFlex="100">
      <h3 class="mr-auto primary">
        {{ currentDate | date:'EEEE, MMMM dd, yyyy' }}
      </h3>
    </div>
    <div fxLayout="row" fxLayoutGap="8px" class="m-2">
      <kendo-grid [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [columnMenu]="{ filter: true }"
                  [groupable]="false" [pageSize]="100"
                  [pageable]="(list && (list.length > 100) ? true : false)" [rowClass]="rowCallback" class="condensed" #grid fxFlex
                  id="grdMembership">
        <ng-template kendoGridToolbarTemplate>
          <button type="button" kendoGridPDFCommand icon="file-pdf">Export to PDF</button>
          <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
        </ng-template>
        <kendo-grid-excel fileName="Membership.xlsx" [fetchData]="this.allData"></kendo-grid-excel>
        <kendo-grid-pdf fileName="Membership.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true" [landscape]="true">
          <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
          <!--<ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
       <div class="page-template">
        <div class="header">
           <div style="float: right">
             Page {{ pageNum }} of {{ totalPages }}
           </div>
         </div>
          <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
      </div>
    </ng-template>-->
        </kendo-grid-pdf>
        <kendo-grid-column field="unitName" title="Unit Name" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="battalionName" title="Battalion Name" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="brigadeName" title="Brigade Name" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="unitStrength" title="Unit Strength" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="insuredMembers" title="Insured Members" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="free1000Coverage" title="Free 1000 Coverage" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="insuredPercentage" title="Insured Percentage" [filterable]="true" format="{0:p}"></kendo-grid-column>
      </kendo-grid>
    </div>

  </div>
