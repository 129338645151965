
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, Validators } from "@angular/forms"
import { ActivatedRoute, Router, ParamMap } from "@angular/router";

import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { PopupSettings } from '@progress/kendo-angular-dropdowns';

import { Battalion } from "../battalion";
import { BattalionService } from "../battalion.service";
import { BaseItemComponent } from '../../../_bases/base-item-component';
import { Filter } from '../../../_bases/filter';
import { FormValidation } from '../../../shared/forms/form-validation';


@Component({
  selector: 'app-battalion-detail',
  templateUrl: './battalion-detail.component.html',
})
export class BattalionComponent extends BaseItemComponent implements OnInit, OnDestroy {

  model: Battalion | any;
  public loading: boolean = false;
  brigadeId: number = 0;

  constructor(
    private fb: FormBuilder,
    private service: BattalionService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router) {
    super();
  }

  ngOnInit() {


    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.brigadeId = +params.get('brigadeId')!;
          return this.service.getSingleByCustomAction$<Battalion>("getSingle", Battalion, [new Filter("id", params.get("id"), "eq")]);
        })
      )
      .subscribe((model: Battalion | any) => {

        this.createForm(model);
      });
  }

  ngOnChanges() {
    if (this.model) {
      this.createForm(this.model);
    }
  }

  createForm(model?: Battalion): void {
    if (this.theForm) {
      this.model = model;
      this.theForm.reset(this.bindForm());
    } else {
      this.theForm = this.fb.group({
        id: [model?.id, Validators.required],
        //brigadeId: [model?.brigadeId, Validators.required],
        brigadeCode: model?.brigadeCode,
        battalionSeqNbr: model?.battalionSeqNbr,
        battalionName: model?.battalionName,
        //xSeqNbr: model?.xSeqNbr,
        //xT_ID: model?.xT_ID,
      });

      this.model = model;
    }
  }

  bindForm(): any {
    return {
      id: this.model.id,
      //brigadeId: this.model.brigadeId,
      brigadeCode: this.model.brigadeCode,
      battalionSeqNbr: this.model.battalionSeqNbr,
      battalionName: this.model.battalionName,
      //xSeqNbr: this.model.xSeqNbr,
      //xT_ID: this.model.xT_ID,
    };
  }

  onSubmit() {
    if (this.theForm?.valid) {

      this.loading = true;
      this.service.postModelByCustomAction$<Battalion>("upsert", this.prepareSave(), Battalion)
        .pipe(
          catchError((err, caught) => {
            this.loading = false;
            FormValidation.parseSubmitErrors(err, this.theForm);
            return of(null as Battalion | any);
          })
        )
        .subscribe((savedData: any) => {
          this.loading = false;
          if (savedData) {
            this.goBack();
          }
        })
    } else {
      FormValidation.validateAllFormFields(this.theForm);
    }
  }

  prepareSave(): Battalion {
    const formModel = this.theForm.value;

    let saveModel: Battalion = new Battalion({
      id: formModel.id,
      brigadeId: this.brigadeId,
      brigadeCode: formModel.brigadeCode,
      battalionSeqNbr: formModel.battalionSeqNbr,
      battalionName: formModel.battalionName,
      //xSeqNbr: formModel.xSeqNbr,
      //xT_ID: formModel.xT_ID,
    });
    return saveModel;
  }

  cancel(): void {
    this.goBack();
  }

  goBack() {
    this.location.back();
  }

  undo(): void {
    this.theForm.reset(this.bindForm());
  }

  // Form Control Helpers
  get id(): AbstractControl {
    return this.theForm.get('id');
  }
  //get brigadeId(): AbstractControl {
  //  return this.theForm.get('brigadeId');
  //}
  get brigadeCode(): AbstractControl {
    return this.theForm.get('brigadeCode');
  }
  get battalionSeqNbr(): AbstractControl {
    return this.theForm.get('battalionSeqNbr');
  }
  get battalionName(): AbstractControl {
    return this.theForm.get('battalionName');
  }
  //get xSeqNbr(): AbstractControl {
  //  return this.theForm.get('xSeqNbr');
  //}
  //get xT_ID(): AbstractControl {
  //  return this.theForm.get('xT_ID');
  //}

}
