
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Lookup } from 'src/app/lookups/lookup';
import { LookupService } from 'src/app/lookups/lookup.service';
import { FormValidation } from '../../../shared/forms/form-validation';
import { BaseItemComponent } from '../../../_bases/base-item-component';
import { Filter } from '../../../_bases/filter';
import { Unit } from "../unit";
import { UnitService } from "../unit.service";





@Component({
  selector: 'app-unit-detail',
  templateUrl: './unit-detail.component.html',
})
export class UnitComponent extends BaseItemComponent implements OnInit, OnDestroy {

  model: Unit | any;
  public loading: boolean = false;
  battalionIdLookups$: Observable<Lookup[]> | any;
  unitRepIdLookups$: Observable<Lookup[]> | any;
  
  constructor(
    private fb: FormBuilder,
    private service: UnitService,
    private route: ActivatedRoute,
    private location: Location,
    private lookupService: LookupService,
    private router: Router) {
    super();
    this.battalionIdLookups$ = this.lookupService.getList("battalions", []);
    this.unitRepIdLookups$ = this.lookupService.getList("people", []);
  }

  ngOnInit() {

    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => this.service.getSingleByCustomAction$<Unit>("getSingle", Unit, [new Filter("id", params.get("id"), "eq")]))
      )
      .subscribe((model: Unit | any) => {

        this.createForm(model);
      });
  }

  ngOnChanges() {
    if (this.model) {
      this.createForm(this.model);
    }
  }

  createForm(model?: Unit): void {
    if (this.theForm) {
      this.model = model;
      this.theForm.reset(this.bindForm());
    } else {
      this.theForm = this.fb.group({
        id: [model?.id, Validators.required],
        unitCode: model?.unitCode,
        uic: model?.uic,
        unitName: model?.unitName,
        //nbrMembers: model?.nbrMembers,
        //unitStrength: model?.unitStrength,
        //battalionCode: model?.battalionCode,
        battalionId: model?.battalionId,
        //nbrInsured: model?.nbrInsured,
        armyGuard: [model?.armyGuard, Validators.required],
      });

      this.model = model;
    }
  }

  bindForm(): any {
    return {
      id: this.model.id,
      unitCode: this.model.unitCode,
      uic: this.model.uic,
      unitName: this.model.unitName,
      nbrMembers: this.model.nbrMembers,
      unitStrength: this.model.unitStrength,
      battalionCode: this.model.battalionCode,
      battalionId: this.model.battalionId,
      nbrInsured: this.model.nbrInsured,
      armyGuard: this.model.armyGuard,
    };
  }

  onSubmit() {
    if (this.theForm?.valid) {

      this.loading = true;
      this.service.postModelByCustomAction$<Unit>("upsert", this.prepareSave(), Unit)
        .pipe(
          catchError((err, caught) => {
            this.loading = false;
            FormValidation.parseSubmitErrors(err, this.theForm);
            return of(null as Unit | any);
          })
        )
        .subscribe((savedData: any) => {
          this.loading = false;
          if (savedData) {
            this.goBack();
          }
        })
    } else {
      FormValidation.validateAllFormFields(this.theForm);
    }
  }

  prepareSave(): Unit {
    const formModel = this.theForm.value;

    let saveModel: Unit = new Unit({
      id: formModel.id,
      unitCode: formModel.unitCode,
      uic: formModel.uic,
      unitName: formModel.unitName,
      //nbrMembers: formModel.nbrMembers,
      //unitStrength: formModel.unitStrength,
      //battalionCode: formModel.battalionCode,
      battalionId: formModel.battalionId,
      //nbrInsured: formModel.nbrInsured,
      armyGuard: formModel.armyGuard,
    });
    return saveModel;
  }

  cancel(): void {
    this.goBack();
  }

  goBack() {
    this.location.back();
  }

  undo(): void {
    this.theForm.reset(this.bindForm());
  }

  // Form Control Helpers
  get id(): AbstractControl {
    return this.theForm.get('id');
  }
  get unitCode(): AbstractControl {
    return this.theForm.get('unitCode');
  }
  get uic(): AbstractControl {
    return this.theForm.get('uic');
  }
  get unitName(): AbstractControl {
    return this.theForm.get('unitName');
  }
  // get nbrMembers(): AbstractControl {
  //   return this.theForm.get('nbrMembers');
  // }
  // get unitStrength(): AbstractControl {
  //   return this.theForm.get('unitStrength');
  // }
  // get battalionCode(): AbstractControl {
  //   return this.theForm.get('battalionCode');
  // }
  get battalionId(): AbstractControl {
    return this.theForm.get('battalionId');
  }
   // get nbrInsured(): AbstractControl {
  //   return this.theForm.get('nbrInsured');
  // }
  get armyGuard(): AbstractControl {
    return this.theForm.get('armyGuard');
  }

}
