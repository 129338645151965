<a #aTest style="display:none;">&nbsp;</a>
<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      <span *ngIf="isWetInkApplication == true && isEditApplication == false">Wet Ink Applications</span>
      <span *ngIf="!isWetInkApplication && isEditApplication == false">Digital Ink Applications</span>
      <span *ngIf="isEditApplication == true">Applicant Updates</span>
      <span class="k-icon k-i-loading" *ngIf="loading"></span>
    </h1>
  </div>

  <div fxFlex="100" *ngIf="isWetInkApplication == true && isEditApplication == false">
    <div fxLayout="row wrap" fxLayoutAlign="center stretch" fxLayoutGap="10px" [formGroup]="uploadWetInk">
      <div fxFlex="25%">
        <kendo-upload formControlName="upload" #upload [restrictions]="fileRestrictions" [autoUpload]="false"
          [batch]="false" [multiple]="false" [saveUrl]="uploadSaveUrl" (success)="uploadSuccessEventHandler($event)">
        </kendo-upload>
      </div>
    </div>
  </div>

  <kendo-grid #grid fxFlex="100" [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'"
              [filterable]="false" [groupable]="false" [pageSize]="100" [pageable]="(list && (list.length > 100) ? true : false)"
              [rowClass]="rowCallback" class="condensed" [resizable]="true" (dataStateChange)="onDataStateChange($event)">
    <kendo-grid-column field="id" title=" " [filterable]="false" [width]="180">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <button mat-button color="primary" routerLink="/person/{{ dataItem.id }}" *ngIf="this.authService.isAdmin">
          <mat-icon>edit</mat-icon> Edit
        </button>
        <button type="button" mat-button
                (click)="downloadAttachment(dataItem.wetInkAttachmentId, dataItem.wetInkFileName)"
                *ngIf="isWetInkApplication == true && isEditApplication == false">
          <mat-icon>cloud_download</mat-icon> {{ dataItem.wetInkFileName }}
        </button>
        <button type="button" mat-button (click)="approveOrReject(dataItem)" *ngIf="isEditApplication == true">
          <mat-icon>assignment_ind</mat-icon> Approve/Reject
        </button>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="firstName" title="First Name" [filterable]="true" [hidden]="isWetInkApplication == true  && isEditApplication == false">
    </kendo-grid-column>
    <kendo-grid-column field="middleName" title="Middle Name" [filterable]="true"
                       [hidden]="isWetInkApplication == true && isEditApplication == false"></kendo-grid-column>
    <kendo-grid-column field="lastName" title="Last Name" [filterable]="true" [hidden]="isWetInkApplication == true && isEditApplication == false">
    </kendo-grid-column>
    <!--<kendo-grid-column field="ssn" title="SSN" [filterable]="true" [hidden]="isWetInkApplication == true && isEditApplication == false">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      {{dataItem.ssn | mask: '000-00-0000'}}
    </ng-template>
  </kendo-grid-column>-->
    <kendo-grid-column field="uic" title="UIC" [filterable]="true" [hidden]="isWetInkApplication == true && isEditApplication == false">
    </kendo-grid-column>
    <kendo-grid-column field="premium" title="Premium" [filterable]="true" format="{0:c}" [hidden]="isWetInkApplication == true && isEditApplication == false">
    </kendo-grid-column>
    <kendo-grid-column field="pmtType" title="Payment Type" [filterable]="true" [hidden]="isWetInkApplication == true && isEditApplication == false">
    </kendo-grid-column>
    <kendo-grid-column field="rosterName" title="Roster" [filterable]="true" [hidden]="isWetInkApplication == true && isEditApplication == false">
    </kendo-grid-column>
    <kendo-grid-column field="dateChanged" title="Date Changed" [filterable]="true" [hidden]="isEditApplication != true">
    </kendo-grid-column>
  </kendo-grid>
</div>
