<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      Import JUMPS
    </h1>
  </div>
  <div fxFlex="100">
    <form [formGroup]="importAll" (ngSubmit)="onImportButtonClick(uploadAirAgr, uploadArmyAgr, uploadAirJumps, uploadArmyJumps, uploadRetiredPay)" fxFlex="100">
      <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px">
        <div fxFlex="100%">
          <mat-form-field appearance="fill">
            <mat-label>Date</mat-label>
            <input matInput formControlName="date" [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

            <mat-error *ngIf="date?.hasError('required')">
              Please enter a <strong>date</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="100%">
          Air AGR:<br />
          <kendo-upload #uploadAirAgr
                        [restrictions]="fileRestrictions"
                        [autoUpload]="false"
                        [batch]="false"
                        [multiple]="false"
                        [saveUrl]="uploadAirAGRSaveUrl"
                        (success)="uploadSuccessEventHandler($event)"
                        (upload)="uploadEventHandler($event)"
                        (select)="uploadAirAGRFileSelect($event)">
          </kendo-upload>
          <input type="hidden" formControlName="uploadAirAgrFile" />
          <mat-error *ngIf="uploadAirAgrFile?.hasError('required')">
            Please select a <strong>.txt</strong> file
          </mat-error>
        </div>

        <div fxFlex="100%">
          Army AGR:<br />
          <kendo-upload #uploadArmyAgr
                        [restrictions]="fileRestrictions"
                        [autoUpload]="false"
                        [batch]="false"
                        [multiple]="false"
                        [saveUrl]="uploadArmyAGRSaveUrl"
                        (success)="uploadSuccessEventHandler($event)"
                        (upload)="uploadEventHandler($event)"
                        (select)="uploadArmyAGRFileSelect($event)">
          </kendo-upload>
          <input type="hidden" formControlName="uploadArmyAgrFile" />
          <mat-error *ngIf="uploadArmyAgrFile?.hasError('required')">
            Please select a <strong>.txt</strong> file
          </mat-error>
        </div>

        <div fxFlex="100%">
          Air Jumps:<br />
          <kendo-upload #uploadAirJumps
                        [restrictions]="fileRestrictions"
                        [autoUpload]="false"
                        [batch]="false"
                        [multiple]="false"
                        [saveUrl]="uploadAirJumpsSaveUrl"
                        (success)="uploadSuccessEventHandler($event)"
                        (upload)="uploadEventHandler($event)"
                        (select)="uploadAirJumpsFileSelect($event)">
          </kendo-upload>
          <input type="hidden" formControlName="uploadAirJumpsFile" />
          <mat-error *ngIf="uploadAirJumpsFile?.hasError('required')">
            Please select a <strong>.txt</strong> file
          </mat-error>
        </div>

        <div fxFlex="100%">
          Army Jumps:<br />
          <kendo-upload #uploadArmyJumps
                        [restrictions]="fileRestrictions"
                        [autoUpload]="false"
                        [batch]="false"
                        [multiple]="false"
                        [saveUrl]="uploadArmyJumpsSaveUrl"
                        (success)="uploadSuccessEventHandler($event)"
                        (upload)="uploadEventHandler($event)"
                        (select)="uploadArmyJumpsFileSelect($event)">
          </kendo-upload>
          <input type="hidden" formControlName="uploadArmyJumpsFile" />
          <mat-error *ngIf="uploadArmyJumpsFile?.hasError('required')">
            Please select a <strong>.txt</strong> file
          </mat-error>
        </div>

        <div fxFlex="100%">
          Retired Pay File:<br />
          <kendo-upload #uploadRetiredPay
                        [restrictions]="fileRestrictions"
                        [autoUpload]="false"
                        [batch]="false"
                        [multiple]="false"
                        [saveUrl]="uploadRetiredPaySaveUrl"
                        (success)="uploadSuccessEventHandler($event)"
                        (upload)="uploadEventHandler($event)"
                        (select)="uploadRetiredPayFileSelect($event)">
          </kendo-upload>
          <input type="hidden" formControlName="uploadRetiredPayFile" />
          <mat-error *ngIf="uploadRetiredPayFile?.hasError('required')">
            Please select a <strong>.txt</strong> file
          </mat-error>
        </div>

        <div fxFlex="100%">
          <label class="k-form-field">
            <input type="checkbox" id="tagRecords" class="Xk-checkboxX" formControlName="tagRecords">
            <label class="k-checkbox-label" for="tagRecords">Would you like to tag the imported records?</label>
          </label>
        </div>
        <div fxFlex="20%">
          <button kendoButton [primary]="true" icon="check-outline" style="margin-left: 3px;">Upload</button>
        </div>
      </div>
    </form>
  </div>
  

</div>
