import { Component, OnInit, NgZone, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';
import { ReportService } from '../report.service';
import { MediaObserver } from "@angular/flex-layout";
import { Filter } from '../../_bases/filter';
import { AuthenticationService } from '../../auth/auth.service';
import { DatePipe } from '@angular/common';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { ReportSepContLetter } from './report-sep-cont-letter';
import { exportPDF } from '@progress/kendo-drawing';
import { typeModel } from '../../_bases/typeModel';
import { saveAs } from '@progress/kendo-file-saver';
import { PersonService } from '../../features/people/person.service';

@Component({
  selector: 'app-report-sep-cont-letters',
  templateUrl: './report-sep-cont-letter.component.html',
  animations: [
  ]
})
export class ReportSepContLetterComponent implements OnInit {

  public list: ReportSepContLetter[] = [];
  filters: Filter[] = [];
  public scale = 0.8;
  @ViewChild('pdfCont') pdfCont: any;

  sepContLetters = new FormGroup({
    //isSendEmail: new FormControl(),
  });

  protected ngUnsubscribe: Subject<boolean> = new Subject();
  showHiddenContent: boolean = true;

  constructor(
    private service: ReportService,
    public media: MediaObserver,
    public authService: AuthenticationService,
    private ngZone: NgZone,
    public datePipe: DatePipe,
    private cd: ChangeDetectorRef,
    private personService: PersonService,
  ) {
    this.allData = this.allData.bind(this);
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.list, {}).data,
    };

    return result;
  }

  ngOnInit(): void {
    //this.getData();
  }

  getData() {

    if (this.sepContLetters.valid) {

      this.filters = [];
      //this.filters.push(new Filter("isSendEmail", this.sepContLetters.get('isSendEmail')?.value, 'eq'));

      this.service
        .getByCustomAction$<ReportSepContLetter>("getSepContLetters", ReportSepContLetter, this.filters)
        .pipe(
          catchError((err, caught) => {
            return of(null as any);
          })
        ).subscribe(data => {
          console.log(data);
          this.list = data;
        });
    }
  }

  saveToPdf(): void {

    this.showHiddenContent = false;
    this.cd.detectChanges();

    //this.pdfCont.saveAs('SepCont.pdf');


    this.pdfCont.export().then(
      (group: any) => {
        console.log(group);
        exportPDF(group, {
          paperSize: "Letter",
          margin: { left: "1cm", top: "1cm", right: "1cm", bottom: "1cm" }
        }).then((data: any) => {
          saveAs(data, "SepCont.pdf");

          this.showHiddenContent = true;
          this.cd.detectChanges();
        });
      });
  }

  removeFromSepGroup(personId: any): void {
    const tymodel = new typeModel<any>(personId);

    this.personService
      .postByCustomAction$<any>("removePersonFromSepGroup", tymodel)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe((data: any) => {

        console.log(data);
        if (data == true) {
          this.getData();
        }
      });
  }

  emailLetters(): void {

    this.showHiddenContent = false;
    this.cd.detectChanges();

    const noEmailPersonPdfs = document.querySelectorAll<HTMLElement>('.noEmail');

    if (noEmailPersonPdfs != undefined && noEmailPersonPdfs != null && noEmailPersonPdfs.length > 0) {

      [].forEach.call(noEmailPersonPdfs, function (element: HTMLElement, index: number, array: any) {

        if (element != null && element.parentElement != null) {
          element.parentElement.style.display = 'none';
        }
      });
    }

    let emails: string[] = [];
    const allNonHiddenPages = document.querySelectorAll<HTMLElement>('.mainBody');
    [].forEach.call(allNonHiddenPages, function (element: HTMLElement, index: number, array: any) {

      var style = window.getComputedStyle(element);
      if (style.display === 'none') {
      }
      else {
        emails.push(element.querySelector('.personEmail')!.innerHTML);
      }
    });

    if (emails.length > 0) {
      this.pdfCont.export().then(
        (group: any) => {
          console.log(group);
          exportPDF(group, {
            paperSize: "Letter",
            margin: { left: "1cm", top: "1cm", right: "1cm", bottom: "1cm" }
          }).then((data: any) => {

            const tymodel = new typeModel<any>({
              personEmails: emails,
              pdfByteArray: data
            });

            this.service
              .postByCustomAction$<any>("sendSepContLetters", tymodel)
              .pipe(
                catchError((err, caught) => {
                  return of(null as any);
                })
              ).subscribe((data: any) => {
                console.log(data);

                if (noEmailPersonPdfs != undefined && noEmailPersonPdfs != null && noEmailPersonPdfs.length > 0) {

                  [].forEach.call(noEmailPersonPdfs, function (element: HTMLElement, index: number, array: any) {

                    if (element != null && element.parentElement != null) {
                      element.parentElement.style.display = 'block';
                    }
                  });
                }
              });

            this.showHiddenContent = true;
            this.cd.detectChanges();
          });
        });

    }
    else {
      if (noEmailPersonPdfs != undefined && noEmailPersonPdfs != null && noEmailPersonPdfs.length > 0) {

        [].forEach.call(noEmailPersonPdfs, function (element: HTMLElement, index: number, array: any) {

          if (element != null && element.parentElement != null) {
            element.parentElement.style.display = 'block';
          }
        });
      }

      this.showHiddenContent = true;
      this.cd.detectChanges();
    }
  }
}
