export class CoverageType {

  constructor()
  constructor(jsonData: CoverageType)
  constructor(jsonData?: CoverageType) {
    if (jsonData) {
      Object.assign(this, jsonData);
    }
    else {
      this.id = 0;
    }
  }

  id: number | undefined;
  coverageTypeName: string | undefined;
  coverageTypeUnitCode: string | undefined;
  comments: string | undefined;
  seqNbr: string | undefined;

}


