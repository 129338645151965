export class Coverage {

  constructor()
  constructor(jsonData: Coverage)
  constructor(jsonData?: Coverage) {
    if (jsonData) {
      Object.assign(this, jsonData);
    }
    else {
      this.id = 0;
    }
  }

  id: number | undefined;
  coverageTypeId: number | undefined;
  type: string | undefined;
  coverageCode: string | undefined;
  coverageName: number | undefined;
  premium: number | undefined;
  isAssignable: boolean | undefined;

}


