<app-nav>
  <router-outlet *ngIf="!isIframe"></router-outlet>
  <br>
  <div>
    <p class="my-4 text-center">
      <small class="text-muted">
        Copyright &copy; {{ copyrightDate }} NCNGA. All Rights Reserved.
      </small>
    </p>
  </div>
</app-nav>
