<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      Previous Month
    </h1>
  </div>

  <div fxFlex="100">
    <h3 class="mr-auto primary">
      {{ currentDate | date:'EEEE, MMMM dd, yyyy' }}
    </h3>
  </div>
  <div fxLayout="row" fxLayoutGap="8px" class="m-2">
    <kendo-grid [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [columnMenu]="{ filter: true }"
                [groupable]="false" [pageSize]="100"
                [pageable]="(list && (list.length > 100) ? true : false)" [rowClass]="rowCallback" class="condensed" #grid fxFlex
                id="grdPreviousMonth">
      <ng-template kendoGridToolbarTemplate>
        <!--<button type="button" kendoGridPDFCommand icon="file-pdf">Export to PDF</button>-->
        <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
      </ng-template>
      <kendo-grid-excel fileName="PreviousMonth.xlsx" [fetchData]="this.allData"></kendo-grid-excel>
      <kendo-grid-pdf fileName="PreviousMonth.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true" [landscape]="true">
        <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
        <!--<ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
       <div class="page-template">
        <div class="header">
           <div style="float: right">
             Page {{ pageNum }} of {{ totalPages }}
           </div>
         </div>
          <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
      </div>
    </ng-template>-->
      </kendo-grid-pdf>
      <kendo-grid-column field="firstName" title="First Name" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="middleName" title="Middle Name" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="lastName" title="Last Name" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="pmtType" title="Payment Type" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="premiumAmount" title="Premium" [filterable]="true" format="{0:c}"></kendo-grid-column>
      <kendo-grid-column field="amountPaid" title="Amount Paid" [filterable]="true" format="{0:c}"></kendo-grid-column>
      <kendo-grid-column field="monthsCollected" title="Months Collected" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="monthsInsufficient" title="Months Insufficient" [filterable]="true"></kendo-grid-column>

    </kendo-grid>
  </div>

</div>
