export class Lookup {

  constructor()
  constructor(key?: any, value?: string)
  constructor(key?: any, value?: string) {
    this.key = key;
    this.value = value;
  }

  public key: any;
  public value: any;

	static filterLookups(nameOrId: string | number, lookups: Lookup[]) {
		if (typeof nameOrId === "string") {
			return lookups.filter(itm => itm.value.toLowerCase().indexOf(nameOrId.toLowerCase()) === 0);
		} else {
			return lookups.filter(itm => itm.key == nameOrId);
		}
	}
	static displayLookups(key: number, lookups: Lookup[]) {
		return key ? (lookups.length > 0 ? lookups.find(x => x.key == key)?.value : key) : key;
	}
}
