export class BankAccountTypeReadOnly {

  constructor()
  constructor(jsonData: BankAccountTypeReadOnly)
  constructor(jsonData?: BankAccountTypeReadOnly) {
    if (jsonData) {
      Object.assign(this, jsonData);
    }
  }

  id: number | undefined;
  name: string | undefined;

}


