export class AmIInsured {

  constructor()
  constructor(jsonData: AmIInsured)
  constructor(jsonData?: AmIInsured) {
    if (jsonData) {
      Object.assign(this, jsonData);
    }
  }

  lastName: string | undefined;

  ssn: string | undefined;

  isInsured: boolean | undefined;

  id?: number | undefined;

  firstName?: string | undefined;

  middleName?: string | undefined;

  address1?: string | undefined;

  address2?: string | undefined;

  city?: string | undefined;

  state?: string | undefined;

  zip?: string | undefined;

  primaryPhone?: string | undefined;

  secondaryPhone?: string | undefined;

  email?: string | undefined;

  militaryEmail?: string | undefined;

  lifeMemberNbr?: string | undefined;

  pmtType?: string | undefined;

  unitCode?: string | undefined;

  unitName?: string | undefined;

  coverageTypeName?: string | undefined;

  memberCoverageName?: number | undefined;

  memberPremium?: number | undefined;

  memberDOB?: Date | undefined;

  spouseCoverageName?: number | undefined;

  spousePremium?: number | undefined;

  spouseDOB?: Date | undefined;

  dependentCoverageName?: number | undefined;

  dependentPremium?: number | undefined;

  totalPremium?: number | undefined;

  beneficiaries?: any | undefined;

  isFromFindMyPolicy?: boolean | undefined;

}


