export class ReportPreviousMonth {

  constructor();
  constructor(jsonData?: ReportPreviousMonth) {
    Object.assign(this, jsonData);
  }

  public id: number | undefined;
  public firstName: string | undefined;
  public lastName: string | undefined;
  public middleName: string | undefined;
  public pmtType: string | undefined;
  public premiumAmount: number | undefined;
  public amountPaid: number | undefined;
  public monthsCollected: number | undefined;
  public monthsInsufficient: number | undefined;
}
