<div fxLayout="column" fxLayoutGap="8px" class="m-2   main-ctr">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      Annual Payments Received
    </h1>
  </div>
  <div fxFlex="100">
    <mat-form-field appearance="fill">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>
    <button mat-raised-button (click)="loadList()">Go</button>
  </div>
  <div fxLayout="row" fxLayoutGap="8px" class="m-2">
    <kendo-grid [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [columnMenu]="{ filter: true }"
      [groupable]="false" [pageable]="false" [rowClass]="rowCallback" class="condensed" #grid fxFlex
      id="grdAnnualPaymentsReceived" (dataStateChange)="dataStateChange($event)">
      <ng-template kendoGridToolbarTemplate>
        <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
      </ng-template>
      <kendo-grid-excel fileName="AnnualPaymentsReceivedReport.xlsx" [fetchData]="this.allData"></kendo-grid-excel>
      <kendo-grid-column field="person" title="Person" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="premium" title="Premium" [filterable]="true" format="{0:c}"></kendo-grid-column>
      <kendo-grid-column field="nextPmtDue" title="Next Pmt Due" [filterable]="true" filter="date" format="{0:d}">
      </kendo-grid-column>
      <kendo-grid-column field="depositDate" title="Deposit Date" [filterable]="true" filter="date" format="{0:d}">
      </kendo-grid-column>
      <kendo-grid-column field="depositAmt" title="Deposit Amount" [filterable]="true" format="{0:c}">
        <ng-template kendoGridFooterTemplate>
          Total: {{ total["depositAmt"]?.sum | currency }}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>

</div>