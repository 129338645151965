export class ArmyNonReviewTransfers {

  constructor();
  constructor(jsonData?: ArmyNonReviewTransfers) {

    if (jsonData) {
      Object.assign(this, jsonData);
    }
  }

  public id: number | undefined;
  public piqName: string | undefined;
  public payCode: string | undefined;
  public currentUnitCode: string | undefined;
}
