<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      Membership Export
    </h1>
  </div>

  <div fxLayout="row" fxLayoutGap="8px" class="m-2">
    <kendo-grid [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [columnMenu]="{ filter: true }"
                [groupable]="false" [pageSize]="100"
                [pageable]="(list && (list.length > 100) ? true : false)" [rowClass]="rowCallback" class="condensed" #grid fxFlex
                id="grdMembershipExport">
      <ng-template kendoGridToolbarTemplate>
        <!--<button type="button" kendoGridPDFCommand icon="file-pdf">Export to PDF</button>-->
        <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
      </ng-template>
      <kendo-grid-excel fileName="MembershipExport.xlsx" [fetchData]="this.allData">
        <kendo-excelexport-column field="firstName" title="First Name"></kendo-excelexport-column>
        <kendo-excelexport-column field="middleName" title="Middle Name"></kendo-excelexport-column>
        <kendo-excelexport-column field="lastName" title="Last Name"></kendo-excelexport-column>
        <kendo-excelexport-column field="address1" title="Address1"></kendo-excelexport-column>
        <kendo-excelexport-column field="address2" title="Address2"></kendo-excelexport-column>
        <kendo-excelexport-column field="city" title="City"></kendo-excelexport-column>
        <kendo-excelexport-column field="state" title="State"></kendo-excelexport-column>
        <kendo-excelexport-column field="zip" title="Zip"></kendo-excelexport-column>
        <kendo-excelexport-column field="primaryPhone" title="Primary Phone"></kendo-excelexport-column>
        <kendo-excelexport-column field="secondaryPhone" title="Secondary Phone"></kendo-excelexport-column>
        <kendo-excelexport-column field="email" title="Email"></kendo-excelexport-column>
        <kendo-excelexport-column field="groupName" title="Group Name"></kendo-excelexport-column>
        <kendo-excelexport-column field="membershipNumber" title="Membership Number"></kendo-excelexport-column>
        <kendo-excelexport-column field="annualExpirationDate" title="Annual Expiration Date"></kendo-excelexport-column>
        <kendo-excelexport-column field="paymentDate" title="Payment Date"></kendo-excelexport-column>
      </kendo-grid-excel>
      <kendo-grid-pdf fileName="MembershipExport.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true" [landscape]="true">
        <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
        <!--<ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
       <div class="page-template">
        <div class="header">
           <div style="float: right">
             Page {{ pageNum }} of {{ totalPages }}
           </div>
         </div>
          <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
      </div>
    </ng-template>-->
      </kendo-grid-pdf>
      <!--<kendo-grid-column field="ssn" title="SSN" [filterable]="true">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      {{dataItem.ssn | mask: '000-00-0000'}}
    </ng-template>
  </kendo-grid-column>-->
      <kendo-grid-column field="firstName" title="Name" [filterable]="true" [width]="175">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ dataItem.lastName }} {{ dataItem.firstName }} {{ dataItem.middleName }}
        </ng-template>
      </kendo-grid-column>
      <!--<kendo-grid-column field="middleName" title="Middle Name" [filterable]="true"></kendo-grid-column>
  <kendo-grid-column field="lastName" title="Last Name" [filterable]="true"></kendo-grid-column>-->

      <kendo-grid-column field="address1" title="Address" [width]="180">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ dataItem.address1 }} {{ dataItem.address2 }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="city" title="City" [filterable]="true" [width]="120"></kendo-grid-column>
      <kendo-grid-column field="state" title="State" [filterable]="true" [width]="100"></kendo-grid-column>
      <kendo-grid-column field="zip" title="Zip" [filterable]="true" [width]="100"></kendo-grid-column>
      <kendo-grid-column field="email" title="Email" [filterable]="true" [width]="180"></kendo-grid-column>
      <kendo-grid-column field="primaryPhone" title="Primary Phone" [filterable]="true" [width]="150"></kendo-grid-column>
      <kendo-grid-column field="secondaryPhone" title="Secondary Phone" [filterable]="true" [width]="160"></kendo-grid-column>
      <kendo-grid-column field="groupName" title="Group Name" [filterable]="true" [width]="190"></kendo-grid-column>
      <kendo-grid-column field="membershipNumber" title="Membership Number" [filterable]="true" [width]="150"></kendo-grid-column>
      <kendo-grid-column field="annualExpirationDate" title="Annual Expiration Date" [filterable]="true" filter="date" format="{0:d}" [width]="190"></kendo-grid-column>
      <kendo-grid-column field="paymentDate" title="Payment Date" [filterable]="true" filter="date" format="{0:d}" [width]="180"></kendo-grid-column>
    </kendo-grid>
  </div>
</div>
