<div fxLayout="column" fxFlex="100" fxLayoutGap="8px">
  <!-- <div fxFlex="100">
    <div fxLayout="row" fxFlex="100" [style.margin-left.px]="25">
      <span class="error-color">Required "*"</span>
      <button (click)="dummyData()">Fill With Dummy Data</button>
    </div>
  </div> -->
  <form [formGroup]="theForm" (ngSubmit)="onSubmit()" fxFlex="100">

    <!--<ng-template #step1Temp>
      <div formArrayName="formArray">
        <div formGroupName="0">
          <div fxLayout="row" fxFlex="100" fxLayoutGap="8px">
            <mat-form-field appearance="fill" fxFlex="15">
              <mat-label>Enlistment Month <span class="error-color">*</span></mat-label>
              <mat-select formControlName="enlistmentDateMonth" (selectionChange)="checkEnlistmentDate()">
                <mat-option value="1">Jan</mat-option>
                <mat-option value="2">Feb</mat-option>
                <mat-option value="3">Mar</mat-option>
                <mat-option value="4">Apr</mat-option>
                <mat-option value="5">May</mat-option>
                <mat-option value="6">Jun</mat-option>
                <mat-option value="7">Jul</mat-option>
                <mat-option value="8">Aug</mat-option>
                <mat-option value="9">Sep</mat-option>
                <mat-option value="10">Oct</mat-option>
                <mat-option value="11">Nov</mat-option>
                <mat-option value="12">Dec</mat-option>
              </mat-select>
              <mat-hint></mat-hint>
              <mat-error *ngIf="enlistmentDateMonth.touched && enlistmentDateMonth.hasError('required')">
                Enlistment Month or NC Guard Join Month is <strong>required</strong>
              </mat-error>

              <mat-error *ngFor="let e of fieldErrors('enlistmentDateMonth')">
                {{ e.params }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="15">
              <mat-label>Enlistment Year <span class="error-color">*</span></mat-label>
              <mat-select formControlName="enlistmentDateYear" (selectionChange)="checkEnlistmentDate()">
                <mat-option *ngFor="let item of this.years" [value]="item.key">
                  {{item.value}}
                </mat-option>
              </mat-select>
              <mat-hint></mat-hint>
              <mat-error *ngIf="enlistmentDateYear.touched && enlistmentDateYear.hasError('required')">
                Enlistment Year or NC Guard Join Year is <strong>required</strong>
              </mat-error>

              <mat-error *ngFor="let e of fieldErrors('enlistmentDateYear')">
                {{ e.params }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-template>-->

    <mat-stepper orientation="vertical" [linear]="true" #newApplicationStepper
                 formArrayName="formArray" (selectionChange)="matStepperSelectionChange($event)">
      <mat-step formGroupName="0" [stepControl]="formArray?.get([0])!">
        <ng-template matStepLabel>Enlistment Date</ng-template>

        <mat-card fxFlex="100" class="mt-3">
          <mat-card-header>
            <mat-card-subtitle>Enlistment Date or NC Guard Join Date</mat-card-subtitle>
          </mat-card-header>

          <mat-card-content>
            <!--<div *ngTemplateOutlet="step1Temp;"></div>-->

            <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxFlex="100" fxLayoutGap="8px">
              <input hidden="true" formControlName="id" />
              <mat-form-field appearance="fill" fxFlex.gt-sm="18">
                <mat-label>Enlistment Month <span class="error-color">*</span></mat-label>
                <mat-select formControlName="enlistmentDateMonth" (selectionChange)="checkEnlistmentDate()">
                  <mat-option value="1">Jan</mat-option>
                  <mat-option value="2">Feb</mat-option>
                  <mat-option value="3">Mar</mat-option>
                  <mat-option value="4">Apr</mat-option>
                  <mat-option value="5">May</mat-option>
                  <mat-option value="6">Jun</mat-option>
                  <mat-option value="7">Jul</mat-option>
                  <mat-option value="8">Aug</mat-option>
                  <mat-option value="9">Sep</mat-option>
                  <mat-option value="10">Oct</mat-option>
                  <mat-option value="11">Nov</mat-option>
                  <mat-option value="12">Dec</mat-option>
                </mat-select>
                <mat-hint></mat-hint>
                <mat-error *ngIf="enlistmentDateMonth.touched && enlistmentDateMonth.hasError('required')">
                  Enlistment Month or NC Guard Join Month is <strong>required</strong>
                </mat-error>

                <mat-error *ngFor="let e of fieldErrors('enlistmentDateMonth')">
                  {{ e.params }}
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill" fxFlex.gt-sm="18">
                <mat-label>Enlistment Year <span class="error-color">*</span></mat-label>
                <mat-select formControlName="enlistmentDateYear" (selectionChange)="checkEnlistmentDate()">
                  <mat-option *ngFor="let item of this.years" [value]="item.key">
                    {{item.value}}
                  </mat-option>
                </mat-select>
                <mat-hint></mat-hint>
                <mat-error *ngIf="enlistmentDateYear.touched && enlistmentDateYear.hasError('required')">
                  Enlistment Year or NC Guard Join Year is <strong>required</strong>
                </mat-error>

                <mat-error *ngFor="let e of fieldErrors('enlistmentDateYear')">
                  {{ e.params }}
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="fill" fxFlex.gt-sm="18">
                <mat-label>DoD ID# <span class="error-color">*</span></mat-label>
                <input matInput formControlName="dodId" mask="0000000000" />
                <mat-hint></mat-hint>
                <mat-error *ngIf="dodId.touched && dodId.hasError('required')">
                  DOD Id is <strong>required</strong>
                </mat-error>

                <mat-error *ngFor="let e of fieldErrors('dodId')">
                  {{ e.params }}
                </mat-error>

              </mat-form-field>
              <div fxFlex.gt-sm="18" fxLayoutAlign="left center" fxFlexFill>
                <input hidden="true" formControlName="dodIdVerified" />
                <mat-error *ngIf="dodIdVerified.touched && dodIdVerified.hasError('required')">
                  DOD Id is not verified, click next to verify.
                </mat-error>

                <mat-error *ngFor="let e of fieldErrors('dodIdVerified')">
                  {{ e.params }}
                </mat-error>


              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <div class="mt-2">
          <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100">
            <div fxFlex>
              <button type="button" mat-button [disabled]="verifyingDODId" (click)="goForward()">Next &raquo;</button>
            </div>
            <div fxFlex="99" fxLayoutAlign="left center" [hidden]="!verifyingDODId" fxFlexFill>
              <mat-error>Verifying DODId, please wait....</mat-error>
            </div>
          </div>
          <!--<button type="button" mat-button matStepperNext>Next &raquo;</button>-->
        </div>
      </mat-step>

      <mat-step formGroupName="1" [stepControl]="formArray?.get([1])!">
        <ng-template matStepLabel>
          <div>National Guard Member Information</div>
        </ng-template>
        <mat-card fxFlex="100" class="mt-3">
          <!--<mat-card-header>
            <mat-card-subtitle>&nbsp;</mat-card-subtitle>
          </mat-card-header>-->

          <mat-card-content>
            <div fxLayout="column" fxFlex="100">
              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100">
                <mat-form-field appearance="fill" fxFlex.gt-sm="18">
                  <mat-label>Last Name <span class="error-color">*</span></mat-label>
                  <input matInput formControlName="lastName" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="lastName.touched && lastName.hasError('required')">
                    Last Name is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('lastName')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="18">
                  <mat-label>First Name <span class="error-color">*</span></mat-label>
                  <input matInput formControlName="firstName" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="firstName.touched && firstName.hasError('required')">
                    First Name is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('firstName')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>


                <mat-form-field appearance="fill" fxFlex.gt-sm="15">
                  <mat-label>Middle Name</mat-label>
                  <input matInput formControlName="middleName" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="middleName.touched && middleName.hasError('required')">
                    Middle Name is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('middleName')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="10">
                  <mat-label>Suffix</mat-label>
                  <input matInput formControlName="suffixName" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="suffixName.touched && suffixName.hasError('required')">
                    Suffix Name is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('suffixName')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="15" *ngIf="this.hasNameChanges">
                  <mat-label>Name Change Reason</mat-label>
                  <input matInput formControlName="nameChangeReason" [matAutocomplete]="autoNameChange" placeholder="Pick One Or Type Details">
                  <mat-autocomplete #autoNameChange="matAutocomplete">
                    <mat-option value="Marriage">Marriage</mat-option>
                    <mat-option value="Divorce">Divorce</mat-option>
                    <mat-option value="Adoption">Adoption</mat-option>
                  </mat-autocomplete>

                  <mat-hint></mat-hint>
                  <mat-error *ngIf="nameChangeReason.touched && nameChangeReason.hasError('required')">
                    Name Change Reason is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('nameChangeReason')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

              </div>

              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100">
                <mat-form-field appearance="fill" fxFlex.gt-sm="18">
                  <mat-label>Rank</mat-label>
                  <!-- <input matInput formControlName="rank" /> -->
                  <mat-select formControlName="rankId">
                    <mat-option *ngFor="let item of this.rankIdLookups$ | async" [value]="item.key">
                      {{item.value}}
                    </mat-option>
                  </mat-select>
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="rankId.touched && rankId.hasError('required')">
                    Rank is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('rankId')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <!-- <div>
                  <kendo-textbox-container floatingLabel="SSN *" fxFlex="100">
                    <kendo-maskedtextbox matInput mask="000-00-0000" formControlName="ssn"></kendo-maskedtextbox>
                  </kendo-textbox-container>
                  <div class="k-mat-error-wrapper" fxFlex="100">
                    <mat-error *ngIf="ssn.touched && ssn.hasError('required')">
                      SSN is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngFor="let e of fieldErrors('ssn')">
                      {{ e.params }}
                    </mat-error>
                  </div>
                </div> -->

                <mat-form-field appearance="fill" fxFlex.gt-sm="10">
                  <mat-label>SSN [xxx-xx-xxxx] <span class="text-danger">*</span></mat-label>
                  <input matInput formControlName="ssn" mask="000-00-0000" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="ssn.touched && ssn.hasError('required')">
                    SSN is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="ssn.touched && ssn.hasError('mask')">
                    Please enter 9 digits
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="10">
                  <mat-label>DOB <span class="error-color">*</span></mat-label>
                  <input matInput [matDatepicker]="dobpicker" formControlName="dob"
                         (dateChange)="dobChange($event.value)" [max]="today" />
                  <mat-datepicker-toggle matSuffix [for]="dobpicker"></mat-datepicker-toggle>
                  <mat-datepicker #dobpicker></mat-datepicker>
                  <mat-hint></mat-hint>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="10">
                  <mat-label>Age</mat-label>
                  <input matInput formControlName="age" type="number" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="age.touched && age.hasError('required')">
                    Age is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('age')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="10" *ngIf="!this.isLessThan90Days">
                  <mat-label>Height Feet <span class="error-color">*</span></mat-label>
                  <input matInput formControlName="heightFeet" type="number" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="heightFeet.touched && heightFeet.hasError('required')">
                    Height Feet is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('heightFeet')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="10" *ngIf="!this.isLessThan90Days">
                  <mat-label>Height inch <span class="error-color">*</span></mat-label>
                  <input matInput formControlName="heightInch" type="number" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="heightInch.touched && heightInch.hasError('required')">
                    Height Inch is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('heightInch')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="15" *ngIf="!this.isLessThan90Days">
                  <mat-label>Weight (In LBS) <span class="error-color">*</span></mat-label>
                  <input matInput formControlName="weightInLBS" type="number" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="weightInLBS.touched && weightInLBS.hasError('required')">
                    Weight (In LBS) is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('weightInLBS')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

              </div>

              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100">

                <mat-form-field appearance="fill" fxFlex.gt-sm="15">
                  <mat-label>Gender <span class="error-color">*</span></mat-label>
                  <mat-select formControlName="gender">
                    <mat-option value="Male">Male</mat-option>
                    <mat-option value="Female">Female</mat-option>
                  </mat-select>
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="gender.touched && gender.hasError('required')">
                    Gender is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('gender')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="15" *ngIf="!this.isLessThan90Days">
                  <mat-label>Married? <span class="error-color">*</span></mat-label>
                  <mat-select formControlName="isMarried" (selectionChange)="marriedChange($event.value)">
                    <mat-option value="1">Married</mat-option>
                    <mat-option value="0">Not-Married</mat-option>
                  </mat-select>
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="isMarried.touched && isMarried.hasError('required')">
                    Please <strong>answer</strong> this question
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('isMarried')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="15" *ngIf="!this.isLessThan90Days">
                  <mat-label>Duty Status <span class="error-color">*</span></mat-label>
                  <mat-select formControlName="dutyStatusAGR">
                    <mat-option value="1">AGR (Active Guard Reserve)</mat-option>
                    <mat-option value="0">Traditional</mat-option>
                  </mat-select>
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="dutyStatusAGR.touched && dutyStatusAGR.hasError('required')">
                    Please <strong>answer</strong> this question
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('dutyStatusAGR')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="10">
                  <mat-label>Branch Of Service <span class="error-color">*</span></mat-label>
                  <mat-select formControlName="branchOfService">
                    <mat-option value="Army">Army</mat-option>
                    <mat-option value="Air Force">Air Force</mat-option>
                  </mat-select>
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="branchOfService.touched && branchOfService.hasError('required')">
                    Please <strong>answer</strong> this question
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('branchOfService')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="20">
                  <mat-label>Unit <span class="error-color">*</span></mat-label>
                  <input matInput formControlName="nameOfUnit" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="nameOfUnit.touched && nameOfUnit.hasError('required')">
                    Unit is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('nameOfUnit')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

              </div>

              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100">

                <mat-form-field appearance="fill" fxFlex.gt-sm="25">
                  <mat-label>Address1 <span class="error-color">*</span></mat-label>
                  <input matInput formControlName="address1" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="address1.touched && address1.hasError('required')">
                    Address1 is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('address1')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="25">
                  <mat-label>Address2</mat-label>
                  <input matInput formControlName="address2" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="address2.touched && address2.hasError('required')">
                    Address2 is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('address2')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>



              </div>

              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100">

                <mat-form-field appearance="fill" fxFlex.gt-sm="20">
                  <mat-label>City <span class="error-color">*</span></mat-label>
                  <input matInput formControlName="city" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="city.touched && city.hasError('required')">
                    City is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('city')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="20">
                  <mat-label>State <span class="error-color">*</span></mat-label>
                  <input matInput formControlName="state" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="state.touched && state.hasError('required')">
                    State is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('state')">
                    {{ e.params }}
                  </mat-error>
                  <mat-error *ngIf="state.touched && (state.hasError('minlength') || state.hasError('maxlength'))">
                    Your state must be 2 characters long.
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="10">
                  <mat-label>Zip <span class="error-color">*</span></mat-label>
                  <input matInput formControlName="zip" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="zip.touched && zip.hasError('required')">
                    Zip is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('zip')">
                    {{ e.params }}
                  </mat-error>
                  <mat-error *ngIf="zip.touched && (zip.hasError('minlength') || zip.hasError('maxlength'))">
                    Your state must be 5 characters long.
                  </mat-error>
                </mat-form-field>

                <!-- <kendo-textbox-container floatingLabel="Primary Phone *" fxFlex.gt-sm="25">
                  <kendo-maskedtextbox formControlName="primaryPhone" mask="(000) 000-0000"></kendo-maskedtextbox>
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="primaryPhone.touched && primaryPhone.hasError('required')">
                    Primary Phone is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('primaryPhone')">
                    {{ e.params }}
                  </mat-error>
                </kendo-textbox-container> -->

                <mat-form-field appearance="fill" fxFlex="100">
                  <mat-label>Primary Phone [(xxx) xxx-xxxx] <span class="text-danger">*</span></mat-label>
                  <input matInput formControlName="primaryPhone" mask="(000) 000-0000" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="primaryPhone.touched && primaryPhone.hasError('required')">
                    Primary Phone is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngIf="primaryPhone.touched && primaryPhone.hasError('mask')">
                    Please enter 10 digits
                  </mat-error>
                </mat-form-field>

                <!-- <kendo-textbox-container floatingLabel="Secondary Phone" fxFlex.gt-sm="25">
                  <kendo-maskedtextbox formControlName="secondaryPhone" mask="(000) 000-0000"></kendo-maskedtextbox>
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="secondaryPhone.touched && secondaryPhone.hasError('required')">
                    Secondary Phone is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('secondaryPhone')">
                    {{ e.params }}
                  </mat-error>
                </kendo-textbox-container> -->

                <mat-form-field appearance="fill" fxFlex="100">
                  <mat-label>Secondary Phone [(xxx) xxx-xxxx]</mat-label>
                  <input matInput formControlName="secondaryPhone" mask="(000) 000-0000" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="secondaryPhone.touched && secondaryPhone.hasError('mask')">
                    Please enter 10 digits
                  </mat-error>
                </mat-form-field>

              </div>

              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100">

                <mat-form-field appearance="fill" fxFlex.gt-sm="25">
                  <mat-label>Civilian Email <span class="error-color">*</span></mat-label>
                  <input matInput formControlName="email" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="email.touched && email.hasError('required')">
                    Email is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('email')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="25" *ngIf="!this.isLessThan90Days">
                  <mat-label>Military Email</mat-label>
                  <input matInput formControlName="militaryEmail" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="militaryEmail.touched && militaryEmail.hasError('required')">
                    Military Email is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('militaryEmail')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>
              </div>

              <!--<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100">
                <mat-form-field appearance="fill" fxFlex.gt-sm="20">
                  <mat-label>DoD ID# <span class="error-color" *ngIf="!this.isLessThan90Days">*</span></mat-label>
                  <input matInput formControlName="dodId" mask="0000000000" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="dodId.touched && dodId.hasError('required')">
                    DOD Id is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('dodId')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

              </div>-->

              <p fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                As applicant, I designate beneficiary(ies) to receive benefits as indicated below. If designating
                multiple beneficiaries, total percentages designated must
                equal 100%. <span class="error-color">*</span>
              </p>
              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100">
                <input type="hidden" formControlName="newApplicationPersonInsTotalPercentage" />
                <div fxFlex="100">
                  <input type="hidden" formControlName="newApplicationPersonInsTotalPercentage" />
                  <div class="mat-error"
                       *ngIf="newApplicationPersonInsTotalPercentage.errors?.min || newApplicationPersonInsTotalPercentage.errors?.max">
                    Total Beneficiary Percentage Must Be 100%.
                    <!--{{ theForm.get('newApplicationPersonInsTotalPercentage').value }}-->
                  </div>
                  <mat-hint></mat-hint>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="8px" fxFlex="100" class="my-2">
                <app-newapplicationpersoninsbeneficiaries-list></app-newapplicationpersoninsbeneficiaries-list>
              </div>

              <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                <mat-form-field appearance="fill" fxFlex="100">
                  <mat-label>
                    Is anyone assisting you with this application <span class="error-color">*</span>
                  </mat-label>
                  <mat-select formControlName="volunteerNetworkId" (selectionChange)="volunteerChange($event.value)">
                    <mat-option *ngFor="let item of this.volunteerNetworkIdLookups$ | async" [value]="item.key">
                      {{item.value}}
                    </mat-option>
                  </mat-select>
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="volunteerNetworkId.touched && volunteerNetworkId.hasError('required')">
                    Volunteer is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('volunteerNetworkId')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>
              </div>

            </div>
          </mat-card-content>
        </mat-card>

        <div class="mt-2">
          <button type="button" mat-button matStepperPrevious>&laquo; Back</button>
          <button type="button" mat-button matStepperNext>Next &raquo;</button>
        </div>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Benefits</ng-template>
        <mat-card fxFlex="100" class="mt-3">
          <mat-card-content>
            <div fxFlex="100">
              <div fxLayout="column" fxFlex="100">
                <div fxFlex="100">
                  <h1>NOW AVAILABLE UP TO $50,000</h1>
                </div>
                <div fxLayout="column" fxLayout.gt-sm="row" fxFlex="100" fxLayoutGap="24px">
                  <div fxFlex.gt-sm="50">
                    <div fxLayout="column" fxFlex="100" fxLayoutGap="8px">
                      <h2 fxFlex="100">BASIC MEMBER DEATH BENEFIT:</h2>
                      <p fxFlex="100">
                        $1,000 NON-CONTRIBUTORY provided to you
                        by the National Guard Association of NC.
                      </p>
                      <h2 fxFlex="100">
                        OPTIONAL COVERAGE funded through life
                        insurance underwritten by 5Star Life
                        Insurance Company: $10,000-$50,000
                      </h2>
                      <h2 fxFlex="100"> INDIVIDUAL CERTIFICATES</h2>
                      <p>
                        Each member enrolled will receive a certificate giving a complete
                        statement of the benefits as outlined.
                      </p>
                      <h2 fxFlex="100">MONTHLY CONTRIBUTIONS (Guard Member)</h2>

                      <table mat-table [dataSource]="this.listMcover" class="mat-elevation-z2 noScreen onExport">
                        <ng-container matColumnDef="coverage">
                          <th mat-header-cell *matHeaderCellDef> COVERAGE </th>
                          <td mat-cell *matCellDef="let element"> {{ element.coverage | currency }} </td>
                        </ng-container>

                        <ng-container matColumnDef="contribution">
                          <th mat-header-cell *matHeaderCellDef> CONTRIBUTION </th>
                          <td mat-cell *matCellDef="let element"> {{ element.contribution | currency }} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsCov"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsCov;"></tr>
                      </table>
                      <h2 fxFlex="100">
                        DEPENDENT COVERAGE (Includes Spouse)
                      </h2>
                      <h4 fxFlex="100">
                        Cannot exceed 50% of Member’s coverage
                      </h4>

                      <table mat-table [dataSource]="this.listDcover" class="mat-elevation-z2 noScreen onExport">
                        <ng-container matColumnDef="coverage">
                          <th mat-header-cell *matHeaderCellDef> COVERAGE </th>
                          <td mat-cell *matCellDef="let element"> {{ element.coverage | currency }} </td>
                        </ng-container>

                        <ng-container matColumnDef="contribution">
                          <th mat-header-cell *matHeaderCellDef> CONTRIBUTION </th>
                          <td mat-cell *matCellDef="let element"> {{ element.contribution | currency }} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsCov"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsCov;"></tr>
                      </table>

                      <p fxFlex="100">
                        Dependent child coverage birth to 21 years or age, or
                        25 if a full-time student.
                      </p>

                      <h2 fxFlex="100">
                        SPOUSE COVERAGE ONLY
                      </h2>
                      <h4 fxFlex="100">
                        Cannot exceed Member’s coverage
                      </h4>

                      <table mat-table [dataSource]="this.listDcover" class="mat-elevation-z2 noScreen onExport">
                        <ng-container matColumnDef="coverage">
                          <th mat-header-cell *matHeaderCellDef> COVERAGE </th>
                          <td mat-cell *matCellDef="let element"> {{ element.coverage | currency }} </td>
                        </ng-container>

                        <ng-container matColumnDef="contribution">
                          <th mat-header-cell *matHeaderCellDef> CONTRIBUTION </th>
                          <td mat-cell *matCellDef="let element"> {{ element.contribution | currency }} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsCov"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsCov;"></tr>
                      </table>
                    </div>
                  </div>
                  <div fxFlex.gt-sm="50">
                    <h2>BENEFITS</h2>
                    <ul>
                      <li>$1,000, $10,000, $20,000, $25,000, $30,000, $40,000 or $50,000</li>
                      <li>Benefit payable in event of death from any cause (subject to contestability)</li>
                      <li>Coverage is twenty-four hours a day, 365 days a year</li>
                      <li>No War Clause</li>
                      <li>No Aviation Exclusion</li>
                      <li>No Hazardous Duty or Civilian Occupation Restriction</li>
                      <li>
                        Full Conversion privilege upon termination regardless of health
                        (see Conversion Privilege section below)
                      </li>
                    </ul>

                    <h2>BENEFICIARY</h2>
                    <p>
                      Benefits will be paid to the member’s named beneficiary in a lump-sum
                      payment. If no beneficiary is living at the time of death of the insured member,
                      the amount shall be paid to the duly qualified executors or administrators of
                      the member’s estate.
                    </p>

                    <h2>TERMINATION</h2>
                    <p>
                      Optional Coverage will terminate the date the policy or section of the policy
                      under which coverage is offered ends, or the last day of the month for which
                      contributions have been paid (subject to the Grace Period).
                    </p>
                    <p>
                      Optional Coverage may be continued after leaving the National Guard until
                      age 65.
                    </p>
                    <p>
                      The benefit(s) elected will remain level until age 60. When the Insured attains
                      age 60 (Guard Member, Spouse or Dependent), the benefits will be reduced by
                      50% and the contribution will remain the same. All optional coverages expire
                      on the last day of the month in which the member attains age 65.
                    </p>
                    <h2>CONVERSION PRIVILEGE</h2>
                    <p>
                      If elected benefit ceases because of termination of membership in the classes
                      eligible for coverage under this program or separation from the National
                      Guard, coverage may be converted to an individual policy within 31 days. See
                      your certificate for details and requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </mat-card-content>
        </mat-card>
        <div class="mt-2">
          <button type="button" mat-button matStepperPrevious>&laquo; Back</button>
          <button type="button" mat-button matStepperNext>Next &raquo;</button>
        </div>
      </mat-step>
      <mat-step formGroupName="2" [stepControl]="formArray?.get([2])!" *ngIf="!this.isLessThan90Days">
        <ng-template matStepLabel>Coverage</ng-template>
        <mat-card fxFlex="100" class="mt-3">
          <mat-card-header>
            <mat-card-subtitle>Coverage</mat-card-subtitle>
          </mat-card-header>

          <mat-card-content>
            <div fxLayout="column" fxFlex="100">
              <!--<mat-form-field appearance="fill" fxFlex="100">
                <mat-label>Coverage Type</mat-label>
                <mat-select formControlName="coverageTypeId" (selectionChange)="loadCoverages($event.value)">
                  <mat-option *ngFor="let item of this.coverageTypeIdLookups$ | async" [value]="item.key">
                    {{item.value}}
                  </mat-option>
                </mat-select>
                <mat-hint></mat-hint>
                <mat-error *ngIf="coverageTypeId.touched && coverageTypeId.hasError('required')">
                  Coverage Type is <strong>required</strong>
                </mat-error>

                <mat-error *ngFor="let e of fieldErrors('coverageTypeId')">
                  {{ e.params }}
                </mat-error>
              </mat-form-field>-->

              <div fxFlex="100">
                <input type="hidden" formControlName="newApplicationSpouseAndDependentCoverage" />
                <div class="mat-error" *ngIf="newApplicationSpouseAndDependentCoverage.errors?.max">
                  Combined Coverage cannot exceed the Member's coverage.
                  <!--{{ newApplicationSpouseAndDependentCoverage.value }}-->
                </div>
                <mat-hint></mat-hint>

                <input type="hidden" formControlName="newApplicationDependentCoverage" />
                <div class="mat-error" *ngIf="newApplicationDependentCoverage.errors?.max">
                  Dependent Coverage cannot exceed the 50% of Member's coverage.
                  <!--{{ newApplicationDependentCoverage.value }}-->
                </div>
                <mat-hint></mat-hint>
              </div>

              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100">
                <mat-form-field appearance="fill" fxFlex.gt-sm="30">
                  <mat-label>Member Coverage <span class="error-color">*</span></mat-label>
                  <mat-select formControlName="memberCoverageId" (selectionChange)="memberCoverageChange($event.value)">
                    <mat-option *ngFor="let item of this.coveragesMember" [value]="item.key">
                      ${{item.value}}
                    </mat-option>
                  </mat-select>
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="memberCoverageId.touched && memberCoverageId.hasError('required')">
                    Member Coverage is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('memberCoverageId')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="25">
                  <mat-label>Premium</mat-label>
                  <input matInput formControlName="premium" prefix="$ " mask="separator.2" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="premium.touched && premium.hasError('required')">
                    Premium is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('premium')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100"
                   *ngIf="this.isMarried.value == '1'">
                <mat-form-field appearance="fill" fxFlex.gt-sm="30">
                  <mat-label>Spouse Coverage</mat-label>
                  <mat-select formControlName="spouseCoverageId" (selectionChange)="spouseCoverageChange($event.value)">
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let item of this.coveragesSpouse" [value]="item.key">
                      ${{item.value}}
                    </mat-option>
                  </mat-select>
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="spouseCoverageId.touched && spouseCoverageId.hasError('required')">
                    Spouse Coverage is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('spouseCoverageId')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="25">
                  <mat-label>Spouse Premium</mat-label>
                  <input matInput formControlName="spousePremium" prefix="$ " mask="separator.2" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="spousePremium.touched && spousePremium.hasError('required')">
                    Spouse Premium is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('spousePremium')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

              </div>
              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100">

                <mat-form-field appearance="fill" fxFlex.gt-sm="30">
                  <mat-label>Dependent Coverage</mat-label>
                  <mat-select formControlName="dependentCoverageId"
                              (selectionChange)="dependentCoverageChange($event.value)">
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let item of this.coveragesDependent" [value]="item.key">
                      ${{item.value}}
                    </mat-option>
                  </mat-select>
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="dependentCoverageId.touched && dependentCoverageId.hasError('required')">
                    Dependent Coverage is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('dependentCoverageId')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="25">
                  <mat-label>Dependent Premium</mat-label>
                  <input matInput formControlName="dependentPremium" prefix="$ " mask="separator.2" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="dependentPremium.touched && dependentPremium.hasError('required')">
                    Dependent Premium is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('dependentPremium')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

              </div>
              <!--<div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                <mat-form-field appearance="fill" fxFlex="50">
                  <mat-label>Effective Date *</mat-label>
                  <input matInput [matDatepicker]="effectiveDatepicker" formControlName="effectiveDate" />
                  <mat-datepicker-toggle matSuffix [for]="effectiveDatepicker"></mat-datepicker-toggle>
                  <mat-datepicker #effectiveDatepicker></mat-datepicker>
                  <mat-error *ngFor="let e of fieldErrors('effectiveDate')">
                    {{ e.params }}
                  </mat-error>
                  <mat-hint></mat-hint>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="50">
                  <mat-label>Next Pmt Due *</mat-label>
                  <input matInput [matDatepicker]="nextPmtDueDatepicker" formControlName="nextPmtDue" />
                  <mat-datepicker-toggle matSuffix [for]="nextPmtDueDatepicker"></mat-datepicker-toggle>
                  <mat-datepicker #nextPmtDueDatepicker></mat-datepicker>
                  <mat-error *ngFor="let e of fieldErrors('nextPmtDue')">
                    {{ e.params }}
                  </mat-error>
                  <mat-hint></mat-hint>
                </mat-form-field>
              </div>-->
            </div>
          </mat-card-content>
        </mat-card>
        <div class="mt-2">
          <button type="button" mat-button matStepperPrevious>&laquo; Back</button>
          <button type="button" mat-button matStepperNext>Next &raquo;</button>
        </div>
      </mat-step>

      <mat-step formGroupName="3" [stepControl]="formArray?.get([3])!"
                *ngIf="!this.isLessThan90Days && (this.addSpouseInfo || this.addChildren)">
        <ng-template matStepLabel>Dependent Information</ng-template>

        <mat-card fxFlex="100" class="mt-3">
          <!--<mat-card-header>
            <mat-card-subtitle>Beneficiaries</mat-card-subtitle>
          </mat-card-header>-->

          <mat-card-content>

            <div fxLayout="column" fxFlex="100">

              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100"
                   *ngIf="this.isMarried.value == '1'">
                <mat-form-field appearance="fill" fxFlex.gt-sm="25">
                  <mat-label>Spouse First Name <span class="error-color">*</span></mat-label>
                  <input matInput formControlName="spouseFirstName" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="spouseFirstName.touched && spouseFirstName.hasError('required')">
                    Spouse First Name is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('spouseFirstName')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="25">
                  <mat-label>Spouse Last Name <span class="error-color">*</span></mat-label>
                  <input matInput formControlName="spouseLastName" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="spouseLastName.touched && spouseLastName.hasError('required')">
                    Spouse Last Name is <strong>required</strong>
                  </mat-error>
                  <mat-error *ngFor="let e of fieldErrors('spouseLastName')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="25">
                  <mat-label>Spouse Middle Name</mat-label>
                  <input matInput formControlName="spouseMiddleName" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="spouseMiddleName.touched && spouseMiddleName.hasError('required')">
                    Spouse Middle Name is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('spouseMiddleName')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="25">
                  <mat-label>Spouse SSN [xxx-xx-xxxx]</mat-label>
                  <input matInput formControlName="spouseSSN" mask="000-00-0000" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="spouseSSN.touched && spouseSSN.hasError('required')">
                    Spouse SSN is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('spouseSSN')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="20">
                  <mat-label>Spouse Gender <span class="error-color">*</span></mat-label>
                  <mat-select formControlName="spouseGender">
                    <mat-option value="Male">Male</mat-option>
                    <mat-option value="Female">Female</mat-option>
                  </mat-select>
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="spouseGender.touched && spouseGender.hasError('required')">
                    Spouse Gender is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('spouseGender')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100"
                   *ngIf="this.isMarried.value == '1'">

                <mat-form-field appearance="fill" fxFlex.gt-sm="15">
                  <mat-label>Spouse DOB <span class="error-color">*</span></mat-label>
                  <input matInput [matDatepicker]="spouseDOBpicker" formControlName="spouseDOB"
                         (dateChange)="spouseDobChange($event.value)" [max]="today" />
                  <mat-datepicker-toggle matSuffix [for]="spouseDOBpicker"></mat-datepicker-toggle>
                  <mat-datepicker #spouseDOBpicker></mat-datepicker>
                  <mat-error *ngFor="let e of fieldErrors('spouseDOB')">
                    {{ e.params }}
                  </mat-error>
                  <mat-hint></mat-hint>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="10">
                  <mat-label>Spouse Age</mat-label>
                  <input matInput formControlName="spouseAge" type="number" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="spouseAge.touched && spouseAge.hasError('required')">
                    Spouse Age is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('spouseAge')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>


                <mat-form-field appearance="fill" fxFlex.gt-sm="10">
                  <mat-label>Spouse Height (Feet) <span class="error-color">*</span></mat-label>
                  <input matInput formControlName="spouseHeightFeet" type="number" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="spouseHeightFeet.touched && spouseHeightFeet.hasError('required')">
                    Spouse Height Feet is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('spouseHeightFeet')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="10">
                  <mat-label>Spouse Height (Inches) <span class="error-color">*</span></mat-label>
                  <input matInput formControlName="spouseHeightInch" type="number" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="spouseHeightInch.touched && spouseHeightInch.hasError('required')">
                    Spouse Height Inch is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('spouseHeightInch')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="15">
                  <mat-label>Spouse Weight (In LBS) <span class="error-color">*</span></mat-label>
                  <input matInput formControlName="spouseWeightInLBS" type="number" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="spouseWeightInLBS.touched && spouseWeightInLBS.hasError('required')">
                    Spouse Weight (In LBS) is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('spouseWeightInLBS')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="15">
                  <mat-label>Spouse Phone [(xxx) xxx-xxxx] </mat-label>
                  <input matInput formControlName="spousePhone" mask="(000) 000-0000" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="spousePhone.touched && spousePhone.hasError('required')">
                    Spouse Phone is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('spousePhone')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex.gt-sm="25">
                  <mat-label>Spouse Email</mat-label>
                  <input matInput formControlName="spouseEmail" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="spouseEmail.touched && spouseEmail.hasError('required')">
                    Spouse Email is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('spouseEmail')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

              </div>

              <!--<div fxLayout="row" fxLayoutGap="8px" fxFlex="100">

                <mat-form-field appearance="fill" fxFlex="15">
                  <mat-label># Of Children</mat-label>
                  <mat-select formControlName="noOfChildren">
                    <mat-option value="1">1</mat-option>
                    <mat-option value="2">2</mat-option>
                    <mat-option value="3">3</mat-option>
                    <mat-option value="4">4</mat-option>
                    <mat-option value="5">5</mat-option>
                    <mat-option value="6">6</mat-option>
                    <mat-option value="7">7</mat-option>
                    <mat-option value="8">8</mat-option>
                    <mat-option value="9">9</mat-option>
                    <mat-option value="10">10</mat-option>
                  </mat-select>
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="noOfChildren.touched && noOfChildren.hasError('required')">
                    Please <strong>answer</strong> this question
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('noOfChildren')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>
              </div>-->
              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100"
                   *ngIf="this.addChildren">
                List all children under 21, or 25 if a full-time student.
              </div>
              <div fxLayout="row" fxLayoutGap="8px" fxFlex="100" *ngIf="this.addChildren">
                <input type="hidden" formControlName="newApplicationPersonChild" />
                <div fxFlex="100">
                  <input type="hidden" formControlName="newApplicationAddChildren" />
                  <div class="mat-error" *ngIf="newApplicationAddChildren.errors?.min">

                    Please add at-least one dependent.
                    <!--{{ theForm.get('newApplicationAddChildren').value }}-->
                  </div>
                  <mat-hint></mat-hint>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="8px" fxFlex="100" *ngIf="this.addChildren">
                <app-newapplicationpersonchild-list></app-newapplicationpersonchild-list>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <div class="mt-2">
          <button type="button" mat-button matStepperPrevious>&laquo; Back</button>
          <button type="button" mat-button matStepperNext>Next &raquo;</button>
        </div>
      </mat-step>

      <mat-step formGroupName="4" [stepControl]="formArray?.get([4])!" *ngIf="!this.isLessThan90Days">
        <ng-template matStepLabel>Statement of Health</ng-template>

        <mat-card fxFlex="100" class="mt-3">
          <mat-card-content>
            <div fxLayout="column" fxFlex="100">
              <div fxLayout="column" fxFlex="100" fxLayoutGap="8px">
                <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100">
                  <div fxFlex="70">
                    &nbsp;
                  </div>
                  <div fxFlex="15">
                    Member
                  </div>
                  <div fxFlex="15" *ngIf="this.addSpouseInfo">
                    Spouse
                  </div>
                </div>

                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">

                  <div fxFlex="70">
                    In the past 10 years, has any Applicant been diagnosed, advised, or treated by a physician or health
                    advisor for the listed
                    conditions: Heart attack, coronary artery disease, or any heart disorder, stroke, high blood
                    pressure, blood or circulatory
                    disorder, diabetes, cancer, tumor, chronic obstructive pulmonary disease (COPD) or any lung or
                    respiratory disorder, liver
                    disorder, alcohol or drug abuse, kidney disorder, disorder of the pancreas, paralysis, epilepsy, or
                    mental, nervous or
                    emotional disorder? *
                  </div>

                  <div fxFlex="15">
                    <label class="k-form-field">
                      <input type="radio" id="last10YearsDiagnosedYes" value="1" class="k-checkboxX"
                             formControlName="last10YearsDiagnosed">
                      <label class="k-checkbox-label" for="last10YearsDiagnosedYes">Yes</label>
                    </label>
                    <label class="k-form-field ms-2">
                      <input type="radio" id="last10YearsDiagnosedNo" value="0" class="k-checkboxX"
                             formControlName="last10YearsDiagnosed">
                      <label class="k-checkbox-label" for="last10YearsDiagnosedNo">No</label>
                    </label>
                    <mat-error *ngIf="last10YearsDiagnosed.hasError('required')">
                      Please <strong>answer</strong> this question
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('last10YearsDiagnosed')">
                      {{ e.params }}
                    </mat-error>
                  </div>
                  <div fxFlex="15" *ngIf="this.addSpouseInfo">
                    <label class="k-form-field">
                      <input type="radio" id="spouseLast10YearsDiagnosedYes" value="1" class="k-checkboxX"
                             formControlName="spouseLast10YearsDiagnosed">
                      <label class="k-checkbox-label" for="spouseLast10YearsDiagnosedYes">Yes</label>
                    </label>
                    <label class="k-form-field ms-2">
                      <input type="radio" id="spouseLast10YearsDiagnosedNo" value="0" class="k-checkboxX"
                             formControlName="spouseLast10YearsDiagnosed">
                      <label class="k-checkbox-label" for="spouseLast10YearsDiagnosedNo">No</label>
                    </label>
                    <mat-error *ngIf="spouseLast10YearsDiagnosed.hasError('required')">
                      Please <strong>answer</strong> this question
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('spouseLast10YearsDiagnosed')">
                      {{ e.params }}
                    </mat-error>
                  </div>
                </div>


                <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100">
                  <div fxFlex="70">
                    In the past 5 years, has any Applicant been admitted or confined to any hospital or medical
                    treatment facility or consulted a
                    physician or health advisor for any disease not listed above, or been advised to have any surgical
                    operation or diagnostic
                    tests (excluding genetic tests and screenings)? *
                  </div>
                  <div fxFlex="15">
                    <label class="k-form-field">
                      <input type="radio" id="last5YearAdmittedYes" value="1" class="k-checkboxX"
                             formControlName="last5YearAdmitted">
                      <label class="k-checkbox-label" for="last5YearAdmittedYes">Yes</label>
                    </label>
                    <label class="k-form-field ms-2">
                      <input type="radio" id="last5YearAdmittedNo" value="0" class="k-checkboxX"
                             formControlName="last5YearAdmitted">
                      <label class="k-checkbox-label" for="last5YearAdmittedNo">No</label>
                    </label>
                    <mat-error *ngIf="last5YearAdmitted.hasError('required')">
                      Please <strong>answer</strong> this question
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('last5YearAdmitted')">
                      {{ e.params }}
                    </mat-error>
                  </div>
                  <div fxFlex="15" *ngIf="this.addSpouseInfo">
                    <label class="k-form-field">
                      <input type="radio" id="spouseLast5YearAdmittedYes" value="1" class="k-checkboxX"
                             formControlName="spouseLast5YearAdmitted">
                      <label class="k-checkbox-label" for="spouseLast5YearAdmittedYes">Yes</label>
                    </label>
                    <label class="k-form-field ms-2">
                      <input type="radio" id="spouseLast5YearAdmittedNo" value="0" class="k-checkboxX"
                             formControlName="spouseLast5YearAdmitted">
                      <label class="k-checkbox-label" for="spouseLast5YearAdmittedNo">No</label>
                    </label>
                    <mat-error *ngIf="spouseLast5YearAdmitted.hasError('required')">
                      Please <strong>answer</strong> this question
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('spouseLast5YearAdmitted')">
                      {{ e.params }}
                    </mat-error>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100">
                  <div fxFlex="70">
                    Has any Applicant ever been diagnosed or treated by a physician or tested positive for Human
                    Immunodeficiency Virus (HIV),
                    Acquired Immunodeficiency Syndrome (AIDS), or AIDS-Related Complex (ARC)? *
                  </div>
                  <div fxFlex="15">
                    <label class="k-form-field">
                      <input type="radio" id="everDignosedForAidsYes" value="1" class="k-checkboxX"
                             formControlName="everDignosedForAids">
                      <label class="k-checkbox-label" for="everDignosedForAidsYes">Yes</label>
                    </label>
                    <label class="k-form-field ms-2">
                      <input type="radio" id="everDignosedForAidsNo" value="0" class="k-checkboxX"
                             formControlName="everDignosedForAids">
                      <label class="k-checkbox-label" for="everDignosedForAidsNo">No</label>
                    </label>
                    <mat-error *ngIf="everDignosedForAids.hasError('required')">
                      Please <strong>answer</strong> this question
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('everDignosedForAids')">
                      {{ e.params }}
                    </mat-error>
                  </div>
                  <div fxFlex="15" *ngIf="this.addSpouseInfo">
                    <label class="k-form-field">
                      <input type="radio" id="spouseEverDignosedForAidsYes" value="1" class="k-checkboxX"
                             formControlName="spouseEverDignosedForAids">
                      <label class="k-checkbox-label" for="spouseEverDignosedForAidsYes">Yes</label>
                    </label>
                    <label class="k-form-field ms-2">
                      <input type="radio" id="spouseEverDignosedForAidsNo" value="0" class="k-checkboxX"
                             formControlName="spouseEverDignosedForAids">
                      <label class="k-checkbox-label" for="spouseEverDignosedForAidsNo">No</label>
                    </label>
                    <mat-error *ngIf="spouseEverDignosedForAids.hasError('required')">
                      Please <strong>answer</strong> this question
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('spouseEverDignosedForAids')">
                      {{ e.params }}
                    </mat-error>
                  </div>
                </div>

                <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100">
                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>
                      For each Applicant list any prescribed medication taken regularly or frequently
                    </mat-label>
                    <input matInput formControlName="listPrescribedMedication" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="listPrescribedMedication.touched && listPrescribedMedication.hasError('required')">
                      List Prescribed Medication is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('listPrescribedMedication')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                </div>
              </div>


            </div>
          </mat-card-content>
        </mat-card>
        <div class="mt-2">
          <button type="button" mat-button matStepperPrevious>&laquo; Back</button>
          <button type="button" mat-button matStepperNext>Next &raquo;</button>
        </div>
      </mat-step>


      <!-- <mat-step>
        <ng-template matStepLabel>Review</ng-template>
        <div fxLayout="column" fxFlex="100">

          <mat-card fxFlex="100" class="mt-3">
            <mat-card-header>
              <mat-card-subtitle>Enlistment Date</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
              <div fxLayout="row" fxFlex="100" fxLayoutGap="8px">
                <mat-form-field appearance="fill" fxFlex="15">
                  <mat-label>Enlistment Month</mat-label>
                  <input matInput value="{{ this.enlistmentDateMonth.value }}" readonly />
                  <mat-hint></mat-hint>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="15">
                  <mat-label>Enlistment Year</mat-label>
                  <input matInput value="{{ this.enlistmentDateYear.value }}" readonly />
                  <mat-hint></mat-hint>
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>


          <mat-card fxFlex="100" class="mt-3">
            <mat-card-header>
              <mat-card-subtitle>National Guard Member Information</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
              <div fxLayout="column" fxFlex="100">
                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">

                  <mat-form-field appearance="fill" fxFlex="15">
                    <mat-label>Last Name</mat-label>
                    <input matInput value="{{ this.lastName.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="15">
                    <mat-label>First Name</mat-label>
                    <input matInput value="{{ this.firstName.value }}" readonly />
                  </mat-form-field>


                  <mat-form-field appearance="fill" fxFlex="15">
                    <mat-label>Middle Name</mat-label>
                    <input matInput value="{{ this.middleName.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="10">
                    <mat-label>Rank</mat-label>
                    <input matInput value="{{ this.rank.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="15">
                    <mat-label>SSN</mat-label>
                    <input matInput value="{{ this.ssn.value }}" readonly />
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                  <mat-form-field appearance="fill" fxFlex="10">
                    <mat-label>DOB</mat-label>
                    <input matInput value="{{ this.dob.value | date:'shortDate' }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="10">
                    <mat-label>Age</mat-label>
                    <input matInput value="{{ this.age.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="10" *ngIf="!this.isLessThan90Days">
                    <mat-label>Height Feet <span class="error-color">*</span></mat-label>
                    <input matInput value="{{ this.heightFeet.value }}" type="number" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="10" *ngIf="!this.isLessThan90Days">
                    <mat-label>Height inch <span class="error-color">*</span></mat-label>
                    <input matInput value="{{ this.heightInch.value }}" type="number" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="15" *ngIf="!this.isLessThan90Days">
                    <mat-label>Weight (In LBS) <span class="error-color">*</span></mat-label>
                    <input matInput value="{{ this.weightInLBS.value }}" type="number" readonly />
                  </mat-form-field>

                </div>

                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100" *ngIf="!this.isLessThan90Days">
                  <mat-form-field appearance="fill" fxFlex="50">
                    <mat-label>Suffix Name</mat-label>
                    <input matInput value="{{ this.suffixName.value }}" readonly />
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                  <mat-form-field appearance="fill" fxFlex="20">
                    <mat-label>Gender <span class="error-color">*</span></mat-label>
                    <input matInput value="{{ this.gender.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="15" *ngIf="!this.isLessThan90Days">
                    <mat-label>Married? <span class="error-color">*</span></mat-label>
                    <input matInput value="{{ this.isMarried.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="10" *ngIf="!this.isLessThan90Days">
                    <mat-label>Duty Status <span class="error-color">*</span></mat-label>
                    <input matInput value="{{ this.dutyStatusAGR.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="10">
                    <mat-label>Branch Of Service <span class="error-color">*</span></mat-label>
                    <input matInput value="{{ this.branchOfService.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="20">
                    <mat-label>Unit</mat-label>
                    <input matInput value="{{ this.nameOfUnit.value }}" readonly />
                  </mat-form-field>

                </div>

                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Address1</mat-label>
                    <input matInput value="{{ this.address1.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Address2</mat-label>
                    <input matInput value="{{ this.address2.value }}" readonly />
                  </mat-form-field>
                  <div fxFlex="5">
                    &nbsp;
                  </div>

                  <mat-form-field appearance="fill" fxFlex="20">
                    <mat-label>Primary Phone</mat-label>
                    <input matInput value="{{ this.primaryPhone.value }}" readonly />
                    <mat-hint></mat-hint>
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                  <mat-form-field appearance="fill" fxFlex="20">
                    <mat-label>City</mat-label>
                    <input matInput value="{{ this.city.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="20">
                    <mat-label>State</mat-label>
                    <input matInput value="{{ this.state.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="10">
                    <mat-label>Zip</mat-label>
                    <input matInput value="{{ this.zip.value }}" readonly />
                  </mat-form-field>

                  <div fxFlex="5">
                    &nbsp;
                  </div>

                  <mat-form-field appearance="fill" fxFlex="20">
                    <mat-label>Secondary Phone</mat-label>
                    <input matInput value="{{ this.secondaryPhone.value }}" readonly />
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">

                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Civilian Email</mat-label>
                    <input matInput value="{{ this.email.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="25" *ngIf="!this.isLessThan90Days">
                    <mat-label>Military Email</mat-label>
                    <input matInput value="{{ this.militaryEmail.value }}" readonly />
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                  <mat-form-field appearance="fill" fxFlex="20">
                    <mat-label>DOD Id</mat-label>
                    <input matInput value="{{ this.dodId.value }}" readonly />
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Volunteer</mat-label>
                    <input matInput value="{{ this.volunteerName }}" readonly />
                  </mat-form-field>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card fxFlex="100" class="mt-3">
            <mat-card-header>
              <mat-card-subtitle>Beneficiaries</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
              <div fxLayout="column" fxFlex="100">
                <table mat-table [dataSource]="this.list" class="mat-elevation-z8 noScreen onExport">
                  <ng-container matColumnDef="beneficiary">
                    <th mat-header-cell *matHeaderCellDef> Beneficiary </th>
                    <td mat-cell *matCellDef="let element"> {{ element.beneficiary}} </td>
                  </ng-container>

                  <ng-container matColumnDef="relationship">
                    <th mat-header-cell *matHeaderCellDef> Relationship </th>
                    <td mat-cell *matCellDef="let element"> {{ element.relationship }} </td>
                  </ng-container>

                  <ng-container matColumnDef="percentage">
                    <th mat-header-cell *matHeaderCellDef> Percentage </th>
                    <td mat-cell *matCellDef="let element"> {{ element.percentage }} </td>
                  </ng-container>

                  <ng-container matColumnDef="beneficiaryDate">
                    <th mat-header-cell *matHeaderCellDef> Beneficiary Date </th>
                    <td mat-cell *matCellDef="let element"> {{ element.beneficiaryDate | date:'shortDate' }} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card fxFlex="100" *ngIf="!this.isLessThan90Days">
            <mat-card-header>
              <mat-card-subtitle>Coverage</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
              <div fxLayout="column" fxFlex="100">
                <mat-form-field appearance="fill" fxFlex="100">
                  <mat-label>Coverage Type</mat-label>
                  <input matInput value="{{ this.coverageName }}" readonly />
                </mat-form-field>

                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                  <mat-form-field appearance="fill" fxFlex="30">
                    <mat-label>Member Coverage</mat-label>
                    <input matInput value="{{ this.memberCoverageName }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Premium</mat-label>
                    <input matInput value="{{ this.premium.value }}" readonly />
                  </mat-form-field>


                </div>
                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                  <mat-form-field appearance="fill" fxFlex="30">
                    <mat-label>Spouse Coverage</mat-label>
                    <input matInput value="{{ this.spouseCoverageName }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Spouse Premium</mat-label>
                    <input matInput value="{{ this.spousePremium.value }}" readonly />
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">

                  <mat-form-field appearance="fill" fxFlex="30">
                    <mat-label>Dependent Coverage</mat-label>
                    <input matInput value="{{ this.dependentCoverageName }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Dependent Premium</mat-label>
                    <input matInput value="{{ this.dependentPremium.value }}" readonly />
                  </mat-form-field>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card fxFlex="100" *ngIf="!this.isLessThan90Days">
            <mat-card-header>
              <mat-card-subtitle>Dependent Information</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>

              <div fxLayout="column" fxFlex="100">

                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Spouse First Name <span class="error-color">*</span></mat-label>
                    <input matInput value="{{ this.spouseFirstName.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Spouse Last Name <span class="error-color">*</span></mat-label>
                    <input matInput value="{{ this.spouseLastName.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Spouse Middle Name</mat-label>
                    <input matInput value="{{ this.spouseMiddleName.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="15">
                    <mat-label>Spouse SSN <span class="error-color">*</span></mat-label>
                    <input matInput value="{{ this.spouseSSN.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="20">
                    <mat-label>Spouse Gender <span class="error-color">*</span></mat-label>
                    <input matInput value="{{ this.spouseGender.value }}" readonly />
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">

                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Spouse DOB <span class="error-color">*</span></mat-label>
                    <input matInput value="{{ this.spouseDOB.value | date:'shortDate' }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="15">
                    <mat-label>Spouse Age</mat-label>
                    <input matInput value="{{ this.spouseAge.value }}" readonly />
                  </mat-form-field>


                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Spouse Height Feet <span class="error-color">*</span></mat-label>
                    <input matInput value="{{ this.spouseHeightFeet.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Spouse Height inch <span class="error-color">*</span></mat-label>
                    <input matInput value="{{ this.spouseHeightInch.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Spouse Weight (In LBS)<span class="error-color">*</span>*</mat-label>
                    <input matInput value="{{ this.spouseWeightInLBS.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Spouse Phone <span class="error-color">*</span></mat-label>
                    <input matInput value="{{ this.spousePhone.value }}" readonly />
                  </mat-form-field>

                </div>

                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Spouse Email <span class="error-color">*</span></mat-label>
                    <input matInput value="{{ this.spouseEmail.value }}" readonly />
                  </mat-form-field>
                </div>

                <p fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                  List all children under 21, or 25 if a full-time student.
                </p>
                <table mat-table [dataSource]="this.listPersonChild" class="mat-elevation-z8 noScreen onExport">
                  <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef> First Name </th>
                    <td mat-cell *matCellDef="let element"> {{ element.firstName}} </td>
                  </ng-container>

                  <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef> Last Name </th>
                    <td mat-cell *matCellDef="let element"> {{ element.lastName}} </td>
                  </ng-container>

                  <ng-container matColumnDef="middleName">
                    <th mat-header-cell *matHeaderCellDef> Middle Name </th>
                    <td mat-cell *matCellDef="let element"> {{ element.middleName}} </td>
                  </ng-container>

                  <ng-container matColumnDef="dob">
                    <th mat-header-cell *matHeaderCellDef> Date of Birth </th>
                    <td mat-cell *matCellDef="let element"> {{ element.dob | date:'shortDate' }} </td>
                  </ng-container>

                  <ng-container matColumnDef="gender">
                    <th mat-header-cell *matHeaderCellDef> Gender </th>
                    <td mat-cell *matCellDef="let element"> {{ element.gender}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsPersonChild"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsPersonChild;"></tr>
                </table>

                <div fxLayout="column" fxFlex="100" fxLayoutGap="8px">
                  <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                    <div fxFlex="70">
                      &nbsp;
                    </div>
                    <div fxFlex="15">
                      Member
                    </div>
                    <div fxFlex="15">
                      Spouse
                    </div>
                  </div>

                  <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">

                    <div fxFlex="70">
                      In the past 10 years, has any Applicant been diagnosed, advised, or treated by a physician or
                      health advisor for the listed
                      conditions: Heart attack, coronary artery disease, or any heart disorder, stroke, high blood
                      pressure, blood or circulatory
                      disorder, diabetes, cancer, tumor, chronic obstructive pulmonary disease (COPD) or any lung or
                      respiratory disorder, liver
                      disorder, alcohol or drug abuse, kidney disorder, disorder of the pancreas, paralysis, epilepsy,
                      or mental, nervous or
                      emotional disorder? *
                    </div>

                    <div fxFlex="15">
                      <label class="k-form-field">
                        <input type="radio" [checked]="this.last10YearsDiagnosed.value == '1'" class="k-checkboxX"
                          disabled />
                        <label class="k-checkbox-label">Yes</label>
                      </label>
                      <label class="k-form-field">
                        <input type="radio" [checked]="this.last10YearsDiagnosed.value != '1'" class="k-checkboxX"
                          disabled>
                        <label class="k-checkbox-label">No</label>
                      </label>
                    </div>
                    <div fxFlex="15">
                      <label class="k-form-field">
                        <input type="radio" [checked]="this.spouseLast10YearsDiagnosed.value == '1'" class="k-checkboxX"
                          disabled>
                        <label class="k-checkbox-label">Yes</label>
                      </label>
                      <label class="k-form-field">
                        <input type="radio" [checked]="this.spouseLast10YearsDiagnosed.value != '1'" class="k-checkboxX"
                          disabled>
                        <label class="k-checkbox-label">No</label>
                      </label>
                    </div>
                  </div>


                  <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                    <div fxFlex="70">
                      In the past 5 years, has any Applicant been admitted or confined to any hospital or medical
                      treatment facility or consulted a
                      physician or health advisor for any disease not listed above, or been advised to have any surgical
                      operation or diagnostic
                      tests (excluding genetic tests and screenings)? *
                    </div>
                    <div fxFlex="15">
                      <label class="k-form-field">
                        <input type="radio" [checked]="this.last5YearAdmitted.value == '1'" class="k-checkboxX" disabled>
                        <label class="k-checkbox-label">Yes</label>
                      </label>
                      <label class="k-form-field">
                        <input type="radio" [checked]="this.last5YearAdmitted.value != '1'" class="k-checkboxX" disabled>
                        <label class="k-checkbox-label">No</label>
                      </label>
                    </div>
                    <div fxFlex="15">
                      <label class="k-form-field">
                        <input type="radio" [checked]="this.spouseLast5YearAdmitted.value == '1'" class="k-checkboxX"
                          disabled>
                        <label class="k-checkbox-label">Yes</label>
                      </label>
                      <label class="k-form-field">
                        <input type="radio" [checked]="this.spouseLast5YearAdmitted.value != '1'" class="k-checkboxX"
                          disabled>
                        <label class="k-checkbox-label">No</label>
                      </label>
                    </div>
                  </div>

                  <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                    <div fxFlex="70">
                      Has any Applicant ever been diagnosed or treated by a physician or tested positive for Human
                      Immunodeficiency Virus (HIV),
                      Acquired Immunodeficiency Syndrome (AIDS), or AIDS-Related Complex (ARC)? *
                    </div>
                    <div fxFlex="15">
                      <label class="k-form-field">
                        <input type="radio" [checked]="this.everDignosedForAids.value == '1'" class="k-checkboxX"
                          disabled>
                        <label class="k-checkbox-label">Yes</label>
                      </label>
                      <label class="k-form-field">
                        <input type="radio" [checked]="this.everDignosedForAids.value != '1'" class="k-checkboxX"
                          disabled>
                        <label class="k-checkbox-label">No</label>
                      </label>
                    </div>
                    <div fxFlex="15">
                      <label class="k-form-field">
                        <input type="radio" [checked]="this.spouseEverDignosedForAids.value == '1'" class="k-checkboxX"
                          disabled>
                        <label class="k-checkbox-label">Yes</label>
                      </label>
                      <label class="k-form-field">
                        <input type="radio" [checked]="this.spouseEverDignosedForAids.value != '1'" class="k-checkboxX"
                          disabled>
                        <label class="k-checkbox-label">No</label>
                      </label>
                    </div>
                  </div>

                  <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                    <mat-form-field appearance="fill" fxFlex="100">
                      <mat-label>
                        For each Applicant list any prescribed medication taken regularly or frequently
                      </mat-label>
                      <input matInput value="{{ this.listPrescribedMedication.value }}" readonly />
                    </mat-form-field>

                  </div>
                </div>
                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Sign City</mat-label>
                    <input matInput value="{{ this.signCity.value }}" readonly />
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Sign State</mat-label>
                    <input matInput value="{{ this.signState.value }}" readonly />
                  </mat-form-field>
                </div>
              </div>

            </mat-card-content>
          </mat-card>




        </div>

      </mat-step> -->

      <mat-step formGroupName="5" [stepControl]="formArray?.get([5])!">
        <ng-template matStepLabel>Review</ng-template>

        <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="100">
          <mat-form-field appearance="fill" fxFlex="100">
            <mat-label>Current City</mat-label>
            <input matInput formControlName="signCity" />
            <mat-hint></mat-hint>
            <mat-error *ngIf="signCity.touched && signCity.hasError('required')">
              Current City is <strong>required</strong>
            </mat-error>

            <mat-error *ngFor="let e of fieldErrors('signCity')">
              {{ e.params }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" fxFlex="100">
            <mat-label>Current State</mat-label>
            <input matInput formControlName="signState" />
            <mat-hint></mat-hint>
            <mat-error *ngIf="signState.touched && signState.hasError('required')">
              Current State is <strong>required</strong>
            </mat-error>

            <mat-error *ngFor="let e of fieldErrors('signState')">
              {{ e.params }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="100">
          <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
            <button mat-raised-button color="primary" type="submit" mat-button [disabled]="saving">
              Submit Application
            </button>
          </div>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
</div>

<style>
  .k-checkbox:indeterminate,
  .k-checkbox.k-state-indeterminate,
  .k-checkbox.k-indeterminate {
    /*border-color: unset;*/
    background-color: unset;
  }

  .k-checkbox {
    width: 20px;
    height: 20px;
  }

  .error-color {
    color: red;
  }
</style>
