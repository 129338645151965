import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';
import { MediaObserver } from "@angular/flex-layout";
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { catchError, switchMap, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { NavigatorService } from '../navigator.service';
import { AuthenticationService } from '../../../auth/auth.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Filter } from '../../../_bases/filter';
import { typeModel } from '../../../_bases/typeModel';
import { MembershipData } from './membership-data';
import { ImportMembershipModalComponent } from '../import-membership-modal/import-membership-modal.component';

@Component({
  selector: 'app-membership-data',
  templateUrl: './membership-data.component.html',
  animations: [
  ]
})
export class MembershipDataComponent implements OnInit {

  @ViewChild(GridComponent)
  public grdMembershipData!: GridComponent;

  membershipType: string | undefined;
  list: MembershipData[] = [];
  filteredList: MembershipData[] = [];
  protected ngUnsubscribe: Subject<boolean> = new Subject();
  loading: boolean = true;

  displayedColumns: string[] = ['record', 'lastName', 'firstName', 'middleName', 'suffixName', 'address1', 'address2',
    'city', 'state', 'zip', 'primaryPhone', 'secondaryPhone', 'email', 'dob', 'actions'];

  selectedValues: any = {};
  selectedCommonId: number | undefined;
  distinctCommonIds: any[] = [];

  listMembershipData = new FormGroup({
    membershipType: new FormControl('EANGUS')
  });

  constructor(
    private service: NavigatorService,
    public media: MediaObserver,
    public authService: AuthenticationService,
    public datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {

  }

  ngOnInit(): void {

    this.loadMembershipRawList();

    this.listMembershipData.get('membershipType')?.valueChanges.subscribe(value => {
      this.loadMembershipRawList();
    });
  }

  uploadNewFile() {
    let dialogRef = this.dialog.open(ImportMembershipModalComponent, {
      data: { membershipType: this.membershipType, hasRecords: this.list.length > 0 },
      width: "420px"
    });

    dialogRef.afterClosed().subscribe((iscontinue: boolean) => {
      if (iscontinue == true) {
        this.loadMembershipRawList();
      }
    });
  }

  loadMembershipRawList() {

    this.membershipType = this.listMembershipData.get('membershipType')?.value;
    this.loading = true;
    this.service
      .getByCustomAction$<MembershipData>("getMembershipUnProcessedData", MembershipData, [new Filter('membershipType', this.membershipType, "eq")])
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe((data: any) => {

        this.list = data;
        this.loading = false;

        if (this.list.length > 0) {
          this.selectedCommonId = this.list[0].commonId;
          this.getFilteredRecords();

          this.distinctCommonIds = [...new Set(data.map((item: MembershipData) => item.commonId))];
        }
        else {
          this.filteredList = [];
          this.distinctCommonIds = [];
          this.selectedValues = {};
        }
      });
  }

  getFilteredRecords() {
    this.filteredList = this.list.filter(r => r.commonId === this.selectedCommonId);

    if (this.filteredList.length > 0) {
      this.selectedValues =
      {
        personId: this.filteredList.filter(r => r.record === 'Person')[0].id,
        membershipType: this.membershipType,
        dataSyncPersonId: this.filteredList.filter(r => r.record === this.membershipType)[0].id,
        address1: this.filteredList[0].address1,
        address2: this.filteredList[0].address2,
        city: this.filteredList[0].city,
        state: this.filteredList[0].state,
        zip: this.filteredList[0].zip,
        primaryPhone: this.filteredList[0].primaryPhone,
        secondaryPhone: this.filteredList[0].secondaryPhone,
        email: this.filteredList[0].email,
        dob: this.filteredList[0].dob,
      }
    }
  }

  onRadioChange(commonId: string, field: string, value: any) {
    this.selectedValues[field] = value
  }

  isDifferent(field: keyof MembershipData): boolean {
    if (this.list.length > 1) {
      const firstItem = this.list[0];
      const secondItem = this.list[1];
      if (firstItem[field] !== undefined && secondItem[field] !== undefined) {
        if (firstItem[field] instanceof Date && secondItem[field] instanceof Date) {
          return new Date(firstItem[field]?.toString() ?? '').getTime() !== new Date(secondItem[field]?.toString() ?? '').getTime();
        }
        else {
          return firstItem[field] !== secondItem[field];
        }
      }
    }
    return false;
  }

  createNewPersonRecord(element: MembershipData) {
    console.log('Editing element:', element.id);
    const tymodel = new typeModel<any>(element.id);
    this.service
      .postByCustomAction$<any>("createNewPersonAndMembership", tymodel)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe((data: any) => {
        this.loadMembershipRawList();
      });
  }

  updateExistingPersonRecord() {
    console.log(this.selectedValues);

    const tymodel = new typeModel<any>(this.selectedValues);

    this.service
      .postByCustomAction$<any>("updatePersonAndAddMembership", tymodel)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe((data: any) => {
        this.loadMembershipRawList();
      });
  }


  previous() {
    const currentIndex = this.distinctCommonIds.indexOf(this.selectedCommonId);
    if (currentIndex > 0) {
      this.selectedCommonId = this.distinctCommonIds[currentIndex - 1];

      this.getFilteredRecords();
    }
  }

  next() {
    const currentIndex = this.distinctCommonIds.indexOf(this.selectedCommonId);
    if (currentIndex < this.distinctCommonIds.length - 1) {
      this.selectedCommonId = this.distinctCommonIds[currentIndex + 1];

      this.getFilteredRecords();
    }
  }

  isFirst(): boolean {
    return this.selectedCommonId === this.distinctCommonIds[0];
  }

  isLast(): boolean {
    return this.selectedCommonId === this.distinctCommonIds[this.distinctCommonIds.length - 1];
  }

  public rowCallback(context: RowClassArgs) {
    return {
    };
  }

}
