<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <button mat-raised-button color="accent" (click)="pdf.saveAs('MemberDetail.pdf')">
      Save As PDF...
    </button>
  </div>

  <div fxLayout="column" fxLayoutGap="8px" class="m-2">
    <div fxFlex="100" *ngIf="this.list">
      Page {{ index + 1 }} of {{ this.list.length }}

      <button mat-raised-button (click)="moveToPage(0)" [disabled]="index == 0">
        <mat-icon aria-label="Side nav toggle icon">first_page</mat-icon>
      </button>

      <button mat-raised-button (click)="moveNextPrev(-1)" [disabled]="index == 0">
        <mat-icon aria-label="Side nav toggle icon">chevron_left</mat-icon>
      </button>

      <button mat-raised-button (click)="moveNextPrev(1)" [disabled]="index == this.list.length - 1">
        <mat-icon aria-label="Side nav toggle icon">chevron_right</mat-icon>
      </button>

      <button mat-raised-button (click)="moveToPage(this.list.length -1)" [disabled]="index == this.list.length - 1">
        <mat-icon aria-label="Side nav toggle icon">last_page</mat-icon>
      </button>

    </div>
    <div fxLayout="row" fxLayoutGap="8px" class="m-2  main-ctr" #divMain>
      <kendo-pdf-export #pdf paperSize="Letter" margin="0cm" [scale]=".75" forcePageBreak=".page-break" keepTogether=".prevent-split">
        <div class="pageSizeLetter prevent-split" *ngFor="let selectedRow of this.list; let idxMain = index">
          <div fxLayout="column" fxLayoutGap="8px" class="m-2">
            <div fxFlex="100">
              <h1>{{ selectedRow.firstName }} {{ selectedRow.middleName }} {{ selectedRow.lastName }}</h1>
            </div>

            <div fxLayout="row">
              <div fxFlex="20">
                <strong>Address</strong>
              </div>
              <div fxFlex="80">
                {{ selectedRow.address1 }} {{ selectedRow.address2 }}
              </div>
            </div>
            <div fxLayout="row">
              <div fxFlex="20">
                <strong>City</strong>
              </div>
              <div fxFlex="80">
                {{ selectedRow.city }}
              </div>
            </div>
            <div fxLayout="row">
              <div fxFlex="20">
                <strong>State</strong>
              </div>
              <div fxFlex="80">
                {{ selectedRow.state }}
              </div>
            </div>
            <div fxLayout="row">
              <div fxFlex="20">
                <strong>Zip</strong>
              </div>
              <div fxFlex="80">
                {{ selectedRow.zip }}
              </div>
            </div>
            <div fxLayout="row">
              <div fxFlex="20">
                <strong>Primary Phone</strong>
              </div>
              <div fxFlex="80">
                {{ selectedRow.primaryPhone }}
              </div>
            </div>
            <div fxLayout="row">
              <div fxFlex="20">
                <strong>Secondary Phone</strong>
              </div>
              <div fxFlex="80">
                {{ selectedRow.secondaryPhone }}
              </div>
            </div>

            <div fxLayout="row">
              <div fxFlex="20">
                <strong>Email</strong>
              </div>
              <div fxFlex="80">
                {{ selectedRow.email }}
              </div>
            </div>
            <div fxLayout="row">
              <div fxFlex="20">
                <strong>Military Email</strong>
              </div>
              <div fxFlex="80">
                {{ selectedRow.militaryEmail }}
              </div>
            </div>
            <!--<div fxLayout="row">
              <div fxFlex="20">
                <strong>SSN</strong>
              </div>
              <div fxFlex="80">
                {{ selectedRow.ssn }}
              </div>
            </div>-->
            <div fxLayout="row">
              <div fxFlex="20">
                <strong>Life Member #</strong>
              </div>
              <div fxFlex="80">
                {{ selectedRow.lifeMemberNbr }}
              </div>
            </div>
            <div fxLayout="row">
              <div fxFlex="20">
                <strong>Payment Type</strong>
              </div>
              <div fxFlex="80">
                {{ selectedRow.pmtType }}
              </div>
            </div>
            <div fxLayout="row">
              <div fxFlex="20">
                <strong>Unit Code</strong>
              </div>
              <div fxFlex="80">
                {{ selectedRow.unitCode }}
              </div>
            </div>
            <div fxLayout="row">
              <div fxFlex="20">
                <strong>Unit Name</strong>
              </div>
              <div fxFlex="80">
                {{ selectedRow.unitName }}
              </div>
            </div>
            <div fxLayout="row">
              <div fxFlex="20">
                <strong>Coverage Type</strong>
              </div>
              <div fxFlex="80">
                {{ selectedRow.coverageTypeName }}
              </div>
            </div>
            <table width="70%">
              <tr>
                <td width="25">
                  &nbsp;
                </td>
                <td width="25" class="k-text-right">
                  <strong>Coverage Amount</strong>
                </td>
                <td width="25" class="k-text-right">
                  <strong>Premium Amount</strong>
                </td>
                <td width="20" class="k-text-right">
                  <strong>DOB</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Member</strong>
                </td>
                <td class="k-text-right">
                  {{  selectedRow.memberCoverageName | currency }}
                </td>
                <td class="k-text-right">
                  {{  selectedRow.memberPremium | currency }}
                </td>
                <td class="k-text-right">
                  {{  selectedRow.dob | date: 'MM/dd/yyyy' }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Spouse</strong>
                </td>
                <td class="k-text-right">
                  {{  selectedRow.spouseCoverageName | currency }}
                </td>
                <td class="k-text-right">
                  {{  selectedRow.spousePremium | currency }}
                </td>
                <td class="k-text-right">
                  {{  selectedRow.spouseDOB | date: 'MM/dd/yyyy' }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Dependent</strong>
                </td>
                <td class="k-text-right">
                  {{  selectedRow.dependentCoverageName | currency }}
                </td>
                <td class="k-text-right">
                  {{  selectedRow.dependentPremium | currency }}
                </td>
                <td>
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <strong>Total</strong>
                </td>
                <td class="k-text-right">
                  {{  selectedRow.premium | currency }}
                </td>
                <td>
                  &nbsp;
                </td>
              </tr>
            </table>
            
            <div fxLayout="row" fxFlex="60">
              <div fxFlex="25">
                <strong>Beneficiaries</strong>
              </div>
            </div>
            <table width="70%">
              <tr *ngFor="let itm of selectedRow.beneficiaries; let i = index">
                <td width="40%">
                  {{ itm.beneficiary }}
                </td>
                <td width="20%">
                  {{ itm.relationship }}
                </td>
                <td width="20%">
                  {{ itm.percentage }}%
                </td>
                <td width="20%">
                  {{ itm.beneficiaryDate | date: 'MM/dd/yyyy' }}
                </td>
              </tr>
            </table>
            <div fxLayout="row">
              <div fxFlex="100">
                <strong>Comments</strong>
              </div>
            </div>
            <div fxLayout="row">
              <div fxFlex="100" style="white-space: pre-line;">
                {{  selectedRow.comment }}
              </div>
            </div>
            <ng-container *ngIf="selectedRow.commentsList.length > 0">
              <div fxLayout="row" fxFlex="60" *ngFor="let itm of selectedRow.commentsList; let i = index">
                <div fxFlex="100" style="white-space: pre-line;">
                  {{ itm.commentText }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </kendo-pdf-export>
    </div>
  </div>
</div>
