//import { IDto } from "src/app/core/iDto";

export class RelationshipReadOnly { //} implements IDto<Relationship> {

	constructor() 
	constructor(jsonData: RelationshipReadOnly)
	constructor(jsonData?: RelationshipReadOnly) {
		if(jsonData) {
			Object.assign(this, jsonData);
		}
	}

  relationshipName: string | undefined;
  id: number | undefined;

}


