import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../auth/auth.service';
import { FormValidation } from '../../shared/forms/form-validation';
import { BaseItemComponent } from '../../_bases/base-item-component';
import { AmIInsured } from './amiinsured';
import { AmIInsuredModalComponent } from './amiinsured-result-modal.component';
import { AmIInsuredService } from './amiinsured.service';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-amiinsured',
  templateUrl: './amiinsured.component.html',
})
export class AmIInsuredComponent
  extends BaseItemComponent
  implements OnInit, OnDestroy
{
  loading: boolean = false;
  isFromFindMyPolicy: boolean = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    public service: AmIInsuredService,
    public authService: AuthenticationService,
    private dialog: MatDialog,
    private router: Router
  ) {
    super();

    if (this.router.url.endsWith('find-my-policy')) {
      this.isFromFindMyPolicy = true;
    }
  }

  ngOnInit() {
    this.theForm = this.fb.group({
      ssn: ['', Validators.required],
      lastName: ['', Validators.required],
    });
  }

  ngOnChanges() {}

  onSubmit() {
    if (this.theForm?.valid) {
      let saveModel: AmIInsured = new AmIInsured({
        lastName: this.lastName.value,
        ssn: this.ssn.value,
        isInsured: false,
      });

      this.service
        .postModelByCustomAction$<AmIInsured>(
          'amiinsured',
          saveModel,
          AmIInsured
        )
        .pipe(
          catchError((err, caught) => {
            FormValidation.parseSubmitErrors(err, this.theForm);
            return of(null as AmIInsured | any);
          })
        )
        .subscribe((savedData: AmIInsured) => {
          console.log(savedData);
          if (savedData) {

            savedData.isFromFindMyPolicy = this.isFromFindMyPolicy;

            let dialogRef = this.dialog.open(AmIInsuredModalComponent, {
              data: savedData,
              width: '620px',
            });

            dialogRef.afterClosed().subscribe((redirectToNew: boolean) => {
              this.theForm.reset();
            });
          }
        });
    } else {
      FormValidation.validateAllFormFields(this.theForm);
    }
  }

  get ssn(): AbstractControl {
    return this.theForm.get('ssn');
  }
  get lastName(): AbstractControl {
    return this.theForm.get('lastName');
  }
}
