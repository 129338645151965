export class SettingReadOnly {

	constructor() 
  constructor(jsonData: SettingReadOnly)
  constructor(jsonData?: SettingReadOnly) {
		if(jsonData) {
			Object.assign(this, jsonData);
		}
	}

  id: number | undefined;
  notInsuredCoverageTypeId: number | undefined;
  formerMembersGroupId: number | undefined;
  airFormerMembersUnitId: number | undefined;
  armyFormerMembersUnitId: number | undefined;
  nonMemberPaymentTypeId: number | undefined;
}


