
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { BaseItemComponent } from '../../_bases/base-item-component';
import { AuthenticationService } from '../../auth/auth.service';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
})
export class ContactInfoComponent extends BaseItemComponent implements OnInit, OnDestroy {

  loading: boolean = false;

  constructor(
    public authService: AuthenticationService) {
    super();
  }

  ngOnInit() {

  }

  ngOnChanges() {
  }

  
}
