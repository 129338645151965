import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KendoControlsModule } from '../_modules/kendo-controls.module';
import { MaterialControlsModule } from '../_modules/material-controls.module';
import { ReportBankDraftDiscrepancyComponent } from './report-bank-draft-discrepancy/report-bank-draft-discrepancy.component';
import { ReportBankDraftComponent } from './report-bank-draft/report-bank-draft.component';
import { ReportBillingJournalNotPaidComponent } from './report-billing-journal-not-paid/report-billing-journal-not-paid.component';
import { ReportRoutingModule } from './report-routing.module';
import { ReportAnnualStatementComponent } from './report-annual-statement/report-annual-statement.component';
import { ReportAnnualPremiumMonthlyDueComponent } from './report-annual-premium-monthly-due/report-annual-premium-monthly-due.component';
import { ReportAmericanEquityDownloadFileComponent } from './report-american-equity-download-file/report-american-equity-download-file.component';
import { TokenInterceptor } from '../auth/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReportDiscrepancyComponent } from './report-discrepancy/report-discrepancy.component';
import { ReportSeparationComponent } from './report-separation/report-separation.component';
import { NgxMaskModule } from 'ngx-mask';
import { MsalInterceptor } from '@azure/msal-angular';
import { ReportAlphaRosterComponent } from './report-alpha-roster/report-alpha-roster.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReportMemberDetailComponent } from './report-member-detail/report-member-detail.component';
import { ReportAmericanEquitySummaryModalComponent } from './report-american-equity-summary-modal/report-american-equity-summary-modal.component';
import { ReportPreviousMonthComponent } from './report-previous-month/report-previous-month.component';
import { ReportDelinquencyComponent } from './report-delinquency/report-delinquency.component';
import { ReportMembershipComponent } from './report-membership/report-membership.component';
import { ReportUnitRosterComponent } from './report-unit-roster/report-unit-roster.component';
import { ReportDFASDepositComponent } from './report-dfas-deposit/report-dfas-deposit.component';
import { ReportAnnualPaymentsReceivedComponent } from './report-annual-payments-received/report-annual-payments-received.component';
import { ReportInsuredPercentageComponent } from './report-insured-percentage/report-insured-percentage.component';
import { ReportReductionTerminationLetterComponent } from './report-reduction-termination-letter/report-reduction-termination-letter.component';
import { ReportSepContLetterComponent } from './report-sep-cont-letter/report-sep-cont-letter.component';
import { ReportMembershipExportComponent } from './report-membership-export/report-membership-export.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    KendoControlsModule,
    MaterialControlsModule,
    ReportRoutingModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    ReportAnnualPremiumMonthlyDueComponent,
    ReportAnnualStatementComponent,
    ReportBankDraftComponent,
    ReportBankDraftDiscrepancyComponent,
    ReportAnnualPaymentsReceivedComponent,
    ReportBillingJournalNotPaidComponent,
    ReportAmericanEquityDownloadFileComponent,
    ReportDiscrepancyComponent,
    ReportSeparationComponent,
    ReportAlphaRosterComponent,
    ReportMemberDetailComponent,
    ReportAmericanEquitySummaryModalComponent,
    ReportPreviousMonthComponent,
    ReportDelinquencyComponent,
    ReportMembershipComponent,
    ReportUnitRosterComponent,
    ReportDFASDepositComponent,
    ReportInsuredPercentageComponent,
    ReportReductionTerminationLetterComponent,
    ReportSepContLetterComponent,
    ReportMembershipExportComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  exports: [
    ReportAnnualPremiumMonthlyDueComponent,
    ReportAnnualStatementComponent,
    ReportBankDraftComponent,
    ReportBankDraftDiscrepancyComponent,
    ReportAnnualPaymentsReceivedComponent,
    ReportBillingJournalNotPaidComponent,
    ReportAmericanEquityDownloadFileComponent,
    ReportDiscrepancyComponent,
    ReportSeparationComponent,
    ReportAlphaRosterComponent,
    ReportMemberDetailComponent,
    ReportAmericanEquitySummaryModalComponent,
    ReportPreviousMonthComponent,
    ReportDelinquencyComponent,
    ReportMembershipComponent,
    ReportUnitRosterComponent,
    ReportDFASDepositComponent,
    ReportInsuredPercentageComponent,
    ReportReductionTerminationLetterComponent,
    ReportSepContLetterComponent,
    ReportMembershipExportComponent,
  ],
})
export class ReportsModule { }
