<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <div fxLayout="row" fxLayoutGap="8px" class="m-2">
      <div fxFlex="25">
        <h1 class="mr-auto primary">
          Bank Draft Report
        </h1>
      </div>

      <div fxFlex="100">
        <form [formGroup]="saveDepositInfo" (ngSubmit)="onSaveDepositInfoButtonClick()" fxFlex="100">
          <div fxLayout="row wrap" fxLayoutAlign="center stretch" fxLayoutGap="10px">
            <div fxFlex="25%">
              <mat-form-field appearance="fill">
                <mat-label>Date</mat-label>
                <input matInput formControlName="date" [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

                <mat-error *ngIf="date?.hasError('required')">
                  Please enter a <strong>date</strong>
                </mat-error>

              </mat-form-field>
            </div>
            <div fxFlex="20%">
              <button kendoButton [primary]="true" icon="check-outline" style="margin-left: 3px;">Save Deposit Info</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div fxFlex="100">
    <kendo-grid [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [columnMenu]="{ filter: true }"
                [groupable]="false" [pageSize]="100"
                [pageable]="(list && (list.length > 100) ? true : false)" [rowClass]="rowCallback" class="condensed" #grid fxFlex
                id="grdBankDraft" (dataStateChange)="dataStateChange($event)">
      <ng-template kendoGridToolbarTemplate>
        <!--<button type="button" kendoGridPDFCommand icon="file-pdf">Export to PDF</button>
  <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>-->
        <button mat-raised-button type="button" class="k-button" icon="file-excel" (click)="exportBankDraftToCSV()">
          Export to CSV
        </button>
      </ng-template>
      <kendo-grid-excel fileName="BankDraft.xlsx" [fetchData]="this.allData"></kendo-grid-excel>
      <kendo-grid-pdf fileName="BankDraft.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true" [landscape]="true">
        <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
        <!--<ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
           <div class="page-template">
            <div class="header">
               <div style="float: right">
                 Page {{ pageNum }} of {{ totalPages }}
               </div>
             </div>
              <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
          </div>
        </ng-template>-->
      </kendo-grid-pdf>

      <kendo-grid-column field="ssn" title="SSN" [filterable]="true">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{dataItem.ssn | mask: '000-00-0000'}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="person" title="Person" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="bankAcctNbr" title="Bank Acc Nbr" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="premium" title="Premium" [filterable]="true" format="{0:c}">
        <ng-template kendoGridFooterTemplate>
          Total:  {{ total["premium"]?.sum | currency }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="abaNbr" title="ABA Number" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="bankName" title="Bank" [filterable]="true"></kendo-grid-column>

    </kendo-grid>
  </div>
</div>
