import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../_bases/base-service.service';
import { Filter } from '../../_bases/filter';

@Injectable({
	providedIn: 'root'
})
export class PersonPaymentInfoService extends BaseService<Filter, Filter> {

	constructor(public http: HttpClient) {
    super(http, "personpaymentinfos", "id");
	}
}
