import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  NgZone,
  OnInit,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { Subject } from "rxjs";

@Component({
  selector: "app-confirm-modal-modal",
  templateUrl: "./confirm-modal.component.html",
  //styleUrls: ['./agencylandingpage-enlarge-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit, AfterViewInit {
  @Input() data: any;

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public message: string,
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    protected sanitizer: DomSanitizer
  ) {}

  ngAfterViewInit() {}

  onLoad() {}

  ngOnInit() {
    //console.log(this.model);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  ok(): void {
    this.dialogRef.close(true);
  }
}
