
export class PersonCommentReadOnly { 

	constructor() 
  constructor(jsonData: PersonCommentReadOnly)
  constructor(jsonData?: PersonCommentReadOnly) {
		if(jsonData) {
			Object.assign(this, jsonData);
      this.addedDate = (jsonData.addedDate && jsonData.addedDate.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.addedDate) : undefined;
		}
	}

  id: number | undefined;
  personId: number | undefined;
  commentText: string | undefined;
  addedBy: string | undefined;
  addedDate: Date | undefined;
  isNew: boolean | any;
}


