import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';

import { PopupSettings } from '@progress/kendo-angular-dropdowns';

import { Filter } from '../../../_bases/filter';
import { SettingReadOnly } from '../setting-readonly';
import { SettingService } from '../setting.service';
import { Lookup } from '../../../lookups/lookup';
import { LookupService } from '../../../lookups/lookup.service';

@Component({
  selector: 'app-setting-list',
  templateUrl: './setting-list.component.html',
  animations: [
  ]
})
export class SettingListComponent implements OnInit {

  @ViewChild(GridComponent) public grid!: GridComponent;
  
  list: SettingReadOnly[] = [];
  filters: Filter[] = [];
  public loading: boolean = false;

  public formGroup: FormGroup | any;
  private editedRowIndex: number | undefined;

  coverageTypesIdLookups$: Observable<Lookup[]> | any;
  coverageTypesArray: Lookup[] | any;

  groupsIdLookups$: Observable<Lookup[]> | any;
  groupsArray: Lookup[] | any;

  airUnitIdLookups$: Observable<Lookup[]> | any;
  airUnitsArray: Lookup[] | any;

  armyUnitIdLookups$: Observable<Lookup[]> | any;
  armyUnitsArray: Lookup[] | any;

  paymentTypeIdLookups$: Observable<Lookup[]> | any;
  paymentTypesArray: Lookup[] | any;

  createFormGroup = (dataItem: SettingReadOnly) =>
    new FormGroup({
      id: new FormControl(dataItem.id),
      notInsuredCoverageTypeId: new FormControl(dataItem.notInsuredCoverageTypeId, Validators.compose([Validators.required, Validators.pattern("^[0-9]*$"), Validators.max(99999)])),
      formerMembersGroupId: new FormControl(dataItem.formerMembersGroupId, Validators.compose([Validators.required, Validators.pattern("^[0-9]*$"), Validators.max(99999)])),
      airFormerMembersUnitId: new FormControl(dataItem.airFormerMembersUnitId, Validators.compose([Validators.required, Validators.pattern("^[0-9]*$"), Validators.max(99999)])),
      armyFormerMembersUnitId: new FormControl(dataItem.armyFormerMembersUnitId, Validators.compose([Validators.required, Validators.pattern("^[0-9]*$"), Validators.max(99999)])),
      nonMemberPaymentTypeId: new FormControl(dataItem.nonMemberPaymentTypeId, Validators.compose([Validators.required, Validators.pattern("^[0-9]*$"), Validators.max(99999)])),
    });

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: SettingService,
    private ngZone: NgZone,
    private lookupService: LookupService,
  ) {

    this.coverageTypesIdLookups$ = this.lookupService.getList("coverageTypes", []);
    this.coverageTypesIdLookups$.subscribe((s: Lookup[]) => {
      this.coverageTypesArray = s;
    });

    this.groupsIdLookups$ = this.lookupService.getList("groups", []);
    this.groupsIdLookups$.subscribe((s: Lookup[]) => {
      this.groupsArray = s;
    });

    this.airUnitIdLookups$ = this.lookupService.getList("airunits", []);
    this.airUnitIdLookups$.subscribe((s: Lookup[]) => {
      this.airUnitsArray = s;
    });

    this.armyUnitIdLookups$ = this.lookupService.getList("armyunits", []);
    this.armyUnitIdLookups$.subscribe((s: Lookup[]) => {
      this.armyUnitsArray = s;
    });

    this.paymentTypeIdLookups$ = this.lookupService.getList("paymenttypes", []);
    this.paymentTypeIdLookups$.subscribe((s: Lookup[]) => {
      this.paymentTypesArray = s;
    });
  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {
    this.service
      .getByCustomAction$<SettingReadOnly>("list", SettingReadOnly, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {
        this.list = data;
      });
  }
  
  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }

  public editHandler({ sender, rowIndex, dataItem }: { sender: any, rowIndex: any, dataItem: any }) {
    this.closeEditor(sender);
    this.formGroup = this.createFormGroup(dataItem);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({ sender, rowIndex }: { sender: any, rowIndex: any }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }: { sender: any, rowIndex: any, formGroup: any, isNew: any }) {

    const row: SettingReadOnly = formGroup.value;
    this.loading = true;

    this.service.postModelByCustomAction$<SettingReadOnly>("upsert", row, SettingReadOnly)
      .pipe(
        catchError((err, caught) => {
          this.loading = false;
          return of(null as SettingReadOnly | any);
        })
      )
      .subscribe((savedData: any) => {
        this.loading = false;
        if (savedData) {
          //this.grid.data = this.list;
          sender.closeRow(rowIndex);
          this.loadList();
        }
      })
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }


  private fitColumns(): void {
    //this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
    //  this.grid.autoFitColumns();
    //});
  }

  public rowCallback(context: RowClassArgs) {
    return {
      // blocked: context.dataItem.isBlocked
    };
  }

  public coveragetypes(value: any): any {

    if (this.coverageTypesArray !== undefined) {
      let val = this.coverageTypesArray.find((f: Lookup) => f.key == value);
      return val;
    }

    return "";
  }

  public groups(value: any): any {

    if (this.groupsArray !== undefined) {
      let val = this.groupsArray.find((f: Lookup) => f.key == value);
      return val;
    }

    return "";
  }

  public airUnits(value: any): any {

    if (this.airUnitsArray !== undefined) {
      let val = this.airUnitsArray.find((f: Lookup) => f.key == value);
      return val;
    }

    return "";
  }

  public armyUnits(value: any): any {

    if (this.armyUnitsArray !== undefined) {
      let val = this.armyUnitsArray.find((f: Lookup) => f.key == value);
      return val;
    }

    return "";
  }

  public paymentTypes(value: any): any {

    if (this.paymentTypesArray !== undefined) {
      let val = this.paymentTypesArray.find((f: Lookup) => f.key == value);
      return val;
    }

    return "";
  }

}
