import { Component, OnInit, NgZone, ViewChild, Input } from '@angular/core';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';
import { PersonInsBeneficiaryService } from '../personinsbeneficiary.service';
import { PersonInsBeneficiaryReadOnly } from '../personinsbeneficiary-readonly';
import { Filter } from '../../../_bases/filter';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter } from '@progress/kendo-data-query/dist/npm/transducers';
import { Lookup } from '../../../lookups/lookup';
import { LookupService } from '../../../lookups/lookup.service';
import { AuthenticationService } from '../../../auth/auth.service';

@Component({
  selector: 'app-personinsbeneficiaries-list',
  templateUrl: './personinsbeneficiary-list.component.html',
  animations: [
  ]
})
export class PersonInsBeneficiaryListComponent implements OnInit {

  @ViewChild(GridComponent)
  public grid!: GridComponent;

  list: PersonInsBeneficiaryReadOnly[] = [];
  @Input() filters: Filter[] = [];
  @Input() newItemProperties: any;
  public loading: boolean = false;

  public formGroup: FormGroup | any;
  private editedRowIndex: number | undefined;
  relationshipIdLookups$: Observable<Lookup[]> | any;
  relationshipsArray: Lookup[] | any;

  private increment: number = 0;
  createPersonInsBenFormGroup = (dataItem: PersonInsBeneficiaryReadOnly) =>
    new FormGroup({
      id: new FormControl(dataItem.id),
      personId: new FormControl(dataItem.personId, Validators.required),
      beneficiary: new FormControl(dataItem.beneficiary, Validators.required),
      beneficiaryDate: new FormControl(dataItem.beneficiaryDate, Validators.required),
      percentage: new FormControl(dataItem.percentage, Validators.compose([Validators.required, Validators.pattern("^[0-9]*$"), Validators.max(100)])),
      relationship: new FormControl(dataItem.relationship, Validators.required),
      isNew: new FormControl(dataItem.isNew == true ? true : false),
    });

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: PersonInsBeneficiaryService,
    private ngZone: NgZone,
    private lookupService: LookupService,
    private authService: AuthenticationService,
  ) {
    this.relationshipIdLookups$ = this.lookupService.getList("relationships", []);

    this.relationshipIdLookups$.subscribe((s: Lookup[])  => {
      this.relationshipsArray = s;
    });
  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {

    this.service
      .getByCustomAction$<PersonInsBeneficiaryReadOnly>("list", PersonInsBeneficiaryReadOnly, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {
        this.list = data;
      });
  }

  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }

  private fitColumns(): void {
    //this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
    //  this.grid.autoFitColumns();
    //});
  }

  public rowCallback(context: RowClassArgs) {
    return {

    };
  }

  public addPersonInsBenHandler(evt: any) {
    this.closePersonInsBenEditor(evt.sender);

    this.increment += 1;

    this.formGroup = this.createPersonInsBenFormGroup({
      personId: this.filters[0].value,
      beneficiary: '',
      beneficiaryDate: new Date(),
      id: this.increment,
      percentage: 0,
      relationship: '',
      isNew: true,
    });

    evt.sender.addRow(this.formGroup);
  }

  public editPersonInsBenHandler({ sender, rowIndex, dataItem }: { sender: any, rowIndex: any, dataItem: any }) {
    this.closePersonInsBenEditor(sender);
    this.formGroup = this.createPersonInsBenFormGroup(dataItem);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelPersonInsBenHandler({ sender, rowIndex }: { sender: any, rowIndex: any }) {
    this.closePersonInsBenEditor(sender, rowIndex);
  }

  public savePersonInsBenHandler({ sender, rowIndex, formGroup, isNew }: { sender: any, rowIndex: any, formGroup: any, isNew: any }) {

    const personInsBeneficiary: PersonInsBeneficiaryReadOnly = formGroup.value;

    if (isNew) {
      if (this.list == null) {
        this.list = [];
      }
      this.list.push(personInsBeneficiary);
    }
    else {
      this.list.forEach(o => {

        if (o.id == personInsBeneficiary.id) {
          o.beneficiary = personInsBeneficiary.beneficiary;
          o.beneficiaryDate = personInsBeneficiary.beneficiaryDate;
          o.percentage = personInsBeneficiary.percentage;
          o.relationship = personInsBeneficiary.relationship;
          o.isNew = personInsBeneficiary.isNew;
        }
      });
    }

    setTimeout(() => {
      this.authService.eventPersonSubDataEventRaised(true);
    }, 500);
    this.grid.data = this.list;
    sender.closeRow(rowIndex);
  }

  public removePersonInsBenHandler({ dataItem }: { dataItem: any }) {
    let tempList: PersonInsBeneficiaryReadOnly[] = [];

    this.list.forEach(o => {

      if (o.id != dataItem.id) {
        tempList.push(o);
      }
    });

    setTimeout(() => {
      this.authService.eventPersonSubDataEventRaised(true);
    }, 500);
    this.list = tempList;
    this.grid.data = this.list;
  }

  private closePersonInsBenEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public relationships(value: any): any {

    if (this.relationshipsArray !== undefined) {
      let val = this.relationshipsArray.find((f: Lookup) => f.value == value);
      return val;
    }

    return "";
  }
}
