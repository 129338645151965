import { Component, OnInit, NgZone, ViewChild, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';

import { PopupSettings } from '@progress/kendo-angular-dropdowns';

import { Filter } from '../../../_bases/filter';
import { BattalionReadOnly } from '../battalion-readonly';
import { BattalionService } from '../battalion.service';

@Component({
  selector: 'app-battalions-list',
  templateUrl: './battalion-list.component.html',
  animations: [
  ]
})
export class BattalionListComponent implements OnInit {

  @ViewChild(GridComponent) public grid!: GridComponent;
  
  list: BattalionReadOnly[] = [];
  @Input() filters: Filter[] = [];
  @Input() newItemProperties: any;
  public loading: boolean = false;
  brigadeId: number = 0;

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: BattalionService,
	 private ngZone: NgZone,
  ) {
  }

  ngOnInit(): void {

    if (this.filters === undefined || this.filters.length == 0) {
      this.filters = [new Filter("brigadeId", "0")]
    }

    this.brigadeId = this.filters[0].value;

    this.loadList();
  }

  loadList() {

    this.service
      .getByCustomAction$<BattalionReadOnly>("list", BattalionReadOnly, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {
        this.list = data;
      });
  }
  
  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }


  private fitColumns(): void {
    //this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
    //  this.grid.autoFitColumns();
    //});
  }

  public rowCallback(context: RowClassArgs) {
    return {
      // blocked: context.dataItem.isBlocked
    };
  }

}
