<form *ngIf="model" [formGroup]="theForm" (ngSubmit)="onSubmit('')" class="detail-component" novalidate>
  <mat-tab-group>
    <mat-tab label="Basic Person Info">
      <div fxLayout="row">
        <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="50">
          <div fxFlex="100">
            <mat-card fxFlex="100">
              <mat-card-header>
                <mat-card-subtitle>Basic</mat-card-subtitle>
              </mat-card-header>

              <mat-card-content>
                <div fxLayout="column" fxFlex="100">
                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Last Name</mat-label>
                    <input matInput formControlName="lastName" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="lastName.touched && lastName.hasError('required')">
                      Last Name is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('lastName')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>First Name</mat-label>
                    <input matInput formControlName="firstName" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="firstName.touched && firstName.hasError('required')">
                      First Name is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('firstName')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>


                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Middle Name</mat-label>
                    <input matInput formControlName="middleName" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="middleName.touched && middleName.hasError('required')">
                      Middle Name is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('middleName')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Roster Name</mat-label>
                    <input matInput formControlName="rosterName" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="rosterName.touched && rosterName.hasError('required')">
                      Roster Name is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('rosterName')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Suffix Name</mat-label>
                    <input matInput formControlName="suffixName" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="suffixName.touched && suffixName.hasError('required')">
                      Suffix Name is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('suffixName')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Address1</mat-label>
                    <input matInput formControlName="address1" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="address1.touched && address1.hasError('required')">
                      Address1 is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('address1')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Address2</mat-label>
                    <input matInput formControlName="address2" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="address2.touched && address2.hasError('required')">
                      Address2 is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('address2')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                    <mat-form-field appearance="fill" fxFlex="34">
                      <mat-label>City</mat-label>
                      <input matInput formControlName="city" />
                      <mat-hint></mat-hint>
                      <mat-error *ngIf="city.touched && city.hasError('required')">
                        City is <strong>required</strong>
                      </mat-error>

                      <mat-error *ngFor="let e of fieldErrors('city')">
                        {{ e.params }}
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" fxFlex="34">
                      <mat-label>State</mat-label>
                      <input matInput formControlName="state" maxlength="2" />
                      <mat-hint></mat-hint>
                      <mat-error *ngIf="state.touched && state.hasError('required')">
                        State is <strong>required</strong>
                      </mat-error>

                      <mat-error *ngFor="let e of fieldErrors('state')">
                        {{ e.params }}
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" fxFlex="28">
                      <mat-label>Zip</mat-label>
                      <input matInput formControlName="zip" />
                      <mat-hint></mat-hint>
                      <mat-error *ngIf="zip.touched && zip.hasError('required')">
                        Zip is <strong>required</strong>
                      </mat-error>

                      <mat-error *ngFor="let e of fieldErrors('zip')">
                        {{ e.params }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Primary Phone</mat-label>
                    <input matInput formControlName="primaryPhone" mask="(000) 000-0000" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="primaryPhone.touched && primaryPhone.hasError('required')">
                      Primary Phone is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('primaryPhone')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Secondary Phone</mat-label>
                    <input matInput formControlName="secondaryPhone" mask="(000) 000-0000" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="secondaryPhone.touched && secondaryPhone.hasError('required')">
                      Secondary Phone is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('secondaryPhone')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="email.touched && email.hasError('required')">
                      Email is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('email')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Military Email</mat-label>
                    <input matInput formControlName="militaryEmail" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="militaryEmail.touched && militaryEmail.hasError('required')">
                      Military Email is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('militaryEmail')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>SSN</mat-label>
                    <input matInput formControlName="ssn" mask="000-00-0000" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="ssn.touched && ssn.hasError('required')">
                      SSN is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('ssn')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>DOD Id</mat-label>
                    <input matInput formControlName="dodId" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="dodId.touched && dodId.hasError('required')">
                      DOD Id is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('dodId')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>


                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Volunteer Name</mat-label>
                    <mat-select formControlName="volunteerNetworkId">
                      <mat-option *ngFor="let item of this.volunteerNetworkIdLookups$ | async" [value]="item.key">
                        {{item.value}}
                      </mat-option>
                    </mat-select>
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="volunteerNetworkId.touched && volunteerNetworkId.hasError('required')">
                      Volunteer is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('volunteerNetworkId')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div fxFlex="100">
            <mat-card fxFlex="100">
              <mat-card-header>
                <mat-card-subtitle>Membership Information</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <div fxLayout="column" fxFlex="100">



                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Unit</mat-label>
                    <mat-select formControlName="unitId">
                      <mat-option *ngFor="let item of this.unitIdLookups$ | async" [value]="item.key">
                        {{item.value}}
                      </mat-option>
                    </mat-select>
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="unitId.touched && unitId.hasError('required')">
                      Unit is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('unitId')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="50">
          <div fxFlex="100">
            <mat-card fxFlex="100">
              <mat-card-header>
                <mat-card-subtitle>Other Information</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <div fxLayout="column" fxFlex="100">

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Group(s)</mat-label>
                    <mat-select id="groupIdsPicker" formControlName="groupIds" multiple>
                      <mat-option *ngFor="let item of this.groupIdLookups$ | async" [value]="item.key">
                        {{item.value}}
                      </mat-option>
                    </mat-select>
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="groupIds.touched && groupIds.hasError('required')">
                      Group is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('groupIds')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Rank</mat-label>
                    <mat-select id="rankIdPicker" formControlName="rankId">
                      <mat-option *ngFor="let item of this.rankIdLookups$ | async" [value]="item.key">
                        {{item.value}}
                      </mat-option>
                    </mat-select>
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="rankId.touched && rankId.hasError('required')">
                      Rank is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('rankId')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <div fxLayout="row" fxFlex="100" *ngIf="activeMembershipGroups.length > 0"   fxLayoutGap="8px">
                    <div *ngFor="let payment of activeMembershipGroups; let i = index" [fxFlex]="getFlexValue(i)">
                      <mat-form-field  appearance="fill"  fxFlex="100">
                        <mat-label>{{ payment.groupName }}</mat-label>
                        <input matInput [value]="payment.value" readonly>
                      </mat-form-field>
                    </div>
                  </div>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Comment</mat-label>
                    <input matInput formControlName="comment" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="comment.touched && comment.hasError('required')">
                      Comment is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('comment')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Created</mat-label>
                    <input matInput [matDatepicker]="createdpicker" formControlName="created" />
                    <mat-datepicker-toggle matSuffix [for]="createdpicker"></mat-datepicker-toggle>
                    <mat-datepicker #createdpicker></mat-datepicker>
                    <mat-hint></mat-hint>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Created By</mat-label>
                    <input matInput formControlName="createdBy" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="createdBy.touched && createdBy.hasError('required')">
                      Created By is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('createdBy')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Updated</mat-label>
                    <input matInput [matDatepicker]="updatedpicker" formControlName="updated" />
                    <mat-datepicker-toggle matSuffix [for]="updatedpicker"></mat-datepicker-toggle>
                    <mat-datepicker #updatedpicker></mat-datepicker>
                    <mat-hint></mat-hint>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Updated By</mat-label>
                    <input matInput formControlName="updatedBy" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="updatedBy.touched && updatedBy.hasError('required')">
                      Updated By is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('updatedBy')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Insurance Info">
      <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="50">
        <div fxFlex="100">
          <mat-card fxFlex="100">
            <mat-card-header>
              <mat-card-subtitle>Coverage</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
              <div fxLayout="column" fxFlex="100">


                <mat-form-field appearance="fill" fxFlex="100">
                  <mat-label>Coverage Type</mat-label>
                  <mat-select formControlName="coverageTypeId">
                    <mat-option *ngFor="let item of this.coverageTypeIdLookups$ | async" [value]="item.key">
                      {{item.value}}
                    </mat-option>
                  </mat-select>
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="coverageTypeId.touched && coverageTypeId.hasError('required')">
                    Coverage Type is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('coverageTypeId')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                  <mat-form-field appearance="fill" fxFlex="30">
                    <mat-label>Member Coverage</mat-label>
                    <mat-select formControlName="memberCoverageId" (selectionChange)="memberCoverageChange($event.value)">
                      <mat-option *ngFor="let item of this.coveragesMember" [value]="item.key">
                        ${{item.value}}
                      </mat-option>
                    </mat-select>
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="memberCoverageId.touched && memberCoverageId.hasError('required')">
                      Member Coverage is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('memberCoverageId')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Premium</mat-label>
                    <input matInput formControlName="premium" type="number" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="premium.touched && premium.hasError('required')">
                      Premium is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('premium')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>DOB</mat-label>
                    <input matInput formControlName="dob" (blur)="dobChange()" [max]="today" mask="00/00/0000" placeholder="MM/DD/YYYY" />
                    <mat-hint></mat-hint>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="15">
                    <mat-label>Age</mat-label>
                    <input matInput formControlName="age" type="number" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="age.touched && age.hasError('required')">
                      Age is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('age')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                  <mat-form-field appearance="fill" fxFlex="30">
                    <mat-label>Spouse Coverage</mat-label>
                    <mat-select formControlName="spouseCoverageId" (selectionChange)="spouseCoverageChange($event.value)">
                      <mat-option>None</mat-option>
                      <mat-option *ngFor="let item of this.coveragesSpouse" [value]="item.key">
                        ${{item.value}}
                      </mat-option>
                    </mat-select>
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="spouseCoverageId.touched && spouseCoverageId.hasError('required')">
                      Spouse Coverage is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('spouseCoverageId')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Spouse Premium</mat-label>
                    <input matInput formControlName="spousePremium" type="number" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="spousePremium.touched && spousePremium.hasError('required')">
                      Spouse Premium is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('spousePremium')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Spouse DOB</mat-label>
                    <input matInput formControlName="spouseDOB" (blur)="spouseDobChange()" [max]="today" mask="00/00/0000" placeholder="MM/DD/YYYY" />
                    <mat-error *ngFor="let e of fieldErrors('spouseDOB')">
                      {{ e.params }}
                    </mat-error>
                    <mat-hint></mat-hint>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="15">
                    <mat-label>Spouse Age</mat-label>
                    <input matInput formControlName="spouseAge" type="number" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="spouseAge.touched && spouseAge.hasError('required')">
                      Spouse Age is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('spouseAge')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                </div>
                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">

                  <mat-form-field appearance="fill" fxFlex="30">
                    <mat-label>Dependent Coverage</mat-label>
                    <mat-select formControlName="dependentCoverageId" (selectionChange)="dependentCoverageChange($event.value)">
                      <mat-option>None</mat-option>
                      <mat-option *ngFor="let item of this.coveragesDependent" [value]="item.key">
                        ${{item.value}}
                      </mat-option>
                    </mat-select>
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="dependentCoverageId.touched && dependentCoverageId.hasError('required')">
                      Dependent Coverage is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('dependentCoverageId')">
                      {{ e.params }}
                    </mat-error>



                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="25">
                    <mat-label>Dependent Premium</mat-label>
                    <input matInput formControlName="dependentPremium" type="number" />
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="dependentPremium.touched && dependentPremium.hasError('required')">
                      Dependent Premium is <strong>required</strong>
                    </mat-error>

                    <mat-error *ngFor="let e of fieldErrors('dependentPremium')">
                      {{ e.params }}
                    </mat-error>
                  </mat-form-field>

                </div>
                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                  <input type="hidden" formControlName="newApplicationSpouseAndDependentCoverage" />
                  <div class="mat-error" *ngIf="newApplicationSpouseAndDependentCoverage.errors?.max">
                    Combined Coverage cannot exceed the Member's coverage.
                    <!--{{ newApplicationSpouseAndDependentCoverage.value }}-->
                  </div>
                  <mat-hint></mat-hint>

                  <input type="hidden" formControlName="newApplicationDependentCoverage" />
                  <div class="mat-error" *ngIf="newApplicationDependentCoverage.errors?.max">
                    Dependent Coverage cannot exceed the 50% of Member's coverage.
                    <!--{{ newApplicationDependentCoverage.value }}-->
                  </div>
                  <mat-hint></mat-hint>
                </div>
                <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
                  <mat-form-field appearance="fill" fxFlex="50">
                    <mat-label>Effective Date</mat-label>
                    <input matInput [matDatepicker]="effectiveDatepicker" formControlName="effectiveDate" />
                    <mat-datepicker-toggle matSuffix [for]="effectiveDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #effectiveDatepicker></mat-datepicker>
                    <mat-error *ngFor="let e of fieldErrors('effectiveDate')">
                      {{ e.params }}
                    </mat-error>
                    <mat-hint></mat-hint>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="50">
                    <mat-label>Next Pmt Due</mat-label>
                    <input matInput [matDatepicker]="nextPmtDueDatepicker" formControlName="nextPmtDue" />
                    <mat-datepicker-toggle matSuffix [for]="nextPmtDueDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #nextPmtDueDatepicker></mat-datepicker>
                    <mat-error *ngFor="let e of fieldErrors('nextPmtDue')">
                      {{ e.params }}
                    </mat-error>
                    <mat-hint></mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="100">
        <div fxFlex="100">
          <mat-card fxFlex="100">
            <mat-card-header>
              <mat-card-subtitle>Beneficiaries</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <app-personinsbeneficiaries-list *ngIf="model.id != 0" fxFlex="100"
                                               [filters]="childFilters" [newItemProperties]="{personId: model.id}">
              </app-personinsbeneficiaries-list>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Payment Info">
      <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="100">
        <div fxFlex="100">

          <mat-form-field appearance="fill" fxFlex="30">
            <mat-label>Payment Type</mat-label>
            <mat-select formControlName="paymentTypeId" (selectionChange)="paymentTypeChange($event.value)">
              <mat-option *ngFor="let item of this.paymentTypeIdLookups$ | async" [value]="item.key">
                {{item.value}}
              </mat-option>
            </mat-select>
            <mat-hint></mat-hint>
            <mat-error *ngIf="paymentTypeId.touched && paymentTypeId.hasError('required')">
              Payment Type is <strong>required</strong>
            </mat-error>

            <mat-error *ngFor="let e of fieldErrors('paymentTypeId')">
              {{ e.params }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="100" *ngIf="showPaymentInfo == true">
          <mat-card fxFlex="100">
            <mat-card-header>
              <mat-card-subtitle>Payment Information</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
              <div fxLayout="column" fxFlex="100">
                <mat-form-field appearance="fill" fxFlex="30">
                  <mat-label>Bank</mat-label>
                  <mat-select formControlName="bankId">
                    <mat-option *ngFor="let item of this.bankIdLookups$ | async" [value]="item.key">
                      {{item.value}}
                    </mat-option>
                  </mat-select>
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="bankId.touched && bankId.hasError('required')">
                    Bank is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('bankId')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="100">
                  <mat-label>Bank Acct Nbr</mat-label>
                  <input matInput formControlName="bankAcctNbr" />
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="bankAcctNbr.touched && bankAcctNbr.hasError('required')">
                    Bank Acct Nbr is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('bankAcctNbr')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="30">
                  <mat-label>Bank Account Type</mat-label>
                  <mat-select formControlName="bankAccountTypeId">
                    <mat-option *ngFor="let item of this.bankAccountTypeIdLookups$ | async" [value]="item.key">
                      {{item.value}}
                    </mat-option>
                  </mat-select>
                  <mat-hint></mat-hint>
                  <mat-error *ngIf="bankAccountTypeId.touched && bankAccountTypeId.hasError('required')">
                    Bank Account Type is <strong>required</strong>
                  </mat-error>

                  <mat-error *ngFor="let e of fieldErrors('bankAccountTypeId')">
                    {{ e.params }}
                  </mat-error>
                </mat-form-field>

              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxFlex="100">
          <mat-card fxFlex="100">
            <mat-card-header>
              <mat-card-subtitle>Deposits</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <app-personpaymentinfos-list *ngIf="model.id != 0" fxFlex="100"
                                           [filters]="childFilters" [newItemProperties]="{personId: model.id}">
              </app-personpaymentinfos-list>
            </mat-card-content>
          </mat-card>
        </div>
      </div>

    </mat-tab>

    <mat-tab label="Membership Payments">
      <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="100">
        <div fxFlex="100">
          <mat-card fxFlex="100">
            <mat-card-header>
              <mat-card-subtitle>Membership Payments</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div fxLayout="column" fxFlex="100">
                <app-personmembershippayments-list *ngIf="model.id != 0" fxFlex="100"
                                                   [filters]="childFilters" [newItemProperties]="{personId: model.id}">
                </app-personmembershippayments-list>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Documents">
      <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="100">
        <div fxFlex="100">
          <mat-card fxFlex="100">
            <mat-card-header>
              <mat-card-subtitle>Documents</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div fxLayout="column" fxFlex="100">
                <app-personattachments-list *ngIf="model.id != 0" fxFlex="100"
                                            [filters]="childFilters" [newItemProperties]="{personId: model.id}">
                </app-personattachments-list>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Comments">
      <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="100">
        <div fxFlex="100">
          <mat-card fxFlex="100">
            <mat-card-header>
              <mat-card-subtitle>Comments</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div fxLayout="column" fxFlex="100">
                <app-personcomments-list *ngIf="model.id != 0" fxFlex="100"
                                         [filters]="childFilters" [newItemProperties]="{personId: model.id}">
                </app-personcomments-list>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-tab>


  </mat-tab-group>



  <!--<div fxLayout="row">
    <div fxFlex>
        <label class="k-form-field">
          <input type="checkbox" id="xFreeCoverage" class="k-checkboxX" formControlName="xFreeCoverage">
          <label class="k-checkbox-label" for="xFreeCoverage">x Free Coverage</label>
        </label>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x Comment2">
          <input kendoTextBox formControlName="xComment2" />

          <mat-error *ngFor="let e of fieldErrors('xComment2')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x Pmt Type Code">
          <input kendoTextBox formControlName="xPmtTypeCode" />

          <mat-error *ngFor="let e of fieldErrors('xPmtTypeCode')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x ADDTOLOG">
          <input kendoTextBox formControlName="xADDTOLOG" />

          <mat-error *ngFor="let e of fieldErrors('xADDTOLOG')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x Beneficiary1">
          <input kendoTextBox formControlName="xBeneficiary1" />

          <mat-error *ngFor="let e of fieldErrors('xBeneficiary1')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x Beneficiary2">
          <input kendoTextBox formControlName="xBeneficiary2" />

          <mat-error *ngFor="let e of fieldErrors('xBeneficiary2')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x Beneficiary3">
          <input kendoTextBox formControlName="xBeneficiary3" />

          <mat-error *ngFor="let e of fieldErrors('xBeneficiary3')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x Special Note1">
          <input kendoTextBox formControlName="xSpecialNote1" />

          <mat-error *ngFor="let e of fieldErrors('xSpecialNote1')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x Special Note2">
          <input kendoTextBox formControlName="xSpecialNote2" />

          <mat-error *ngFor="let e of fieldErrors('xSpecialNote2')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x Special Note3">
          <input kendoTextBox formControlName="xSpecialNote3" />

          <mat-error *ngFor="let e of fieldErrors('xSpecialNote3')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x Insurance Code">
          <input kendoTextBox formControlName="xInsuranceCode" />

          <mat-error *ngFor="let e of fieldErrors('xInsuranceCode')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x Life Code">
          <input kendoTextBox formControlName="xLifeCode" />

          <mat-error *ngFor="let e of fieldErrors('xLifeCode')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x Life Effective Date">
          <kendo-datepicker formControlName="xLifeEffectiveDate"></kendo-datepicker>

          <mat-error *ngFor="let e of fieldErrors('xLifeEffectiveDate')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x Dependent Code">
          <input kendoTextBox formControlName="xDependentCode" />

          <mat-error *ngFor="let e of fieldErrors('xDependentCode')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x Dependent Effective Date">
          <kendo-datepicker formControlName="xDependentEffectiveDate"></kendo-datepicker>

          <mat-error *ngFor="let e of fieldErrors('xDependentEffectiveDate')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x Spouse Code">
          <input kendoTextBox formControlName="xSpouseCode" />

          <mat-error *ngFor="let e of fieldErrors('xSpouseCode')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
        <div fxFlex>
        <kendo-textbox-container floatingLabel="x Spouse Effective Date">
          <kendo-datepicker formControlName="xSpouseEffectiveDate"></kendo-datepicker>

          <mat-error *ngFor="let e of fieldErrors('xSpouseEffectiveDate')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x New Member Id">
          <kendo-numerictextbox formControlName="xNewMemberId" [format]="'#######'"></kendo-numerictextbox>

          <mat-error *ngFor="let e of fieldErrors('xNewMemberId')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="XABANbr">
          <input kendoTextBox formControlName="xabaNbr" />

          <mat-error *ngFor="let e of fieldErrors('xabaNbr')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="x Unit Code">
          <input kendoTextBox formControlName="xUnitCode" />

          <mat-error *ngFor="let e of fieldErrors('xUnitCode')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>

    -->
  <!--
    <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="100">
        <div fxLayout="row">
        <div fxLayout="row">
          <div fxFlex>
            <kendo-textbox-container floatingLabel="Insurance Comments">
              <input kendoTextBox formControlName="insuranceComments" />

              <mat-error *ngFor="let e of fieldErrors('insuranceComments')">
                {{ e.params }}
              </mat-error>
            </kendo-textbox-container>
            <mat-hint></mat-hint>
          </div>
          <div fxFlex>
            <kendo-textbox-container floatingLabel="Annual Membership Year">
              <kendo-numerictextbox formControlName="annualMembershipYear" [format]="'#######'"></kendo-numerictextbox>

              <mat-error *ngFor="let e of fieldErrors('annualMembershipYear')">
                {{ e.params }}
              </mat-error>
            </kendo-textbox-container>
            <mat-hint></mat-hint>
          </div>
          <div fxFlex>
            <kendo-textbox-container floatingLabel="Annual Membership Paid">
              <kendo-datepicker formControlName="annualMembershipPaid"></kendo-datepicker>

              <mat-error *ngFor="let e of fieldErrors('annualMembershipPaid')">
                {{ e.params }}
              </mat-error>
            </kendo-textbox-container>
            <mat-hint></mat-hint>
          </div>
          <div fxFlex>
            <kendo-textbox-container floatingLabel="Life Member Nbr">
              <input kendoTextBox formControlName="lifeMemberNbr" />

              <mat-error *ngFor="let e of fieldErrors('lifeMemberNbr')">
                {{ e.params }}
              </mat-error>
            </kendo-textbox-container>
            <mat-hint></mat-hint>
          </div>

        <div fxLayout="row">
      <div fxFlex>
        <kendo-textbox-container floatingLabel="Prev Am Eq Unit Code">
          <input kendoTextBox formControlName="prevAmEqUnitCode" />

          <mat-error *ngFor="let e of fieldErrors('prevAmEqUnitCode')">

            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>

      <div fxFlex>
        <kendo-textbox-container floatingLabel="To Be Deleted">
          <input kendoTextBox formControlName="toBeDeleted" />

          <mat-error *ngFor="let e of fieldErrors('toBeDeleted')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
    </div>

    <div fxLayout="row">
      <div fxFlex>
        <kendo-textbox-container floatingLabel="Date Billed">
          <kendo-datepicker formControlName="dateBilled"></kendo-datepicker>

          <mat-error *ngFor="let e of fieldErrors('dateBilled')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="Amt Due">
          <kendo-numerictextbox formControlName="amtDue"></kendo-numerictextbox>

          <mat-error *ngFor="let e of fieldErrors('amtDue')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
      <div fxFlex>
        <kendo-textbox-container floatingLabel="Date Paid">
          <kendo-datepicker formControlName="datePaid"></kendo-datepicker>

          <mat-error *ngFor="let e of fieldErrors('datePaid')">
            {{ e.params }}
          </mat-error>
        </kendo-textbox-container>
        <mat-hint></mat-hint>
      </div>
    </div>
    -->

  <section class="footer">
    <button mat-raised-button color="primary" type="button" [disabled]="theForm.pristine" (click)="saveAndCloseClicked()">
      Save and Close
    </button>
    &nbsp;
    <button mat-raised-button color="primary" type="button" [disabled]="theForm.pristine" (click)="saveAndContinueClicked()">
      Save and Continue
    </button>
    &nbsp;
    <button mat-button type="button" (click)="undo()" [disabled]="theForm.pristine">
      Undo
    </button>
    &nbsp;
    <button mat-button type="button" (click)="cancel()">
      Cancel
    </button>
  </section>
</form>
