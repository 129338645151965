import { DatePipe } from '@angular/common';
import { Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { of, Subject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AuthenticationService } from '../../auth/auth.service';
import { Filter } from '../../_bases/filter';
import { ReportService } from '../report.service';

@Component({
  selector: 'app-american-equity-summary-modal',
  templateUrl: './report-american-equity-summary-modal.component.html',
  animations: [],
})
export class ReportAmericanEquitySummaryModalComponent implements OnInit {
  @ViewChild(GridComponent)
  public grid!: GridComponent;


  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: ReportService,
    public media: MediaObserver,
    public authService: AuthenticationService,
    private ngZone: NgZone,
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public model: any,
    public dialogRef: MatDialogRef<ReportAmericanEquitySummaryModalComponent>
  ) {
  }

  ngOnInit(): void {


    //Lives
    console.log(`MemberLives: ${this.model.MemberLives}`);
    console.log(`ChildLives: ${this.model.ChildLives}`);
    console.log(`SpouseLives: ${this.model.SpouseLives}`);

    //Insurance Volume
    console.log(`MemberCoverage: ${this.model.MemberCoverage}`);
    console.log(`ChildCoverage: ${this.model.ChildCoverage}`);
    console.log(`SpouseCoverage: ${this.model.SpouseCoverage}`);

    //Premium Volume
    console.log(`MemberPremium: ${this.model.MemberPremium}`);
    console.log(`ChildPremium: ${this.model.ChildPremium}`);
    console.log(`SpousePremium: ${this.model.SpousePremium}`);

    //revenue
    console.log(`MemberActualRevenue: ${this.model.MemberActualRevenue}`);
    console.log(`ChildActualRevenue: ${this.model.ChildActualRevenue}`);
    console.log(`SpouseActualRevenue: ${this.model.SpouseActualRevenue}`);
  }

  cancel(): void {
    this.model = null;
    this.dialogRef.close();
  }
}
