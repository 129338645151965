import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dodId-exists-modal',
  templateUrl: './dodId-exists-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .k-actions {
        display: none;
      }
    `,
  ],
})
export class DodIdExistsModelComponent implements OnInit {
  constructor(
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public model: any,
    public dialogRef: MatDialogRef<DodIdExistsModelComponent>,
    private router: Router
  ) {}

  ngOnInit() {}

  navigateToFindMyPolicy() {
    this.model = null;
    this.dialogRef.close(false);
    this.router.navigate(['find-my-policy'], { state: {} });
  }

  cancel(): void {
    this.model = null;
    this.dialogRef.close(true);
  }
}
