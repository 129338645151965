<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <h3 mat-dialog-title>
    Import Membership Data
  </h3>
  <div class="loader" *ngIf="isLoading">
    <div class="spinner"></div>
  </div>

  <div class="mat-dialog-content" style="display:block !important">
    <div fxLayout="column" fxLayoutGap="8px" class="m-2" [formGroup]="importMembership">
      <div fxFlex>
        <kendo-upload formControlName="upload"
                      #upload
                      [restrictions]="fileRestrictions"
                      [autoUpload]="false"
                      [batch]="false"
                      [multiple]="false"
                      (select)="onFileSelect($event)">
          <!--[saveUrl]="uploadSaveUrl"
          (success)="uploadSuccessEventHandler($event)"
          (upload)="uploadEventHandler($event)"-->

        </kendo-upload>
      </div>
    </div>
  </div>
  <section class="footer">
    <button mat-button type="button" (click)="cancel()">
      Close
    </button>

    <button type="button" kendoButton [primary]="true" icon="check-outline" (click)="onUploadButtonClick(upload)" style="margin-left: 3px;">
      Import Membership Data
    </button>
  </section>
</div>

<style>
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top of everything */
  }

  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Light color for spinner background */
    border-top: 4px solid #fff; /* White color for spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite; /* Animation for spinning effect */
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>
