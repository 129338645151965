export class ReportAnnualPaymentsReceived {

  constructor();
  constructor(jsonData?: ReportAnnualPaymentsReceived) {
    if (jsonData) {
      Object.assign(this, jsonData);
      this.depositDate = (jsonData.depositDate && jsonData.depositDate.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.depositDate) : null;
      this.nextPmtDue = (jsonData.nextPmtDue && jsonData.nextPmtDue.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.nextPmtDue) : null;
    }
  }

  public id: number | undefined;
  public person: string | undefined;
  public premium: number | undefined;
  public depositDate?: Date | null;
  public nextPmtDue?: Date | null;
  public depositAmt: number | undefined;
  public firstName: string | undefined;
  public lastName: string | undefined;
}
