import { WebApiError } from "./webapi-error";

export class WebApi {
    constructor() {
        // this.hasSucceeded = json.hasSucceeded;
        // this.messageAutoHide = json.messageAutoHide;
        // this.messages = json.messages;
        // this.modelState = json.modelState;
    }

    static parseError(error: any): WebApiError {
        let ret: WebApiError = JSON.parse(JSON.stringify(error));
        return ret;
    }

}