import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewEncapsulation, } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FileRestrictions, SuccessEvent, UploadComponent, UploadEvent } from "@progress/kendo-angular-upload";
import { environment } from "../../../../environments/environment";
import { typeModel } from "../../../_bases/typeModel";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-import-membership-modal",
  templateUrl: "./import-membership-modal.component.html",
  encapsulation: ViewEncapsulation.None,
  styles: [`
  .k-actions {
    display: none;
  }
`],
})
export class ImportMembershipModalComponent implements OnInit {

  @ViewChild('fileUpload', { static: false }) fileUpload!: UploadComponent;
  uploadSaveUrl: string =
    environment.serviceUrl + "people/importMembershipData";

  //uploadSaveUrl: string = "people/importMembershipData";
  fileRestrictions: FileRestrictions = {
    allowedExtensions: [".xls", ".xlsx"],
  };
  selectedFile: File | null = null;

  importMembership = new FormGroup({
    upload: new FormControl(),
  });

  isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private router: Router,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public model: any,
    public dialogRef: MatDialogRef<ImportMembershipModalComponent>
  ) {

  }

  ngOnInit() {
  }

  cancel(): void {
    this.model = null;
    this.dialogRef.close(false);
  }

  //uploadSuccessEventHandler(e: SuccessEvent) {
  //  console.log("The " + e.operation + " was successful!");
  //  this.dialogRef.close(true);
  //}

  //uploadEventHandler(e: UploadEvent) {
  //  e.data = {
  //    membershipTypeId: this.model.membershipTypeId,
  //  };
  //}


  public onUploadButtonClick(upload: any) {
    if (this.model.hasRecords == false) {
      //upload.uploadFiles();
      this.postData();
      return;
    }

    if (confirm("Please note, there are existing unmatched uploaded records. If you continue with this new upload, existing records will be cleared.") == false)
    {
      return;
    }
    this.postData();
    //upload.uploadFiles();
  }

  onFileSelect(event: any) {
    const files = event.files;
    if (files.length > 0) {
      this.selectedFile = files[0].rawFile; // Store the selected file
    }
  }

  postData() {

    if (this.selectedFile) {

      this.isLoading = true;

      const formData = new FormData();
      formData.append('file', this.selectedFile);
      formData.append('membershipTypeId', this.model.membershipTypeId.toString());

      const tymodel = new typeModel<FormData>(formData);

      this.http.post(this.uploadSaveUrl, formData).subscribe({
        next: (response) => {
          console.log('File uploaded successfully:', response);
          this.dialogRef.close(true);
          this.isLoading = false;
        },
        error: (error) => {
          console.error('Error uploading file:', error);
          this.isLoading = false;
        },
      });

      //this.service
      //  .postByCustomAction$<any>(this.uploadSaveUrl, tymodel).subscribe({
      //  next: (response) => console.log('File uploaded successfully:', response),
      //  error: (error) => console.error('Error uploading file:', error),
      //});
    } else {
      alert('Please select a file first!');
    }

  }
}
