import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-within-90-days-modal',
  templateUrl: './within-90-days-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .k-actions {
        display: none;
      }
    `,
  ],
})
export class Within90DaysModalComponent implements OnInit {
  constructor(
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public model: any,
    public dialogRef: MatDialogRef<Within90DaysModalComponent>,
    private router: Router
  ) {}

  ngOnInit() {}

  cancel(): void {
    this.model = null;
    this.dialogRef.close(true);
  }
}
