export class PersonNavigatorTagUntag {

  constructor();
  constructor(jsonData?: PersonNavigatorTagUntag) {

    if (jsonData) {
      Object.assign(this, jsonData);
    }
  }

  public allIds: number[] | any;
  public selectedIds: number[] | any;
  public tagUntagTypeId: number | any;
}
