import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  subscriptions: Subscription[] = [];
  //show = false;
  isIframe = false;
  loggedIn: boolean | undefined;

  constructor(
    private router: Router,
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    let loginSuccessSubscription: Subscription;
    let loginFailureSubscription: Subscription;

    this.isIframe = window !== window.parent && !window.opener;

    // this.checkAccount();

    loginSuccessSubscription = this.broadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: any) => {
        console.log(result);

        this.authService.isAuthenticated;
        
      });

    loginFailureSubscription = this.broadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => console.log('Login Fails:', result));

    this.subscriptions.push(loginSuccessSubscription);
    this.subscriptions.push(loginFailureSubscription);

    this.msalService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result) {
          debugger;
          this.msalService.instance.setActiveAccount(result.account);
          console.log(JSON.stringify(result.account));

          //document.cookie = `authForAccHang=${JSON.stringify(result.accessToken)};Path=/`;
          document.cookie = `authForIdHang=${result.idToken};Path=/`;
        }
      },
      error: (error) => console.log(error),
    });
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  get copyrightDate() {
    return new Date().getFullYear();
  }
}
