import { DatePipe } from '@angular/common';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { of, Subject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AuthenticationService } from '../../auth/auth.service';
import { Filter } from '../../_bases/filter';
import { typeModel } from '../../_bases/typeModel';
import { ReportAmericanEquitySummaryModalComponent } from '../report-american-equity-summary-modal/report-american-equity-summary-modal.component';
import { ReportService } from '../report.service';
import { ReportAmericanEquityDownloadFile } from './report-american-equity-download-file';

@Component({
  selector: 'app-american-equity-download-file',
  templateUrl: './report-american-equity-download-file.component.html',
  animations: [],
})
export class ReportAmericanEquityDownloadFileComponent implements OnInit {
  @ViewChild(GridComponent)
  public grid!: GridComponent;

  list: ReportAmericanEquityDownloadFile[] = [];
  filters: Filter[] = [];

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: ReportService,
    public media: MediaObserver,
    public authService: AuthenticationService,
    private ngZone: NgZone,
    public datePipe: DatePipe,
    private dialog: MatDialog,
  ) {
    this.allData = this.allData.bind(this);
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.list, {}).data,
    };

    return result;
  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {
    this.filters = [];

    this.service
      .getByCustomAction$<ReportAmericanEquityDownloadFile>(
        'getMacAmEqDownloadTest',
        ReportAmericanEquityDownloadFile,
        this.filters
      )
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      )
      .subscribe((data) => {
        console.log(data);
        this.list = data;
      });
  }

  exportToCSV() {
    let columnHeaders: any[] = [
      { field: 'ssn', title: 'ssn' },
      { field: 'lastName', title: 'lastName' },
      { field: 'firstName', title: 'firstName' },
      { field: 'middleName', title: 'middleName' },
      { field: 'memberDOB', title: 'memberDOB' },
      { field: 'effectiveDate', title: 'effectiveDate' },
      { field: 'amEqUnitCode', title: 'amEqUnitCode' },
      { field: 'memberCoverageCode', title: 'memberCoverageCode' },
      { field: 'memberCoverage', title: 'memberCoverage' },
      { field: 'memberPremium', title: 'memberPremium' },
      { field: 'spouseCoverageCode', title: 'spouseCoverageCode' },
      { field: 'spouseCoverage', title: 'spouseCoverage' },
      { field: 'spousePremium', title: 'spousePremium' },
      { field: 'spouseDOB', title: 'spouseDOB' },
      //{ field: 'dependentCoverageCode', title: 'dependentCoverageCode' },
      { field: 'dependentCoverage', title: 'dependentCoverage' },
      { field: 'dependentPremium', title: 'dependentPremium' },
      { field: 'nextPmtDue', title: 'nextPmtDue' },
      { field: 'unitCode', title: 'unitCode' },
      { field: 'pmtType', title: 'pmtType' },
      { field: 'premiumDue', title: 'premiumDue' },
    ];

    this.service.ExportToCSV(
      columnHeaders,
      this.list,
      ['id'],
      'InsuranceDownloadFile.txt'
    );
  }

  showMonthlySummary() {

    const sumMemberCoverage = this.list.filter(item => item.memberCoverage !== undefined && item.memberCoverage > 0)
      .reduce((sum, current) => sum + (current.memberCoverage !== undefined ? current.memberCoverage : 0), 0);

    const sumChildCoverage = this.list.filter(item => item.dependentCoverage !== undefined && item.dependentCoverage > 0)
      .reduce((sum, current) => sum + (current.dependentCoverage !== undefined ? current.dependentCoverage : 0), 0);

    const sumSpouseCoverage = this.list.filter(item => item.spouseCoverage !== undefined && item.spouseCoverage > 0)
      .reduce((sum, current) => sum + (current.spouseCoverage !== undefined ? current.spouseCoverage : 0), 0);

    const childLives = this.list.filter(item => item.dependentCoverage !== undefined && item.dependentCoverage > 0).length;
    const spouseLives = this.list.filter(item => item.spouseCoverage !== undefined && item.spouseCoverage > 0).length;

    const sumMemberPremium = this.list.filter(item => item.memberPremium !== undefined && item.memberPremium > 0)
      .reduce((sum, current) => sum + (current.memberPremium !== undefined ? current.memberPremium : 0), 0);

    const sumChildPremium = this.list.filter(item => item.dependentPremium !== undefined && item.dependentPremium > 0)
      .reduce((sum, current) => sum + (current.dependentPremium !== undefined ? current.dependentPremium : 0), 0);

    const sumSpousePremium = this.list.filter(item => item.spousePremium !== undefined && item.spousePremium > 0)
      .reduce((sum, current) => sum + (current.spousePremium !== undefined ? current.spousePremium : 0), 0);

    this.service
      .postByCustomAction$<any>("getSummaryActualMonthlyRevenue", new typeModel<any>({}))
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe((data: any) => {


        let dialogRef = this.dialog.open(ReportAmericanEquitySummaryModalComponent, {
          data: {
            MemberLives: this.list.length,
            ChildLives: childLives,
            SpouseLives: spouseLives,
            MemberCoverage: sumMemberCoverage,
            ChildCoverage: sumChildCoverage,
            SpouseCoverage: sumSpouseCoverage,
            MemberPremium: sumMemberPremium,
            ChildPremium: sumChildPremium,
            SpousePremium: sumSpousePremium,
            MemberActualRevenue: data.length > 0 ? data[0].memberActualRevenue : 0,
            ChildActualRevenue: data.length > 0 ? data[0].dependentActualRevenue : 0,
            SpouseActualRevenue: data.length > 0 ? data[0].spouseActualRevenue : 0,
          },
          width: "820px"
        });

        console.log(data);
      });

  }

  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.grid.autoFitColumns();
      });
  }

  public rowCallback(context: RowClassArgs) {
    return {};
  }
}
