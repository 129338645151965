<a #aTest style="display:none;">&nbsp;</a>
<div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="100">
  <div fxFlex="100" [formGroup]="importPersonAttachment">
    <kendo-upload formControlName="upload"
                  #upload
                  [restrictions]="fileRestrictions"
                  [autoUpload]="false"
                  [batch]="false"
                  [multiple]="false"
                  [saveUrl]="uploadSaveUrl"
                  (success)="uploadSuccessEventHandler($event)"
                  (upload)="uploadEventHandler($event)">
    </kendo-upload>
  </div>

  <kendo-grid #grid fxFlex="100" [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [filterable]="false"
              [groupable]="false" [pageSize]="100" [pageable]="(list && (list.length > 100) ? true : false)" [rowClass]="rowCallback"
              class="condensed" [resizable]="true"
              (dataStateChange)="onDataStateChange($event)">

    <kendo-grid-column field="id" title="-" [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <button type="button" mat-button (click)="downloadAttachment(dataItem.id, dataItem.fileName)">
          <mat-icon>cloud_download</mat-icon> {{ dataItem.fileName }}
        </button>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="dateAdded" title="Date Added" [filterable]="true" filter="date" format="{0:d}"></kendo-grid-column>
  </kendo-grid>
</div>
