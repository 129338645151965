<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav noprint" fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="false">
    <!-- !(isHandset$ | async) -->
    <mat-toolbar color="primary" fxLayout="row">
      Menu
    </mat-toolbar>
    <mat-nav-list>
      <ng-container *ngTemplateOutlet="(isHandset$ | async) ? menuMain : null"></ng-container>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar color="primary" class="mb-2 noprint">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <a routerLink="/">
        <button mat-button *ngIf="loggedIn" routerLink="home">
          <img src="../../../assets/logo-clear.png" class="logo img-fluid" alt="logo">
        </button>
      </a>
      <h1 class="zilla-slab">
        <button mat-button *ngIf="!loggedIn" routerLink="home">
          <img src="../../../assets/logo-clear.png" class="logo img-fluid" alt="logo">
        </button>
      </h1>
      <span class="example-spacer"></span>
      <ng-container *ngTemplateOutlet="(isHandset$ | async) ? null : menuMain"></ng-container>
    </mat-toolbar>
    <mat-progress-bar mode="indeterminate" color="accent" *ngIf="loading" id="loading-bar"></mat-progress-bar>
    <ng-content>

    </ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
<ng-template #menuMain>

  

  <button mat-button *ngIf="loggedIn" routerLink="/navigator" routerLinkActive="active">
    <mat-icon class="example-icon">my_location</mat-icon> Navigator
  </button>

  <button mat-button *ngIf="loggedIn" routerLink="/person/0" routerLinkActive="active">
    <mat-icon class="example-icon">person_add</mat-icon> New Person
  </button>

  <button mat-button *ngIf="loggedIn" [matMenuTriggerFor]="matMenuNewApplicants">
    <mat-icon class="example-icon" [matBadge]="(this.newApplicationsCount$ | async)?.allNewApplications"
              matBadgeColor="accent">assignment_ind</mat-icon> New Applicants <mat-icon>keyboard_arrow_down</mat-icon>
  </button>

  <button mat-button *ngIf="loggedIn && (isAdmin)" [matMenuTriggerFor]="matMenuFile">
    <mat-icon class="example-icon">topic</mat-icon> File <mat-icon>keyboard_arrow_down</mat-icon>
  </button>

  <button mat-button *ngIf="loggedIn && (isAdmin)" [matMenuTriggerFor]="matMenuReport">
    <mat-icon class="example-icon">summarize</mat-icon> Reports <mat-icon>keyboard_arrow_down</mat-icon>
  </button>

  <button mat-button *ngIf="loggedIn && (isAdmin)" [matMenuTriggerFor]="matMenuLibraries">
    <mat-icon class="example-icon">add_circle_outline</mat-icon> Libraries <mat-icon>keyboard_arrow_down</mat-icon>
  </button>

  <button mat-button (click)="dashboard()" *ngIf="!loggedIn">
    <mat-icon class="example-icon">fingerprint</mat-icon> Staff Login
  </button>

  <mat-divider [vertical]="true"></mat-divider>
  <button mat-button *ngIf="loggedIn" [matMenuTriggerFor]="matMenuUser">
    <mat-icon class="example-icon">face</mat-icon> {{ fullName }} <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
</ng-template>

<mat-menu #matMenuNewApplicants="matMenu" yPosition="below" xPosition="before" overlapTrigger="false">

  <button mat-menu-item routerLink="/people/wetink">
    <mat-icon class="example-icon" [matBadge]="(this.newApplicationsCount$ | async)?.wetInkApplications"
              matBadgeColor="accent">mode</mat-icon> Wet Ink
  </button>
  <button mat-menu-item routerLink="/people/digitalink">
    <mat-icon class="example-icon" [matBadge]="(this.newApplicationsCount$ | async)?.digitalInkApplications"
              matBadgeColor="accent">mode</mat-icon> Digital Ink
  </button>
  <button mat-menu-item routerLink="/people/applicantUpdates">
    <mat-icon class="example-icon" [matBadge]="(this.newApplicationsCount$ | async)?.untouchedEditApplication"
              matBadgeColor="accent">mode</mat-icon> Applicant Updates
  </button>
</mat-menu>

<mat-menu #matMenuFile="matMenu" yPosition="below" xPosition="before" overlapTrigger="false">

  <!--<button mat-menu-item [matMenuTriggerFor]="matMenuJumps">
    <mat-icon class="example-icon">attach_money</mat-icon> JUMPS
  </button>-->

  <button mat-menu-item routerLink="/jumps/importalljumps">
    <mat-icon class="example-icon">attach_money</mat-icon> JUMPS
  </button>

  <button mat-menu-item routerLink="/jumps/missingjumps">
    <mat-icon class="example-icon">attach_money</mat-icon> Missing JUMPS
  </button>

  <button mat-menu-item routerLink="/reports/americanequitydownloadfile">
    <mat-icon class="example-icon">file_download</mat-icon> Download Insurance File
  </button>

  <button mat-menu-item [matMenuTriggerFor]="matMenuRoster">
    <mat-icon class="example-icon">table_view</mat-icon> Roster
  </button>

  <button mat-menu-item routerLink="/membership-data">
    <mat-icon class="example-icon">upload_file</mat-icon> Membership Data
  </button>


</mat-menu>

<mat-menu #matMenuJumps="matMenu" yPosition="below" xPosition="before" overlapTrigger="false">

  <button mat-menu-item routerLink="/jumps/importarmyjumps">
    <mat-icon class="example-icon">military_tech</mat-icon> Import Army JUMPS Data
  </button>

  <button mat-menu-item routerLink="/jumps/importairjumps">
    <mat-icon class="example-icon">military_tech</mat-icon> Import Air JUMPS Data
  </button>

  <button mat-menu-item routerLink="/jumps/importarmyagr">
    <mat-icon class="example-icon">military_tech</mat-icon> Import Army Agr Data
  </button>

  <button mat-menu-item routerLink="/jumps/importairagr">
    <mat-icon class="example-icon">military_tech</mat-icon> Import Air Agr Data
  </button>

  <button mat-menu-item routerLink="/jumps/importretiredpay">
    <mat-icon class="example-icon">military_tech</mat-icon> Import Retired Pay
  </button>

</mat-menu>



<mat-menu #matMenuReport="matMenu" yPosition="below" xPosition="before" overlapTrigger="false">
  <button mat-menu-item routerLink="/reports/bankdraft">
    <mat-icon class="example-icon">account_balance</mat-icon> Bank Draft
  </button>

  <button mat-menu-item routerLink="/reports/bankdraftdiscrepancy">
    <mat-icon class="example-icon">calculate</mat-icon> Bank Draft Discrepancy
  </button>

  <button mat-menu-item routerLink="/reports/annualpremiummonthlydue">
    <mat-icon class="example-icon">calendar_view_month</mat-icon> Annual Premium Monthly Due
  </button>

  <!-- <button mat-menu-item routerLink="/reports/billingjournal">
    <mat-icon class="example-icon">receipt</mat-icon> Billing Journal
  </button>

  <button mat-menu-item routerLink="/reports/billingjournalnotpaid">
    <mat-icon class="example-icon">receipt</mat-icon> Billing Journal Not Paid
  </button>

  <button mat-menu-item routerLink="/reports/annualstatement">
    <mat-icon class="example-icon">receipt_long</mat-icon> Annual Statement
  </button> -->

  <button mat-menu-item routerLink="/reports/discrepancy">
    <mat-icon class="example-icon">calculate</mat-icon> Discrepancy
  </button>

  <button mat-menu-item routerLink="/reports/separation">
    <mat-icon class="example-icon">calculate</mat-icon> Separation
  </button>
  <button mat-menu-item routerLink="/reports/previousmonth">
    <mat-icon class="example-icon">calculate</mat-icon> Previous Month
  </button>
  <button mat-menu-item routerLink="/reports/delinquency">
    <mat-icon class="example-icon">calculate</mat-icon> Delinquency
  </button>
  <button mat-menu-item routerLink="/reports/membership">
    <mat-icon class="example-icon">calculate</mat-icon> Membership
  </button>
  <button mat-menu-item routerLink="/reports/dfasdepositreport">
    <mat-icon class="example-icon">calculate</mat-icon> DFAS Deposit report
  </button>
  <button mat-menu-item routerLink="/reports/annualpaymentsreceived">
    <mat-icon class="example-icon">calculate</mat-icon>Annual Payments Received
  </button>
  <button mat-menu-item routerLink="/reports/insuredpercentage">
    <mat-icon class="example-icon">calculate</mat-icon>Insured Percentage
  </button>
  <button mat-menu-item routerLink="/reports/membershipexport">
    <mat-icon class="example-icon">calculate</mat-icon>Membership Export
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item routerLink="/reports/reductionterminationletters">
    <mat-icon class="example-icon">calculate</mat-icon>Reduction Letters / Termination Letters
  </button>
  <button mat-menu-item routerLink="/reports/sepcontletters">
    <mat-icon class="example-icon">calculate</mat-icon>Sep/Cont Letters
  </button>

</mat-menu>

<mat-menu #matMenuRoster="matMenu" yPosition="below" xPosition="before" overlapTrigger="false">
  <button mat-menu-item (click)="importAirAlphaRoster()">
    <mat-icon class="example-icon">upload_file</mat-icon> Import Air Alpha Roster
  </button>
  <button mat-menu-item (click)="importArmyAlphaRoster()">
    <mat-icon class="example-icon">upload_file</mat-icon> Import Army Alpha Roster
  </button>
</mat-menu>

<mat-menu #matMenuLibraries="matMenu" yPosition="below" xPosition="before" overlapTrigger="false">
  <button mat-menu-item routerLink="/bank">
    <mat-icon class="example-icon">account_balance</mat-icon> Bank
  </button>
  <button mat-menu-item routerLink="/bankaccounttype">
    <mat-icon class="example-icon">account_balance</mat-icon> Bank Account Type
  </button>
  <!--<button mat-menu-item routerLink="/battalion">
    <mat-icon class="example-icon">account_balance</mat-icon> Battalion
  </button>-->
  <button mat-menu-item routerLink="/brigade">
    <mat-icon class="example-icon">account_balance</mat-icon> Brigade
  </button>
  <button mat-menu-item routerLink="/coverage">
    <mat-icon class="example-icon">account_balance</mat-icon> Coverage
  </button>
  <button mat-menu-item routerLink="/coveragetype">
    <mat-icon class="example-icon">account_balance</mat-icon> Coverage Type
  </button>
  <button mat-menu-item routerLink="/group">
    <mat-icon class="example-icon">account_balance</mat-icon> Group
  </button>
  <button mat-menu-item routerLink="/paymenttype">
    <mat-icon class="example-icon">account_balance</mat-icon> Payment Type
  </button>
  <button mat-menu-item routerLink="/relationship">
    <mat-icon class="example-icon">account_balance</mat-icon> Relationship
  </button>
  <button mat-menu-item routerLink="/title">
    <mat-icon class="example-icon">account_balance</mat-icon> Title
  </button>
  <button mat-menu-item routerLink="/unit">
    <mat-icon class="example-icon">account_balance</mat-icon> Unit
  </button>
  <button mat-menu-item routerLink="/volunteernetwork">
    <mat-icon class="example-icon">account_balance</mat-icon> Volunteer Network
  </button>
  <button mat-menu-item routerLink="/setting">
    <mat-icon class="example-icon">settings</mat-icon> Setting
  </button>
  <button mat-menu-item routerLink="/ncngahangfire">
    <mat-icon class="example-icon">table_view</mat-icon> Hangfire
  </button>
</mat-menu>

<mat-menu #matMenuUser="matMenu" yPosition="below" xPosition="before" overlapTrigger="false">
  <button mat-menu-item *ngIf="loggedIn && isAdmin" [matMenuTriggerFor]="matMenuAdmin">
    <mat-icon class="example-icon">settings</mat-icon> Admin
  </button>
  <button mat-menu-item (click)="logout()" *ngIf="loggedIn">
    <mat-icon class="example-icon">power_settings_new</mat-icon> Logout
  </button>
</mat-menu>

<mat-menu #matMenuAdmin="matMenu" yPosition="below" xPosition="before" overlapTrigger="false">
  <button mat-menu-item routerLink="/useraccounts">
    <mat-icon class="example-icon">person</mat-icon> System Users
  </button>
  <mat-divider></mat-divider>
</mat-menu>
