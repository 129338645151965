
export class PersonMembershipPaymentReadOnly { 

	constructor() 
	constructor(jsonData: PersonMembershipPaymentReadOnly)
	constructor(jsonData?: PersonMembershipPaymentReadOnly) {
		if(jsonData) {
			Object.assign(this, jsonData);
      this.paymentDate = (jsonData.paymentDate && jsonData.paymentDate.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.paymentDate) : undefined;
      this.annualExpirationDate = (jsonData.annualExpirationDate && jsonData.annualExpirationDate.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.annualExpirationDate) : undefined;
		}
	}

  id: number | undefined;
  personId: number | undefined;
  paymentDate: Date | undefined;
  groupId: number | undefined;
  paymentTypeId: number | undefined;
  membershipNumber: string | undefined;
  annualExpirationDate: Date | undefined;
  groupName?: string | undefined;
  isActive?: boolean | undefined;
  isToBeExpire?: boolean | undefined;

  isNew: boolean | undefined;
}


