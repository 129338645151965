import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-amiinsured-result-modal',
  templateUrl: './amiinsured-result-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .k-actions {
        display: none;
      }
    `,
  ],
})
export class AmIInsuredModalComponent implements OnInit {
  constructor(
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public model: any,
    public dialogRef: MatDialogRef<AmIInsuredModalComponent>,
    private router: Router
  ) {}

  ngOnInit() {

    if (this.model !== undefined && this.model.id > 0 && this.model.isInsured == false) {
      this.navigateToEditPolicy();
    }
  }

  navigateToContact() {
    this.model = null;
    this.dialogRef.close(false);
    this.router.navigate(['contact-info'], { state: {} });
  }

  navigateToApplication() {
    this.model = null;
    this.dialogRef.close(false);
    this.router.navigate(['new-application'], { state: {} });
  }

  navigateToEditPolicy() {
    let id = this.model.id;
    let isInsured = this.model.isInsured;
    this.model = null;
    this.dialogRef.close(false);
    this.router.navigate(['edit-application'], { state: { pid: id, isInsured: isInsured  } });
  }

  cancel(): void {
    this.model = null;
    this.dialogRef.close(true);
  }
}
