<div fxLayout="column" fxLayoutGap="8px" class="m-2 main-ctr">
  <div fxFlex="100">
    <button mat-raised-button color="accent" (click)="pdf.saveAs('UnitRoster.pdf')">
      Save As PDF...
    </button>
  </div>
  <kendo-pdf-export #pdf paperSize="Letter" margin="1cm" [scale]=".5" forcePageBreak=".page-break" keepTogether=".prevent-split">
    <div fxLayout="column" fxLayoutGap="8px" class="m-2">
      <div fxFlex="100">
        <h1 class="mr-auto primary text-center">
          N.C National Guard Association
        </h1>
      </div>
      <div fxFlex="100">
        <h3 class="text-center">
          (919) 851-3390
        </h3>
      </div>

      <div fxFlex="100">
        <h3 class="text-center">
          UNIT ROSTER
        </h3>
      </div>

      <div fxFlex="100" *ngIf="this.list">
        <div fxLayout="row" *ngFor="let person of list; let i = index">
          <div fxFlex="100">
            <div fxLayout="column" class="prevent-split">
              <div fxLayout="row" fxLayoutGap="8px" class="m-2" *ngIf="i == 0 || list[i-1].unitId !== person.unitId">
                <div fxFlex="10">
                  <strong>
                    Unit:
                  </strong>
                </div>
                <div fxFlex="10">
                  <strong>{{ person.unitCode }}</strong>
                </div>
                <div fxFlex="80">
                  <strong>{{ person.unitName }}</strong>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="8px" class="m-2 bdr-btm" *ngIf="i == 0 || list[i-1].unitId !== person.unitId">
                <div fxFlex="10">
                  <strong>&nbsp;</strong>
                </div>
                <div fxFlex="25">
                  <strong>Person</strong>
                </div>
                <div fxFlex="25">
                  <strong>Address</strong>
                </div>
                <div fxFlex="10">
                  <strong>City</strong>
                </div>
                <div fxFlex="10">
                  <strong>State</strong>
                </div>
                <div fxFlex="10">
                  <strong>Zip</strong>
                </div>
                <div fxFlex="10">
                  <strong>Premium</strong>
                </div>
              </div>

              <div fxLayout="row" fxLayoutGap="8px" class="m-2">
                <div fxFlex="10">
                  &nbsp;
                </div>
                <div fxFlex="25">
                  {{ person.lastName }} {{ person.firstName }} {{ person.middleName }}
                </div>
                <div fxFlex="25">
                  {{ person.address1 }} {{ person.address2 }}
                </div>
                <div fxFlex="10">
                  {{ person.city }}
                </div>
                <div fxFlex="10">
                  {{ person.state }}
                </div>
                <div fxFlex="10">
                  {{ person.zip }}
                </div>
                <div fxFlex="10">
                  {{ person.memberPremium | currency }}
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="8px" class="m-2">
                <div fxFlex="10">
                  <strong>Coverage:</strong>
                </div>
                <div fxFlex="15">
                  <strong>Member:</strong>
                  {{ person.memberCoverageName| currency }}
                </div>
                <div fxFlex="15">
                  <strong>Spouse:</strong>
                  {{ person.spouseCoverageName| currency }}
                </div>
                <div fxFlex="15">
                  <strong>Dependent:</strong>
                  {{ person.dependentCoverageName| currency }}
                </div>
                <div fxFlex="15">
                  <strong>Effective Date:</strong>
                  {{ person.effectiveDate| date:'yyyy-MM-dd' }}
                </div>
                <div fxFlex="30">
                  Primary Phone: {{ person.primaryPhone }} <br />
                  Secondary Phone: {{ person.secondaryPhone }}<br />
                  Email: {{ person.email }}<br />
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="8px" class="m-2 bdr-dashed" *ngIf="person !== undefined && person.beneficiaries !== undefined && person.beneficiaries.length > 0">
                <div fxFlex="65" class="beneficiary m-2">
                  <div fxLayout="row" fxLayoutGap="8px" class="m-2">
                    <div fxFlex="30">
                      <strong> Member/Beneficiary</strong>
                    </div>
                    <div fxFlex="20">
                      <strong>
                        Relationship
                      </strong>
                    </div>
                    <div fxFlex="15">
                      <strong>
                        Percentage
                      </strong>
                    </div>
                    <div fxFlex="15">
                      <strong>
                        Beneficiary Date
                      </strong>
                    </div>
                  </div>
                  <ng-container>
                    <ng-container *ngFor="let beneficiary of person.beneficiaries">
                      <div fxLayout="row" fxLayoutGap="8px" class="m-2">
                        <div fxFlex="30">
                          {{ beneficiary.beneficiary }}
                        </div>
                        <div fxFlex="20">
                          {{ beneficiary.relationship }}
                        </div>
                        <div fxFlex="15">
                          {{ beneficiary.percentage | number }}%
                        </div>
                        <div fxFlex="15">
                          {{ beneficiary.beneficiaryDate | date: 'yyyy-MM-dd' }}
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>

            <div fxLayout="row" fxLayoutGap="8px" class="m-2 bdr-btm" *ngIf="i == list.length - 1 || list[i+1].unitId !== person.unitId">
              <div fxFlex="10">
                Member Count:
              </div>
              <div fxFlex="5">
                {{ i + 1 }}
              </div>
              <div fxFlex="10">
                Unit Strength:
              </div>
              <div fxFlex="5">
                {{ person.unitStrength }}
              </div>
              <div fxFlex="15">
                Membership Percentage:
              </div>
              <div fxFlex="5">
                {{ ((person?.unitStrength || 0) / (i + 1)) | percent }}

              </div>
              <div class="page-break">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </kendo-pdf-export>
</div>
