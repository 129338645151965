//import { IDto } from "src/app/core/iDto";

export class PersonInsBeneficiaryReadOnly { //} implements IDto<PersonInsBeneficiary> {

	constructor() 
	constructor(jsonData: PersonInsBeneficiaryReadOnly)
	constructor(jsonData?: PersonInsBeneficiaryReadOnly) {
		if(jsonData) {
			Object.assign(this, jsonData);
			this.beneficiaryDate = (jsonData.beneficiaryDate && jsonData.beneficiaryDate.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.beneficiaryDate) : undefined;
		}
	}

  id: number | undefined;
  personId: number | undefined;
  beneficiary: string | undefined;
  relationship: string | undefined;
  percentage: number | undefined;
  beneficiaryDate: Date | undefined;
  isNew: boolean | undefined;

}


