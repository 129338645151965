
<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      Group
      <span class="k-icon k-i-loading" *ngIf="loading"></span>
      <button mat-button color="primary" routerLink="/group/0">
        <mat-icon color="primary">add_circle_outline</mat-icon> Add New Group
      </button>
    </h1>
  </div>

  <kendo-grid #grid fxFlex="100" [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [filterable]="false"
              [groupable]="false" [pageSize]="100" [pageable]="(list && (list.length > 100) ? true : false)" [rowClass]="rowCallback"
              class="condensed mt-5" [resizable]="true" (dataStateChange)="onDataStateChange($event)">

    <kendo-grid-column>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <button mat-button color="primary" routerLink="/group/{{ dataItem.id }}">
          <mat-icon>edit</mat-icon>
        </button>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="groupName" title="Group Name" [filterable]="true"></kendo-grid-column>
    <kendo-grid-column field="insGroup" title="Ins Group" [filterable]="true"></kendo-grid-column>
    <kendo-grid-column field="isMembership" title="Is Membership?" [filterable]="true"></kendo-grid-column>
    <!--<kendo-grid-column field="xCommitteeNbr" title="x Committee Nbr" [filterable]="true"></kendo-grid-column>
  <kendo-grid-column field="xCommitteeYear" title="x Committee Year" [filterable]="true" filter="numeric"></kendo-grid-column>
  <kendo-grid-column field="xReportHeading1" title="x Report Heading1" [filterable]="true"></kendo-grid-column>
  <kendo-grid-column field="xReportHeading2" title="x Report Heading2" [filterable]="true"></kendo-grid-column>-->
  </kendo-grid>
</div>
