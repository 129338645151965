<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      N.C National Guard Association
    </h1>
  </div>

  <div fxFlex="100">
    <h3>
      Annual Premium Monthly Due Report
    </h3>
  </div>


  <div fxLayout="row" fxLayoutGap="8px" class="m-2">
    <kendo-grid [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [columnMenu]="{ filter: true }"
                [groupable]="false" [pageSize]="100"
                [pageable]="(list && (list.length > 100) ? true : false)" [rowClass]="rowCallback" class="condensed" #grid fxFlex
                id="grdSeparatedLifeMembership">
      <ng-template kendoGridToolbarTemplate>
        <button type="button" kendoGridPDFCommand icon="file-pdf">Export to PDF</button>
        <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
      </ng-template>
      <kendo-grid-excel fileName="AnnualPremiumMonthlyDue.xlsx" [fetchData]="this.allData"></kendo-grid-excel>
      <kendo-grid-pdf fileName="AnnualPremiumMonthlyDue.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true" [landscape]="true">
        <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
        <!--<ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
       <div class="page-template">
        <div class="header">
           <div style="float: right">
             Page {{ pageNum }} of {{ totalPages }}
           </div>
         </div>
          <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
      </div>
    </ng-template>-->
      </kendo-grid-pdf>
      <kendo-grid-column field="ssn" title="SSN" [filterable]="true">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{dataItem.ssn | mask: '000-00-0000'}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="name" title="Name" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="coverageName" title="Coverage" [filterable]="true" format="{0:c}"></kendo-grid-column>
      <kendo-grid-column field="age" title="Age" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="totalPremium" title="Premium" [filterable]="true" format="{0:c}"></kendo-grid-column>
      <kendo-grid-column field="pmtTypeCode" title="Payment Type Code" [filterable]="true"></kendo-grid-column>
    </kendo-grid>
  </div>
</div>
