
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, Validators } from "@angular/forms"
import { ActivatedRoute, Router, ParamMap } from "@angular/router";

import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { PopupSettings } from '@progress/kendo-angular-dropdowns';

import { Brigade } from "../brigade";
import { BrigadeService } from "../brigade.service";
import { BaseItemComponent } from '../../../_bases/base-item-component';
import { Filter } from '../../../_bases/filter';
import { FormValidation } from '../../../shared/forms/form-validation';


@Component({
  selector: 'app-brigade-detail',
  templateUrl: './brigade-detail.component.html',
})
export class BrigadeComponent extends BaseItemComponent implements OnInit, OnDestroy {

  model: Brigade | any;
  public loading: boolean = false;
  childFilters: Filter[] = [];

  constructor(
    private fb: FormBuilder,
    private service: BrigadeService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router) {
    super();
  }

  ngOnInit() {

    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => this.service.getSingleByCustomAction$<Brigade>("getSingle", Brigade, [new Filter("id", params.get("id"), "eq")]))
      )
      .subscribe((model: Brigade | any) => {
        this.childFilters = [new Filter("brigadeId", model.id)];
        this.createForm(model);
      });
  }

  ngOnChanges() {
    if (this.model) {
      this.createForm(this.model);
    }
  }

  createForm(model?: Brigade): void {
    if (this.theForm) {
      this.model = model;
      this.theForm.reset(this.bindForm());
    } else {
      this.theForm = this.fb.group({
        id: [model?.id, Validators.required],
        brigadeCode: model?.brigadeCode,
        brigadeName: model?.brigadeName,
        brigadeSeqNbr: model?.brigadeSeqNbr,
        //xG_MEM: model.xG_MEM,
        //xG_ASSOC: model.xG_ASSOC,
      });

      this.model = model;
    }
  }

  bindForm(): any {
    return {
      id: this.model.id,
      brigadeCode: this.model.brigadeCode,
      brigadeName: this.model.brigadeName,
      brigadeSeqNbr: this.model.brigadeSeqNbr,
      //xG_MEM: this.model.xG_MEM,
      //xG_ASSOC: this.model.xG_ASSOC,
    };
  }

  onSubmit() {
    if (this.theForm?.valid) {

      this.loading = true;
      this.service.postModelByCustomAction$<Brigade>("upsert", this.prepareSave(), Brigade)
        .pipe(
          catchError((err, caught) => {
            this.loading = false;
            FormValidation.parseSubmitErrors(err, this.theForm);
            return of(null as Brigade | any);
          })
        )
        .subscribe((savedData: any) => {
          this.loading = false;
          if (savedData) {
            this.goBack();
          }
        })
    } else {
      FormValidation.validateAllFormFields(this.theForm);
    }
  }

  prepareSave(): Brigade {
    const formModel = this.theForm.value;

    let saveModel: Brigade = new Brigade({
      id: formModel.id,
      brigadeCode: formModel.brigadeCode,
      brigadeName: formModel.brigadeName,
      brigadeSeqNbr: formModel.brigadeSeqNbr,
      //xG_MEM: formModel.xG_MEM,
      //xG_ASSOC: formModel.xG_ASSOC,
    });
    return saveModel;
  }

  cancel(): void {
    this.goBack();
  }

  goBack() {
    this.location.back();
  }

  undo(): void {
    this.theForm.reset(this.bindForm());
  }

  // Form Control Helpers
  get id(): AbstractControl {
    return this.theForm.get('id');
  }
  get brigadeCode(): AbstractControl {
    return this.theForm.get('brigadeCode');
  }
  get brigadeName(): AbstractControl {
    return this.theForm.get('brigadeName');
  }
  get brigadeSeqNbr(): AbstractControl {
    return this.theForm.get('brigadeSeqNbr');
  }
  //get xG_MEM(): AbstractControl {
  //  return this.theForm.get('xG_MEM');
  //}
  //get xG_ASSOC(): AbstractControl {
  //  return this.theForm.get('xG_ASSOC');
  //}

}
