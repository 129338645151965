export class PaymentTypeReadOnly {

	constructor() 
	constructor(jsonData: PaymentTypeReadOnly)
	constructor(jsonData?: PaymentTypeReadOnly) {
		if(jsonData) {
			Object.assign(this, jsonData);
		}
	}

  id: number | undefined;
  pmtTypeCode: string | undefined;
  pmtType: string | undefined;
  setPmtDueDate: boolean | undefined;
  isInsurance: boolean | undefined;
}


