<style>
  .frame {
    flex: 1 1 auto;
    border: 0;
  }

  .wrap body {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .wrap {
    display: flex;
    flex-direction: column;
    height: 80vh;
    /*border: 2px solid blue;*/
    min-height: 0;
  }

  .mat-dialog-content {
    max-height: none !important;
  }
</style>

<div *ngIf="this.message">
  <div class="mat-dialog-content">
    <h2>  {{ this.message }} </h2>
  </div>
  <section class="footer">
    <div fxFlex="100" fxLayoutGap="8px" fxLayoutAlign="flex-end">
      <div flex>
        <button mat-button type="button" (click)="cancel()">
          Cancel
        </button>
      </div>
      <div flex>
        <button mat-raised-button color="primary" type="button" (click)="ok()">
          Yes
        </button>
      </div>
    </div>
  </section>
</div>
