import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MsalInterceptor } from '@azure/msal-angular';
import { NgxMaskModule } from 'ngx-mask';
import { TokenInterceptor } from '../auth/auth.interceptor';
import { KendoControlsModule } from '../_modules/kendo-controls.module';
import { MaterialControlsModule } from '../_modules/material-controls.module';
import { AmIInsuredModalComponent } from './amiinsured/amiinsured-result-modal.component';
import { AmIInsuredComponent } from './amiinsured/amiinsured.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { HomeComponent } from './home/home.component';
import { DodIdExistsModelComponent } from './newapplication/dodId-exists-modal.component';
import { NewApplicationComponent } from './newapplication/newapplication.component';
import { NewApplicationPersonChildListComponent } from './newapplication/newapplicationpersonchild-list.component';
import { NewApplicationPersonInsBeneficiaryListComponent } from './newapplication/newapplicationpersoninsbeneficiary-list.component';
import { NewApplicationSubmittedComponent } from './newapplication/newapplicationsubmitted.component';
import { Within90DaysModalComponent } from './newapplication/within-90-days-modal.component';
import { PublicRoutingModule } from './public-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    KendoControlsModule,
    MaterialControlsModule,
    PublicRoutingModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    HomeComponent,
    ContactInfoComponent,
    NewApplicationPersonInsBeneficiaryListComponent,
    NewApplicationPersonChildListComponent,
    NewApplicationComponent,
    NewApplicationSubmittedComponent,
    AmIInsuredModalComponent,
    AmIInsuredComponent,
    Within90DaysModalComponent,
    DodIdExistsModelComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  exports: [
    HomeComponent,
    ContactInfoComponent,
    NewApplicationPersonInsBeneficiaryListComponent,
    NewApplicationPersonChildListComponent,
    NewApplicationComponent,
    NewApplicationSubmittedComponent,
    AmIInsuredModalComponent,
    AmIInsuredComponent,
    Within90DaysModalComponent,
    DodIdExistsModelComponent,
  ],
})
export class PublicModule {}
