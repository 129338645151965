import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Filter } from '../_bases/filter';
import { Lookup } from './lookup';

@Injectable()
export class LookupService {
  private lookupsUri = 'lookups/';
  private lookup = '';
  public loading = new BehaviorSubject<boolean>(false);
  loading$ = this.loading.asObservable();

  private _data$!: BehaviorSubject<Lookup[]>;
  public get data$(): Observable<Lookup[]> {
    return this._data$.asObservable();
  }

  constructor(private http: HttpClient) {}

  getList(lookup: string): Observable<Lookup[]>;
  getList(lookup: string, filter?: Filter[]): Observable<Lookup[]>;
  getList(
    lookup: string,
    filter?: Filter[],
    sendCredentials?: boolean
  ): Observable<Lookup[]> {
    this.lookup = lookup;
    var url =
      (sendCredentials
        ? environment.serviceUrl
        : environment.serviceUrlAnonymous) +
      this.lookupsUri +
      this.lookup;

    var params = new HttpParams();
    if (filter && filter.length > 0) {
      filter.forEach((itm) => {
        params = params.append('filter', JSON.stringify(itm));
      });
    }

    this.loading.next(true);
    return this.http.get<Lookup[]>(url, { params: params }).pipe(
      map((result) => {
        return result;
      })
    );
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    if (error.status == 401) {
      // should redirect to login here
    }
    return Promise.reject(error.message || error);
  }
}
