export class PersonNavigator {

  constructor();
  constructor(jsonData?: PersonNavigator) {

    if (jsonData) {
      Object.assign(this, jsonData);
    }
  }

  public id: number | undefined;
  public lastName: string | undefined;
  public firstName: string | undefined;
  public middleName: string | undefined;
  public ssn: string | undefined;
  public unitCode: string | undefined;
  public premium: number | undefined;
  public pmtType: string | undefined;
  public rosterName: string | undefined;
  public uic: string | undefined;
  public address1: string | undefined;
	public address2: string | undefined;
	public city: string | undefined;
	public state: string | undefined;
	public zip: string | undefined;
	public primaryPhone: string | undefined;
	public secondaryPhone: string | undefined;
	public email: string | undefined;
	public militaryEmail: string | undefined;
	public dob: Date | undefined;

}
