export class Relationship {

	constructor() 
	constructor(jsonData: Relationship)
	constructor(jsonData?: Relationship) {
		if(jsonData) {
			Object.assign(this, jsonData);
    }
    else {
      this.id = 0;
    }
	}

  relationshipName: string | undefined;
  id: number | undefined;

}


