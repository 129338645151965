<div fxLayout="column" fxLayoutGap="10px" fxFlex="100">
  <div fxFlex="100">
    <div fxLayout="row" fxLayoutGap="8px" class="m-2" [formGroup]="listMembershipData">
      <h1 fxFlex="50" class="mr-auto primary">
        Membership Data -

        <mat-form-field appearance="fill" fxFlex="15">
          <mat-label>Membership Type</mat-label>
          <mat-select formControlName="membershipTypeId">
            <mat-option *ngFor="let item of this.membershipTypeLookups" [value]="item.key">
              {{item.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>


      </h1>
      <div fxFlex="50" class="mr-auto primary text-end">
        <button mat-raised-button color="secondary" [disabled]="loading" (click)="uploadNewFile()"><mat-icon class="example-icon">upload_file</mat-icon> Upload New {{ this.membershipType }} File</button>
      </div>
    </div>
  </div>

  <div fxFlex="100">
    <div fxLayout="row" fxLayoutGap="8px" class="m-2">
      <button *ngIf="distinctCommonIds.length > 0" mat-raised-button color="secondary" (click)="previous()" [disabled]="isFirst()"><mat-icon class="example-icon">navigate_before</mat-icon>Previous</button>
      <b fxFlex="100" class="text-end">Total {{ distinctCommonIds.length }} Matching Record(s) found</b>
      <button *ngIf="distinctCommonIds.length > 0" mat-raised-button color="secondary" (click)="next()" [disabled]="isLast()"><mat-icon class="example-icon">navigate_next </mat-icon> Next</button>
    </div>
  </div>

  <div fxFlex="100">
    <table fxFlex="100" fxLayoutGap="8px" mat-table [dataSource]="this.filteredList" class="m-2 mat-table mat-elevation-z2">
      <ng-container matColumnDef="record">
        <th mat-header-cell *matHeaderCellDef> Record Type</th>
        <td mat-cell *matCellDef="let element"> {{ element.record}} </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef> Last Name </th>
        <td mat-cell *matCellDef="let element"> {{ element.lastName}} </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef> First Name </th>
        <td mat-cell *matCellDef="let element"> {{ element.firstName }} </td>
      </ng-container>

      <ng-container matColumnDef="middleName">
        <th mat-header-cell *matHeaderCellDef> Middle Name </th>
        <td mat-cell *matCellDef="let element"> {{ element.middleName }} </td>
      </ng-container>

      <ng-container matColumnDef="suffixName">
        <th mat-header-cell *matHeaderCellDef> Suffix Name </th>
        <td mat-cell *matCellDef="let element"> {{ element.suffixName }} </td>
      </ng-container>

      <ng-container matColumnDef="address1">
        <th mat-header-cell *matHeaderCellDef> Address 1</th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': isDifferent('address1') ? 'yellow' : 'transparent'}">
          <input type="radio"
                 *ngIf="isDifferent('address1');"
                 id="rdaddress1{{ element.commonId }}{{ element.id }}"
                 name="rdaddress1{{ element.commonId }}"
                 (change)="onRadioChange(element.commonId, 'address1', element.address1)"
                 [checked]="element.record == 'Person'" />
          &nbsp;<label for="rdaddress1{{ element.commonId }}{{ element.id }}">{{ element.address1 }}</label>
        </td>
      </ng-container>

      <ng-container matColumnDef="address2">
        <th mat-header-cell *matHeaderCellDef> Address 2 </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': isDifferent('address2') ? 'yellow' : 'transparent'}">
          <input type="radio"
                 *ngIf="isDifferent('address2');"
                 id="rdaddress2{{ element.commonId }}{{ element.id }}"
                 name="rdaddress2{{ element.commonId }}"
                 (change)="onRadioChange(element.commonId, 'address2', element.address2)"
                 [checked]="element.record == 'Person'" />
          &nbsp;<label for="rdaddress2{{ element.commonId }}{{ element.id }}">{{ element.address2 }}</label>
        </td>
      </ng-container>

      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef> City</th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': isDifferent('city') ? 'yellow' : 'transparent'}">
          <input type="radio"
                 *ngIf="isDifferent('city');"
                 id="rdcity{{ element.commonId }}{{ element.id }}"
                 name="rdcity{{ element.commonId }}"
                 (change)="onRadioChange(element.commonId, 'city', element.city)"
                 [checked]="element.record == 'Person'" />
          &nbsp;<label for="rdcity{{ element.commonId }}{{ element.id }}">{{ element.city }}</label>
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef> State </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': isDifferent('state') ? 'yellow' : 'transparent'}">
          <input type="radio"
                 *ngIf="isDifferent('state');"
                 id="rdstate{{ element.commonId }}{{ element.id }}"
                 name="rdstate{{ element.commonId }}"
                 (change)="onRadioChange(element.commonId, 'state', element.state)"
                 [checked]="element.record == 'Person'" />
          &nbsp;<label for="rdstate{{ element.commonId }}{{ element.id }}">{{ element.state }}</label>
        </td>
      </ng-container>

      <ng-container matColumnDef="zip">
        <th mat-header-cell *matHeaderCellDef> Zip </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': isDifferent('zip') ? 'yellow' : 'transparent'}">
          <input type="radio"
                 *ngIf="isDifferent('zip');"
                 id="rdzip{{ element.commonId }}{{ element.id }}"
                 name="rdzip{{ element.commonId }}"
                 (change)="onRadioChange(element.commonId, 'zip', element.zip)"
                 [checked]="element.record == 'Person'" />
          &nbsp;<label for="rdzip{{ element.commonId }}{{ element.id }}">{{ element.zip }}</label>
        </td>
      </ng-container>

      <ng-container matColumnDef="primaryPhone">
        <th mat-header-cell *matHeaderCellDef> Primary Phone </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': isDifferent('primaryPhone') ? 'yellow' : 'transparent'}">
          <input type="radio"
                 *ngIf="isDifferent('primaryPhone');"
                 id="rdprimaryPhone{{ element.commonId }}{{ element.id }}"
                 name="rdprimaryPhone{{ element.commonId }}"
                 (change)="onRadioChange(element.commonId, 'primaryPhone', element.primaryPhone)"
                 [checked]="element.record == 'Person'" />
          &nbsp;<label for="rdprimaryPhone{{ element.commonId }}{{ element.id }}">{{ element.primaryPhone }}</label>
        </td>
      </ng-container>

      <ng-container matColumnDef="secondaryPhone">
        <th mat-header-cell *matHeaderCellDef> Secondary Phone </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': isDifferent('secondaryPhone') ? 'yellow' : 'transparent'}">
          <input type="radio"
                 *ngIf="isDifferent('secondaryPhone');"
                 id="rdsecondaryPhone{{ element.commonId }}{{ element.id }}"
                 name="rdsecondaryPhone{{ element.commonId }}"
                 (change)="onRadioChange(element.commonId, 'secondaryPhone', element.secondaryPhone)"
                 [checked]="element.record == 'Person'" />
          &nbsp;<label for="rdsecondaryPhone{{ element.commonId }}{{ element.id }}">{{ element.secondaryPhone }}</label>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': isDifferent('email') ? 'yellow' : 'transparent'}">
          <input type="radio"
                 *ngIf="isDifferent('email');"
                 id="rdemail{{ element.commonId }}{{ element.id }}"
                 name="rdemail{{ element.commonId }}"
                 (change)="onRadioChange(element.commonId, 'email', element.email)"
                 [checked]="element.record == 'Person'" />
          &nbsp;<label for="rdemail{{ element.commonId }}{{ element.id }}">{{ element.email }}</label>
        </td>
      </ng-container>

      <ng-container matColumnDef="dob">
        <th mat-header-cell *matHeaderCellDef> DOB </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': isDifferent('dob') ? 'yellow' : 'transparent'}">
          <input type="radio"
                 *ngIf="isDifferent('dob');"
                 id="rddob{{ element.commonId }}{{ element.id }}"
                 name="rddob{{ element.commonId }}"
                 (change)="onRadioChange(element.commonId, 'dob', element.dob)"
                 [checked]="element.record == 'Person'" />
          &nbsp;<label for="rddob{{ element.commonId }}{{ element.id }}">{{ element.dob | date:'MMM dd, yyyy' }}</label>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element">
          <button mat-raised-button color="secondary" *ngIf="element.record != 'Person'" (click)="createNewPersonRecord(element)">Create New Person Record</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [style.font-weight]="row.record === 'Person' ? 'bold' : 'normal'"></tr>
    </table>
  </div>

  <div fxFlex="100" *ngIf="distinctCommonIds && distinctCommonIds.length > 0">
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" class="m-2">
      <button mat-raised-button color="primary" type="button" (click)="updateExistingPersonRecord()">Update Existing Person Record</button>
    </div>
  </div>
</div>

<style>
  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    padding: 5px;
  }
</style>
