import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';

import { PopupSettings } from '@progress/kendo-angular-dropdowns';

import { CoverageService } from '../coverage.service';
import { CoverageReadOnly } from '../coverage-readonly';
import { Filter } from '../../../_bases/filter';

@Component({
  selector: 'app-coverages-list',
  templateUrl: './coverage-list.component.html',
  animations: [
  ]
})
export class CoverageListComponent implements OnInit {

  @ViewChild(GridComponent) public grid!: GridComponent;
  
  list: CoverageReadOnly[] = [];
  filters: Filter[] = [];
  public loading: boolean = false;

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: CoverageService,
	 private ngZone: NgZone,
  ) {
  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {
    this.service
      .getByCustomAction$<CoverageReadOnly>("list", CoverageReadOnly, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {
        this.list = data;
      });
  }
  
  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }


  private fitColumns(): void {
    //this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
    //  this.grid.autoFitColumns();
    //});
  }

  public rowCallback(context: RowClassArgs) {
    return {
      // blocked: context.dataItem.isBlocked
    };
  }

}
