export class ReportDiscrepancy {

  constructor();
  constructor(jsonData?: ReportDiscrepancy) {
    Object.assign(this, jsonData);
  }

  public id: number | undefined;
  public ssn: string | undefined;
  public personName: string | undefined;
  public unitCode: string | undefined;
  public premiumAmount: number | undefined;
  public premium: number | undefined;
  public amountPaid: number | undefined;
  public comment: string | undefined;
  public sepCode: string | undefined;
  public monthsInsufficient: number | undefined;
  public problem: string | undefined;
  public payCode: string | undefined;
  public branch: string | undefined;
}
