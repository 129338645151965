<form [formGroup]="theForm" (ngSubmit)="onSubmit()" fxLayout="row" fxLayoutAlign="space-around" class="detail-component"
  novalidate>

  <mat-card fxFlex="94" fxFlex.gt-sm="40">
    <mat-card-header>
      <mat-card-title>
        <span *ngIf="this.isFromFindMyPolicy == true">Find My Policy</span>
        <span *ngIf="this.isFromFindMyPolicy != true">Am I Insured?</span>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div fxLayout="column" fxFlex="100">
        <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
          <mat-form-field appearance="fill" fxFlex="100">
            <mat-label>Last Name <span class="text-danger">*</span></mat-label>
            <input matInput formControlName="lastName" />
            <mat-hint></mat-hint>
            <mat-error *ngIf="lastName.touched && lastName.hasError('required')">
              Last Name is <strong>required</strong>
            </mat-error>

            <mat-error *ngFor="let e of fieldErrors('lastName')">
              {{ e.params }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="8px" fxFlex="100">
          <mat-form-field appearance="fill" fxFlex="100">
            <mat-label>SSN [xxx-xx-xxxx] <span class="text-danger">*</span></mat-label>
            <input matInput type="password" formControlName="ssn" mask="000-00-0000" />
            <mat-hint></mat-hint>
            <mat-error *ngIf="ssn.touched && ssn.hasError('required')">
              SSN is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="ssn.touched && ssn.hasError('mask')">
              Please enter 9 digits
            </mat-error>
          </mat-form-field>
        </div>
        <section class="footer mt-3">
          <button mat-raised-button color="primary" type="submit" [disabled]="theForm.pristine">
            <span *ngIf="this.isFromFindMyPolicy == true">Find My Policy</span>
            <span *ngIf="this.isFromFindMyPolicy != true">Am I Insured?</span>
          </button>
        </section>
      </div>
    </mat-card-content>
  </mat-card>
</form>
