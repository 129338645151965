export class NewApplication {

  constructor()
  constructor(jsonData: NewApplication)
  constructor(jsonData?: NewApplication) {
    if (jsonData) {
      Object.assign(this, jsonData);
    }
  }

  id: number | undefined;
  enlistmentDate: any | undefined;

  lastName: string | undefined;
  firstName: string | undefined;
  middleName: string | undefined;
  //rosterName: string | undefined;
  suffixName: string | undefined;
  nameChangeReason: string | undefined;
  address1: string | undefined;
  address2: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zip: string | undefined;
  primaryPhone: string | undefined;
  secondaryPhone: string | undefined;
  email: string | undefined;
  militaryEmail: string | undefined;
  ssn: string | undefined;
  dodId: string | undefined;
  volunteerNetworkId: any | undefined;
  rankId: any | undefined;
  heightInInch: number | undefined;
  weightInLBS: number | undefined;
  gender: string | undefined;
  isMarried: boolean | undefined;
  dutyStatusAGR: boolean | undefined;
  branchOfService: string | undefined;
  nameOfUnit: string | undefined;

  spouseFirstName: string | undefined;
  spouseLastName: string | undefined;
  spouseMiddleName: string | undefined;
  spouseSSN: string | undefined;
  spouseGender: string | undefined;
  spouseHeightInInch: number | undefined;
  spouseWeightInLBS: number | undefined;
  spousePhone: string | undefined;
  spouseEmail: string | undefined;
  noOfChildren: number | undefined;
  last10YearsDiagnosed: boolean | undefined;
  last5YearAdmitted: boolean | undefined;
  everDignosedForAids: boolean | undefined;
  listPrescribedMedication: string | undefined;
  signCity: string | undefined;
  signState: string | undefined;
  spouseLast10YearsDiagnosed: boolean | undefined;
  spouseLast5YearAdmitted: boolean | undefined;
  spouseEverDignosedForAids: boolean | undefined;

  coverageTypeId: number | undefined;
  memberCoverageId: number | undefined;
  premium: number | undefined;
  dob: Date | undefined;
  age: number | undefined;
  spouseCoverageId: number | undefined;
  spousePremium: number | undefined;
  spouseDOB: Date | undefined;
  spouseAge: number | undefined;
  dependentCoverageId: number | undefined;
  dependentPremium: string | undefined;
  //effectiveDate: Date | undefined;
  //nextPmtDue: Date | undefined;

  newApplicationPersonInsBeneficiary: any[] | undefined;
  newApplicationPersonChild: any[] | undefined;
  isEditApplication: boolean | undefined;
  isInsured?: boolean | undefined;
}


