export class MembershipData {

  constructor();
  constructor(jsonData?: MembershipData) {

    if (jsonData) {
      Object.assign(this, jsonData);
      this.dob = (jsonData.dob && jsonData.dob.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.dob) : null;
    }
  }

  public commonId: number | undefined;
  public id: number | undefined;
  public record: string | undefined;
  public lastName: string | undefined;
  public firstName: string | undefined;
  public middleName: string | undefined;
  public suffixName: string | undefined;
  public address1: string | undefined;
  public address2: string | undefined;
  public city: string | undefined;
  public state: string | undefined;
  public zip: string | undefined;
  public primaryPhone: string | undefined;
  public secondaryPhone: string | undefined;
  public email: string | undefined;
  public dob?: Date | null;
}
