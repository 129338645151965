export class BattalionReadOnly {

	constructor() 
	constructor(jsonData: BattalionReadOnly)
	constructor(jsonData?: BattalionReadOnly) {
		if(jsonData) {
			Object.assign(this, jsonData);
		}
	}

  id: number | undefined;
  brigadeId: number | undefined;;
  brigadeCode: string | undefined;;
  battalionSeqNbr: number | undefined;;
  battalionName: string | undefined;;
  xSeqNbr: string | undefined;;
  xT_ID: string | undefined;;

}


