
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, Validators } from "@angular/forms"
import { ActivatedRoute, Router, ParamMap } from "@angular/router";

import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { PopupSettings } from '@progress/kendo-angular-dropdowns';

import { Relationship } from "../relationship";
import { RelationshipService } from "../relationship.service";
import { BaseItemComponent } from '../../../_bases/base-item-component';
import { Filter } from '../../../_bases/filter';
import { FormValidation } from '../../../shared/forms/form-validation';

@Component({
  selector: 'app-relationship-detail',
  templateUrl: './relationship-detail.component.html',
})
export class RelationshipComponent extends BaseItemComponent implements OnInit, OnDestroy {

  model: Relationship | any;
  public loading: boolean = false;


  constructor(
    private fb: FormBuilder,
    private service: RelationshipService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router) {
    super();
  }

  ngOnInit() {

    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => this.service.getSingleByCustomAction$<Relationship>("getSingle", Relationship, [new Filter("id", params.get("id"), "eq")]))
      )
      .subscribe((model: Relationship | any) => {

        this.createForm(model);
      });
  }

  ngOnChanges() {
    if (this.model) {
      this.createForm(this.model);
    }
  }

  createForm(model?: Relationship): void {
    if (this.theForm) {
      this.model = model;
      this.theForm.reset(this.bindForm());
    } else {
      this.theForm = this.fb.group({
        relationshipName: model?.relationshipName,
        id: [model?.id, Validators.required],
      });

      this.model = model;
    }
  }

  bindForm(): any {
    return {
      relationshipName: this.model.relationshipName,
      id: this.model.id,
    };
  }

  onSubmit() {
    if (this.theForm?.valid) {

      this.loading = true;
      this.service.postModelByCustomAction$<Relationship>("upsert", this.prepareSave(), Relationship)
        .pipe(
          catchError((err, caught) => {
            this.loading = false;
            FormValidation.parseSubmitErrors(err, this.theForm);
            return of(null as Relationship | any);
          })
        )
        .subscribe((savedData: any) => {
          this.loading = false;
          if (savedData) {
            this.goBack();
          }
        })
    } else {
      FormValidation.validateAllFormFields(this.theForm);
    }
  }

  prepareSave(): Relationship {
    const formModel = this.theForm.value;

    let saveModel: Relationship = new Relationship({
      relationshipName: formModel.relationshipName,
      id: formModel.id,
    });
    return saveModel;
  }

  cancel(): void {
    this.goBack();
  }

  goBack() {
    this.location.back();
  }

  undo(): void {
    this.theForm.reset(this.bindForm());
  }

  get relationshipName(): AbstractControl {
    return this.theForm.get('relationshipName');
  }
  get id(): AbstractControl {
    return this.theForm.get('id');
  }

}
