import { Component, OnInit, NgZone, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent, SelectableSettings } from '@progress/kendo-angular-grid';
import { MediaObserver } from "@angular/flex-layout";
import { DatePipe } from '@angular/common';
import { AuthenticationService } from '../../auth/auth.service';
import { NavigatorService } from './navigator.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Lookup } from '../../lookups/lookup';
import { LookupService } from '../../lookups/lookup.service';
import { Filter } from '../../_bases/filter';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { catchError, take } from 'rxjs/operators';
import { PersonNavigator } from './person-navigator';
import { UnitNavigator } from './unit-navigator';
import { MatDialog } from '@angular/material/dialog';
import { ImportAirAlphaRosterModalComponent } from './import-air-alpha-roster-modal/import-air-alpha-roster-modal.component';
import { ImportArmyAlphaRosterModalComponent } from './import-army-alpha-roster-modal/import-army-alpha-roster-modal.component';
import { PersonNavigatorTagUntag } from './person-navigator-tag-untag';
import { ReportAlphaRosterComponent } from '../../reports/report-alpha-roster/report-alpha-roster.component';
import { ReportAnnualStatementComponent } from '../../reports/report-annual-statement/report-annual-statement.component';
import { ReportBillingJournalNotPaidComponent } from '../../reports/report-billing-journal-not-paid/report-billing-journal-not-paid.component';
import { ReportMemberDetailComponent } from '../../reports/report-member-detail/report-member-detail.component';
import { ReportUnitRosterComponent } from '../../reports/report-unit-roster/report-unit-roster.component';
import { typeModel } from '../../_bases/typeModel';
import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ReportAnnualPaymentsReceivedComponent } from '../../reports/report-annual-payments-received/report-annual-payments-received.component';
import { ReportService } from '../../reports/report.service';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [`
  .k-grid td.k-state-selected, .k-grid tr.k-state-selected {
    background-color: #d1e3ff;
  }
`],
})
export class NavigatorComponent implements OnInit {

  @ViewChild(GridComponent)
  public gridPerson!: GridComponent;
  public gridUnit!: GridComponent;
  listPerson: PersonNavigator[] = [];
  listUnit: PersonNavigator[] = [];
  filtersPerson: Filter[] = [];
  filtersUnit: Filter[] = [];
  public personSelectionKeys: number[] = [];
  processingFiles: boolean = false;

  public personSelectableSettings: SelectableSettings = {
    checkboxOnly: true,
    mode: "multiple",
    drag: false,
  };

  frmPersonSearchFilters = new FormGroup({
    lastName: new FormControl(),
    firstName: new FormControl(),
    ssn: new FormControl(),
    dodid: new FormControl(),
    dobStart: new FormControl(),
    dobEnd: new FormControl(),
    uic: new FormControl(),
    effectiveStart: new FormControl(),
    effectiveEnd: new FormControl(),
    nextPmtDueStart: new FormControl(),
    nextPmtDueEnd: new FormControl(),
    paymentTypeId: new FormControl(),
    premiumStart: new FormControl(),
    premiumEnd: new FormControl(),
    groupId: new FormControl(),
    coverageTypeId: new FormControl(),
    taggedUntagged: new FormControl(),
    tagUntagTypeId: new FormControl(),
  });

  frmUnitSearchFilters = new FormGroup({
    uic: new FormControl(),
    unit: new FormControl(),
    battalionId: new FormControl(),
    brigadeId: new FormControl(),
    armyOrAir: new FormControl(),
  });

  paymentTypeLookups!: Lookup[];
  groupLookups!: Lookup[];
  coverageTypesLookups!: Lookup[];
  taggedUntaggedLookups: Lookup[] = [new Lookup("1", "Tagged"), new Lookup("0", "Untagged")];

  battalionsLookups!: Lookup[];
  brigadesLookups!: Lookup[];
  taggedUntaggedTypeLookups!: Lookup[];

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: NavigatorService,
    public media: MediaObserver,
    public authService: AuthenticationService,
    private lookupService: LookupService,
    private ngZone: NgZone,
    public datePipe: DatePipe,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
    public reportService: ReportService,
  ) {
    this.allPersonData = this.allPersonData.bind(this);
    this.allUnitData = this.allUnitData.bind(this);
  }

  public allPersonData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.listPerson, {}).data,
    };

    return result;
  }

  public allUnitData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.listUnit, {}).data,
    };

    return result;
  }

  ngOnInit(): void {

    this.lookupService.getList("paymentTypes", []).subscribe((data) => {
      this.paymentTypeLookups = data;
    });

    this.lookupService.getList("groups", []).subscribe((data) => {
      this.groupLookups = data;
    });

    this.lookupService.getList("coverageTypes", []).subscribe((data) => {
      this.coverageTypesLookups = data;
    });

    this.lookupService.getList("battalions", []).subscribe((data) => {
      this.battalionsLookups = data;
    });

    this.lookupService.getList("brigades", []).subscribe((data) => {
      this.brigadesLookups = data;
    });

    this.lookupService.getList("tagUntagTypes", []).subscribe((data) => {
      this.taggedUntaggedTypeLookups = data;
    });

    if (localStorage.hasOwnProperty("filtersPerson")) {

      let filtersPerson = JSON.parse(localStorage?.getItem("filtersPerson")!.toString());

      var firstName = filtersPerson.filter((f: Filter) => f.field == 'firstName');
      if (firstName.length > 0 && firstName[0].value !== null) {
        this.frmPersonSearchFilters.get('firstName')?.setValue(firstName[0].value);
      }

      var lastName = filtersPerson.filter((f: Filter) => f.field == 'lastName');
      if (lastName.length > 0 && lastName[0].value !== null) {
        this.frmPersonSearchFilters.get('lastName')?.setValue(lastName[0].value);
      }

      var ssn = filtersPerson.filter((f: Filter) => f.field == 'ssn');
      if (ssn.length > 0 && ssn[0].value !== null) {
        this.frmPersonSearchFilters.get('ssn')?.setValue(ssn[0].value);
      }

      var dodid = filtersPerson.filter((f: Filter) => f.field == 'dodid');
      if (dodid.length > 0 && dodid[0].value !== null) {
        this.frmPersonSearchFilters.get('dodid')?.setValue(dodid[0].value);
      }

      var dobStart = filtersPerson.filter((f: Filter) => f.field == 'dobStart');
      if (dobStart.length > 0 && dobStart[0].value !== null) {
        this.frmPersonSearchFilters.get('dobStart')?.setValue(dobStart[0].value);
      }
      var dobEnd = filtersPerson.filter((f: Filter) => f.field == 'dobEnd');
      if (dobEnd.length > 0 && dobEnd[0].value !== null) {
        this.frmPersonSearchFilters.get('dobEnd')?.setValue(dobEnd[0].value);
      }

      var uic = filtersPerson.filter((f: Filter) => f.field == 'uic');
      if (uic.length > 0 && uic[0].value !== null) {
        this.frmPersonSearchFilters.get('uic')?.setValue(uic[0].value);
      }

      var effectiveStart = filtersPerson.filter((f: Filter) => f.field == 'effectiveStart');
      if (effectiveStart.length > 0 && effectiveStart[0].value !== null) {
        this.frmPersonSearchFilters.get('effectiveStart')?.setValue(effectiveStart[0].value);
      }

      var effectiveEnd = filtersPerson.filter((f: Filter) => f.field == 'effectiveEnd');
      if (effectiveEnd.length > 0 && effectiveEnd[0].value !== null) {
        this.frmPersonSearchFilters.get('effectiveEnd')?.setValue(effectiveEnd[0].value);
      }

      var nextPmtDueStart = filtersPerson.filter((f: Filter) => f.field == 'nextPmtDueStart');
      if (nextPmtDueStart.length > 0 && nextPmtDueStart[0].value !== null) {
        this.frmPersonSearchFilters.get('nextPmtDueStart')?.setValue(nextPmtDueStart[0].value);
      }

      var nextPmtDueEnd = filtersPerson.filter((f: Filter) => f.field == 'nextPmtDueEnd');
      if (nextPmtDueEnd.length > 0 && nextPmtDueEnd[0].value !== null) {
        this.frmPersonSearchFilters.get('nextPmtDueEnd')?.setValue(nextPmtDueEnd[0].value);
      }

      var paymentTypeId = filtersPerson.filter((f: Filter) => f.field == 'paymentTypeId');
      if (paymentTypeId.length > 0 && paymentTypeId[0].value !== null) {
        this.frmPersonSearchFilters.get('paymentTypeId')?.setValue(paymentTypeId[0].value);
      }

      var premiumStart = filtersPerson.filter((f: Filter) => f.field == 'premiumStart');
      if (premiumStart.length > 0 && premiumStart[0].value !== null) {
        this.frmPersonSearchFilters.get('premiumStart')?.setValue(premiumStart[0].value);
      }

      var premiumEnd = filtersPerson.filter((f: Filter) => f.field == 'premiumEnd');
      if (premiumEnd.length > 0 && premiumEnd[0].value !== null) {
        this.frmPersonSearchFilters.get('premiumEnd')?.setValue(premiumEnd[0].value);
      }

      var groupId = filtersPerson.filter((f: Filter) => f.field == 'groupId');
      if (groupId.length > 0 && groupId[0].value !== null) {
        this.frmPersonSearchFilters.get('groupId')?.setValue(groupId[0].value);
      }

      var coverageTypeId = filtersPerson.filter((f: Filter) => f.field == 'coverageTypeId');
      if (coverageTypeId.length > 0 && coverageTypeId[0].value !== null) {
        this.frmPersonSearchFilters.get('coverageTypeId')?.setValue(coverageTypeId[0].value);
      }

      var taggedUntagged = filtersPerson.filter((f: Filter) => f.field == 'taggedUntagged');
      if (taggedUntagged.length > 0 && taggedUntagged[0].value !== null) {
        this.frmPersonSearchFilters.get('taggedUntagged')?.setValue(taggedUntagged[0].value);
      }

    }

    if (localStorage.hasOwnProperty("filtersUnit")) {

      let filtersUnit = JSON.parse(localStorage?.getItem("filtersUnit")!.toString());

      var uic = filtersUnit.filter((f: Filter) => f.field == 'uic');
      if (uic.length > 0 && uic[0].value !== null) {
        this.frmUnitSearchFilters.get('uic')?.setValue(uic[0].value);
      }

      var unit = filtersUnit.filter((f: Filter) => f.field == 'unit');
      if (unit.length > 0 && unit[0].value !== null) {
        this.frmUnitSearchFilters.get('unit')?.setValue(unit[0].value);
      }

      var battalionId = filtersUnit.filter((f: Filter) => f.field == 'battalionId');
      if (battalionId.length > 0 && battalionId[0].value !== null) {
        this.frmUnitSearchFilters.get('battalionId')?.setValue(battalionId[0].value);
      }

      var brigadeId = filtersUnit.filter((f: Filter) => f.field == 'brigadeId');
      if (brigadeId.length > 0 && brigadeId[0].value !== null) {
        this.frmUnitSearchFilters.get('brigadeId')?.setValue(brigadeId[0].value);
      }

      var armyOrAir = filtersUnit.filter((f: Filter) => f.field == 'armyOrAir');
      if (armyOrAir.length > 0 && armyOrAir[0].value !== null) {
        this.frmUnitSearchFilters.get('armyOrAir')?.setValue(armyOrAir[0].value);
      }
    }
  }

  searchPerson(): void {
    this.loadPersonList();
  }

  loadPersonList() {

    this.personSelectionKeys = [];

    this.filtersPerson = [];
    this.filtersPerson.push(new Filter("firstName", this.frmPersonSearchFilters.get('firstName')?.value, "eq"));
    this.filtersPerson.push(new Filter("lastName", this.frmPersonSearchFilters.get('lastName')?.value, "eq"));
    this.filtersPerson.push(new Filter("ssn", this.frmPersonSearchFilters.get('ssn')?.value, "eq"));
    this.filtersPerson.push(new Filter("dodid", this.frmPersonSearchFilters.get('dodid')?.value, "eq"));
    this.filtersPerson.push(new Filter("dobStart", this.frmPersonSearchFilters.get('dobStart')?.value, "eq"));
    this.filtersPerson.push(new Filter("dobEnd", this.frmPersonSearchFilters.get('dobEnd')?.value, "eq"));
    this.filtersPerson.push(new Filter("uic", this.frmPersonSearchFilters.get('uic')?.value, "eq"));
    this.filtersPerson.push(new Filter("effectiveStart", this.frmPersonSearchFilters.get('effectiveStart')?.value, "eq"));
    this.filtersPerson.push(new Filter("effectiveEnd", this.frmPersonSearchFilters.get('effectiveEnd')?.value, "eq"));
    this.filtersPerson.push(new Filter("nextPmtDueStart", this.frmPersonSearchFilters.get('nextPmtDueStart')?.value, "eq"));
    this.filtersPerson.push(new Filter("nextPmtDueEnd", this.frmPersonSearchFilters.get('nextPmtDueEnd')?.value, "eq"));
    this.filtersPerson.push(new Filter("paymentTypeId", this.frmPersonSearchFilters.get('paymentTypeId')?.value, "eq"));
    this.filtersPerson.push(new Filter("premiumStart", this.frmPersonSearchFilters.get('premiumStart')?.value, "eq"));
    this.filtersPerson.push(new Filter("premiumEnd", this.frmPersonSearchFilters.get('premiumEnd')?.value, "eq"));
    this.filtersPerson.push(new Filter("groupId", this.frmPersonSearchFilters.get('groupId')?.value, "eq"));
    this.filtersPerson.push(new Filter("coverageTypeId", this.frmPersonSearchFilters.get('coverageTypeId')?.value, "eq"));
    this.filtersPerson.push(new Filter("taggedUntagged", this.frmPersonSearchFilters.get('taggedUntagged')?.value, "eq"));

    localStorage.setItem("filtersPerson", JSON.stringify(this.filtersPerson));

    this.service
      .getByCustomAction$<PersonNavigator>("getPersonOnNavigator", PersonNavigator, this.filtersPerson)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {

        console.log(data);

        this.listPerson = data;
      });
  }

  exportPersonToCSV() {
    let columnHeaders: any[] = [
      { field: "firstName", title: "firstName" },
      { field: "middleName", title: "middleName" },
      { field: "lastName", title: "lastName" },
      { field: "ssn", title: "ssn" },
      { field: "uic", title: "uic" },
      //{ field: "unitCode", title: "unitCode" },
      { field: "premium", title: "premium" },
      { field: "pmtType", title: "pmtType" },
      { field: "rosterName", title: "rosterName" },
    ];

    this.service.ExportToCSV(columnHeaders, this.listPerson, ["id", "unitCode"], "People.csv");
  }

  tagUntagPersonClick(): void {
    console.log(this.personSelectionKeys);
    let personNavigatorTagUntag = new PersonNavigatorTagUntag();
    personNavigatorTagUntag.allIds = this.listPerson.map(({ id }) => id);
    personNavigatorTagUntag.selectedIds = this.personSelectionKeys;
    personNavigatorTagUntag.tagUntagTypeId = this.frmPersonSearchFilters.get('tagUntagTypeId')?.value;

    this.service.postModelByCustomAction$<PersonNavigatorTagUntag>("tagUntagPerson", personNavigatorTagUntag, PersonNavigatorTagUntag)
      .subscribe((savedData: any) => {
        if (savedData) {
          this.loadPersonList();
        }
      })
  }

  importFiles(): void {

    this.processingFiles = true;

    const tymodel = new typeModel<any>({});

    this.service.postByCustomAction$<any>("importFilesFromDisk", tymodel)
      .subscribe((res: any) => {
        this.processingFiles = false;
        alert('Files Processed');
      })
  }

  searchUnit(): void {
    this.loadUnitList();
  }

  loadUnitList() {

    this.filtersUnit = [];
    this.filtersUnit.push(new Filter("uic", this.frmUnitSearchFilters.get('uic')?.value, "eq"));
    this.filtersUnit.push(new Filter("unit", this.frmUnitSearchFilters.get('unit')?.value, "eq"));
    this.filtersUnit.push(new Filter("battalionId", this.frmUnitSearchFilters.get('battalionId')?.value, "eq"));
    this.filtersUnit.push(new Filter("brigadeId", this.frmUnitSearchFilters.get('brigadeId')?.value, "eq"));
    this.filtersUnit.push(new Filter("armyOrAir", this.frmUnitSearchFilters.get('armyOrAir')?.value, "eq"));

    localStorage.setItem("filtersUnit", JSON.stringify(this.filtersUnit));

    this.service
      .getByCustomAction$<UnitNavigator>("getUnitOnNavigator", UnitNavigator, this.filtersUnit)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {
        console.log(data);
        this.listUnit = data;
      });
  }

  exportUnitToCSV() {
    let columnHeaders: any[] = [
      { field: "uic", title: "uic" },
      { field: "unitName", title: "unitName" },
      { field: "battalionName", title: "battalionName" },
      { field: "brigadeName", title: "brigadeName" },
    ];

    this.service.ExportToCSV(columnHeaders, this.listUnit, ["id", "unitCode"], "Unit.csv");
  }

  resetPerson(): void {
    this.frmPersonSearchFilters.reset();
    this.listPerson = [];
    localStorage.removeItem("filtersPerson");
  }

  resetUnit(): void {
    this.frmUnitSearchFilters.reset();
    this.listUnit = [];
    localStorage.removeItem("filtersUnit");
  }

  public rowCallback(context: RowClassArgs) {
    return {
    };
  }

  importAirAlphaRoster(): void {
    let dialogRef = this.dialog.open(ImportAirAlphaRosterModalComponent, {
      data: {},
      width: "420px"
    });
  }

  importArmyAlphaRoster(): void {
    let dialogRef = this.dialog.open(ImportArmyAlphaRosterModalComponent, {
      data: {},
      width: "420px"
    });
  }

  alphaRoster(): void {

    let personIds = this.getPersonIdFilter();

    if (personIds != "") {
      //let dialogRef = this.dialog.open(ReportAlphaRosterComponent, {
      //  data: personIds,
      //  width: "90vw"
      //});

      const tymodel = new typeModel<any>({
        personIds: personIds,
      });

      this.reportService
        .postByCustomAction$<any>("getAlphaRoster", tymodel)
        .pipe(
          catchError((err, caught) => {
            return of(null as any);
          })
        ).subscribe((data: any) => {

          console.log(data);

          const blob = new Blob([data.html], { type: 'text/html' });
          const url = window.URL.createObjectURL(blob);
          //window.open(url);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'alphaRoster.html';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

          //this.list = data;
          //this.expandedDetailKeys = this.list.map((itm) => itm.id);
        });
    }
  }

  annualStatement(): void {

    let personIds = this.getPersonIdFilter();

    if (personIds != "") {
      let dialogRef = this.dialog.open(ReportAnnualStatementComponent, {
        data: personIds,
        width: "10.5in"
      });
    }
  }

  billingJournalNotPaid(): void {

    let personIds = this.getPersonIdFilter();

    if (personIds != "") {
      let dialogRef = this.dialog.open(ReportBillingJournalNotPaidComponent, {
        data: personIds,
        width: "90vw"
      });
    }
  }

  memberDetail(): void {

    let personIds = this.getPersonIdFilter();

    if (personIds != "") {
      let dialogRef = this.dialog.open(ReportMemberDetailComponent, {
        data: personIds,
        width: "10.5in"
      });
    }
  }

  getPersonIdFilter(): any {
    let personIds = "";

    if (this.personSelectionKeys.length > 0) {
      personIds = this.personSelectionKeys.join(',');
    }
    else if (this.listPerson.length > 0) {
      personIds = this.listPerson.map(m => { return m.id; }).join(",");
    }

    return personIds;
  }

  unitRoster(): void {


    if (this.listUnit.length > 0) {

      let unitIds = this.listUnit.map(m => { return m.id; }).join(",");

      if (unitIds != "") {
        let dialogRef = this.dialog.open(ReportUnitRosterComponent, {
          data: unitIds,
          width: "90vw"
        });
      }
    }
  }

  deleteClick(value: any): void {

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: "Are you sure you want to delete this Person?",
      width: "40%",
    });

    dialogRef.afterClosed().subscribe((iscontinue: boolean) => {
      if (iscontinue) {
        const model = new typeModel<any>(value);
        this.service
          .postByCustomAction$("deletePerson", model)
          .pipe(
            catchError((err, caught) => {
              return of(null as any);
            })
          )
          .subscribe((res) => {
            if (res != "") {
              let verticalPosition: MatSnackBarVerticalPosition = "top";

              let snackBarRef = this.snackBar.open(res, "OK", {
                duration: 2500,
                panelClass: ["warning-snackbar"],
                verticalPosition: verticalPosition,
              });
              snackBarRef.afterDismissed().subscribe((result) => { });
              snackBarRef.onAction().subscribe(() => {
                snackBarRef.dismiss();
              });
            }

            this.loadPersonList();
          });
      }
    });
  }
}
