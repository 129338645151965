import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../auth/auth.service';
import { ApplicantUpdatesApproveReject } from './applicant-updates-approve-reject';
import { PersonReadOnly } from './person-readonly';
import { PersonService } from './person.service';

@Component({
  selector: 'app-applicant-updates-approve-or-reject-modal',
  templateUrl: './applicant-updates-approve-or-reject-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .k-actions {
        display: none;
      }
    `,
  ],
})
export class ApplicantUpdatesApproveOrRejectModalComponent implements OnInit {
  constructor(
    public datePipe: DatePipe,
    private service: PersonService,
    public authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public model: PersonReadOnly,
    public dialogRef: MatDialogRef<ApplicantUpdatesApproveOrRejectModalComponent>,
    private router: Router
  ) { }

  ngOnInit() { }

  approve() {
    this.approveOrReject(true);
  }

  reject() {
    this.approveOrReject(false);
  }

  approveOrReject(isApproved: boolean) {

    let applicantUpdatesApproveReject = new ApplicantUpdatesApproveReject();
    applicantUpdatesApproveReject.personId = this.model.id;
    applicantUpdatesApproveReject.isApproved = isApproved;

    this.service.postModelByCustomAction$<ApplicantUpdatesApproveReject>("approveOrRejectApplicantUpdates", applicantUpdatesApproveReject, ApplicantUpdatesApproveReject)
      .subscribe((savedData: any) => {
        if (savedData) {
          this.authService.eventNewApplicationCountChangeRaised();
          this.dialogRef.close(true);
        }
      });
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
