<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <h2 mat-dialog-title>
    Approve or Reject
  </h2>

  <div class="mat-dialog-content" style="display:block !important">
    <div fxLayout="column" fxLayoutGap="8px" class="m-2">
      <div fxFlex *ngIf="this.model">
        <div fxLayout="column" fxLayoutGap="8px">
          <div fxFlex="100">
            {{ this.model.oldJsonData }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="footer">
    <button mat-raised-button color="primary" (click)="approve()">
      Approve
    </button> &nbsp;
    <button mat-raised-button color="danger" (click)="reject()">
      Reject
    </button>&nbsp;
    <button mat-stroked-button type="button" (click)="cancel()">
      Close
    </button>
  </section>
</div>
<style>
  table {
    border: 1px solid black;
    border-collapse: collapse;
  }

  tr {
    border: 1px solid black;
  }

  td {
    border: 1px solid black;
  }
</style>
