import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';
import { Filter } from '../../../_bases/filter';
import { FileRestrictions, SuccessEvent, UploadEvent } from '@progress/kendo-angular-upload';
import { environment } from '../../../../environments/environment';
import { FormControl, FormGroup } from '@angular/forms';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { JumpsService } from '../jumps.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../auth/auth.service';
import { MissingJumps } from './missing-jumps';
import { typeModel } from '../../../_bases/typeModel';
import { MatDialog } from '@angular/material/dialog';
import { AlertModalComponent } from '../../../shared/alert/alert-modal.component';

@Component({
  selector: 'app-missing-jumps-list',
  templateUrl: './missing-jumps-list.component.html',
  animations: [
  ]
})
export class MissingJumpsListComponent implements OnInit {

  @ViewChild(GridComponent) public grid!: GridComponent;
  @ViewChild('aTest') aTest: ElementRef | any;

  list: MissingJumps[] = [];
  filters: Filter[] = [];

  loading: boolean = true;

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: JumpsService,
    private ngZone: NgZone,
    private router: Router,
    public authService: AuthenticationService,
    private dialog: MatDialog,
  ) {
    this.allData = this.allData.bind(this);
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.list, {}).data,
    };

    return result;
  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {

    this.filters = [];

    this.service
      .getByCustomAction$<MissingJumps>("getMissingJUMPS", MissingJumps, this.filters)
      .pipe(
        catchError((err, caught) => {
          this.loading = false;
          return of(null as any);
        })
      ).subscribe((data: MissingJumps[]) => {
        console.log(data);
        this.loading = false;
        this.list = data;
      });
  }

  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }

  private fitColumns(): void {
    this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
      this.grid.autoFitColumns();
    });
  }

  public rowCallback(context: RowClassArgs) {
    return {
    };
  }

  applyJumps(id: string): string {

    const tymodel = new typeModel<any>({
      id: id,
    });

    this.service
      .postByCustomAction$<any>("processMissingJump", tymodel)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe((data: any) => {

        if (data != true) {

          const dialogRef = this.dialog.open(AlertModalComponent, {
            data: "Unable to match SSN.",
            width: "40%",
          });
        }

        this.loadList();
       
      });
    return "";
  }

}
