
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, Validators } from "@angular/forms"
import { ActivatedRoute, Router, ParamMap } from "@angular/router";

import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { BankAccountType } from "../bankaccounttype";
import { BankAccountTypeService } from "../bankaccounttype.service";  
import { BaseItemComponent } from '../../../_bases/base-item-component';
import { FormValidation } from '../../../shared/forms/form-validation';
import { Filter } from '../../../_bases/filter';


@Component({
  selector: 'app-bankaccounttype-detail',
  templateUrl: './bankaccounttype-detail.component.html',
})
export class BankAccountTypeComponent extends BaseItemComponent implements OnInit, OnDestroy {

  model: BankAccountType | any;
  public loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private service: BankAccountTypeService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router) {
    super();
  }

  ngOnInit() {


    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => this.service.getSingleByCustomAction$<BankAccountType>("getSingle", BankAccountType, [new Filter("id", params.get("id"), "eq")]))
      )
      .subscribe((model: BankAccountType | any) => {

        this.createForm(model);
      });
  }

  ngOnChanges() {
    if (this.model) {
      this.createForm(this.model);
    }
  }

  createForm(model?: BankAccountType): void {
    if (this.theForm) {
      this.model = model;
      this.theForm.reset(this.bindForm());
    } else {
      this.theForm = this.fb.group({
        id: [model?.id, Validators.required],
        name: [model?.name, Validators.required],
      });

      this.model = model;
    }
  }

  bindForm(): any {
    return {
      id: this.model.id,
      name: this.model.name,
    };
  }

  onSubmit() {
    if (this.theForm?.valid) {

      this.loading = true;
      this.service.postModelByCustomAction$<BankAccountType>("upsert", this.prepareSave(), BankAccountType)
        .pipe(
          catchError((err, caught) => {
            this.loading = false;
            FormValidation.parseSubmitErrors(err, this.theForm);
            return of(null as BankAccountType | any);
          })
        )
        .subscribe((savedData: any) => {
          this.loading = false;
          if (savedData) {
            this.goBack();
          }
        })
    } else {
      FormValidation.validateAllFormFields(this.theForm);
    }
  }

  prepareSave(): BankAccountType {
    const formModel = this.theForm.value;
    let saveModel: BankAccountType = new BankAccountType({
      id: formModel.id,
      name: formModel.name,
    });
    return saveModel;
  }

  cancel(): void {
    this.goBack();
  }

  goBack() {
    this.location.back();
  }

  undo(): void {
    this.theForm.reset(this.bindForm());
  }

  get id(): AbstractControl {
    return this.theForm.get('id');
  }
  get name(): AbstractControl {
    return this.theForm.get('name');
  }

}
