export class Filter {

    constructor(field: string, value: any)
    constructor(field: string, value: any, operator: string)
    constructor(field: string, value: any, operator?: string) {
        this.field = field;
        this.value = value;
        this.operator = operator ? operator : "eq";
    }
    
    field: string;
    value: any;
    operator: string;
    
}