export class CoverageTypeReadOnly {

  constructor()
  constructor(jsonData: CoverageTypeReadOnly)
  constructor(jsonData?: CoverageTypeReadOnly) {
    if (jsonData) {
      Object.assign(this, jsonData);
    }
  }

  id: number | undefined;
  coverageTypeName: string | undefined;
  coverageTypeUnitCode: string | undefined;
  comments: string | undefined;
  seqNbr: string | undefined;

}


