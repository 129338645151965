import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { Observable, Subject } from 'rxjs';
import { AuthenticationService } from '../../auth/auth.service';
import { Lookup } from '../../lookups/lookup';
import { LookupService } from '../../lookups/lookup.service';
import { NewApplicationPersonChild } from './newapplicationpersonchild';

@Component({
  selector: 'app-newapplicationpersonchild-list',
  templateUrl: './newapplicationpersonchild-list.component.html',
  animations: [],
})
export class NewApplicationPersonChildListComponent implements OnInit {
  @ViewChild(GridComponent)
  public grid!: GridComponent;

  list: NewApplicationPersonChild[] = [];
  @Input() newItemProperties: any;
  public loading: boolean = false;

  public formGroup: FormGroup | any;
  private editedRowIndex: number | undefined;
  relationshipIdLookups$: Observable<Lookup[]> | any;
  relationshipsArray: Lookup[] | any;

  private increment: number = 0;
  createPersonChildFormGroup = (dataItem: NewApplicationPersonChild) =>
    new FormGroup({
      id: new FormControl(dataItem.id),
      personId: new FormControl(dataItem.personId, Validators.required),
      firstName: new FormControl(dataItem.firstName, Validators.required),
      lastName: new FormControl(dataItem.lastName, Validators.required),
      middleName: new FormControl(dataItem.middleName), //, Validators.required),
      dob: new FormControl(dataItem.dob, Validators.required),
      gender: new FormControl(dataItem.gender, Validators.required),
      isNew: new FormControl(dataItem.isNew == true ? true : false),
    });

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private ngZone: NgZone,
    private lookupService: LookupService,
    private authService: AuthenticationService
  ) {
    this.relationshipIdLookups$ = this.lookupService.getList(
      'relationships',
      []
    );

    this.relationshipIdLookups$.subscribe((s: Lookup[]) => {
      this.relationshipsArray = s;
    });
  }

  ngOnInit(): void {}

  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }

  private fitColumns(): void {
    //this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
    //  this.grid.autoFitColumns();
    //});
  }

  public rowCallback(context: RowClassArgs) {
    return {};
  }

  public addPersonChildHandler(evt: any) {
    this.closePersonChildEditor(evt.sender);

    this.increment += 1;

    this.formGroup = this.createPersonChildFormGroup({
      personId: 0,
      firstName: '',
      lastName: '',
      middleName: '',
      dob: new Date(),
      id: this.increment,
      gender: '',
      isNew: true,
    });

    evt.sender.addRow(this.formGroup);
  }

  public editPersonChildHandler({
    sender,
    rowIndex,
    dataItem,
  }: {
    sender: any;
    rowIndex: any;
    dataItem: any;
  }) {
    this.closePersonChildEditor(sender);
    this.formGroup = this.createPersonChildFormGroup(dataItem);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelPersonChildHandler({
    sender,
    rowIndex,
  }: {
    sender: any;
    rowIndex: any;
  }) {
    this.closePersonChildEditor(sender, rowIndex);
  }

  public savePersonChildHandler({
    sender,
    rowIndex,
    formGroup,
    isNew,
  }: {
    sender: any;
    rowIndex: any;
    formGroup: any;
    isNew: any;
  }) {
    const personChild: NewApplicationPersonChild = formGroup.value;

    if (isNew) {
      if (this.list == null) {
        this.list = [];
      }
      this.list = [...this.list, personChild];
    } else {
      this.list.forEach((o) => {
        if (o.id == personChild.id) {
          o.firstName = personChild.firstName;
          o.lastName = personChild.lastName;
          o.middleName = personChild.middleName;
          o.dob = personChild.dob;
          o.gender = personChild.gender;
          o.isNew = personChild.isNew;
        }
      });
    }

    setTimeout(() => {
      this.authService.eventPersonNewAppChildDataEventRaised(true);
    }, 500);
    this.grid.data = this.list;
    sender.closeRow(rowIndex);
  }

  public removePersonChildHandler({ dataItem }: { dataItem: any }) {
    let tempList: NewApplicationPersonChild[] = [];

    this.list.forEach((o) => {
      if (o.id != dataItem.id) {
        tempList.push(o);
      }
    });

    setTimeout(() => {
      this.authService.eventPersonNewAppChildDataEventRaised(true);
    }, 500);
    this.list = tempList;
    this.grid.data = this.list;
  }

  private closePersonChildEditor(
    grid: GridComponent,
    rowIndex = this.editedRowIndex
  ) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
}
