<form *ngIf="model" [formGroup]="theForm" (ngSubmit)="onSubmit()" class="detail-component" novalidate>
  <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="60">

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Relationship</mat-label>
      <input matInput formControlName="relationshipName" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="relationshipName.touched && relationshipName.hasError('required')">
        Relationship is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('relationshipName')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <div fxFlex>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <section class="footer">
      <button mat-raised-button color="primary" type="submit" [disabled]="theForm.pristine || (loading)">
        Save
      </button>
      <button mat-button type="button" (click)="undo()" [disabled]="theForm.pristine">
        Undo
      </button>
      <button mat-button type="button" (click)="cancel()">
        Cancel
      </button>
    </section>
  </div>
</form>

