<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <!--<h2 mat-dialog-title>
    Am I Insured?
  </h2>-->

  <div class="mat-dialog-content" style="display:block !important">
    <div fxLayout="column" fxLayoutGap="8px" class="m-2">
      <div fxFlex>
        You are within 90 days of your enlistment date. You will be rolled into the 1 year free $10,000 coverage. Payments of $3.66 will begin 13 months after your enrollment.
      </div>
    </div>
  </div>
  <section class="footer">
    <button mat-stroked-button type="button" (click)="cancel()">
      Close
    </button>
  </section>
</div>
