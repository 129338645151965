  <kendo-grid #grid fxFlex="100" [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [filterable]="false"
            [groupable]="false" [pageSize]="100" [pageable]="(list && (list.length > 100) ? true : false)" [rowClass]="rowCallback"
            class="condensed" [resizable]="true"
            (dataStateChange)="onDataStateChange($event)"
            (edit)="editPersonInsBenHandler($event)"
            (cancel)="cancelPersonInsBenHandler($event)"
            (save)="savePersonInsBenHandler($event)"
            (remove)="removePersonInsBenHandler($event)"
            (add)="addPersonInsBenHandler($event)">
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand>Add new</button>
  </ng-template>
  <!--<kendo-grid-column field="personId" title="Person Id" [filterable]="true" filter="numeric"></kendo-grid-column>-->
  <kendo-grid-column field="beneficiary" title="Beneficiary" [filterable]="true"></kendo-grid-column>
  <kendo-grid-column field="relationship" title="Relationship" [filterable]="true"></kendo-grid-column>
  <!--<kendo-grid-column field="relationship" title="Relationship" [filterable]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{relationships(dataItem.relationship)?.value}}
    </ng-template>
    <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">
      <kendo-combobox [data]="relationshipIdLookups$ | async" textField="value" valueField="value"
                      [valuePrimitive]="true"
                      [suggest]="true" [popupSettings]="{width: 360,height: 150}"
                      [formControl]="formGroup.get(column.field)">
      </kendo-combobox>
    </ng-template>
  </kendo-grid-column>-->
  <kendo-grid-column field="percentage" title="Percentage" editor="numeric" [filterable]="true"></kendo-grid-column>
  <kendo-grid-column field="beneficiaryDate" editor="date" title="Beneficiary Date" [filterable]="true" filter="date" format="{0:d}"></kendo-grid-column>
  <kendo-grid-command-column title="-">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridEditCommand [primary]="true">Edit</button>
      <button kendoGridRemoveCommand>Remove</button>
      <button kendoGridSaveCommand [disabled]="formGroup?.invalid">
        {{ isNew ? "Add" : "Update" }}
      </button>
      <button kendoGridCancelCommand>
        {{ isNew ? "Discard changes" : "Cancel" }}
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
