<div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="60">
  <h2>
    Thank you for your submission
  </h2>
  <p *ngIf="this.isDocUSign == true">
    The next step is to sign your application.
  </p>
  <p *ngIf="this.isDocUSign == true">
    Please check your email for a message from DocuSign. Follow the instructions to sign your document.
  </p>
  <p *ngIf="this.isEditApplication == true">
    The changes in your application would be reviewed shortly.
  </p>
</div>
