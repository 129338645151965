export class ArmyReviewTransfers {

  constructor();
  constructor(jsonData?: ArmyReviewTransfers) {

    if (jsonData) {
      Object.assign(this, jsonData);
    }
  }

  public id: number | undefined;
  public piqName: string | undefined;
  public payCode: string | undefined;
  public currentUnitCode: string | undefined;
  public currentPayCode: string | undefined;
  public newUnitCode: string | undefined;
  public newPayCode: string | undefined;
}
