<div fxLayout="column" fxLayoutGap="8px" class="m-2  main-ctr">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      Insured Percentage Report
    </h1>
  </div>
  <div fxLayout="row" fxLayoutGap="8px" class="m-2">
    <kendo-grid [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [columnMenu]="{ filter: true }"
                [groupable]="false" [pageSize]="100"
                [pageable]="(list && (list.length > 100) ? true : false)" [rowClass]="rowCallback" class="condensed" #grid fxFlex
                id="grdInsuredPercentageReport">
      <ng-template kendoGridToolbarTemplate>
        <button type="button" kendoGridPDFCommand icon="file-pdf">Export to PDF</button>
        <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
      </ng-template>
      <kendo-grid-excel fileName="InsuredPercentageReport.xlsx" [fetchData]="this.allData"></kendo-grid-excel>
      <kendo-grid-pdf fileName="InsuredPercentageReport.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true" [landscape]="true">
        <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
      </kendo-grid-pdf>
      <kendo-grid-column field="brigadeName" title="Brigade" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="battalionName" title="Battalion" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="uic" title="UIC" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="unitName" title="Unit" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="memberCount" title="# Members" [filterable]="true"></kendo-grid-column>
      <kendo-grid-column field="insuredMemberCount" title="# w/ Insurance" [filterable]="true"></kendo-grid-column>
    </kendo-grid>
  </div>

</div>
