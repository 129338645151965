import { HttpClient, HttpParams } from '@angular/common/http';
import { Type } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Filter } from './filter';
import { IDto } from './iDto';
import { typeModel } from './typeModel';

// @Injectable()
export abstract class BaseService<T extends IDto<T>, S extends IDto<S>> {
  readonly http: HttpClient;
  readonly remoteApiController: string;
  readonly remoteApiControllerFullPath: string;
  readonly keyPropertyName: string;
  readonly genericType: { new (json: T): T };
  readonly genericTypeReadOnly: { new (json: S): S };

  private source = new Subject<T>();
  sourceUpdated$ = this.source.asObservable();

  private cancelled = new Subject<boolean>();
  cancelledUpdated$ = this.cancelled.asObservable();

  public loading = new BehaviorSubject<boolean>(false);
  loading$ = this.loading.asObservable();

  static createEntity<T>(type: { new (): T }): T {
    return new type();
  }

  /**
   * Construct a new Base Service for a Type (Table) and its ReadOnly partner.
   * @param http - the HttpClient instance
   * @param remoteApiController - the name of the Web API controller, typically the table name
   * @param keyPropertyName - the field name of the Primary Key
   */
  constructor(
    http: HttpClient,
    remoteApiController: string,
    keyPropertyName: string
  );
  /**
   * Construct a new Base Service for a Type (Table) and its ReadOnly partner.
   * @param http - the HttpClient instance
   * @param remoteApiController - the name of the Web API controller, typically the table name
   * @param keyPropertyName - the field name of the Primary Key
   * @param genericType - the type of the object representing the Table being requested
   * @param allowAnonymous - wires up the API Url for the anonymous access route
   * @param genericTypeReadOnly - the type of the ReadOnly object representing the Table being requested
   */
  constructor(
    http: HttpClient,
    remoteApiController: string,
    keyPropertyName: string,
    allowAnonymous: boolean
  );
  constructor(
    http: HttpClient,
    remoteApiController: string,
    keyPropertyName: string,
    allowAnonymous: boolean,
    genericType: { new (json: T): T } | any,
    genericTypeReadOnly: { new (json: S): S } | any
  );
  constructor(
    http: HttpClient,
    remoteApiController: string,
    keyPropertyName: string,
    allowAnonymous?: boolean,
    genericType?: { new (json: T): T } | any,
    genericTypeReadOnly?: { new (json: S): S } | any
  ) {
    this.http = http;
    this.remoteApiController = remoteApiController;
    this.remoteApiControllerFullPath =
      (allowAnonymous
        ? environment.serviceUrlAnonymous
        : environment.serviceUrl) + remoteApiController;
    this.keyPropertyName = keyPropertyName;
    this.genericType = genericType;
    this.genericTypeReadOnly = genericTypeReadOnly;
  }

  /**
   * Get one record by its primary key.
   * @param id - the primary key of the item to load
   */
  getOne$(id: any): Observable<T> {
    const url = `${this.remoteApiControllerFullPath}/getsingle?id=${id}`;
    return this.http.get<T>(url, { responseType: 'json' }).pipe(
      map((result) => {
        this.loading.next(false);
        return new this.genericType(result);
      })
    );
  }

  /**
   * Get a new record, with defaults set by the Web API.
   * @param defaultProperties - the object with defaults to apply to the new object
   */
  getNew$(defaultProperties?: any): Observable<T> {
    return this.getOne$(environment.defaultNewId).pipe(
      map((result) => {
        this.applyParentProperties<T>(result, defaultProperties);
        return result;
      })
    );
  }

  /**
   * Get the list of ReadOnly objects.
   * @param filter - an optional Filter array to apply in the Web API
   * @param customReadOnlyListAction - an optional custom action. If none is supplied, this method will use the 'list' action.
   */
  getReadOnlyList$(
    filter?: Filter[],
    customReadOnlyListAction?: string
  ): Observable<S[]> {
    var action = customReadOnlyListAction ? customReadOnlyListAction : 'list';
    var url = this.remoteApiControllerFullPath + '/' + action;

    var params = new HttpParams();
    if (filter && filter.length > 0) {
      filter.forEach((itm) => {
        params = params.append('filter', JSON.stringify(itm));
      });
    }
    this.loading.next(true);
    return this.http.get<S[]>(url, { params: params }).pipe(
      map((result) => {
        let items: S[] = [];
        result.forEach((o) => {
          items.push(new this.genericTypeReadOnly(o));
        });
        this.loading.next(false);
        return items;
      })
    );
  }

  /**
   * Get a list of 'U' by calling a custom action on the table's default controller
   * @param customAction - the action to call in the Web API
   * @param typeOfU - the generic type for the object array to return
   */
  getByCustomAction$<U>(
    customAction: string,
    typeOfU: { new (json: U): U }
  ): Observable<U[]>;
  /**
   * Get a list of 'U' by calling a custom action on the table's default controller
   * @param customAction - the action to call in the Web API
   * @param typeOfU - the generic type for the object array to return
   * @param filter - a Filter array to apply in the Web API
   */
  getByCustomAction$<U>(
    customAction: string,
    typeOfU: { new (json: U): U },
    filter: Filter[]
  ): Observable<U[]>;
  getByCustomAction$<U>(
    customAction: string,
    typeOfU: { new (json: U): U },
    filter?: Filter[]
  ): Observable<U[]> {
    this.loading.next(true);
    const url = `${this.remoteApiControllerFullPath}/${customAction}`;
    var params = new HttpParams();
    if (filter && filter.length > 0) {
      filter.forEach((itm) => {
        params = params.append('filter', JSON.stringify(itm));
      });
    }
    return this.http
      .get<U[]>(url, { responseType: 'json', params: params })
      .pipe(
        map((result) => {
          let items: U[] = [];
          result.forEach((o) => {
            items.push(new typeOfU(o));
          });
          //this.getAll$.next(items);
          this.loading.next(false);
          return items;
        })
      );
  }

  /**
   * Get a list of 'U' by calling a custom action on the table's default controller
   * @param customAction - the action to call in the Web API
   * @param typeOfU - the generic type for the object array to return
   */
  // getSingleByCustomAction$<U>(
  //   customAction: string,
  //   typeOfU: Type<U> //{ new (json: U): U }
  // ): Observable<U>;

  // getSingleByCustomAction$<U>(
  //   customAction: string,
  //   typeOfU: Type<U>, //{ new (json: U): U },
  //   filter: Filter[]
  // ): Observable<U>;

  /**
   * Get a list of 'U' by calling a custom action on the table's default controller
   * @param customAction - the action to call in the Web API
   * @param typeOfU - the generic type for the object array to return
   * @param filter - a Filter array to apply in the Web API
   */
  getSingleByCustomAction$<U>(
    customAction: string,
    typeOfU: Type<U>, //{ new (json: U): U },
    filter?: Filter[]
  ): Observable<U> {
    this.loading.next(true);
    const url = `${this.remoteApiControllerFullPath}/${customAction}`;
    var params = new HttpParams();
    if (filter && filter.length > 0) {
      filter.forEach((itm) => {
        params = params.append('filter', JSON.stringify(itm));
      });
    }
    return this.http.get<U>(url, { responseType: 'json', params: params }).pipe(
      map((result) => {
        let item: U = new typeOfU(result);
        //result.forEach(o => {
        //  items.push();
        //});
        //this.getAll$.next(items);
        this.loading.next(false);
        return item;
      })
    );
  }

  postByCustomAction$<U>(customAction: string, model: typeModel<U>) {
    this.loading.next(true);
    const url = `${this.remoteApiControllerFullPath}/${customAction}`;
    return this.http.post(url, model).pipe(
      map((result) => {
        //let ret = new this.genericType(result);
        //this.source.next(ret);
        this.loading.next(false);
        return result;
      })
    );
  }

  postModelByCustomAction$<U>(
    customAction: string,
    model: U,
    typeOfU: Type<U>
  ) {
    this.loading.next(true);
    const url = `${this.remoteApiControllerFullPath}/${customAction}`;
    return this.http.post(url, model).pipe(
      map((result) => {
        let item: U = new typeOfU(result);
        //let ret = new this.genericType(result);
        //this.source.next(ret);
        this.loading.next(false);
        return item;
      })
    );
  }

  /**
   * Insert or Update a new record in the table
   * @param model - the object to upsert
   */
  upsert$(model: T): Observable<T> {
    if (this.getIdIsNew(model)) {
      // create
      return this.create$(model);
    } else {
      // update
      return this.update$(model);
    }
  }

  cancel(): void {
    this.cancelled.next(true);
  }

  updateSource(model: T) {
    this.source.next(model);
  }

  private update$(model: T): Observable<T> {
    this.loading.next(true);
    const url = `${this.remoteApiControllerFullPath}`;
    return this.http.put<T>(url, JSON.stringify(model)).pipe(
      map((result) => {
        let ret = new this.genericType(result);
        this.source.next(ret);
        this.loading.next(false);
        return ret;
      })
    );
  }

  private create$(model: T): Observable<T> {
    this.loading.next(true);
    return this.http
      .post<T>(this.remoteApiControllerFullPath, JSON.stringify(model))
      .pipe(
        map((result) => {
          let ret = new this.genericType(result);
          this.source.next(ret);
          this.loading.next(false);
          return ret;
        })
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<U>(operation = 'operation', result?: U) {
    return (error: any): Observable<U> | null => {
      console.error(error); // log to console instead

      BaseService.log(`${operation} failed: ${error.message}`);
      return null;
    };
  }

  /** Log a HeroService message with the MessageService */
  static log(message: string) {
    console.log(message);
  }

  private applyParentProperties<T>(newItem: T | any, parentProperties: any) {
    if (parentProperties) {
      Object.keys(parentProperties).forEach((field) => {
        newItem[field] = parentProperties[field];
      });
    }
    return newItem;
  }

  private getIdIsNew(model: T | any): boolean {
    return this.getIdIsNewValue(model[this.keyPropertyName]);
  }

  private getIdIsNewValue(primaryKey: number | string): boolean {
    if (typeof primaryKey == 'number') {
      return primaryKey == 0;
    } else {
      return +primaryKey.replace(new RegExp('-', 'g'), '') == 0;
    }
  }

  ExportToCSV(
    columnHeaders: any,
    data: any,
    columnHeaderNotToBeIncluded: any,
    fileName: any,
    showHeader: boolean = true,
    wrapInDoubleQuotes: boolean = true
  ) {
    this.ConvertDataToCSVFile(
      columnHeaders,
      data,
      columnHeaderNotToBeIncluded,
      fileName,
      showHeader,
      wrapInDoubleQuotes
    );
  }

  ConvertDataToCSVFile(
    HeaderColumns: any[],
    data: any,
    HeaderColumnsIgnored: any[] | undefined,
    filename: string,
    showHeader: boolean,
    wrapInDoubleQuotes: boolean
  ) {
    let csvArray: any;
    const replacer = (key: any, value: any) =>
      value === null
        ? ''
        : value.toString().indexOf('"') > 0
        ? value.replace(/"/g, ' ')
        : value; // specify how you want to handle null values here

    if (data.length > 0) {
      const header_original = Object.keys(data[0]).filter(function (item) {
        return HeaderColumnsIgnored?.indexOf(item) === -1;
      });
      const header_show = header_original.map(function (
        value: string,
        index: number
      ) {
        return HeaderColumns.filter(function (item) {
          return item.field === value;
        })[0].title;
      });
      let csv = data.map((row: any) =>
        header_original
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(',')
      );
      if (showHeader == true) {
        csv.unshift(header_show.join(','));
      }
      csvArray = csv.join('\r\n');
      if (wrapInDoubleQuotes == false) {
        csvArray = csvArray.replaceAll('"', '');
      }
    } else {
      // no record rows
      const header_show = HeaderColumns.map(function (
        value: any,
        index: number
      ) {
        return value['title'];
      });
      let csv = data.map((row: any) =>
        header_show
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(',')
      );
      csv.unshift(header_show.join(','));
      csvArray = csv.join('\r\n');
    }
    var a = document.createElement('a');
    var blob = new Blob([csvArray], { type: 'text/csv' }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
