export class BankAccountType {

  constructor()
  constructor(jsonData: BankAccountType)
  constructor(jsonData?: BankAccountType) {
    if (jsonData) {
      Object.assign(this, jsonData);
    }
    else {
      this.id = 0;
    }
  }

  id: number | undefined;
  name: string | undefined;

}


