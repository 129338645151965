export class UnitReadOnly {

	constructor() 
	constructor(jsonData: UnitReadOnly)
	constructor(jsonData?: UnitReadOnly) {
		if(jsonData) {
			Object.assign(this, jsonData);
		}
	}

	id: number | undefined;
	unitCode: string | undefined;
	uic: string | undefined;
	unitName: string | undefined;
	nbrMembers: number | undefined;
	unitStrength: number | undefined;
	battalionCode: string | undefined;
	battalionId: number | undefined;
	nbrInsured: number | undefined;
  armyGuard: boolean | undefined;
  battalionName: string | undefined;
  brigadeName: string | undefined;

}


