export class PersonChildrenReadOnly {

	constructor() 
  constructor(jsonData: PersonChildrenReadOnly)
  constructor(jsonData?: PersonChildrenReadOnly) {
		if(jsonData) {
			Object.assign(this, jsonData);
      this.dob = (jsonData.dob && jsonData.dob.toString() !== "0001-01-01T00:00:00") ? new Date(jsonData.dob) : undefined;
		}
	}

  id: number | undefined;
  personId: number | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  middleName: string | undefined;
  dob: Date | undefined;
  gender: string | undefined;
  isNew: boolean | undefined;

}


