export class PaymentType {

	constructor() 
	constructor(jsonData: PaymentType)
	constructor(jsonData?: PaymentType) {
		if(jsonData) {
			Object.assign(this, jsonData);
    }
    else {
      this.id = 0;
    }
	}

  id: number | undefined;
  pmtTypeCode: string | undefined;
  pmtType: string | undefined;
  setPmtDueDate: boolean | undefined;
  isInsurance: boolean | undefined;

}


