import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation, } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FileRestrictions, SuccessEvent, UploadEvent } from "@progress/kendo-angular-upload";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-import-membership-modal",
  templateUrl: "./import-membership-modal.component.html",
  encapsulation: ViewEncapsulation.None,
  styles: [`
  .k-actions {
    display: none;
  }
`],
})
export class ImportMembershipModalComponent implements OnInit {

  uploadSaveUrl: string =
    environment.serviceUrl + "people/importMembershipData";
  fileRestrictions: FileRestrictions = {
    allowedExtensions: [".xls", ".xlsx"],
  };

  importMembership = new FormGroup({
    upload: new FormControl(),
  });

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public model: any,
    public dialogRef: MatDialogRef<ImportMembershipModalComponent>
  ) {

  }

  ngOnInit() {
  }

  cancel(): void {
    this.model = null;
    this.dialogRef.close(false);
  }

  uploadSuccessEventHandler(e: SuccessEvent) {
    console.log("The " + e.operation + " was successful!");
    this.dialogRef.close(true);
  }

  uploadEventHandler(e: UploadEvent) {
    e.data = {
      membershipType: this.model.membershipType,
    };
  }


  public onUploadButtonClick(upload: any) {
    if (this.model.hasRecords == false) {
      upload.uploadFiles();

      return;
    }

    if (confirm("Please note, there are existing unmatched uploaded records. If you continue with this new upload, existing records will be cleared.") == false)
    {
      return;
    }

    upload.uploadFiles();
  }
}
