<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      Separation
    </h1>
  </div>
  <div fxFlex="100">
    <mat-form-field appearance="fill">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>
    <button mat-raised-button (click)="loadList()">Go</button>
  </div>
    <div fxFlex="100">
      <h3 class="mr-auto primary">
        {{ currentDate | date:'EEEE, MMMM dd, yyyy' }}
      </h3>
    </div>
    <div fxLayout="row" fxLayoutGap="8px" class="m-2">
      <kendo-grid [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [columnMenu]="{ filter: true }"
                  [groupable]="false" [pageSize]="100"
                  [pageable]="(list && (list.length > 100) ? true : false)" [rowClass]="rowCallback" class="condensed" #grid fxFlex
                  id="grdSeparation">
        <ng-template kendoGridToolbarTemplate>
          <button type="button" kendoGridPDFCommand icon="file-pdf">Export to PDF</button>
          <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
        </ng-template>
        <kendo-grid-excel fileName="Separation.xlsx" [fetchData]="this.allData"></kendo-grid-excel>
        <kendo-grid-pdf fileName="Separation.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true" [landscape]="true">
          <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
          <!--<ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
       <div class="page-template">
        <div class="header">
           <div style="float: right">
             Page {{ pageNum }} of {{ totalPages }}
           </div>
         </div>
          <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
      </div>
    </ng-template>-->
        </kendo-grid-pdf>
        <kendo-grid-column field="ssn" title="SSN" [filterable]="true">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem.ssn | mask: '000-00-0000'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="personName" title="Person" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="monthsInsufficient" title="Months Insufficient" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="payCode" title="Pay Code" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="branch" title="Branch" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="problem" title="Problem" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="sepCode" title="SepCode" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="comment" title="Comment" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="amountPaid" title="Amount Paid" [filterable]="true" format="{0:c}"></kendo-grid-column>
        <kendo-grid-column field="premium" title="Premium" [filterable]="true" format="{0:c}"></kendo-grid-column>
        <kendo-grid-column field="unitCode" title="Unit Code" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="address1" title="Address1" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="address2" title="Address2" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="city" title="City" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="state" title="State" [filterable]="true"></kendo-grid-column>
        <kendo-grid-column field="zip" title="Zip" [filterable]="true"></kendo-grid-column>
      </kendo-grid>
    </div>

  </div>
