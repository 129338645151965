<form *ngIf="model" [formGroup]="theForm" (ngSubmit)="onSubmit()" class="detail-component" novalidate>
  <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="60">
    <div fxFlex>
    </div>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Coverage Type</mat-label>
      <mat-select formControlName="coverageTypeId">
        <mat-option *ngFor="let item of this.coverageTypeIdLookups$ | async" [value]="item.key">
          {{item.value}}
        </mat-option>
      </mat-select>
      <mat-hint></mat-hint>
      <mat-error *ngIf="coverageTypeId.touched && coverageTypeId.hasError('required')">
        Coverage Type is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('coverageTypeId')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Type</mat-label>
      <input matInput formControlName="type" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="type.touched && type.hasError('required')">
        Type is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('type')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Coverage Code</mat-label>
      <input matInput formControlName="coverageCode" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="coverageCode.touched && coverageCode.hasError('required')">
        Coverage Code is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('coverageCode')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Coverage Name</mat-label>
      <input matInput formControlName="coverageName" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="coverageName.touched && coverageName.hasError('required')">
        Coverage Name is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('coverageName')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="25">
      <mat-label>Premium</mat-label>
      <input matInput formControlName="premium" type="number" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="premium.touched && premium.hasError('required')">
        Premium is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('premium')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>
   
    <div fxFlex>
      <label class="k-form-field">
        <input type="checkbox" id="isAssignable" class="k-checkboxX" formControlName="isAssignable">
        <label class="k-checkbox-label" for="isAssignable">Is Assignable?</label>
      </label>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <section class="footer">
      <button mat-raised-button color="primary" type="submit" [disabled]="theForm.pristine || (loading)">
        Save
      </button>
      <button mat-button type="button" (click)="undo()" [disabled]="theForm.pristine">
        Undo
      </button>
      <button mat-button type="button" (click)="cancel()">
        Cancel
      </button>
    </section>
  </div>
</form>
