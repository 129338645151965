
<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <div fxFlex="100">
    <h1 class="mr-auto primary">
      Coverage
      <span class="k-icon k-i-loading" *ngIf="loading"></span>
      <button mat-button color="primary" routerLink="/coverage/0">
        <mat-icon color="primary">add_circle_outline</mat-icon> Add New Coverage
      </button>
    </h1>
  </div>

  <kendo-grid #grid fxFlex="100" [kendoGridBinding]="list" [sortable]="true" [scrollable]="'scrollable'" [filterable]="false"
              [groupable]="false" [pageSize]="100" [pageable]="(list && (list.length > 100) ? true : false)" [rowClass]="rowCallback"
              class="condensed mt-5" [resizable]="true" (dataStateChange)="onDataStateChange($event)">

    <kendo-grid-column>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <button mat-button color="primary" routerLink="/coverage/{{ dataItem.id }}">
          <mat-icon>edit</mat-icon>
        </button>
      </ng-template>
    </kendo-grid-column>
    <!--<kendo-grid-column field="coverageTypeId" title="Coverage Type Id" [filterable]="true" filter="numeric"></kendo-grid-column>-->
    <kendo-grid-column field="type" title="Type" [filterable]="true"></kendo-grid-column>
    <kendo-grid-column field="coverageCode" title="Coverage Code" [filterable]="true"></kendo-grid-column>
    <kendo-grid-column field="coverageName" title="Coverage Name" [filterable]="true" filter="numeric" format="{0:c}"></kendo-grid-column>
    <kendo-grid-column field="premium" title="Premium" [filterable]="true" filter="numeric" format="{0:c}"></kendo-grid-column>
  </kendo-grid>
</div>
