import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent, DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ReportService } from '../report.service';
import { ReportBankDraft } from './report-bank-draft';
import { MediaObserver } from "@angular/flex-layout";
import { Filter } from '../../_bases/filter';
import { AuthenticationService } from '../../auth/auth.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { aggregateBy, AggregateDescriptor, AggregateResult, process, State } from '@progress/kendo-data-query';
import { FormValidation } from '../../shared/forms/form-validation';
import { InsertBankDraft } from './insert-bank-draft';

@Component({
  selector: 'app-report-bank-draft',
  templateUrl: './report-bank-draft.component.html',
  animations: [
  ]
})
export class ReportBankDraftComponent implements OnInit {

  @ViewChild(GridComponent)
  public grid!: GridComponent;

  list: ReportBankDraft[] = [];
  filters: Filter[] = [];

  public state: State = {
    skip: 0,
    take: 100,
    filter: {
      logic: "and",
      filters: [],
    },
  };

  public gridData: GridDataResult = process(this.list, this.state);
  public aggregates: AggregateDescriptor[] = [
    { field: "premium", aggregate: "sum" },
  ];

  public total: AggregateResult = aggregateBy(
    this.gridData.data,
    this.aggregates
  );

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.list, this.state);
    this.total = aggregateBy(this.gridData.data, this.aggregates);
  }

  saveDepositInfo = new FormGroup({
    date: new FormControl({ value: null, disabled: false }, Validators.required),
  });

  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: ReportService,
    public media: MediaObserver,
    public authService: AuthenticationService,
    private ngZone: NgZone,
  ) {
    this.allData = this.allData.bind(this);
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.list, {}).data,
    };

    return result;
  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {

    this.service
      .getByCustomAction$<ReportBankDraft>("getBankDraft", ReportBankDraft, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {

        console.log(data);
        this.list = data;

        this.gridData = process(this.list, this.state);
        this.total = aggregateBy(this.gridData.data, this.aggregates);
      });
  }

  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }

  exportBankDraftToCSV() {
    let columnHeaders: any[] = [
      { field: "expCol1", title: "expCol1" },
      { field: "expCol2", title: "expCol2" },
      { field: "expCol3", title: "expCol3" },
      { field: "expCol4", title: "expCol4" },
      { field: "expCol5", title: "expCol5" },
      { field: "expCol6", title: "expCol6" },
      { field: "expCol7", title: "expCol7" },
    ];

    this.service.ExportToCSV(columnHeaders, this.list, ["id", "firstName", "lastName", "ssn", "person", "bankAcctNbr", "premium", "abaNbr", "bankName"], "BankDraft.csv", false, false);
  }

  onSaveDepositInfoButtonClick() {

    if (this.saveDepositInfo?.valid) {

      //console.log(this.exportBankDraft.get('date')?.value);
      //console.log(this.list.map(m => { return m.id; }).join(","));
      
      var data: InsertBankDraft = {
        date: this.saveDepositInfo.get('date')?.value,
        personIds: this.list.map(m => { return m.id; }).join(","),
      };

      this.service.postModelByCustomAction$<InsertBankDraft>("insertBankDraft", data, InsertBankDraft)
        .pipe(
          catchError((err, caught) => {
            return of(null as any);
          })
      )
        .subscribe((savedData: any) => {
          debugger;
          alert('Data has been Saved.')
        })

    } else {
      FormValidation.validateAllFormFields(this.saveDepositInfo);
    }
  }

  private fitColumns(): void {
    this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
      this.grid.autoFitColumns();
    });
  }

  public rowCallback(context: RowClassArgs) {
    return {
    };
  }

  get date(): AbstractControl | null {
    return this.saveDepositInfo.get('date');
  }
}
