
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, Validators } from "@angular/forms"
import { ActivatedRoute, Router, ParamMap } from "@angular/router";

import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { Bank } from "../bank";
import { BankService } from "../bank.service";
import { BaseItemComponent } from '../../../_bases/base-item-component';
import { Filter } from '../../../_bases/filter';
import { FormValidation } from '../../../shared/forms/form-validation';


@Component({
  selector: 'app-bank-detail',
  templateUrl: './bank-detail.component.html',
  //styleUrls: ['./bank.component.scss']
})
export class BankComponent extends BaseItemComponent implements OnInit, OnDestroy {

  model: Bank | any;
  public loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private service: BankService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router) {
    super();
  }

  ngOnInit() {

    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => this.service.getSingleByCustomAction$<Bank>("getSingle", Bank, [new Filter("id", params.get("id"), "eq")]))
      )
      .subscribe((model: Bank | any) => {

        this.createForm(model);
      });
  }

  ngOnChanges() {
    if (this.model) {
      this.createForm(this.model);
    }
  }

  createForm(model?: Bank): void {
    if (this.theForm) {
      this.model = model;
      this.theForm.reset(this.bindForm());
    } else {
      this.theForm = this.fb.group({
        id: [model?.id, Validators.required],
        abaNbr: model?.abaNbr,
        bankName: model?.bankName,
        branch: model?.branch,
        address: model?.address,
        city: model?.city,
        state: model?.state,
        zip: model?.zip,
      });

      this.model = model;
    }
  }

  bindForm(): any {
    return {
      id: this.model.id,
      abaNbr: this.model.abaNbr,
      bankName: this.model.bankName,
      branch: this.model.branch,
      address: this.model.address,
      city: this.model.city,
      state: this.model.state,
      zip: this.model.zip,
    };
  }

  onSubmit() {
    if (this.theForm?.valid) {

      this.loading = true;
      this.service.postModelByCustomAction$<Bank>("upsert", this.prepareSave(), Bank)
        .pipe(
          catchError((err, caught) => {
            this.loading = false;
            FormValidation.parseSubmitErrors(err, this.theForm);
            return of(null as Bank | any);
          })
        )
        .subscribe((savedData: any) => {
          this.loading = false;
          if (savedData) {
            this.goBack();
          }
        })
    } else {
      FormValidation.validateAllFormFields(this.theForm);
    }
  }

  prepareSave(): Bank {
    const formModel = this.theForm.value;
    let saveModel: Bank = new Bank({
      id: formModel.id,
      abaNbr: formModel.abaNbr,
      bankName: formModel.bankName,
      branch: formModel.branch,
      address: formModel.address,
      city: formModel.city,
      state: formModel.state,
      zip: formModel.zip,
    });
    return saveModel;
  }

  cancel(): void {
    this.goBack();
  }

  goBack() {
    this.location.back();
  }

  undo(): void {
    this.theForm.reset(this.bindForm());
  }

  // Form Control Helpers
  get id(): AbstractControl {
    return this.theForm.get('id');
  }
  get abaNbr(): AbstractControl {
    return this.theForm.get('abaNbr');
  }
  get bankName(): AbstractControl {
    return this.theForm.get('bankName');
  }
  get branch(): AbstractControl {
    return this.theForm.get('branch');
  }
  get address(): AbstractControl {
    return this.theForm.get('address');
  }
  get city(): AbstractControl {
    return this.theForm.get('city');
  }
  get state(): AbstractControl {
    return this.theForm.get('state');
  }
  get zip(): AbstractControl {
    return this.theForm.get('zip');
  }

}
