import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { PersonReadOnly } from '../features/people/person-readonly';
import { PersonService } from '../features/people/person.service';
import { IdTokenClaims } from './id-token-claims';

@Injectable()
export class AuthenticationService {
  private serviceUrl = environment.serviceUrl + 'account/login';
  private serviceUrlRefresh =
    environment.serviceUrl + 'account/loginTokenRefresh';
  private loggedIn = new Subject<boolean>();
  loggedIn$ = this.loggedIn.asObservable();

  private raisePersonSubdataEvent = new Subject<boolean>();
  raisePersonSubdataEvent$ = this.raisePersonSubdataEvent.asObservable();

  private raiseNewApplicationCountChangeEvent = new Subject<any>();
  raiseNewApplicationCountChangeEvent$ =
    this.raiseNewApplicationCountChangeEvent.asObservable();

  private raisePersonNewAppBeneDataEvent = new Subject<boolean>();
  raisePersonNewAppBeneDataEvent$ =
    this.raisePersonNewAppBeneDataEvent.asObservable();

  private raisePersonNewAppChildDataEvent = new Subject<boolean>();
  raisePersonNewAppChildDataEvent$ =
    this.raisePersonNewAppChildDataEvent.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private msalService: MsalService,
    private personService: PersonService
  ) {}

  eventPersonSubDataEventRaised(value: boolean) {
    this.raisePersonSubdataEvent.next(value);
  }

  eventPersonNewAppBeneDataEventRaised(value: boolean) {
    this.raisePersonNewAppBeneDataEvent.next(value);
  }

  eventPersonNewAppChildDataEventRaised(value: boolean) {
    this.raisePersonNewAppChildDataEvent.next(value);
  }

  eventNewApplicationCountChangeRaised() {
    this.personService
      .getByCustomAction$<PersonReadOnly>(
        'getAllNewApplications',
        PersonReadOnly,
        []
      )
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      )
      .subscribe((data: PersonReadOnly[]) => {
        var newApplication = {
          allNewApplications: data.filter((f) => f.isUntouchedEditApplication == false).length,
          wetInkApplications: data.filter((f) => f.isWetInkApplication == true && f.isUntouchedEditApplication == false)
            .length,
          digitalInkApplications: data.filter(
            (f) => f.isWetInkApplication == false && f.isUntouchedEditApplication == false
          ).length,
          untouchedEditApplication: data.filter(
            (f) => f.isUntouchedEditApplication == true
          ).length,
        };
        this.raiseNewApplicationCountChangeEvent.next(newApplication);
      });
  }

  dashboard(): void {
    this.router.navigateByUrl('/reports/bankdraft');
  }

  logout(): void {
    localStorage.clear();
    this.msalService.logout();
    this.loggedIn.next(false);
  }

  public get isAuthenticated(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  get fullName(): string | undefined {
    if (this.isAuthenticated) {
      const authInfo = this.msalService.instance.getAllAccounts();
      if (authInfo && authInfo.length > 0) {
        return authInfo[0].name;
      }
    }

    return '';
  }

  isInRole(role: string): boolean {
    if (this.isAuthenticated) {
      const roles: string[] = [];
      const authInfo = this.msalService.instance.getAllAccounts();
      if (authInfo) {
        authInfo.forEach((f: AccountInfo) => {
          const accountInfo = new IdTokenClaims(f.idTokenClaims);
          if (accountInfo.roles) {
            accountInfo.roles.forEach((r) => {
              roles.push(r);
            });
          }
        });
        return roles.indexOf(role) > -1;
      }
    }
    return false;
  }

  get username(): string | undefined {
    if (this.isAuthenticated) {
      const authInfo = this.msalService.instance.getAllAccounts();
      if (authInfo && authInfo.length > 0) {
        return authInfo[0].username;
      }
    }

    return '';
  }

  get userRoles(): any[] {
    if (this.isAuthenticated) {
      const roles: string[] = [];
      const authInfo = this.msalService.instance.getAllAccounts();

      if (authInfo) {
        authInfo.forEach((f: AccountInfo) => {
          const accountInfo = new IdTokenClaims(f.idTokenClaims);
          if (accountInfo.roles) {
            accountInfo.roles.forEach((r) => {
              roles.push(r);
            });
          }
        });

        return roles;
      }
    }

    return [];
  }

  get isAdmin(): boolean {
    return this.isInRole('Admin');
  }

  get isVolunteer(): boolean {
    return this.isInRole('Volunteer');
  }

  get isInsured(): boolean {
    return this.isInRole('Insured');
  }
}
