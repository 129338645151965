
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, Validators } from "@angular/forms"
import { ActivatedRoute, Router, ParamMap } from "@angular/router";

import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { PopupSettings } from '@progress/kendo-angular-dropdowns';

import { Group } from "../group";
import { GroupService } from "../group.service";
import { BaseItemComponent } from '../../../_bases/base-item-component';
import { Filter } from '../../../_bases/filter';
import { FormValidation } from '../../../shared/forms/form-validation';


@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
})
export class GroupComponent extends BaseItemComponent implements OnInit, OnDestroy {

  model: Group | any;
  public loading: boolean = false;


  constructor(
    private fb: FormBuilder,
    private service: GroupService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router) {
    super();
  }

  ngOnInit() {

    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => this.service.getSingleByCustomAction$<Group>("getSingle", Group, [new Filter("id", params.get("id"), "eq")]))
      )
      .subscribe((model: Group | any) => {

        this.createForm(model);
      });
  }

  ngOnChanges() {
    if (this.model) {
      this.createForm(this.model);
    }
  }

  createForm(model?: Group): void {
    if (this.theForm) {
      this.model = model;
      this.theForm.reset(this.bindForm());
    } else {
      this.theForm = this.fb.group({
        id: [model?.id, Validators.required],
        groupName: model?.groupName,
        insGroup: model?.insGroup,
        isMembership: model?.isMembership,
        //xCommitteeNbr: model.xCommitteeNbr,
        //xCommitteeYear: model.xCommitteeYear,
        //xReportHeading1: model.xReportHeading1,
        //xReportHeading2: model.xReportHeading2,
      });

      this.model = model;
    }
  }

  bindForm(): any {
    return {
      id: this.model.id,
      groupName: this.model.groupName,
      insGroup: this.model.insGroup,
      isMembership: this.model.isMembership,
      //xCommitteeNbr: this.model.xCommitteeNbr,
      //xCommitteeYear: this.model.xCommitteeYear,
      //xReportHeading1: this.model.xReportHeading1,
      //xReportHeading2: this.model.xReportHeading2,
    };
  }

  onSubmit() {
    if (this.theForm?.valid) {

      this.loading = true;
      this.service.postModelByCustomAction$<Group>("upsert", this.prepareSave(), Group)
        .pipe(
          catchError((err, caught) => {
            this.loading = false;
            FormValidation.parseSubmitErrors(err, this.theForm);
            return of(null as Group | any);
          })
        )
        .subscribe((savedData: any) => {
          this.loading = false;
          if (savedData) {
            this.goBack();
          }
        })
    } else {
      FormValidation.validateAllFormFields(this.theForm);
    }
  }

  prepareSave(): Group {
    const formModel = this.theForm.value;

    let saveModel: Group = new Group({
      id: formModel.id,
      groupName: formModel.groupName,
      insGroup: formModel.insGroup,
      isMembership: formModel.isMembership,
      //xCommitteeNbr: formModel.xCommitteeNbr,
      //xCommitteeYear: formModel.xCommitteeYear,
      //xReportHeading1: formModel.xReportHeading1,
      //xReportHeading2: formModel.xReportHeading2,
    });
    return saveModel;
  }

  cancel(): void {
    this.goBack();
  }

  goBack() {
    this.location.back();
  }

  undo(): void {
    this.theForm.reset(this.bindForm());
  }

  // Form Control Helpers
  get id(): AbstractControl {
    return this.theForm.get('id');
  }
  get groupName(): AbstractControl {
    return this.theForm.get('groupName');
  }
  get insGroup(): AbstractControl {
    return this.theForm.get('insGroup');
  }
  get isMembership(): AbstractControl {
    return this.theForm.get('isMembership');
  }
  //get xCommitteeNbr(): AbstractControl {
  //  return this.theForm.get('xCommitteeNbr');
  //}
  //get xCommitteeYear(): AbstractControl {
  //  return this.theForm.get('xCommitteeYear');
  //}
  //get xReportHeading1(): AbstractControl {
  //  return this.theForm.get('xReportHeading1');
  //}
  //get xReportHeading2(): AbstractControl {
  //  return this.theForm.get('xReportHeading2');
  //}

}
