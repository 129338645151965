export class ReportDelinquency {

  constructor();
  constructor(jsonData?: ReportDelinquency) {
    Object.assign(this, jsonData);
  }

  public id: number | undefined;
  public piqname: string | undefined;
  public uic: string | undefined;
  public piqatchuic: string | undefined;
  public unitName: string | undefined;
  public gR_ABBR_CODE: string | undefined;
  public battalionName: string | undefined;
  public brigadeName: string | undefined;
}
