<div fxLayout="column" fxLayoutGap="8px" class="m-2">
  <h2 mat-dialog-title>

    <span *ngIf="this.model && this.model.isFromFindMyPolicy == true">Your Policy Details</span>
    <span *ngIf="this.model && this.model.isFromFindMyPolicy != true">Am I Insured?</span>
  </h2>

  <div class="mat-dialog-content" style="display:block !important">
    <div fxLayout="column" fxLayoutGap="8px" class="m-2">
      <div fxFlex *ngIf="this.model">
        <div fxLayout="column" fxLayoutGap="8px" *ngIf="this.model.isInsured == true">
          <!--Yes you are insured. For full details, or to make changes to your policy, please contact us.
  <button mat-button (click)="navigateToContact()">
    Contact Information
  </button>-->
          <div fxFlex="100">
            <h1>{{ this.model.firstName }} {{ this.model.middleName }} {{ this.model.lastName }}</h1>
          </div>

          <div fxLayout="row">
            <div fxFlex="30">
              <strong>Address</strong>
            </div>
            <div fxFlex="70">
              {{ this.model.address1 }} {{ this.model.address2 }}
            </div>
          </div>

          <div fxLayout="row">
            <div fxFlex="30">
              <strong>City</strong>
            </div>
            <div fxFlex="70">
              {{ this.model.city }}
            </div>
          </div>

          <div fxLayout="row">
            <div fxFlex="30">
              <strong>State</strong>
            </div>
            <div fxFlex="70">
              {{ this.model.state }}
            </div>
          </div>

          <div fxLayout="row">
            <div fxFlex="30">
              <strong>Zip</strong>
            </div>
            <div fxFlex="70">
              {{ this.model.zip }}
            </div>
          </div>

          <div fxLayout="row">
            <div fxFlex="30">
              <strong>Primary Phone</strong>
            </div>
            <div fxFlex="70">
              {{ this.model.primaryPhone }}
            </div>
          </div>

          <div fxLayout="row">
            <div fxFlex="30">
              <strong>Secondary Phone</strong>
            </div>
            <div fxFlex="70">
              {{ this.model.secondaryPhone }}
            </div>
          </div>

          <div fxLayout="row">
            <div fxFlex="30">
              <strong>Email</strong>
            </div>
            <div fxFlex="70">
              {{ this.model.email }}
            </div>
          </div>

          <div fxLayout="row">
            <div fxFlex="30">
              <strong>Military Email</strong>
            </div>
            <div fxFlex="70">
              {{ this.model.militaryEmail }}
            </div>
          </div>

          <!--<div fxLayout="row">
    <div fxFlex="30">
      <strong>SSN</strong>
    </div>
    <div fxFlex="70">
      {{ this.model.ssn }}
    </div>
  </div>-->

          <div fxLayout="row">
            <div fxFlex="30">
              <strong>Life Member #</strong>
            </div>
            <div fxFlex="70">
              {{ this.model.lifeMemberNbr }}
            </div>
          </div>

          <div fxLayout="row">
            <div fxFlex="30">
              <strong>Payment Type</strong>
            </div>
            <div fxFlex="70">
              {{ this.model.pmtType }}
            </div>
          </div>

          <div fxLayout="row">
            <div fxFlex="30">
              <strong>Unit Code</strong>
            </div>
            <div fxFlex="70">
              {{ this.model.unitCode }}
            </div>
          </div>

          <div fxLayout="row">
            <div fxFlex="30">
              <strong>Unit Name</strong>
            </div>
            <div fxFlex="70">
              {{ this.model.unitName }}
            </div>
          </div>

          <div fxLayout="row">
            <div fxFlex="30">
              <strong>Coverage Type</strong>
            </div>
            <div fxFlex="70">
              {{ this.model.coverageTypeName }}
            </div>
          </div>

          <table fxFlex="90">
            <tr>
              <td width="25">
                &nbsp;
              </td>
              <td width="25" class="k-text-right">
                <strong>Coverage Amount</strong>
              </td>
              <td width="25" class="k-text-right">
                <strong>Premium Amount</strong>
              </td>
              <td width="20" class="k-text-right">
                <strong>DOB</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Member</strong>
              </td>
              <td class="k-text-right">
                {{  this.model.memberCoverageName | currency }}
              </td>
              <td class="k-text-right">
                {{  this.model.memberPremium | currency }}
              </td>
              <td class="k-text-right">
                {{  this.model.dob | date: 'MM/dd/yyyy' }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Spouse</strong>
              </td>
              <td class="k-text-right">
                {{  this.model.spouseCoverageName | currency }}
              </td>
              <td class="k-text-right">
                {{  this.model.spousePremium | currency }}
              </td>
              <td class="k-text-right">
                {{  this.model.spouseDOB | date: 'MM/dd/yyyy' }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Dependent</strong>
              </td>
              <td class="k-text-right">
                {{  this.model.dependentCoverageName | currency }}
              </td>
              <td class="k-text-right">
                {{  this.model.dependentPremium | currency }}
              </td>
              <td>
                &nbsp;
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <strong>Total</strong>
              </td>
              <td class="k-text-right">
                {{  this.model.totalPremium | currency }}
              </td>
              <td>
                &nbsp;
              </td>
            </tr>
          </table>

          <div fxLayout="row" fxFlex="90">
            <div fxFlex="25">
              <strong>Beneficiaries</strong>
            </div>
          </div>

          <table fxFlex="90">
            <tr *ngFor="let itm of this.model.beneficiaries; let i = index">
              <td width="40%">
                {{ itm.beneficiary }}
              </td>
              <td width="20%">
                {{ itm.relationship }}
              </td>
              <td width="20%">
                {{ itm.percentage }}%
              </td>
              <td width="20%">
                {{ itm.beneficiaryDate | date: 'MM/dd/yyyy' }}
              </td>
            </tr>
          </table>

          <button mat-raised-button color="primary" (click)="navigateToEditPolicy()">
            Edit Policy
          </button>

        </div>
        <div fxLayout="column" *ngIf="this.model.isInsured != true">
          <p>
            We didn't find a policy under this name and SSN. Would you like to enroll now?
          </p>
          <p>
            <button mat-raised-button color="primary" (click)="navigateToApplication()">
              Complete an Application
            </button>
          </p>
          <p>
            For additional information:
          </p>
          <p>
            <button mat-raised-button color="accent" (click)="navigateToContact()">
              Contact Information
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
  <section class="footer">
    <button mat-stroked-button type="button" (click)="cancel()">
      Close
    </button>
  </section>
</div>
<style>
  table {
    border: 1px solid black;
    border-collapse: collapse;
  }

  tr {
    border: 1px solid black;
  }

  td {
    border: 1px solid black;
  }

</style>
