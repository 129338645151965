import { Component, OnInit, NgZone, ViewChild, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil, map, switchMap, take, catchError } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { RowClassArgs, GridComponent } from '@progress/kendo-angular-grid';

import { PopupSettings } from '@progress/kendo-angular-dropdowns';

import { filter } from '@progress/kendo-data-query/dist/npm/transducers';
import { Lookup } from '../../../lookups/lookup';
import { LookupService } from '../../../lookups/lookup.service';
import { AuthenticationService } from '../../../auth/auth.service';
import { Filter } from '../../../_bases/filter';
import { PersonCommentReadOnly } from '../personcomment-readonly';
import { PersonCommentService } from '../personcomment.service';

@Component({
  selector: 'app-personcomments-list',
  templateUrl: './personcomment-list.component.html',
  animations: [
  ]
})
export class PersonCommentListComponent implements OnInit {

  @ViewChild(GridComponent)
  public grid!: GridComponent;

  list: PersonCommentReadOnly[] = [];
  @Input() filters: Filter[] = [];
  @Input() newItemProperties: any;
  public loading: boolean = false;

  public formGroup: FormGroup | any;
  private editedRowIndex: number | undefined;

  private increment: number = 0;
  createPersonCommentsFormGroup = (dataItem: PersonCommentReadOnly) =>
    new FormGroup({
      id: new FormControl(dataItem.id),
      personId: new FormControl(dataItem.personId, Validators.required),
      commentText: new FormControl(dataItem.commentText, Validators.required),
      addedBy: new FormControl(dataItem.addedBy),
      addedDate: new FormControl(dataItem.addedDate, Validators.required),
      isNew: new FormControl(dataItem.isNew == true ? true : false),
    });


  protected ngUnsubscribe: Subject<boolean> = new Subject();

  constructor(
    private service: PersonCommentService,
    private ngZone: NgZone,
    private lookupService: LookupService,
    private authService: AuthenticationService,
  ) {

  }

  ngOnInit(): void {
    this.loadList();
  }

  loadList() {
    this.service
      .getByCustomAction$<PersonCommentReadOnly>("list", PersonCommentReadOnly, this.filters)
      .pipe(
        catchError((err, caught) => {
          return of(null as any);
        })
      ).subscribe(data => {
        this.list = data;
      });
  }

  public onDataStateChange(evt: any): void {
    this.fitColumns();
  }


  private fitColumns(): void {
    //this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
    //  this.grid.autoFitColumns();
    //});
  }

  public rowCallback(context: RowClassArgs) {
    return {
      // blocked: context.dataItem.isBlocked
    };
  }

  public addPersonCommentsHandler(evt: any) {
    this.closePersonCommentsEditor(evt.sender);

    this.increment += 1;

    this.formGroup = this.createPersonCommentsFormGroup({
      id: this.increment,
      personId: this.filters[0].value,
      commentText: '',
      addedDate: new Date(),
      addedBy: '',
      isNew: true,
    });

    evt.sender.addRow(this.formGroup);
  }

  public editPersonCommentsHandler({ sender, rowIndex, dataItem }: { sender: any, rowIndex: any, dataItem: any }) {
    this.closePersonCommentsEditor(sender);
    this.formGroup = this.createPersonCommentsFormGroup(dataItem);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelPersonCommentsHandler({ sender, rowIndex }: { sender: any, rowIndex: any }) {
    this.closePersonCommentsEditor(sender, rowIndex);
  }

  public savePersonCommentsHandler({ sender, rowIndex, formGroup, isNew }: { sender: any, rowIndex: any, formGroup: any, isNew: any }) {

    const personComments: PersonCommentReadOnly = formGroup.value;

    if (isNew) {

      if (this.list == null) {

        this.list = [];
      }

      this.list.push(personComments);
    }
    else {
      this.list.forEach(o => {

        if (o.id == personComments.id) {
          o.commentText = personComments.commentText;
          o.addedBy = personComments.addedBy;
          o.addedDate = personComments.addedDate;
          o.isNew = personComments.isNew;
        }
      });
    }

    setTimeout(() => {
      this.authService.eventPersonSubDataEventRaised(true);
    }, 500);
    this.grid.data = this.list;
    sender.closeRow(rowIndex);
  }

  public removePersonCommentsHandler({ dataItem }: { dataItem: any }) {
    let tempList: PersonCommentReadOnly[] = [];

    this.list.forEach(o => {

      if (o.id != dataItem.id) {
        tempList.push(o);
      }
    });

    setTimeout(() => {
      this.authService.eventPersonSubDataEventRaised(true);
    }, 500);
    this.list = tempList;
    this.grid.data = this.list;
  }

  private closePersonCommentsEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
}
