import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ReportBankDraftComponent } from './report-bank-draft/report-bank-draft.component';

const routes: Routes = [
  //{ path: 'aaa', component: ReportBankDraftComponent, canActivate: [MsalGuard] },

]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class ReportRoutingModule { }

