<form *ngIf="model" [formGroup]="theForm" (ngSubmit)="onSubmit()" class="detail-component" novalidate>
  <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="60">
    <div fxFlex>
    </div>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>ABA Nbr</mat-label>
      <input matInput formControlName="abaNbr" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="abaNbr.touched && abaNbr.hasError('required')">
        ABANbr is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('abaNbr')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Bank Name</mat-label>
      <input matInput formControlName="bankName" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="bankName.touched && bankName.hasError('required')">
        Bank Name is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('bankName')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Branch</mat-label>
      <input matInput formControlName="branch" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="branch.touched && branch.hasError('required')">
        Branch is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('branch')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Address</mat-label>
      <input matInput formControlName="address" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="address.touched && address.hasError('required')">
        Address is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('address')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>City</mat-label>
      <input matInput formControlName="address" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="city.touched && city.hasError('required')">
        City is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('city')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>State</mat-label>
      <input matInput formControlName="address" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="state.touched && state.hasError('required')">
        State is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('state')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Zip</mat-label>
      <input matInput formControlName="zip" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="zip.touched && zip.hasError('required')">
        Zip is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('zip')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <section class="footer">
      <button mat-raised-button color="primary" type="submit" [disabled]="theForm.pristine || (loading)">
        Save
      </button>
      <button mat-button type="button" (click)="undo()" [disabled]="theForm.pristine">
        Undo
      </button>
      <button mat-button type="button" (click)="cancel()">
        Cancel
      </button>
    </section>
  </div>
</form>
