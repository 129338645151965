export class IdTokenClaims {

  constructor(jsonData?: any) {
    Object.assign(this, jsonData);
  }

  public aio: any | undefined;
  public aud: string | undefined;
  public email: string | undefined;
  public exp: number | undefined;
  public iat: number | undefined;
  public iss: string | undefined;
  public name: string | undefined;
  public nbf: number | undefined;
  public nonce: string | undefined;


  public oid: string | undefined;
  public preferred_username: string | undefined;
  public rh: string | undefined;
  public roles: string[] | undefined;

  public sub: string | undefined;
  public tid: string | undefined;
  public uti: string | undefined;
  public ver: number | undefined;


}
