<form *ngIf="model" [formGroup]="theForm" (ngSubmit)="onSubmit()" class="detail-component" novalidate>
  <div fxLayout="column" fxLayoutGap="8px" class="m-2" fxFlex="60">
    <div fxFlex>
    </div>

    <!--<mat-form-field appearance="fill" fxFlex="25">
      <mat-label>Brigade</mat-label>
      <input matInput formControlName="brigadeId" type="number" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="brigadeId.touched && brigadeId.hasError('required')">
        Brigade is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('brigadeId')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>-->

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Brigade Code</mat-label>
      <input matInput formControlName="brigadeCode" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="brigadeCode.touched && brigadeCode.hasError('required')">
        Brigade Code is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('brigadeCode')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="25">
      <mat-label>Battalion Seq Nbr</mat-label>
      <input matInput formControlName="battalionSeqNbr" type="number" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="battalionSeqNbr.touched && battalionSeqNbr.hasError('required')">
        Battalion Seq Nbr is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('battalionSeqNbr')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100">
      <mat-label>Battalion Name</mat-label>
      <input matInput formControlName="battalionName" />
      <mat-hint></mat-hint>
      <mat-error *ngIf="battalionName.touched && battalionName.hasError('required')">
        Battalion Name is <strong>required</strong>
      </mat-error>

      <mat-error *ngFor="let e of fieldErrors('battalionName')">
        {{ e.params }}
      </mat-error>
    </mat-form-field>

    <!--<div fxFlex>
    <kendo-textbox-container floatingLabel="x Seq Nbr">
      <input kendoTextBox formControlName="xSeqNbr" />

      <mat-error *ngFor="let e of fieldErrors('xSeqNbr')">
        {{ e.params }}
      </mat-error>
    </kendo-textbox-container>
    <mat-hint></mat-hint>
  </div>
  <div fxFlex>
    <kendo-textbox-container floatingLabel="x T ID">
      <input kendoTextBox formControlName="xT_ID" />

      <mat-error *ngFor="let e of fieldErrors('xT_ID')">
        {{ e.params }}
      </mat-error>
    </kendo-textbox-container>
    <mat-hint></mat-hint>
  </div>-->
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <section class="footer">
      <button mat-raised-button color="primary" type="submit" [disabled]="theForm.pristine || (loading)">
        Save
      </button>
      <button mat-button type="button" (click)="undo()" [disabled]="theForm.pristine">
        Undo
      </button>
      <button mat-button type="button" (click)="cancel()">
        Cancel
      </button>
    </section>
  </div>
</form>
