import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { LoadingComponent } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationService } from './auth/auth.service';
import { RoleGuard } from './auth/role.guard';
import { BankAccountTypeService } from './features/bankaccounttypes/bankaccounttype.service';
import { BankService } from './features/banks/bank.service';
import { BattalionService } from './features/battalions/battalion.service';
import { BrigadeService } from './features/brigades/brigade.service';
import { CoverageService } from './features/coverages/coverage.service';
import { CoverageTypeService } from './features/coveragetypes/coveragetype.service';
import { FeaturesModule } from './features/features.module';
import { GroupService } from './features/groups/group.service';
import { JumpsService } from './features/jumps/jumps.service';
import { NavigatorService } from './features/navigator/navigator.service';
import { PaymentTypeService } from './features/paymenttypes/paymenttype.service';
import { PersonService } from './features/people/person.service';
import { RelationshipService } from './features/relationships/relationship.service';
import { UnitService } from './features/units/unit.service';
import { VolunteerNetworkService } from './features/volunteernetwork/volunteernetwork.service';
import { LookupService } from './lookups/lookup.service';
import { AmIInsuredService } from './public/amiinsured/amiinsured.service';
import { NewApplicationService } from './public/newapplication/newapplication.service';
import { PublicModule } from './public/public.module';
import { ReportsModule } from './reports/report.module';
import { ReportService } from './reports/report.service';
import { NavComponent } from './shared/nav/nav.component';
import { KendoControlsModule } from './_modules/kendo-controls.module';
import { MaterialControlsModule } from './_modules/material-controls.module';

//import { LoginFailedComponent } from './login-failed/login-failed.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: any, message: any, piiEnabled: any) {
  //console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      redirectUri: environment.redirectUri,
      postLogoutRedirectUri: environment.redirectUri,
      authority: 'https://login.microsoftonline.com/' + environment.tenant, // auth against the tenant app to ensure roles are present
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", [
  //   "user.read"
  // ]);
  protectedResourceMap.set(environment.serviceUrl + '*', [
    environment.scope + 'access_as_user',
  ]);
  //protectedResourceMap.set("https://localhost:5001/api/*", [
  //  "api://pm.welshtechnologies.com/" +
  //  "e25bad5c-cb51-4397-bb87-e9607e3b15f6" +
  //  "/access_as_user",
  //]);
  //protectedResourceMap.set("https://pm.welshtechnologies.com/api/*", [
  //  "api://pm.welshtechnologies.com/" +
  //  "e25bad5c-cb51-4397-bb87-e9607e3b15f6" +
  //  "/access_as_user",
  //]);
  //protectedResourceMap.set("https://localhost:4200/api/BillLines", []);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    //loginFailedRoute: '/login-failed',
    loginFailedRoute: '/',
  };
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule.withConfig({}),
    InputsModule,
    MaterialControlsModule,
    KendoControlsModule,
    HttpClientModule,
    ReportsModule,
    FeaturesModule,
    PublicModule,
  ],
  declarations: [AppComponent, NavComponent],
  entryComponents: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    DatePipe,
    MsalService,
    MsalGuard,
    RoleGuard,
    MsalBroadcastService,
    AuthenticationService,
    ReportService,
    JumpsService,
    NavigatorService,
    LookupService,
    NewApplicationService,
    PersonService,
    AmIInsuredService,
    BankAccountTypeService,
    BankService,
    BattalionService,
    BrigadeService,
    CoverageService,
    CoverageTypeService,
    GroupService,
    PaymentTypeService,
    RelationshipService,
    UnitService,
    VolunteerNetworkService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
