import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../_bases/base-service.service';
import { Filter } from '../../_bases/filter';

@Injectable()
export class AmIInsuredService extends BaseService<Filter, Filter> {
  constructor(http: HttpClient) {
    super(http, 'newapplication', 'id', true);
  }
}
